import { appConfigKey } from "../../../../config/Config";
import { GenerarMnFormatoRegimenRequest, MnFormatoRegimenUpdateRequest } from "../../../models/RequestManager/solicitud-formato-regimen-request.model";
import { MnInformeTerceraRequest, MnSaveInformeTerceraRequest } from "../../../models/RequestManager/solicitud-informel-tercera-request.model";
import MasterManager from "../../../services/origination/wapimaster.service";
import RequestManager from "../../../services/origination/wapirequest.service";
import ServicesProvider from "../../../services/support/wapiservicesprovider";

export const Lista = {
    ObtenerUsuarioCargo: async (identUsuario: number) => {
        const lista: any = [];
        try {
            const response: any = await MasterManager.GetMnUsuarioCargo(identUsuario);

            if (response.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER CARGOS DE USUARIOS.');
        }

        return lista
    },
    ObtenerSegmento: async () => {
        const lista: any = [];
        try {
            const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
                "v_LIST_IDENT_TABLA": "25"
            });

            if (response.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push({ id: element.genericA_VALO_CAMPO, description: element.genericA_DESCM_CAMPO });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER SEGMENTO.');
        }

        return lista
    },
    GirosNegocios: async () => {
        const lista: any = [];
    
        try {
          const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
            "v_LIST_IDENT_TABLA": appConfigKey.keyIdMargenGiroNegocio
          });
    
          if (response.status !== 200) {
            return lista;
          }
    
          const { content } = response.data;
    
          if (content) {
            content.forEach((element: any) => {
              lista.push({ id: element.genericA_VALO_CAMPO, description: element.genericA_DESCM_CAMPO, margen: Number(element.genericA_CODL_CAMPO_AUX) });
            });
              
            if (lista.length > 0) {
                lista.sort((a: any, b: any) => a.description.localeCompare(b.description));
            }
          }
        } catch (error) {
          console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS GIRO DE NEGOCIO.');
        }
    
        return lista;
    },
    ObtenerTipoDeuda: async () => {
        const lista: any = [];

        try {
            const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
                "v_LIST_IDENT_TABLA": "28"
            });

            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;

            if (content) {
                content.forEach((element: any) => {
                    lista.push({ id: element.genericA_CODL_CAMPO, description: element.genericA_DESCM_CAMPO });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER TIPO DEUDA.');
        }

        return lista;
    },
    ObtenerCantidadCuotas: async (productoUId: number) => {
        const lista: any = [];
        try {
            const response: any = await ServicesProvider.ObtenerCantidadCuotas(productoUId)

            if (response.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.listaValores.sdtsBTValor.forEach((element: any) => {
                    lista.push({ id: element.valor, description: element.valor });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS PLAZOS DE MESES.');
        }

        return lista;
    },
    GetMnEvaluacionSolicitud: async (identSolicitud: number) => {
        const lista: any = [];
        try {
            let response: any = await MasterManager.GetMnEvaluacionSolicitud(identSolicitud);

            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return lista;

    },
    ObtenerAcciones: async () => {
        const lista: any = [];
        try {
            const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
                "v_LIST_IDENT_TABLA": "46"
            });

            if (response.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push({ id: element.genericA_VALO_CAMPO, description: element.genericA_DESCM_CAMPO });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER ACCIONES.');
        }

        return lista
    },
    GetSolicitudExcepcionLiberacionById: async (identSolicitudExcepcion: number) => {
        const lista: any = [];
        try {
            let response: any = await RequestManager.GetSolicitudExcepcionLiberacionById({
                "identSolicitudExcepcion": identSolicitudExcepcion
            })
            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return lista;

    },

    GetSolicitudExcepcionLiberacionByIdSolicitud: async (identSolicitud: number) => {
        const lista: any = [];
        try {
            let response: any = await RequestManager.GetSolicitudExcepcionLiberacionByIdSolicitud({
                "identSolicitud": identSolicitud
            })
            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return lista;

    },
    GetSolicitudEtapas: async (identSolicitudEtapas: any, identSolicitud: any) => {
        const lista: any = [];

        try {
            let response: any = await RequestManager.GetSolicitudEtapas({
                "identSolicitudEtapas": identSolicitudEtapas,
                "identSolicitud": identSolicitud
            })

            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;

            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER ETAPAS POR SOLICITUD');
        }

        return lista;
    }
}
export const General = {
    ValidacionSolicitud: async (idenT_SOLICITUD: number, idenT_USUARIO: number, idenT_FLUJO: number) => {
        try {
            const response = await RequestManager.GetValidacionSolicitud({
                "idenT_SOLICITUD": idenT_SOLICITUD,
                "idenT_USUARIO": idenT_USUARIO,
                "idenT_FLUJO": idenT_FLUJO
            });
            if (response.status === 200) {
                return response;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE VALIDACIÓN SOLICITUD.');
            return null;
        }
    },
    GetMnEvaluacionSolicitudInsertLog: async (
        identSolicitud: number,
        tipoLlamada: number,
        jsonRequest: string,
        jsonResponse: string,
        identUsuarioCreador: number,
        identPerfil: number,
        tipoFlujo: number) => {
        try {
            const response = await MasterManager.GetMnEvaluacionSolicitudInsertLog({
                "identSolicitud": identSolicitud,
                "tipoLlamada": tipoLlamada,
                "jsonRequest": jsonRequest,
                "jsonResponse": jsonResponse,
                "identUsuarioCreador": identUsuarioCreador,
                "identPerfil": identPerfil,
                "tipoFlujo": tipoFlujo
            });
            if (response.status === 200) {
                return response;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACIÓN SOLICITUD INSERTAR LOG.');
            return null;
        }
    },
    GetMnEvaluacionSolicitudInsert: async (
        identSolicitud: number, step: number, modelicaRequest: string, modelicaResponse: string) => {
        try {
            const response = await MasterManager.GetMnEvaluacionSolicitudInsert(identSolicitud, step, modelicaRequest, modelicaResponse);
            if (response?.status === 200) {
                return response;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACIÓN SOLICITUD INSERTAR.');
            return null;
        }
    },
    Modelica: async (body: any) => {
        try {
            const response = await ServicesProvider.ModellicaMotorEvaluacion(body);
            if (response.status === 200) {
                return response;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACION DE MODELICA.');
            return null;
        }
    },


    ObtenerExtExperianConfigFC: async (tipoDeuda: any, deudaReal: any) => {
        const lista: any = [];
        try {
            const response: any = await MasterManager.GetExtExperianConfigFC(tipoDeuda, deudaReal);

            if (response.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER CONFIGURACIONES FC DE EXPERIAN.');
        }
        return lista
    },

    GetSolicitudExcepcionLiberacionInsert: async (data: any) => {
        try {
            const response: any = await RequestManager.GetSolicitudExcepcionLiberacionInsert(data);
            const { isValid } = response.data;
            if (response) {
                return isValid;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO LIBERAR DE EXCEPCIÓN.');
            return false;
        }
    },
    GetSolicitudEtapasInsert: async (data: any) => {
        try {
            const response = await RequestManager.GetSolicitudEtapasInsert(data);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            return null;
        }
    },
    GetSolicitudEtapasUpdate: async (data: any) => {
        try {
            const response = await RequestManager.GetSolicitudEtapasUpdate(data);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            return null;
        }
    },
    GenerarMnFormatoByRegimen: async (body: GenerarMnFormatoRegimenRequest) => {
        try {
            const response = await RequestManager.GenerarMnFormatoByRegimen(body);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE GENERAR FORMATO POR REGIMEN.');
            return null;
        }
    },

    UpdateMnFormatoRegimen: async (body: MnFormatoRegimenUpdateRequest) => {
        try {
            const response = await RequestManager.UpdateMnFormatoRegimen(body);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE ACTUALIZAR FORMATO REGIMEN.');
            return null;
        }
    },

    GetMnEvaluacionScoringTercera: async (body: MnInformeTerceraRequest) => {
        try {
            const response = await RequestManager.GetMnEvaluacionScoringTercera(body);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACION SCORING TERCERA.');
            return null;
        }
    },

    SaveMnEvaluacionScoringTercera: async (body: MnSaveInformeTerceraRequest) => {
        try {
            const response = await RequestManager.SaveMnEvaluacionScoringTercera(body);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DE GUARDAR SCORING TERCERA.');
            return null;
        }
    },
}