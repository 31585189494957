import { appSecurityConfiguration } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { TMnAplicacionDominioDto } from "../../../../models/MasterManager/aplicacion-dominio-response.model";
import { TMnAutonomiaCargoDto } from "../../../../models/MasterManager/autonomia-cargo-response.model";
import { MnUsuarioInsert } from "../../../../models/MasterManager/crear-usuario-request.model";
import { MnUsuarioEdit } from "../../../../models/MasterManager/editar-usuario-request.model";
import { TMnPerfilDto } from "../../../../models/MasterManager/perfil-response.mode";
import { MnUsuarioDto } from "../../../../models/MasterManager/usuarios-response.model";
import { SelectItem } from "../../../../models/Shared/select-item.model";
import { UserModel } from "../interfaces/IUsersManagement";

const ConvertidorHelper = {
    PasswordPlaceHolder: "******",

    ObtenerUserModel(dto: MnUsuarioDto): UserModel {
        return {
            identUsuario: dto.idenT_USUARIO,
            identPerfil: dto.idenT_PERFIL,
            apellidoMaterno: dto.apellidO_MATERNO,
            apellidoPaterno: dto.apellidO_PATERNO,
            nombres: dto.nombres,
            idTipoDocumento: dto.iD_TIPO_DOCUMENTO,
            nroDocumento: dto.nrO_DOCUMENTO,
            username: dto.username?.replace(GeneralHelper.GetSufijo(), ''),
            dominioId: dto.iD_DOMINIO,
            emailInterno: dto.emaiL_INTERNO,
            telefono: dto.telefono,
            telefonoMovil: dto.telefonO_MOVIL,
            esBloqueado: dto.bloqueado,
            esActivo: dto.inD_ESTADO,
            identCargo: dto.idenT_CARGO ?? 0,
            cargo: dto.descC_CARGO ?? "",
            password: "",
            esCambiarPassword: false,
        };
    },

    async ObtenerMnUsuarioInsert(user: UserModel): Promise<MnUsuarioInsert> {
        const usuarioInsert = new MnUsuarioInsert();

        usuarioInsert.idenT_PERFIL = user.identPerfil;
        usuarioInsert.apellidO_MATERNO = user.apellidoMaterno;
        usuarioInsert.apellidO_PATERNO = user.apellidoPaterno;
        usuarioInsert.nombres = user.nombres;
        usuarioInsert.iD_TIPO_DOCUMENTO = user.idTipoDocumento;
        usuarioInsert.nrO_DOCUMENTO = user.nroDocumento;
        usuarioInsert.username = user.username + GeneralHelper.GetSufijo();
        usuarioInsert.emaiL_INTERNO = user.emailInterno;
        usuarioInsert.telefono = user.telefono;
        usuarioInsert.telefonO_MOVIL = user.telefonoMovil;
        usuarioInsert.usU_CREACION = appSecurityConfiguration.SessionId;
        usuarioInsert.idenT_CARGO = user.identCargo === 0 ? null : user.identCargo;
        usuarioInsert.descC_CARGO = user.cargo === "" ? null : user.cargo;
        usuarioInsert.guiD_IDENTIFICADOR = null;
        usuarioInsert.iD_DOMINIO = user.dominioId;
        usuarioInsert.password = user.password;
        usuarioInsert.forcE_CHANGE_PASSWORD = user.esCambiarPassword;

        return usuarioInsert;
    },

    async ObtenerMnUsuarioEdit(user: UserModel): Promise<MnUsuarioEdit> {
        const usuarioEdit = new MnUsuarioEdit();

        usuarioEdit.idenT_USUARIO = user.identUsuario;
        usuarioEdit.idenT_PERFIL = user.identPerfil;
        usuarioEdit.apellidO_MATERNO = user.apellidoMaterno;
        usuarioEdit.apellidO_PATERNO = user.apellidoPaterno;
        usuarioEdit.nombres = user.nombres;
        usuarioEdit.iD_TIPO_DOCUMENTO = user.idTipoDocumento;
        usuarioEdit.nrO_DOCUMENTO = user.nroDocumento;
        usuarioEdit.username = user.username + GeneralHelper.GetSufijo();
        usuarioEdit.bloqueado = user.esBloqueado;
        usuarioEdit.emaiL_INTERNO = user.emailInterno;
        usuarioEdit.telefono = user.telefono;
        usuarioEdit.telefonO_MOVIL = user.telefonoMovil;
        usuarioEdit.USU_MODIF = appSecurityConfiguration.SessionId;
        usuarioEdit.idenT_CARGO = user.identCargo === 0 ? null : user.identCargo;
        usuarioEdit.descC_CARGO = user.cargo === "" ? null : user.cargo;
        usuarioEdit.inD_ESTADO = user.esActivo;
        usuarioEdit.iD_DOMINIO = user.dominioId;
        usuarioEdit.password = user.password;
        usuarioEdit.forcE_CHANGE_PASSWORD = user.esCambiarPassword;

        return usuarioEdit;
    },

    ObtenerDominiosModel(listaGenerica: TMnAplicacionDominioDto[]): SelectItem[] {
        let lista: SelectItem[] = [];
        listaGenerica.forEach((element: TMnAplicacionDominioDto) => {
            lista.push({ id: element.idenT_APLICACION_DOMINIO, description: element.descC_DOMINIO });
        });
        return lista;
    },

    ObtenerPerfilesModel(listaGenerica: TMnPerfilDto[]): SelectItem[] {
        let lista: SelectItem[] = [];
        listaGenerica.forEach((element: TMnPerfilDto) => {
            lista.push({ id: element.idenT_PERFIL, description: element.desC_PERFIL });
        });
        return lista;
    },

    ObtenerAutonomiaCargosModel(listaGenerica: TMnAutonomiaCargoDto[]): SelectItem[] {
        let lista: SelectItem[] = [];
        listaGenerica.forEach((element: TMnAutonomiaCargoDto) => {
            lista.push({ id: element.idenT_CARGO, description: element.descC_CARGO });
        });
        return lista;
    },

}

export default ConvertidorHelper;