import * as React from "react";
import MainLayout from "../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout";
import { Tab, Tabs } from "@mui/material";
import CardTitleStart from "../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart";
import CardDebst from "../13.10.-base/components/CardDedts";
import CardMembers from "../13.10.-base/components/CardMembers";
import CardResult from "../13.10.-base/components/CardResult";
import ScoringTitular from "../13.30.-scoring/scoringTitular";
import ScoringConyuge from "../13.30.-scoring/scoringConyuge";
import ScoringCliente from "../13.30.-scoring/scoringCliente";
import FinanciamientoDatosCondiciones from "../13.40.-financiamiento/financiamientoDatosCondiciones";
import ResultadosDocumentos from "../13.50.-Resultados/resultadosDocumentos";
import ResultadosEvaluacion from "../13.50.-Resultados/resultadosEvaluacion";
import ResultadosHistorial from "../13.50.-Resultados/resultadosHistorial";
import { Button } from "@mui/joy";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import ScoringClasificacionSBS from "../13.30.-scoring/scoringClasificacionSBS";
import ScoringDatosReevaluacion from "../13.30.-scoring/scoringDatosReevaluacion";
import ScoringInformacionEntidadesSBS from "../13.30.-scoring/scoringInformacionEntidadesSBS";
import CardTitleSummary from "../13.20.-cardTitleStart/cardTitleSummary";
import CardTitleScore from "../13.20.-cardTitleStart/CardTitleScore";
import RequestManager from "../../../services/origination/wapirequest.service";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import MasterManager from "../../../services/origination/wapimaster.service";
import {
  Clasificacion,
  ClasificacionDto,
  reqClasificacionDto,
} from "../../../models/ManagerProfile/ClasificacionDto";
import { ResultadoEvaluacionResumen } from "../../../models/ManagerProfile/ResultadoEvaluacionResumen";
import { ConvertidorHelper } from "../13.30.-scoring/helpers/ConvertidorHelper";
import { Solicitud } from "../../../models/ManagerProfile/Solicitud";
import {
  InformacionEntidad,
  InformacioEntidadDefault,
} from "../../../models/ManagerProfile/InformacionEntidad";
import { Debts, DebtsDefault } from "../../../models/ManagerProfile/Debts";
import {
  Members,
  MembersDefault,
  Users,
} from "../../../models/ManagerProfile/Members";
import {
  OtrosIngresos,
  Titular,
  TitularDefault,
} from "../../../models/ManagerProfile/Titular";
import {
  Conyuge,
  ConyugeDefault,
} from "../../../models/ManagerProfile/Conyuge";
import {
  Integrante,
  Ingreso,
  CargaFinanciera,
} from "../../../models/ManagerProfile/Reevaluar";
import {
  DatosReevaluacion,
  DatosReevaluacionDefault,
} from "../../../models/ManagerProfile/DatosReevaluacion";
import "./creditEvaluation.scss";
import {
  DatosCondicionFinanciamiento,
  DatosCondicionFinanciamientoDefault,
} from "../../../models/ManagerProfile/Financiamiento_DatosCondicion";
import {
  appConfigDecisionMotor,
  appConfigEstadosProcesoEtapaAnalisisCredito,
  appConfigEtapaAnalisisCredito,
  appConfigKey,
  appConfigMatrizTerceraTablas,
  appConfigProcesoEtapaAnalisisCredito,
  appConfigReportes,
  appSecurityConfiguration,
} from "../../../../config/Config";
import {
  ResultadoFinanciamiento,
  ResultadoFinanciamientoDefault,
} from "../../../models/ManagerProfile/ResultadoFinanciamiento";
import { Dialog } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { useNavigate } from "react-router-dom";
import {
  ResultadoEvaluacionDto,
  ResultadoEvalucionDtoDefault,
} from "../../../models/ManagerProfile/ResultadoEvaluacion";
import ValidacionResultadosEvaluacionHelper from "../13.10.-base/helpers/ValidacionResultadosEvaluacionHelper";
import ReportManager from "../../../services/support/wapireport";
import CardResultReevaluar from "./components/CardResultReevaluar";
import CardAlerts from "./components/CardAlerts";
import { General, Lista } from "./obtenerServicios";
import { useEffect, useState } from "react";
import ReleaseExceptionDialog from "./components/ReleaseExceptionDialog";
import { GenerarMnFormatoRegimenRequest, MnFormatoRegimenUpdateRequest } from "../../../models/RequestManager/solicitud-formato-regimen-request.model";
import { ConvertidorTablaHelper } from "../../1.-shared/1.6.-table/1.6.1.-regimen/helpers/ConvertidorTablaHelper";
import { InformacionTercera } from "../../../models/ManagerProfile/InformacionTercera";
import MatrizBalanceGeneral from "../13.60.-matriz/matrizBalanceGeneral";
import MatrizDatosCredito from "../13.60.-matriz/matrizDatosCredito";
import MatrizFlujoCaja from "../13.60.-matriz/matrizFlujoCaja";
import { MnInformeTerceraRequest, MnSaveInformeTerceraRequest } from "../../../models/RequestManager/solicitud-informel-tercera-request.model";
import { AppException } from "../../../models/Shared/app-exception";
import DocumentManager from "../../../services/business/document.service";
import { BTAsociarDocumentoDigitalRequest } from "../../../models/ServicesProviderManager/documento-digital-request.model";
import { TMnDocumentacionDocumentoInsertRequest } from "../../../models/MasterManager/documentacion-documento-request.model";
import FileHelper from "../../../helpers/FileHelper";

const START_POSITION = 0;

const CreditEvaluation = () => {
  const { solicitudId, tipo } = useParams();

  const [title, setTitle] = useState("Evaluación crediticia - Scoring");
  const [tabsHeader, setTabsHeader] = React.useState([
    { id: 1, label: "SCORING", value: 0, order: 1, visible: true },
    { id: 2, label: "MATRIZ DE EVALUACIÓN", value: 1, order: 3, visible: false },
    { id: 3, label: "FINANCIAMIENTO", value: 2, order: 2, visible: true },
    { id: 4, label: "RESULTADOS", value: 3, order: 4, visible: true }
  ]);
  const [tabsPosition, setTabsPosition] = useState(START_POSITION);
  const handleTabsPosition = (e: any, value: any) => {
    setTabsPosition(value);
    let title = "Evaluación crediticia - ";
    setTitle(
      value === 0
        ? title + "Scoring"
        : value === 1
          ? title + "Matriz de Evaluación"
          : value === 2
            ? title + "Financiamiento"
            : title + "Resultados"
    );
  };
  const [debts, setDebts] = useState<Debts>(DebtsDefault);
  const [members, setMembers] = useState<Members>(MembersDefault);
  const [solicitud, setSolicitud] = useState<Solicitud | number>(0);
  const [tipoCambio, setTipoCambio] = useState(0);
  const [resultadoEvaluacionResumen, setResultadoEvaluacionResumen] = useState<
    ResultadoEvaluacionResumen | number
  >(0);
  const [informacionTercera, setInformacionTercera] = React.useState<InformacionTercera | number>(0);
  const [informacionTerceraBefore, setInformacionTerceraBefore] = React.useState<InformacionTercera | number>(0);
  const [estadoCivilTitular, setEstadoCivilTitular] = useState(0);
  const [categoriaLaboralConyuge, setCategoriaLaboralConyuge] = useState(0);
  const [defaultValueTab, setDefaultValueTab] = useState(0);
  const [esVisibleSDR, setEsVisibleSDR] = useState(true);
  const [showConyugue, setShowConyugue] = useState(0);
  const [titular, setTitular] = useState<Titular>(TitularDefault);
  const [titularBeforce, setTitularBeforce] = useState<Titular>(TitularDefault);
  const [conyuge, setConyuge] = useState<Conyuge>(ConyugeDefault);
  const [conyugeBeforce, setConyugeBeforce] = useState<Conyuge>(ConyugeDefault);
  const [posicionCliente, setPosicionCliente] = useState({
    posicionCliente: [],
  });
  const [clasificacion, setClasificacion] = useState<Clasificacion | number>(0);
  const [tipoIntegranteClasif, setTipoIntegranteClasif] = useState<
    string | null
  >("TITULAR");
  const [datosReevaluacionTitular, setDatosReevaluacionTitular] =
    useState<DatosReevaluacion>(DatosReevaluacionDefault);
  const [datosReevaluacionConyuge, setDatosReevaluacionConyuge] =
    useState<DatosReevaluacion>(DatosReevaluacionDefault);
  const [datosReevaluacionOrigenTitular, setDatosReevaluacionOrigenTitular] =
    useState<any | null>(null);
  const [datosReevaluacionOrigenConyuge, setDatosReevaluacionOrigenConyuge] =
    useState<any | null>(null);
  const [informacionEntidad, setInformacionEntidad] =
    useState<InformacionEntidad>(InformacioEntidadDefault);
  const [
    datosCondicionFinanciamientoInicial,
    setDatosCondicionFinanciamientoInicial,
  ] = useState<DatosCondicionFinanciamiento>(
    DatosCondicionFinanciamientoDefault
  );
  const [datosCondicionFinanciamiento, setDatosCondicionFinanciamiento] =
    useState<DatosCondicionFinanciamiento>(DatosCondicionFinanciamientoDefault);
  const [
    datosCondicionFinanciamientoOriginal,
    setDatosCondicionFinanciamientoOriginal,
  ] = useState<DatosCondicionFinanciamiento>(
    DatosCondicionFinanciamientoDefault
  );
  const [resultadosDocumento, setResultadosDocumento] = useState([]);
  const [resultadosEvaluacion, setResultadosEvaluacion] = useState({
    resultado: "",
    destinoCredito: "",
    comentario: "",
  });
  const [resultadoFinanciamiento, setResultadoFinanciamiento] =
    useState<ResultadoFinanciamiento>(ResultadoFinanciamientoDefault);

  const [esModoLectura, setEsModoLectura] = useState(false);
  const [flgResultadosHistorial, setFlgResultadosHistorial] = useState(false);
  const handleNewResultadoHistorico = () => {
    setFlgResultadosHistorial(!flgResultadosHistorial);
  };
  const [listaTipoDeuda, setListaTipoDeuda] = useState([]);
  const [clasificacionTitular, setClasificacionTitular] = useState<
    Clasificacion | number
  >(0);
  const [clasificacionConyuge, setClasificacionConyuge] = useState<
    Clasificacion | number
  >(0);
  const [clasificacionTitularOriginal, setClasificacionTitularOriginal] =
    useState<Clasificacion | number>(0);
  const [clasificacionConyugeOriginal, setClasificacionConyugeOriginal] =
    useState<Clasificacion | number>(0);
  const [listaSegmento, setListaSegmento] = useState([]);
  const [cuotas, setCuotas] = useState([]);
  const [esTitularJuridico, setEsTitularJuridico] = useState(false);
  const [listaGiroNegocio, setListaGiroNegocio] = useState([]);

  //Llamada numero 1
  const [requestMotorBeforceStepOne, setRequestMotorBeforceStepOne] =
    useState(null);
  const [responseMotorBeforceStepOne, setResponseMotorBeforceStepOne] =
    useState(null);
  const [requestAMotorfterStepOne, setRequestMotorAfterStepOne] =
    useState(null);
  const [responseMotorAfterStepOne, setResponseMotorAfterStepOne] =
    useState(null);

  //Llamada numero 2
  const [requestMotorBeforceStepTwo, setRequestMotorBeforceStepTwo] =
    useState(null);
  const [responseMotorBeforceStepTwo, setResponseMotorBeforceStepTwo] =
    useState(null);
  const [requestAMotorfterStepTwo, setRequestMotorAfterStepTwo] =
    useState(null);
  const [responseMotorAfterStepTwo, setResponseMotorAfterStepTwo] =
    useState(null);
  const [responseMotorMotivo, setResponseMotorMotivo] =
    useState(null);

  //Llamada numero 3
  const [requestMotorBeforceStepThree, setRequestMotorBeforceStepThree] =
    useState(null);
  const [responseMotorBeforceStepThree, setResponseMotorBeforceStepThree] =
    useState(null);
  const [requestAMotorfterStepThree, setRequestMotorAfterStepThree] =
    useState(null);
  const [responseMotorAfterStepThree, setResponseMotorAfterStepThree] =
    useState(null);

  const [esVisibleResultReevaluar, setEsVisibleResultReevaluar] =
    useState(false);

  const [modelUsuarioCargo, setModelUsuarioCargo] = useState<any>(null);

  const [show, setShow] = useState(false);
  const [openReleaseExceptionDialog, setOpenReleaseExceptionDialog] = useState(false);
  const [documentos, setDocumentos] = useState<any>([]);

  const [nvlRiesgo, setnvlRiesgo] = useState(null);
  const [stateCuotaDoble, setstateCuotaDoble] = useState<boolean>(false);
  const [isVisibleVerSustentoExcepcion, setIsVisibleVerSustentoExcepcion] = useState(false);
  const [isVisibleAprobadoConDocumentacion, setIsVisibleAprobadoConDocumentacion] = useState(true);
  const [isVisibleAprobado, setIsVisibleAprobado] = useState(true);
  const [esSeparadoBienes, setEsSeparadoBienes] = React.useState(false);


  const [matrizRegimen, setMatrizRegimen] = React.useState<any | null>(null);
  const [matrizRegimenBefore, setMatrizRegimenBefore] = React.useState<any | null>(null);
  const [capacidadPagoTabla, setCapacidadPagoTabla] = React.useState<any[] | null>(null);
  const [perdidaGananciaTabla, setPerdidaGananciaTabla] = React.useState<any[] | null>(null);
  const [balanceGeneralTabla, setBalanceGeneralTabla] = React.useState<any[] | null>(null);
  const [flujoCajaTabla, setFlujoCajaTabla] = React.useState<any[] | null>(null);

  let navigate = useNavigate();

  const getMatrizRegimen = async (identRegimen: any, identSolicitud: number) => {
    let formatoRequest = new GenerarMnFormatoRegimenRequest();
    formatoRequest.identRegimen = identRegimen ?? 0;
    formatoRequest.identSolicitud = identSolicitud;
    formatoRequest.identUsuario = appSecurityConfiguration.SessionUserId;
    formatoRequest.identPerfil = appSecurityConfiguration.SessionPerfil;
    const response = await General.GenerarMnFormatoByRegimen(formatoRequest);
    if (!response) {
      toast.error(appConfigKey.keyMsjErrorObtenerFormatoRegimen, { duration: appConfigKey.keyDurationToast });
      setMatrizRegimen(null);
      return;
    }
    const newMatrizRegimen = ConvertidorTablaHelper.ObtenerValoresPorPerfil(response);
    const newCapacidadPagoTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.CapacidadPago);
    const newPerdidaGananciaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.EstadoPerdidasGanancias);
    const newBalanceGeneralTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.BalanceGeneral);
    const newFlujoCajaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.FlujoCaja);

    setMatrizRegimen(newMatrizRegimen);
    setMatrizRegimenBefore(JSON.parse(JSON.stringify(newMatrizRegimen)));
    setCapacidadPagoTabla(newCapacidadPagoTabla);
    setPerdidaGananciaTabla(newPerdidaGananciaTabla);
    setBalanceGeneralTabla(newBalanceGeneralTabla);
    setFlujoCajaTabla(newFlujoCajaTabla);

    return newMatrizRegimen;
  }

  const buildMatrizRegimen = (matrizRegimenBefore: any = null) => {
    if (!matrizRegimen) {
      setCapacidadPagoTabla(null);
      setPerdidaGananciaTabla(null);
      setBalanceGeneralTabla(null);
      setFlujoCajaTabla(null);
      return;
    }

    const newMatrizRegimen = ConvertidorTablaHelper.ObtenerValoresPorMatriz(matrizRegimenBefore ?? matrizRegimen);
    const newCapacidadPagoTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.CapacidadPago);
    const newPerdidaGananciaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.EstadoPerdidasGanancias);
    const newBalanceGeneralTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.BalanceGeneral);
    const newFlujoCajaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizPerfil, appConfigMatrizTerceraTablas.FlujoCaja);

    setMatrizRegimen(newMatrizRegimen);
    setCapacidadPagoTabla(newCapacidadPagoTabla);
    setPerdidaGananciaTabla(newPerdidaGananciaTabla);
    setBalanceGeneralTabla(newBalanceGeneralTabla);
    setFlujoCajaTabla(newFlujoCajaTabla);

  }

  const restoreMatriz = () => {
    if (matrizRegimenBefore) {
      buildMatrizRegimen(JSON.parse(JSON.stringify(matrizRegimenBefore)))
    }
    if (informacionTerceraBefore) {
      setInformacionTercera(GeneralHelper.ClonarObject(informacionTerceraBefore));
    }
  }

  const getObtenerSegmento = async () => {
    const response: any = await Lista.ObtenerSegmento();
    setListaSegmento(response);
  };

  const getObtenerGiroNegocio = async () => {
    const response: any = await Lista.GirosNegocios();
    setListaGiroNegocio(response);
  }

  const getObtenerTipoDeuda = async () => {
    const response: any = await Lista.ObtenerTipoDeuda();
    setListaTipoDeuda(response);
  };

  const getObtenerCuotas = async (productoUId: number) => {
    const response: any = await Lista.ObtenerCantidadCuotas(productoUId);
    setCuotas(response);
  };

  const GetMnEvaluacionSolicitudInsert = async (
    identSolicitud: number,
    step: number,
    modelicaRequest: string,
    modelicaResponse: string
  ) => {
    const response: any = await General.GetMnEvaluacionSolicitudInsert(
      identSolicitud,
      step,
      modelicaRequest,
      modelicaResponse
    );
    return response;
  };

  const getWorkflowClasificacion = async (request: reqClasificacionDto) => {
    await RequestManager.GetMnSolicitudRCC(request).then((response: any) => {
      const mdlInformacionEntidad = ConvertidorHelper.ObtenerInformacionEntidad(
        response.data.content.resultado
      );
      setInformacionEntidad(mdlInformacionEntidad);

      const mdlClasificacion = ConvertidorHelper.ObtenerClasificacion(
        response.data.content.resultado
      );
      setClasificacion(mdlClasificacion);
    });
  };

  const getWorkflowClasificacionInicial = async (
    requestTitular: any,
    requestConyuge: any
  ) => {
    if (requestTitular) {
      await RequestManager.GetMnSolicitudRCC(requestTitular).then(
        (response: any) => {
          const mdlInformacionEntidad =
            ConvertidorHelper.ObtenerInformacionEntidad(
              response.data.content.resultado
            );
          setInformacionEntidad(mdlInformacionEntidad);
          const mdlClasificacion = ConvertidorHelper.ObtenerClasificacion(
            response.data.content.resultado
          );
          setClasificacion(GeneralHelper.ClonarObject(mdlClasificacion));
          setClasificacionTitular(GeneralHelper.ClonarObject(mdlClasificacion));
          setClasificacionTitularOriginal(
            GeneralHelper.ClonarObject(mdlClasificacion)
          );
        }
      );
    }

    if (requestConyuge) {
      await RequestManager.GetMnSolicitudRCC(requestConyuge).then(
        (response: any) => {
          const mdlClasificacion = ConvertidorHelper.ObtenerClasificacion(
            response.data.content.resultado
          );
          setClasificacionConyuge(GeneralHelper.ClonarObject(mdlClasificacion));
          setClasificacionConyugeOriginal(
            GeneralHelper.ClonarObject(mdlClasificacion)
          );
        }
      );
    }
  };

  const getMnEvaluacionScoringGestor = async () => {
    await RequestManager.GetMnEvaluacionScoringGestor({
      idenT_SOLICITUD: solicitudId,
    })
      .then(async (response: any) => {
        setDebts({ ...response.data.content.debts });
        setNewMEN(
          response?.data?.content?.debts?.menS
            ? response?.data?.content?.debts?.menS
            : 0
        );
        const mdlUsers = ConvertidorHelper.ObtenerUser(
          response.data.content.members.users
        );
        setMembers(mdlUsers);
        const mdlResultadoFinanciamiento =
          ConvertidorHelper.ObtenerResultadoFinanciamiento(
            response.data.content.resultadoFinanciamiento
          );

        setResultadoFinanciamiento(mdlResultadoFinanciamiento);
        setNewCuota(mdlResultadoFinanciamiento?.montoCuota);

        const mdlSolicitud = ConvertidorHelper.ObtenerSolicitud(
          response.data.content.solicitud
        );
        setSolicitud(mdlSolicitud);

        setReqResultadoEvaluacion({
          ...reqResultadoEvaluacion,
          idenT_SOLICITUD: Number(solicitudId),
          idenT_DESTINO_CREDITO: mdlSolicitud.idDestinoCredito,
          idenT_USUARIO_CREADOR: Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
          ),
          idenT_TIPO_RESULTADO: 0,
          descc_DETALLE: "",
          idenT_CREDITO_TIPO: 0,
          idenT_SUBTIPO: 0,
          numerO_VISOR: null
        });

        setEsModoLectura(
          mdlSolicitud.idEstadoSolicitud !==
          appConfigKey.keyIdEstadoSolicitudPorEvaluar &&
          mdlSolicitud.idEstadoSolicitud !==
          appConfigKey.keyIdEstadoSolicitudEnEvaluacion &&
          mdlSolicitud.idEstadoSolicitud !==
          appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion &&
          mdlSolicitud.idEstadoSolicitud !==
          appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion &&
          mdlSolicitud.idEstadoSolicitud !==
          appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia &&
          mdlSolicitud.idEstadoSolicitud !==
          appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia &&
          mdlSolicitud.idEstadoSolicitud !==
          appConfigKey.KeyIdEstadoSolicitudRegistrado
        );

        setTipoCambio(mdlSolicitud.tipoCambio);
        getObtenerCuotas(mdlSolicitud.idProducto);
        setEsTitularJuridico(mdlSolicitud.esTitularJuridico)
        const mdlResultadoEvaluacionResumen =
          ConvertidorHelper.ObtenerResultadoEvaluacion(
            response.data.content.resultEvaluacion
          );
        setResultadoEvaluacionResumen(mdlResultadoEvaluacionResumen);

        if (titular.ingresos.idcategoriaLaboral !== 20130) {
          setstateCuotaDoble(true);
        }


        if (mdlSolicitud.esTitularJuridico || (!mdlSolicitud.esTitularJuridico && response?.data?.content?.titularScoringGestor?.ingresos?.idcategoriaLaboral === appConfigKey.keyId3raCategoríaPerRUC)) {
          setIsVisibleAprobado(false);
          const matriz = await getMatrizRegimen(mdlSolicitud.identRegimen, Number(mdlSolicitud.idSolicitud));

          const tabHeader = tabsHeader.find(x => x.id === 2); //MATRIZ DE EVALUACIÓN
          if (tabHeader) tabHeader.visible = true;
          setTabsHeader(tabsHeader);

          await RequestManager.GetMnEvaluacionScoringTercera({
            identSolicitud: solicitudId,
          } as MnInformeTerceraRequest).then(informeResponse => {
            const informeContent = informeResponse.data.content;
            setInformacionTercera({ ...informeContent });
            setInformacionTerceraBefore(GeneralHelper.ClonarObject(informeContent));

            const debtsTmp = ConvertidorHelper.ObtenerDebts(informeContent.capacidadEndeudamiento, informeContent.estadoGananciaPerdidas);
            setDebts(debtsTmp);
            setNewMEN(debtsTmp.menS);
            setNewCuota(informeContent.capacidadEndeudamiento.cuota);

            let titularTmp: any = ConvertidorHelper.ObtenerTitularInformeTercera(mdlSolicitud.esTitularJuridico, { ...response.data.content.titularScoringGestor }, informeContent.datosTitular);
            if (matriz) {
              const utilidadNeta = ConvertidorTablaHelper.ObtenerValorLabelPorTablaPosicion(matriz.matrizPerfil, appConfigMatrizTerceraTablas.CapacidadPago, 'Utilidad Neta', 1)
              titularTmp.ingresos.ingresoNeto = utilidadNeta ?? 0;
            }

            setTitular({ ...titularTmp });
            setTitularBeforce({ ...titularTmp });

          });

        } else {
          setTitular({ ...response.data.content.titularScoringGestor });
          setTitularBeforce({ ...response.data.content.titularScoringGestor });
        }

        setConyuge({ ...response.data.content.conyugueScoringGestor });
        setConyugeBeforce({ ...response.data.content.conyugueScoringGestor });

        if (response.data.content.titularScoringGestor) {
          if (response.data.content.titularScoringGestor?.ingresos) {
            if (response.data.content.titularScoringGestor.ingresos?.separacionBienes) {
              setEsSeparadoBienes(response.data.content.titularScoringGestor.ingresos?.separacionBienes === appConfigKey.keySeparacionBienesSI);
            }
          }
        }

        if (response.data.content.conyugueScoringGestor) {
          if (response.data.content.conyugueScoringGestor?.ingresos) {
            if (
              response.data.content.conyugueScoringGestor.ingresos
                ?.idcategoriaLaboral
            ) {
              setCategoriaLaboralConyuge(
                response.data.content.conyugueScoringGestor.ingresos
                  .idcategoriaLaboral
              );
            }
            if (
              response.data.content.conyugueScoringGestor.ingresos
                ?.idCategoriaLaboralAnterior
            ) {
              setShowConyugue(
                response.data.content.conyugueScoringGestor.ingresos
                  .idCategoriaLaboralAnterior
              );
            }
          }
        }

        setPosicionCliente({ ...response.data.content.posicionCliente });
        const rccTitular = response.data.content.rccTitular;
        const rccConyuge = response.data.content.rccConyuge;
        setDatosReevaluacionTitular(GeneralHelper.ClonarObject(rccTitular));
        setDatosReevaluacionConyuge(GeneralHelper.ClonarObject(rccConyuge));
        const titularResultado = ConvertidorHelper.ObtenerResultado(
          response?.data?.content?.rccTitular
        );
        const conyugeResultado = ConvertidorHelper.ObtenerResultado(
          response?.data?.content?.rccConyuge
        );
        setDatosReevaluacionOrigenTitular(
          GeneralHelper.ClonarObject(titularResultado)
        );
        setDatosReevaluacionOrigenConyuge(
          GeneralHelper.ClonarObject(conyugeResultado)
        );

        const objClasificacion = mdlUsers.users.find(
          (item) =>
            item.nroDocumento === mdlSolicitud.numeroDocumento.toString().trim()
        );
        setEstadoCivilTitular(objClasificacion?.idEstadoCivil || 0);

        const dtoClasificacionTitular: ClasificacionDto = {
          solicitud: String(solicitudId),
          tipo_integrante: objClasificacion?.tipoIntegrante || "titular",
          documento: mdlSolicitud.numeroDocumento,
          tipo_documento: objClasificacion?.tipoDocumento || "DNI",
        };
        const reqClasificacionTitular: reqClasificacionDto = {
          request: dtoClasificacionTitular,
        };

        await getWorkflowClasificacionInicial(reqClasificacionTitular, null);

        if (
          objClasificacion?.idEstadoCivil ===
          appConfigKey.keyIdEstadoCivilCasado ||
          objClasificacion?.idEstadoCivil ===
          appConfigKey.keyIdEstadoCivilConviviente
        ) {
          let Conyuge: any = mdlUsers.users.find(
            (item) =>
              item.tipoIntegrante.trim() ===
              appConfigKey.keyDescripTipoIntegranteConyuge
          );

          const dtoClasificacionCoyuge: ClasificacionDto = {
            solicitud: String(solicitudId),
            tipo_integrante: appConfigKey.keyDescripTipoIntegranteConyuge,
            documento: Conyuge?.nroDocumento,
            tipo_documento: Conyuge?.tipoDocumento,
          };
          const reqClasificacionConyuge: reqClasificacionDto = {
            request: dtoClasificacionCoyuge,
          };
          await getWorkflowClasificacionInicial(null, reqClasificacionConyuge);
        }

        const mdlDatosCondicionFinanciamiento =
          ConvertidorHelper.ObtenerDatosCondicionFinanciamiento(
            response.data.content.datosCondicionFinanciamiento
          );
        setDatosCondicionFinanciamientoInicial(mdlDatosCondicionFinanciamiento);
        setDatosCondicionFinanciamiento(mdlDatosCondicionFinanciamiento);
        setDatosCondicionFinanciamientoOriginal(
          GeneralHelper.ClonarObject(mdlDatosCondicionFinanciamiento)
        );
        setResultadosDocumento({ ...response.data.content.resultados });
        setResultadosEvaluacion({
          ...response.data.content.resultados.resultadoEvaluacion,
        });
        setShow(true);
      })
      .catch((error) => {
        setShow(true);
        //myowasp(error.message);
      });
  };

  const [newMEN, setNewMEN] = useState(0);
  const [newCuota, setNewCuota] = useState(0);
  const [newCFC, setNewCFC] = useState(0);
  const esReevaluarCalculoMENyCFC = async (): Promise<any> => {
    try {

      if (!requestMotorBeforceStepOne || !requestMotorBeforceStepTwo) {
        toast.error(
          appConfigKey.keyMsjErrorObtenerEvaluacionMotor,
          { duration: appConfigKey.keyDurationToast }
        );
        return;
      }

      const validarNewReevaluarTitular =
        !ValidacionResultadosEvaluacionHelper.NewReevaluarTitular(
          titular.ingresos
        );
      if (validarNewReevaluarTitular) return;

      if (titular?.otrosIngresos?.length > 0) {
        const validarNewReevaluarOtrosIngresos =
          !ValidacionResultadosEvaluacionHelper.NewReevaluarOtrosIngresos(
            titular?.otrosIngresos,
            appConfigKey.keyDescripTipoIntegranteTitular
          );
        if (validarNewReevaluarOtrosIngresos) return;
      }

      const validarNewReevaluarClasificacion =
        !ValidacionResultadosEvaluacionHelper.NewReevaluarClasificacion(
          clasificacionTitular,
          "Titular"
        );
      if (validarNewReevaluarClasificacion) return;

      if (
        ((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
          (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria
      ) {
        const validarNewReevaluarConyuge =
          !ValidacionResultadosEvaluacionHelper.NewReevaluarConyuge(
            conyuge.ingresos
          );
        if (validarNewReevaluarConyuge) return;

        if (conyuge?.otrosIngresos?.length > 0) {
          const validarNewReevaluarOtrosIngresos =
            !ValidacionResultadosEvaluacionHelper.NewReevaluarOtrosIngresos(
              conyuge?.otrosIngresos,
              "Cónyuge"
            );
          if (validarNewReevaluarOtrosIngresos) return;
        }

        const validarNewReevaluarClasificacion =
          !ValidacionResultadosEvaluacionHelper.NewReevaluarClasificacion(
            clasificacionConyuge,
            "Cónyuge"
          );
        if (validarNewReevaluarClasificacion) return;
      }

      const validarNewDatosCondicionFinanciamiento =
        !ValidacionResultadosEvaluacionHelper.NewDatosCondicionFinanciamiento(
          datosCondicionFinanciamiento,
          datosCondicionFinanciamientoOriginal
        );
      if (validarNewDatosCondicionFinanciamiento) return;

      let esIgualDeudaOriginalVSModificadaTitular: boolean =
        ValidacionResultadosEvaluacionHelper.EsIgualDeudaOriginalVSModificada(
          clasificacionTitular,
          datosReevaluacionTitular?.resultado
        );
      let esIgualDeudaOriginalVSModificadaConyuge: boolean = true;

      if (
        ((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
          (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria
      ) {
        esIgualDeudaOriginalVSModificadaConyuge =
          ValidacionResultadosEvaluacionHelper.EsIgualDeudaOriginalVSModificada(
            clasificacionConyuge,
            datosReevaluacionConyuge?.resultado
          );
      }

      let modificacion_Deudas_Externas: any = {};
      modificacion_Deudas_Externas.flag_Modificacion_Deudas_Externas = 1;
      if (
        esIgualDeudaOriginalVSModificadaTitular &&
        esIgualDeudaOriginalVSModificadaConyuge
      ) {
        modificacion_Deudas_Externas.flag_Modificacion_Deudas_Externas = 0;
      }

      if (typeof clasificacionTitular !== "number") {
        modificacion_Deudas_Externas.deudas_Titular = {};
        modificacion_Deudas_Externas.deudas_Titular.lineas = {};
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_original = {};
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_original.monto =
          clasificacionTitular?.linea_disponible;
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_modificada =
          {};
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_modificada.monto =
          clasificacionTitular?.linea_disponible_real;
      }

      modificacion_Deudas_Externas.deudas_Titular.deudas = {};
      modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_original = [];
      modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_modificada = [];

      datosReevaluacionTitular?.resultado?.tabla2?.map((row: any) => {
        let deuda_modificada: any = {};
        deuda_modificada.tipo_deuda = row.tipo_deuda;
        deuda_modificada.entidad = row.entidad;
        deuda_modificada.deuda = Number(
          GeneralHelper.QuitarComasAMiles(
            !row.monto_deuda_real ? 0 : row.monto_deuda_real
          )
        );
        deuda_modificada.cuota = Number(
          GeneralHelper.QuitarComasAMiles(
            !row.cuota_fc_real ? 0 : row.cuota_fc_real
          )
        );
        modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_modificada.push(
          deuda_modificada
        );

        let deuda_original: any = {};
        deuda_original.tipo_deuda = row.tipo_deuda;
        deuda_original.entidad = row.entidad;
        deuda_original.deuda = Number(
          GeneralHelper.QuitarComasAMiles(
            !row.monto_deuda ? 0 : row.monto_deuda
          )
        );
        deuda_original.cuota = Number(
          GeneralHelper.QuitarComasAMiles(!row.cuota_fc ? 0 : row.cuota_fc)
        );
        modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_original.push(
          deuda_original
        );
      });

      if (
        ((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
          (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria
      ) {
        if (typeof clasificacionConyuge !== "number") {
          modificacion_Deudas_Externas.deudas_Conyugue = {};
          modificacion_Deudas_Externas.deudas_Conyugue.lineas = {};
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_original =
            {};
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_original.monto =
            clasificacionConyuge?.linea_disponible;
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_modificada =
            {};
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_modificada.monto =
            clasificacionConyuge?.linea_disponible_real;
        }

        modificacion_Deudas_Externas.deudas_Conyugue.deudas = {};
        modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_original = [];
        modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_modificada =
          [];
        datosReevaluacionConyuge?.resultado?.tabla2?.map((row: any) => {
          let deuda_modificada: any = {};
          deuda_modificada.tipo_deuda = row.tipo_deuda;
          deuda_modificada.entidad = row.entidad;
          deuda_modificada.deuda = Number(
            GeneralHelper.QuitarComasAMiles(
              !row.monto_deuda_real ? 0 : row.monto_deuda_real
            )
          );
          deuda_modificada.cuota = Number(
            GeneralHelper.QuitarComasAMiles(
              !row.cuota_fc_real ? 0 : row.cuota_fc_real
            )
          );
          modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_modificada.push(
            deuda_modificada
          );

          let deuda_original: any = {};
          deuda_original.tipo_deuda = row.tipo_deuda;
          deuda_original.entidad = row.entidad;
          deuda_original.deuda = Number(
            GeneralHelper.QuitarComasAMiles(
              !row.monto_deuda ? 0 : row.monto_deuda
            )
          );
          deuda_original.cuota = Number(
            GeneralHelper.QuitarComasAMiles(!row.cuota_fc ? 0 : row.cuota_fc)
          );
          modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_original.push(
            deuda_original
          );
        });
      }


      let requestMotorAfterStepOneTemp: any = requestMotorBeforceStepOne;
      let requestMotorOne = requestMotorAfterStepOneTemp.request;

      let requestMotorAfterStepTwoTemp: any = requestMotorBeforceStepTwo;
      let requestMotorTwo = requestMotorAfterStepTwoTemp.request;

      let requestMotorAfterStepThreeTemp: any = requestMotorBeforceStepThree;
      let requestMotorThree: any = null;
      if (requestMotorAfterStepThreeTemp) {
        requestMotorThree = requestMotorAfterStepThreeTemp.request;
      }

      let fechaIngresoDesglozadoTitular =
        titular.ingresos.fechaIngresoLaboral.split("/");
      let fechaInicioActividadTitular = `${fechaIngresoDesglozadoTitular[2]}-${fechaIngresoDesglozadoTitular[1]}-${fechaIngresoDesglozadoTitular[0]}`;

      requestMotorOne.datos_Evaluacion.modificacion_Deudas_Externas =
        modificacion_Deudas_Externas;
      requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.fecha_Inicio_Actividad =
        fechaInicioActividadTitular;
      requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.ingreso_Mensual =
        Math.trunc(Number(titular.ingresos.ingresoNeto));
      requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.ingreso_Anualizado =
        Math.trunc(
          titular.ingresos.ingresoAnualizado
            ? Number(titular.ingresos.totalAnualizado)
            : Number(titular.ingresos.ingresoNeto)
        );

      requestMotorTwo.datos_Evaluacion.modificacion_Deudas_Externas =
        modificacion_Deudas_Externas;
      requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.fecha_Inicio_Actividad =
        fechaInicioActividadTitular;
      requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.ingreso_Mensual =
        Math.trunc(Number(titular.ingresos.ingresoNeto));
      requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.ingreso_Anualizado =
        Math.trunc(
          titular.ingresos.ingresoAnualizado
            ? Number(titular.ingresos.totalAnualizado)
            : Number(titular.ingresos.ingresoNeto)
        );

      if (requestMotorAfterStepThreeTemp) {
        requestMotorThree.datos_Evaluacion.modificacion_Deudas_Externas =
          modificacion_Deudas_Externas;
        requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.fecha_Inicio_Actividad =
          fechaInicioActividadTitular;
        requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.ingreso_Mensual =
          Math.trunc(Number(titular.ingresos.ingresoNeto));
        requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.ingreso_Anualizado =
          Math.trunc(
            titular.ingresos.ingresoAnualizado
              ? Number(titular.ingresos.totalAnualizado)
              : Number(titular.ingresos.ingresoNeto)
          );
      }

      titular?.otrosIngresos?.forEach((item: OtrosIngresos, index: number) => {
        let fechaIngresoDesglozadoTitular = item.fechaIngresoLaboral.split("/");
        let fechaInicioActividadTitular = `${fechaIngresoDesglozadoTitular[2]}-${fechaIngresoDesglozadoTitular[1]}-${fechaIngresoDesglozadoTitular[0]}`;

        if (
          requestMotorOne?.datos_Evaluacion?.ingreso_declarado?.titular
            ?.otros_Ingresos.length > 0
        ) {
          requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
            index
          ].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
          requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
            index
          ].ingreso_Anualizado = Math.trunc(
            item.ingresoAnualizado
              ? Number(item.totalAnualizado)
              : Number(item.ingresoNeto)
          );
          requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
            index
          ].fecha_Inicio_Actividad = fechaInicioActividadTitular;
        }

        if (
          requestMotorTwo?.datos_Evaluacion?.ingreso_declarado?.titular
            ?.otros_Ingresos.length > 0
        ) {
          requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
            index
          ].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
          requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
            index
          ].ingreso_Anualizado = Math.trunc(
            item.ingresoAnualizado
              ? Number(item.totalAnualizado)
              : Number(item.ingresoNeto)
          );
          requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
            index
          ].fecha_Inicio_Actividad = fechaInicioActividadTitular;
        }

        if (requestMotorAfterStepThreeTemp) {
          if (
            requestMotorThree?.datos_Evaluacion?.ingreso_declarado?.titular
              ?.otros_Ingresos.length > 0
          ) {
            requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
              index
            ].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
            requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
              index
            ].ingreso_Anualizado = Math.trunc(
              item.ingresoAnualizado
                ? Number(item.totalAnualizado)
                : Number(item.ingresoNeto)
            );
            requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[
              index
            ].fecha_Inicio_Actividad = fechaInicioActividadTitular;
          }
        }
      });

      if (
        ((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
          (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria
      ) {
        let fechaIngresoDesglozadoConyuge =
          conyuge.ingresos.fechaIngresoLaboral.split("/");
        let fechaInicioActividadConyuge = `${fechaIngresoDesglozadoConyuge[2]}-${fechaIngresoDesglozadoConyuge[1]}-${fechaIngresoDesglozadoConyuge[0]}`;

        requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.fecha_Inicio_Actividad =
          fechaInicioActividadConyuge;
        requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Mensual =
          Math.trunc(Number(conyuge.ingresos.ingresoNeto));
        requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Anualizado =
          Math.trunc(
            conyuge.ingresos.ingresoAnualizado
              ? Number(conyuge.ingresos.totalAnualizado)
              : Number(conyuge.ingresos.ingresoNeto)
          );

        requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.fecha_Inicio_Actividad =
          fechaInicioActividadConyuge;
        requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Mensual =
          Math.trunc(Number(conyuge.ingresos.ingresoNeto));
        requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Anualizado =
          Math.trunc(
            conyuge.ingresos.ingresoAnualizado
              ? Number(conyuge.ingresos.totalAnualizado)
              : Number(conyuge.ingresos.ingresoNeto)
          );

        if (requestMotorAfterStepThreeTemp) {
          requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.fecha_Inicio_Actividad =
            fechaInicioActividadConyuge;
          requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Mensual =
            Math.trunc(Number(conyuge.ingresos.ingresoNeto));
          requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Anualizado =
            Math.trunc(
              conyuge.ingresos.ingresoAnualizado
                ? Number(conyuge.ingresos.totalAnualizado)
                : Number(conyuge.ingresos.ingresoNeto)
            );
        }

        conyuge?.otrosIngresos?.forEach(
          (item: OtrosIngresos, index: number) => {
            let fechaIngresoDesglozadoConyugue =
              item.fechaIngresoLaboral.split("/");
            let fechaInicioActividadConyugue = `${fechaIngresoDesglozadoConyugue[2]}-${fechaIngresoDesglozadoConyugue[1]}-${fechaIngresoDesglozadoConyugue[0]}`;

            if (
              requestMotorOne?.datos_Evaluacion?.ingreso_declarado?.conyugue
                ?.otros_Ingresos.length > 0
            ) {
              requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                index
              ].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
              requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                index
              ].ingreso_Anualizado = Math.trunc(
                item.ingresoAnualizado
                  ? Number(item.totalAnualizado)
                  : Number(item.ingresoNeto)
              );
              requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                index
              ].fecha_Inicio_Actividad = fechaInicioActividadConyugue;
            }

            if (
              requestMotorTwo?.datos_Evaluacion?.ingreso_declarado?.conyugue
                ?.otros_Ingresos.length > 0
            ) {
              requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                index
              ].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
              requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                index
              ].ingreso_Anualizado = Math.trunc(
                item.ingresoAnualizado
                  ? Number(item.totalAnualizado)
                  : Number(item.ingresoNeto)
              );
              requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                index
              ].fecha_Inicio_Actividad = fechaInicioActividadConyugue;
            }

            if (requestMotorAfterStepThreeTemp) {
              if (
                requestMotorThree?.datos_Evaluacion?.ingreso_declarado?.conyugue
                  ?.otros_Ingresos.length > 0
              ) {
                requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                  index
                ].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
                requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                  index
                ].ingreso_Anualizado = Math.trunc(
                  item.ingresoAnualizado
                    ? Number(item.totalAnualizado)
                    : Number(item.ingresoNeto)
                );
                requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[
                  index
                ].fecha_Inicio_Actividad = fechaInicioActividadConyugue;
              }
            }
          }
        );
      }

      if (datosCondicionFinanciamiento) {
        requestMotorTwo.datos_Evaluacion.datos_financiamiento.cuota_inicial.monto =
          Number(datosCondicionFinanciamiento.cuotaInicial);
        requestMotorTwo.datos_Evaluacion.datos_financiamiento.cuota_inicial.porcentaje =
        GeneralHelper.ParseTwoDecimal(datosCondicionFinanciamiento.porcentajeInicial);
        requestMotorTwo.datos_Evaluacion.datos_financiamiento.monto_a_financiar =
          Number(datosCondicionFinanciamiento.montoVehiculo) -
          Number(datosCondicionFinanciamiento.cuotaInicial);

        if (requestMotorAfterStepThreeTemp) {
          requestMotorThree.datos_Evaluacion.datos_financiamiento.cuota_inicial.monto =
            Number(datosCondicionFinanciamiento.cuotaInicial);
          requestMotorThree.datos_Evaluacion.datos_financiamiento.cuota_inicial.porcentaje =
          GeneralHelper.ParseTwoDecimal(datosCondicionFinanciamiento.porcentajeInicial);
          requestMotorThree.datos_Evaluacion.datos_financiamiento.monto_a_financiar =
            Number(datosCondicionFinanciamiento.montoVehiculo) -
            Number(datosCondicionFinanciamiento.cuotaInicial);
        }
      }

      let successful = true;
      let errorMessages: string[] = [];
      requestMotorAfterStepOneTemp.request = requestMotorOne;
      setRequestMotorAfterStepOne(requestMotorAfterStepOneTemp);
      let responseMotorAfterStepOne: any = null;
      const responseModellicaMotorOne = await ModellicaMotorEvaluacion(
        requestMotorAfterStepOneTemp
      )
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {
          successful = false;
        });

      const validacionOneNewModelicaData = NewModelicaData(
        responseModellicaMotorOne,
        appConfigKey.KeyLlamadaMotorUno
      );

      if (validacionOneNewModelicaData) {
        errorMessages.push(validacionOneNewModelicaData);
        successful = false;
      }

      requestMotorAfterStepTwoTemp.request = requestMotorTwo;
      setRequestMotorAfterStepTwo(requestMotorAfterStepTwoTemp);
      setEsVisibleResultReevaluar(false);
      const cfmAux = debts?.cfm ? Number(debts.cfm.replace("%", "")) * 0.01 : 0;
      let responseMotorAfterStepTwo: any = null;
      const responseModellicaMotorTwo = await ModellicaMotorEvaluacion(
        requestMotorAfterStepTwoTemp
      )
        .then((response: any) => {
          return response;
        })
        .catch((err: any) => {
          return;
        });

      const validacionTwoNewModelicaData = NewModelicaData(
        responseModellicaMotorTwo,
        appConfigKey.KeyLlamadaMotorDos
      );

      if (validacionTwoNewModelicaData) {
        errorMessages.push(validacionTwoNewModelicaData);
        successful = false;
      }

      let responseMotorAfterStepThree: any = null;
      let responseModellicaMotorThree: any = null;
      if (requestMotorAfterStepThreeTemp) {
        requestMotorAfterStepThreeTemp.request = requestMotorThree;
        setRequestMotorAfterStepThree(requestMotorAfterStepThreeTemp);
        responseModellicaMotorThree = await ModellicaMotorEvaluacion(
          requestMotorAfterStepThreeTemp
        )
          .then((response: any) => {
            return response;
          })
          .catch((err: any) => {
            return;
          });

        const validacionThreeNewModelicaData = NewModelicaData(
          responseModellicaMotorThree,
          appConfigKey.KeyLlamadaMotorTres
        );

        if (validacionThreeNewModelicaData) {
          errorMessages.push(validacionThreeNewModelicaData);
          successful = false;
        }
      }

      if (!successful) {
        errorMessages.unshift(appConfigKey.keyMsjErrorEnServicioGenerico.replace(
          "{data}",
          "Modellica"
        ));

        const msgHtml = errorMessages.map((msg: any, index: number) => (
          <div key={index}>{msg}</div>
        ));

        if (msgHtml) {
          toast.error(msgHtml, { duration: appConfigKey.keyDurationToast });
        }
        return;
      }

      if (responseModellicaMotorOne) {
        const content = JSON.parse(responseModellicaMotorOne.data.content);
        setResponseMotorAfterStepOne(content);
        responseMotorAfterStepOne = content;

        General.GetMnEvaluacionSolicitudInsertLog(
          Number(solicitudId),
          appConfigKey.KeyLlamadaMotorUno,
          JSON.stringify(requestMotorOne),
          JSON.stringify(content),
          Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
          ),
          Number(
            localStorage.getItem("UserProfileMn")
              ? localStorage.getItem("UserProfileMn")
              : 0
          ),
          typeof solicitud !== "number"
            ? solicitud.idEstadoSolicitud ===
              appConfigKey.keyIdEstadoSolicitudPorEvaluar ||
              solicitud.idEstadoSolicitud ===
              appConfigKey.keyIdEstadoSolicitudEnEvaluacion
              ? appConfigKey.KeyFlujoEvaluacionCrediticaPorGestionar
              : solicitud.idEstadoSolicitud ===
                appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
                solicitud.idEstadoSolicitud ===
                appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion
                ? appConfigKey.KeyFlujoEvaluacionCrediticaExcepcion
                : solicitud.idEstadoSolicitud ===
                  appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia ||
                  solicitud.idEstadoSolicitud ===
                  appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia
                  ? appConfigKey.KeyFlujoEvaluacionCrediticaAutonomia
                  : 0
            : 0
        );
      }

      if (responseModellicaMotorThree) {
        const content = JSON.parse(responseModellicaMotorThree.data.content);

        General.GetMnEvaluacionSolicitudInsertLog(
          Number(solicitudId),
          appConfigKey.KeyLlamadaMotorTres,
          JSON.stringify(requestMotorThree),
          JSON.stringify(content),
          Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
          ),
          Number(
            localStorage.getItem("UserProfileMn")
              ? localStorage.getItem("UserProfileMn")
              : 0
          ),
          typeof solicitud !== "number"
            ? solicitud.idEstadoSolicitud ===
              appConfigKey.keyIdEstadoSolicitudPorEvaluar ||
              solicitud.idEstadoSolicitud ===
              appConfigKey.keyIdEstadoSolicitudEnEvaluacion
              ? appConfigKey.KeyFlujoEvaluacionCrediticaPorGestionar
              : solicitud.idEstadoSolicitud ===
                appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
                solicitud.idEstadoSolicitud ===
                appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion
                ? appConfigKey.KeyFlujoEvaluacionCrediticaExcepcion
                : solicitud.idEstadoSolicitud ===
                  appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia ||
                  solicitud.idEstadoSolicitud ===
                  appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia
                  ? appConfigKey.KeyFlujoEvaluacionCrediticaAutonomia
                  : 0
            : 0
        );

        setResponseMotorAfterStepThree(content);
        responseMotorAfterStepThree = content;
      }

      if (responseModellicaMotorTwo) {
        const content = JSON.parse(responseModellicaMotorTwo.data.content);
        setResponseMotorAfterStepTwo(content);

        General.GetMnEvaluacionSolicitudInsertLog(
          Number(solicitudId),
          appConfigKey.KeyLlamadaMotorDos,
          JSON.stringify(requestMotorTwo),
          JSON.stringify(content),
          Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
          ),
          Number(
            localStorage.getItem("UserProfileMn")
              ? localStorage.getItem("UserProfileMn")
              : 0
          ),
          typeof solicitud !== "number"
            ? solicitud.idEstadoSolicitud ===
              appConfigKey.keyIdEstadoSolicitudPorEvaluar ||
              solicitud.idEstadoSolicitud ===
              appConfigKey.keyIdEstadoSolicitudEnEvaluacion
              ? appConfigKey.KeyFlujoEvaluacionCrediticaPorGestionar
              : solicitud.idEstadoSolicitud ===
                appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
                solicitud.idEstadoSolicitud ===
                appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion
                ? appConfigKey.KeyFlujoEvaluacionCrediticaExcepcion
                : solicitud.idEstadoSolicitud ===
                  appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia ||
                  solicitud.idEstadoSolicitud ===
                  appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia
                  ? appConfigKey.KeyFlujoEvaluacionCrediticaAutonomia
                  : 0
            : 0
        );

        responseMotorAfterStepTwo = content;
        const responseMotor = content?.response;
        const decision =
          responseMotor?.Politica_Creditos?.decision_Credito?.decision;
        const puntajeAfter =
          responseMotor?.Politica_Creditos?.decision_Credito?.puntaje;
        const segmentoAfter =
          responseMotor?.Politica_Creditos?.decision_Credito?.segmento;
        const nivelRiesgo =
          responseMotor?.Politica_Creditos?.decision_Credito?.nivel_Riesgo;

        if (decision && typeof solicitud !== "number") {
          const maximoEndeudamiento =
            responseMotor?.Politica_Creditos?.capacidad_Endeudamiento.maximo_Endeudamiento.find(
              (x: any) => x.CFM == cfmAux
            );
          const cfc =
            responseMotor?.Politica_Creditos?.capacidad_Endeudamiento?.CFC;
          const valorCFC = (cfc ? Number(cfc) * 100 : 0).toFixed(2) + "%";
          setNewMEN(maximoEndeudamiento.MEM);
          setNewCFC(cfc);
          setDebts({ ...debts, cfc: valorCFC });
          if (
            resultadoEvaluacionResumen &&
            typeof resultadoEvaluacionResumen !== "number"
          ) {
            setResultadoEvaluacionResumen({
              ...resultadoEvaluacionResumen,
              nivel_Riesgo: !nivelRiesgo ? "N/D" : nivelRiesgo,
            });
          }

          if (!responseMotor?.productos_comerciales) {
            setEsVisibleResultReevaluar(true);
            return {
              esReevaluarValido: false,
              esAprobadoSolicitud:
                decision === appConfigKey.CodigoEvaluacionAprobado,
              esRechazadoSolicitud:
                decision === appConfigKey.CodigoEvaluacionRechazado,
              esZonaGrisSolicitud:
                decision === appConfigKey.CodigoEvaluacionZonaGris,
              newMen: maximoEndeudamiento.MEM,
              newCfc: cfc,
              requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
              responseMotorAfterStepOne: responseMotorAfterStepOne,
              requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
              responseMotorAfterStepTwo: responseMotorAfterStepTwo,
              requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
              responseMotorAfterStepThree: responseMotorAfterStepThree,
              newPuntaje: puntajeAfter,
              newIdSegmento: segmentoAfter,
              noTieneProductosComerciales: true,
              noCoincidenProducto: false
            };
          }

          let producto: any = responseMotor?.productos_comerciales.find(
            (x: any) => Number(x.codigo) == solicitud?.idProducto
          );
          if (!producto) {
            setEsVisibleResultReevaluar(true);
            return {
              esReevaluarValido: false,
              esAprobadoSolicitud:
                decision === appConfigKey.CodigoEvaluacionAprobado,
              esRechazadoSolicitud:
                decision === appConfigKey.CodigoEvaluacionRechazado,
              esZonaGrisSolicitud:
                decision === appConfigKey.CodigoEvaluacionZonaGris,
              newMen: maximoEndeudamiento.MEM,
              newCfc: cfc,
              requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
              responseMotorAfterStepOne: responseMotorAfterStepOne,
              requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
              responseMotorAfterStepTwo: responseMotorAfterStepTwo,
              requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
              responseMotorAfterStepThree: responseMotorAfterStepThree,
              newPuntaje: puntajeAfter,
              newIdSegmento: segmentoAfter,
              noTieneProductosComerciales: false,
              noCoincidenProducto: true
            };
          }

          let responseMotorBeforce: any = responseMotorBeforceStepTwo;
          let puntajeBeforce =
            responseMotorBeforce?.response?.Politica_Creditos?.decision_Credito
              ?.puntaje;
          let segmentoBeforce =
            responseMotorBeforce?.response?.Politica_Creditos?.decision_Credito
              ?.segmento;

          if (
            puntajeBeforce !== puntajeAfter ||
            segmentoBeforce !== segmentoAfter
          ) {
            setEsVisibleResultReevaluar(true);
            return {
              esReevaluarValido: false,
              esAprobadoSolicitud:
                decision === appConfigKey.CodigoEvaluacionAprobado,
              esRechazadoSolicitud:
                decision === appConfigKey.CodigoEvaluacionRechazado,
              esZonaGrisSolicitud:
                decision === appConfigKey.CodigoEvaluacionZonaGris,
              newMen: maximoEndeudamiento.MEM,
              newCfc: cfc,
              requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
              responseMotorAfterStepOne: responseMotorAfterStepOne,
              requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
              responseMotorAfterStepTwo: responseMotorAfterStepTwo,
              requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
              responseMotorAfterStepThree: responseMotorAfterStepThree,
              newPuntaje: puntajeAfter,
              newIdSegmento: segmentoAfter,
              noTieneProductosComerciales: false,
              noCoincidenProducto: false
            };
          }

          return {
            esReevaluarValido: true,
            esAprobadoSolicitud:
              decision === appConfigKey.CodigoEvaluacionAprobado,
            esRechazadoSolicitud:
              decision === appConfigKey.CodigoEvaluacionRechazado,
            esZonaGrisSolicitud:
              decision === appConfigKey.CodigoEvaluacionZonaGris,
            newMen: maximoEndeudamiento.MEM,
            newCfc: cfc,
            requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
            responseMotorAfterStepOne: responseMotorAfterStepOne,
            requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
            responseMotorAfterStepTwo: responseMotorAfterStepTwo,
            requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
            responseMotorAfterStepThree: responseMotorAfterStepThree,
            newPuntaje: puntajeAfter,
            newIdSegmento: segmentoAfter,
            noTieneProductosComerciales: false,
            noCoincidenProducto: false
          };
        }
      }

      return;
    } catch (error: any) {
      throw new AppException(`${appConfigKey.keyMsjErrorLogica} Detalle: ${error?.message || appConfigKey.keyMsjErrorUnknown}`);
    }
  };
  const esReevaluarResultados = async (): Promise<any> => {
    let nuevoResultadoFinanciamiento: any = null;
    try {
      let nuevoDatosCondicionFinanciamiento = datosCondicionFinanciamiento;
      let inicialDolares =
        nuevoDatosCondicionFinanciamiento.idmoneda ===
          appConfigKey.keyIdentificadorDolares
          ? nuevoDatosCondicionFinanciamiento.cuotaInicial
          : (
            nuevoDatosCondicionFinanciamiento.cuotaInicial / tipoCambio
          ).toFixed(2);
      let requestSimularOferta = {
        identSolicitud: Number(solicitudId),
        tea: nuevoDatosCondicionFinanciamiento.tea || 0,
        inicial: Number(inicialDolares),
        plazo: nuevoDatosCondicionFinanciamiento.cuota || 0,
        cuotasDobles: nuevoDatosCondicionFinanciamiento.indicadorCuotaDoble,
        comentario: nuevoDatosCondicionFinanciamiento.comentario || "",
        identActualizarBD: false,
        idenT_USUARIO_CREADOR: Number(
          localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
        ),
      };

      const responseSimularOferta =
        await RequestManager.GetMnSimularOfertasVehicularBySolicitud(
          requestSimularOferta
        );
      if (responseSimularOferta) {
        setDatosCondicionFinanciamientoInicial(
          nuevoDatosCondicionFinanciamiento
        );
        setNewCuota(responseSimularOferta.data.content.valorCuota);
        nuevoResultadoFinanciamiento = responseSimularOferta.data.content;
        setResultadoFinanciamiento({
          ...resultadoFinanciamiento,
          montoFinanciamiento:
            responseSimularOferta.data.content.montoFinanciamiento,
          montoCuota: responseSimularOferta.data.content.valorCuota,
          totalGastos: responseSimularOferta.data.content.totalGastos,
          tcea: `${responseSimularOferta.data.content.cft} %`,
          totalFinanciamiento:
            responseSimularOferta.data.content.totalFinanciamiento,
          cuotaDoble: responseSimularOferta.data.content.valorCuotaDoble,
        });
        if (typeof solicitud !== "number" && (solicitud.esTitularJuridico || (!solicitud.esTitularJuridico && titular?.ingresos?.idcategoriaLaboral === appConfigKey.keyId3raCategoríaPerRUC))) {

          if (capacidadPagoTabla) {
            ConvertidorTablaHelper.ModificarParametroTabla(capacidadPagoTabla, 'cuota', responseSimularOferta.data.content.valorCuota);
            buildMatrizRegimen();
          }

          setInformacionTercera((prevState: any) => {
            if (typeof prevState === "number") {
              return 0;
            }
            return {
              ...prevState,
              datosCredito: {
                ...prevState.datosCredito,
                cuotaInicial: nuevoDatosCondicionFinanciamiento.cuotaInicial,
                cuota: responseSimularOferta.data.content.valorCuota,
                plazo: nuevoDatosCondicionFinanciamiento.cuota || 0,
                cuotaInicialPorcentaje: (nuevoDatosCondicionFinanciamiento.cuotaInicial / (prevState.datosCredito?.valorBien ?? 0)) * 100,
                financiamiento: responseSimularOferta.data.content.totalFinanciamiento,
                financiamientoPorcentaje: (responseSimularOferta.data.content.montoFinanciamiento / (prevState.datosCredito?.valorBien ?? 0)) * 100
              }
            };
          });
        }
        toast.success(appConfigKey.keyMsjRecalcularFinanciamiento, {
          duration: appConfigKey.keyDurationToast,
        });
      }
    } catch (error: any) {
      let mensajeError: string =
        appConfigKey.keyMsjErrorEnServicioGenerico.replace(
          "{data}",
          "simular oferta vehícular"
        );
      setResultadoFinanciamiento({
        ...resultadoFinanciamiento,
        montoFinanciamiento: 0,
        montoCuota: 0,
        totalGastos: 0,
        tcea: "N/D",
        totalFinanciamiento: 0,
        cuotaDoble: 0,
      });

      if (typeof solicitud !== "number" && (solicitud.esTitularJuridico || (!solicitud.esTitularJuridico && titular?.ingresos?.idcategoriaLaboral === appConfigKey.keyId3raCategoríaPerRUC))) {
        if (capacidadPagoTabla) {
          ConvertidorTablaHelper.ModificarParametroTabla(capacidadPagoTabla, 'cuota', 0);
          buildMatrizRegimen();
        }

        setInformacionTercera((prevState: any) => {
          if (typeof prevState === "number") {
            return 0;
          }
          return {
            ...prevState,
            datosCredito: {
              ...prevState.datosCredito,
              cuotaInicial: 0,
              cuota: 0,
              plazo: 0,
              cuotaInicialPorcentaje: 0,
              financiamiento: 0,
              financiamientoPorcentaje: 0
            }
          };
        });
      }
      toast.error(mensajeError, { duration: appConfigKey.keyDurationToast });
    } finally {
      return nuevoResultadoFinanciamiento;
    }
  };

  const handleReevaluar = async () => {
    setShow(false);
    if (typeof solicitud == "number") {
      toast.error(appConfigKey.keyMsjSolicitudCargando, { duration: appConfigKey.keyDurationToast });
      return;
    }

    let esReevaluarValido: any;
    if (!solicitud.esTitularJuridico && titular?.ingresos?.idcategoriaLaboral !== appConfigKey.keyId3raCategoríaPerRUC) {

      try {
        esReevaluarValido = await esReevaluarCalculoMENyCFC();

        if (
          !esReevaluarValido?.esAprobadoSolicitud &&
          !esReevaluarValido?.esRechazadoSolicitud &&
          !esReevaluarValido?.esZonaGrisSolicitud
        ) {
          setShow(true);
          return;
        }

      } catch (error) {
        if (error instanceof AppException) {
          toast.error(error.message, { duration: appConfigKey.keyDurationToast });
        }
        else {
          toast.error(appConfigKey.keyMsjErrorLogica, { duration: appConfigKey.keyDurationToast });
        }
        setShow(true);
        return;
      }
      
    }

    if (
      !ValidacionResultadosEvaluacionHelper.DatosFinanciamientoIguales(
        datosCondicionFinanciamientoInicial,
        datosCondicionFinanciamiento,
        tipoCambio
      )
    ) {
      let nuevoResultadoFinanciamiento = await esReevaluarResultados();
      if (nuevoResultadoFinanciamiento) {
        if (esReevaluarValido) {
          setEsVisibleResultReevaluar(true);
        }        
        /*
        MODIFICADO HASTA LA NUEVA ACTUALIZACION DE EXPERIAN
        if (esReevaluarValido && esReevaluarValido.newMen < nuevoResultadoFinanciamiento.valorCuota) {
          setEsVisibleResultReevaluar(true);
        }
        */
      }
    } else {
      if (esReevaluarValido) {
        setEsVisibleResultReevaluar(true);
      }      
      /*
      MODIFICADO HASTA LA NUEVA ACTUALIZACION DE EXPERIAN
      if (esReevaluarValido && esReevaluarValido.newMen < resultadoFinanciamiento?.montoCuota) {
        setEsVisibleResultReevaluar(true);
      }
      */
    }

    toast.success(appConfigKey.keyMsjRecalcularScoring, {
      duration: appConfigKey.keyDurationToast,
    });
    setShow(true);
  };

  const [saveDialogOpen, setSaveDialogOpen] = useState<boolean>(false);
  const [saveMsjAutonomiaDialogOpen, setSaveMsjAutonomiaDialogOpen] = useState<string>('');
  const handleSaveDialogOpen = async () => {
    setSaveMsjAutonomiaDialogOpen('');
    if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
      reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos) {

      const identUsuario = Number(localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0);
      const responseObtenerCargoUsuario: any = await Lista.ObtenerUsuarioCargo(identUsuario);

      if (responseObtenerCargoUsuario.length === 0) {
        toast.error("El usuario no tiene un cargo asignado.");
        return;
      }

      const { monto }: any = responseObtenerCargoUsuario[0];
      const { idmoneda } = datosCondicionFinanciamiento;
      const { montoFinanciamiento } = resultadoFinanciamiento;

      let fueraRango = false;
      let montoFinanciamientoDolares = 0;
      if (!ValidacionResultadosEvaluacionHelper.DatosFinanciamientoIguales(
        datosCondicionFinanciamientoInicial,
        datosCondicionFinanciamiento,
        tipoCambio
      )) {

        let nuevoResultadoFinanciamiento = await esReevaluarResultados();
        if (nuevoResultadoFinanciamiento) {
          if (idmoneda === appConfigKey.keyIdentificadorSoles) {
            montoFinanciamientoDolares = nuevoResultadoFinanciamiento.montoFinanciamiento / tipoCambio;
          } else {
            montoFinanciamientoDolares = nuevoResultadoFinanciamiento.montoFinanciamiento;
          }
        }

      } else {
        if (idmoneda === appConfigKey.keyIdentificadorSoles) {
          montoFinanciamientoDolares = montoFinanciamiento / tipoCambio;
        } else {
          montoFinanciamientoDolares = montoFinanciamiento;
        }
      }

      if (montoFinanciamientoDolares > monto) {
        fueraRango = true;
      }

      if (fueraRango) {
        setSaveMsjAutonomiaDialogOpen(appConfigKey.keyMsjDerivarAutonomia);
      }

    }
    setSaveDialogOpen(true);
  };
  const handleSaveDialogClose = () => {
    setSaveDialogOpen(false);
  };

  const SaveDialog = ({ open, handleClose }: any) => {
    return (
      <Dialog open={open} maxWidth={"xs"}>
        <div className="save-dialong-content">
          <div className="save-dialog-title">GUARDAR</div>
          <div className="save-dialog-list-content">
            <div className="row g-2 mb-2 align-items-center text-center">
              <div className="col cardObjets align-top">
                ¿Esta seguro de finalizar su evaluación?
              </div>
              {saveMsjAutonomiaDialogOpen && (
                <div className="col-lg-12 col-md-6">
                  NOTA :
                  <span className="text-normal">
                    {` ${saveMsjAutonomiaDialogOpen}`}
                  </span>
                </div>)}
            </div>
          </div>
          <div className="d-flex justify-content-center px-1">
            <div
              className="save-dialog-btn-Save cursor-pointer mx-2"
              onClick={SaveResultadoEvaluacion}
            >
              Guardar
            </div>
            <div
              className="save-dialog-btn-close cursor-pointer mx-2"
              onClick={handleClose}
            >
              Cerrar
            </div>
          </div>
        </div>
      </Dialog>
    );
  };

  const postMnSaveGestor = async (newMEN: any, newCFC: any) => {
    //Insertar matriz
    if (titular?.ingresos?.idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres) {

      if (!matrizRegimen || !matrizRegimen.matrizGeneral) {
        toast.error(appConfigKey.keyMsjErrorFormatoRegimen, { duration: appConfigKey.keyDurationToast });
        return;
      }

      let regimenUpdateRequest = new MnFormatoRegimenUpdateRequest();
      regimenUpdateRequest.identFormatoRegistro = matrizRegimen.identFormatoRegistro
      regimenUpdateRequest.jsonTable = JSON.stringify(matrizRegimen.matrizGeneral);
      regimenUpdateRequest.identUsuario = Number(appSecurityConfiguration.SessionUserId ?? localStorage.getItem('UserMn') ?? 0);

      const updateMatrizSuccess = await General.UpdateMnFormatoRegimen(regimenUpdateRequest);

      if (!updateMatrizSuccess) {
        toast.error(appConfigKey.keyMsjErrorActualizarFormatoRegimen, { duration: appConfigKey.keyDurationToast });
        return;
      }

      if (informacionTercera && typeof informacionTercera !== 'number') {

        const giroNegocioId = informacionTercera?.estadoGananciaPerdidas?.giroNegocioId ?? 0;
        if (giroNegocioId == 0) {
          toast.error(appConfigKey.keyMsjErrorGiroNegocioScoringTercera, { duration: appConfigKey.keyDurationToast });
          return;
        }

        let updateScoringTerceraRequest = new MnSaveInformeTerceraRequest();
        updateScoringTerceraRequest.identSolicitud = Number(solicitudId);
        updateScoringTerceraRequest.giroNegocioId = giroNegocioId;
        
        const updateScoringTerceraSuccess = await General.SaveMnEvaluacionScoringTercera(updateScoringTerceraRequest);

        if (!updateScoringTerceraSuccess) {
          toast.error(appConfigKey.keyMsjErrorActualizarScoringTercera, { duration: appConfigKey.keyDurationToast });
          return;
        }
      } else {
        toast.error(appConfigKey.keyMsjErrorCargandoScoringTercera, { duration: appConfigKey.keyDurationToast });
          return;
      }
      
    }

    let ConyugeIngresosRequest: any = null;
    let titularIngresosRequest: any = null;
    let titularSolicitudRccRequest: any = null;
    let conyugeSolicitudRccRequest: any = null;
    let ingresosPrincipal: any = {
      identIngresosPersonaHistorico: 0,
      identSolicitud: Number(solicitudId),
      identPersona: titular.ingresos.idPersona,
      identPersonaIngresos: titular.ingresos.idIngresoPersona,
      tipoParticipacion: "titular",
      montoIngresoNetoNuevo: Number(titular.ingresos.ingresoNeto),
      indAnualizadoNuevo: titular.ingresos.ingresoAnualizado,
      identUsuarioCreador: Number(
        localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
      ),
      comentario: titular.ingresos.comentario,
      fechaLaboral: titular.ingresos.fechaIngresoLaboral,
    };
    let ingresosSecundarios: any[] = [];
    if (titular?.otrosIngresos?.length > 0) {
      titular?.otrosIngresos?.map((item: any) => {
        ingresosSecundarios.push({
          identIngresosPersonaHistorico: 0,
          identSolicitud: Number(solicitudId),
          identPersona: titular.ingresos.idPersona,
          identPersonaIngresos: item.idIngresoPersona,
          tipoParticipacion: "titular",
          montoIngresoNetoNuevo: Number(item.ingresoNeto),
          indAnualizadoNuevo: item.ingresoAnualizado,
          identUsuarioCreador: Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
          ),
          fechaLaboral: item.fechaIngresoLaboral,
          comentario: item.comentario,
        });
      });
    }
    titularIngresosRequest = {
      ingresosPrincipal: ingresosPrincipal,
      ingresosSecundarios: ingresosSecundarios,
    };
    let datosTitular: any = null;
    if (typeof solicitud !== "number") {
      datosTitular = members.users.find(
        (item) =>
          item.nroDocumento === solicitud.numeroDocumento.toString().trim()
      );
    }
    let jsonModificadoTitular: any = {
      resultado: datosReevaluacionTitular.resultado,
    };
    titularSolicitudRccRequest = {
      tipoIntegrante: datosTitular?.tipoIntegrante,
      tipoDocumento: datosTitular?.tipoDocumento,
      numeroDocumento: datosTitular?.nroDocumento,
      jsonModificado: JSON.stringify(jsonModificadoTitular),
      identSolicitudRcc: datosReevaluacionTitular.ident_solicitud_rcc,
      identSolicitud: Number(solicitudId),
    };
    if (
      ((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
        (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
      categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria
    ) {
      ingresosPrincipal = {
        identIngresosPersonaHistorico: 0,
        identSolicitud: Number(solicitudId),
        identPersona: conyuge.ingresos.idPersona,
        identPersonaIngresos: conyuge.ingresos.idIngresoPersona,
        tipoParticipacion: "conyuge",
        montoIngresoNetoNuevo: Number(conyuge.ingresos.ingresoNeto),
        indAnualizadoNuevo: conyuge.ingresos.ingresoAnualizado,
        identUsuarioCreador: Number(
          localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
        ),
        comentario: conyuge.ingresos.comentario,
        fechaLaboral: conyuge.ingresos.fechaIngresoLaboral,
      };

      ingresosSecundarios = [];
      if (conyuge?.otrosIngresos?.length > 0) {
        conyuge?.otrosIngresos?.map((item: any) => {
          ingresosSecundarios.push({
            identIngresosPersonaHistorico: 0,
            identSolicitud: Number(solicitudId),
            identPersona: conyuge.ingresos.idPersona,
            identPersonaIngresos: item.idIngresoPersona,
            tipoParticipacion: "conyuge",
            montoIngresoNetoNuevo: Number(item.ingresoNeto),
            indAnualizadoNuevo: item.ingresoAnualizado,
            identUsuarioCreador: Number(
              localStorage.getItem("UserMn")
                ? localStorage.getItem("UserMn")
                : 0
            ),
            comentario: item.comentario,
            fechaLaboral: item.fechaIngresoLaboral,
          });
        });
      }
      ConyugeIngresosRequest = {
        ingresosPrincipal: ingresosPrincipal,
        ingresosSecundarios: ingresosSecundarios,
      };

      let datosConyuge: any = null;
      datosConyuge = members.users.find(
        (item) =>
          item.tipoIntegrante.trim() ===
          appConfigKey.keyDescripTipoIntegranteConyuge
      );

      let jsonModificadoConyuge: any = {
        resultado: datosReevaluacionConyuge.resultado,
      };

      conyugeSolicitudRccRequest = {
        tipoIntegrante: datosConyuge?.tipoIntegrante,
        tipoDocumento: datosConyuge?.tipoDocumento,
        numeroDocumento: datosConyuge?.nroDocumento,
        jsonModificado: JSON.stringify(jsonModificadoConyuge),
        identSolicitudRcc: datosReevaluacionConyuge.ident_solicitud_rcc,
        identSolicitud: Number(solicitudId),
      };
    }

    let inicialDolares =
      datosCondicionFinanciamiento.idmoneda ===
        appConfigKey.keyIdentificadorDolares
        ? datosCondicionFinanciamiento.cuotaInicial
        : (datosCondicionFinanciamiento.cuotaInicial / tipoCambio).toFixed(2);

    const request = {
      titularIngresosRequest: titularIngresosRequest,
      ConyugeIngresosRequest: ConyugeIngresosRequest,
      reCalcularRequest: {
        nuevoMEM: newMEN,
        nuevoCFC: newCFC,
      },
      cronogramaRequest: {
        identSolicitud: Number(solicitudId),
        tea: datosCondicionFinanciamiento.tea,
        inicial: Number(inicialDolares),
        plazo: datosCondicionFinanciamiento.cuota,
        cuotasDobles: datosCondicionFinanciamiento.indicadorCuotaDoble,
        comentario: datosCondicionFinanciamiento.comentario,
        identActualizarBD: true,
        idenT_USUARIO_CREADOR: Number(
          localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
        ),
      },
      titularSolicitudRccRequest: titularSolicitudRccRequest,
      conyugeSolicitudRccRequest: conyugeSolicitudRccRequest,
    };

    await RequestManager.SaveGestor(request);
  };

  const [reqResultadoEvaluacion, setReqResultadoEvaluacion] =
    useState<ResultadoEvaluacionDto>(ResultadoEvalucionDtoDefault);

  const handleTabsPositionReset = async () => {
    await getMnEvaluacionScoringGestor();
    handleTabsPosition(null, 0);
  };

  const SaveResultadoEvaluacion = async () => {

    setShow(false);

    handleSaveDialogClose();

    const validarNewReqResultadoEvaluacion = !ValidacionResultadosEvaluacionHelper.NewReqResultadoEvaluacion(reqResultadoEvaluacion, documentos);

    const identUsuario = Number(localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0);

    const responseServicioObtenerCargoUsuario: any = await MasterManager.GetMnUsuarioCargo(identUsuario);

    const { status } = responseServicioObtenerCargoUsuario;

    if (status !== 200) {
      toast.error("Ocurrió un error con el servio para obtener cargo.");
      setShow(true);
      return;
    }

    const { isValid } = responseServicioObtenerCargoUsuario.data;

    if (!isValid) {
      toast.error("Ocurrió un error con el servio para obtener cargo.");
      setShow(true);
      return;
    }

    const { content } = responseServicioObtenerCargoUsuario.data;

    if (content.length === 0) {
      toast.error("El usuario no tiene un cargo asignado.");
      setShow(true);
      return;
    }

    const cargoUsuario = content[0];

    const { monto, montO_MINIMO }: any = cargoUsuario;
    const { montoFinanciamiento } = resultadoFinanciamiento;
    const { idmoneda } = datosCondicionFinanciamiento;
    const { tipoCambio }: any = solicitud;



    if (validarNewReqResultadoEvaluacion) {
      setShow(true);
      return;
    }

    const {
      idPersonaCompraPara,
      idEstadoCivilTitular,
      idSolicitud,
      esPendienteEvaluar,
      esTitularJuridico,
    }: any = solicitud;

    // if (!esPendienteEvaluar) {
    //   setShow(true);
    //   return;
    // }

    let esReevaluarValidoAlGuardar: any;
    if (!esTitularJuridico && titular?.ingresos?.idcategoriaLaboral !== appConfigKey.keyId3raCategoríaPerRUC) {
      try {
        esReevaluarValidoAlGuardar = await esReevaluarCalculoMENyCFC();

        if (
          !esReevaluarValidoAlGuardar?.esAprobadoSolicitud &&
          !esReevaluarValidoAlGuardar?.esRechazadoSolicitud &&
          !esReevaluarValidoAlGuardar?.esZonaGrisSolicitud
        ) {
          setShow(true);
          return;
        }

      } catch (error) {
        if (error instanceof AppException) {
          toast.error(error.message, { duration: appConfigKey.keyDurationToast });
        }
        else {
          toast.error(appConfigKey.keyMsjErrorLogica, { duration: appConfigKey.keyDurationToast });
        }
        setShow(true);
        return;
      }
    }

    let fueraRango = false;
    let montoFinanciamientoDolares = 0;

    if (!ValidacionResultadosEvaluacionHelper.DatosFinanciamientoIguales(
      datosCondicionFinanciamientoInicial,
      datosCondicionFinanciamiento,
      tipoCambio
    )) {
      let nuevoResultadoFinanciamiento = await esReevaluarResultados();

      if (nuevoResultadoFinanciamiento) {
        if (esReevaluarValidoAlGuardar) {
          setEsVisibleResultReevaluar(true);
        }        
        /*
        MODIFICADO HASTA LA NUEVA ACTUALIZACION DE EXPERIAN
        if (esReevaluarValidoAlGuardar && esReevaluarValidoAlGuardar.newMen < nuevoResultadoFinanciamiento.valorCuota) {
          setEsVisibleResultReevaluar(true);
        }
        */
      }

      if (idmoneda === appConfigKey.keyIdentificadorSoles) {
        montoFinanciamientoDolares = nuevoResultadoFinanciamiento.montoFinanciamiento / tipoCambio;
      } else {
        montoFinanciamientoDolares = nuevoResultadoFinanciamiento.montoFinanciamiento;
      }

    } else {
      if (esReevaluarValidoAlGuardar) {
        setEsVisibleResultReevaluar(true);
      }      
      /*
      MODIFICADO HASTA LA NUEVA ACTUALIZACION DE EXPERIAN
      if (esReevaluarValidoAlGuardar && esReevaluarValidoAlGuardar.newMen < resultadoFinanciamiento?.montoCuota) {
        setEsVisibleResultReevaluar(true);
      }
      */

      if (idmoneda === appConfigKey.keyIdentificadorSoles) {
        montoFinanciamientoDolares = montoFinanciamiento / tipoCambio;
      } else {
        montoFinanciamientoDolares = montoFinanciamiento;
      }

    }


    if (montoFinanciamientoDolares > monto) {
      fueraRango = true;
    }


    if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
      reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos
    ) {
      if (!isVisibleVerSustentoExcepcion && esReevaluarValidoAlGuardar?.esRechazadoSolicitud) {
        toast.error('No se puede guardar, revisar la reevaluación', { duration: appConfigKey.keyDurationToast });
        setShow(true);
        return;
      }
    }


    if (esReevaluarValidoAlGuardar?.noTieneProductosComerciales) {
      toast.error("No se puede guardar, revisar la reevaluación", {
        duration: appConfigKey.keyDurationToast,
      });
      setShow(true);
      return;
    }

    if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
      reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos) {
      if (esReevaluarValidoAlGuardar?.noCoincidenProducto) {
        toast.error('No se puede guardar, revisar la reevaluación', { duration: appConfigKey.keyDurationToast });
        setShow(true);
        return;
      }
    }

    const listaSolicitudEtapas = await Lista.GetSolicitudEtapas(null, Number(solicitudId));
    let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)
    if (listaSolicitudEtapaRechazado.length > 0) {
      toast.error(appConfigKey.keyMsjContinuarSolicitudRechazada);
      setShow(true);
      return
    }

    let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado ||
      x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
    if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
      toast.error(appConfigKey.keyMsjContinuarSolicitudAprobada);
      setShow(true);
      return
    }

    let solicitudAnteriorAprobadoConDocumentos: any = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion && x.ordenProceso === 1);
    let solicitudConAutonomia: any = listaSolicitudEtapas.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia);

    if (solicitudAnteriorAprobadoConDocumentos.length > 0 && solicitudConAutonomia.length === 0) {
      toast.error(appConfigKey.keyMsjContinuarSolicitudAprobadaConDocumentos);
      setShow(true);
      return
    }

    let listaSolicitudEvaluacionCrediticiaEnviada = listaSolicitudEtapas.filter((x: any) => x.identEtapa === appConfigEtapaAnalisisCredito.EvaluacionCrediticia &&
      x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado)
    if (listaSolicitudEvaluacionCrediticiaEnviada.length < 0) {
      toast.error(appConfigKey.keyMsjContinuarSolicitud);
      setShow(true);
      return
    }

    await MasterManager.GetMnResultadoEvaluacionInsert(reqResultadoEvaluacion).then(async (result: any) => {

      if (!result.data?.isValid) {
        toast.error(appConfigKey.keyMsjErrorEnServicio, {
          duration: appConfigKey.keyDurationToast,
        });
        setShow(true);
        return;
      }
      let solicitudEtapaEvaluacionCrediticia = listaSolicitudEtapas.find((x: any) => x.identEtapa === appConfigEtapaAnalisisCredito.EvaluacionCrediticia
        && x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado)

      if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
        reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos ||
        reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadorRechazado
      ) {

        if (!fueraRango || reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadorRechazado) {
          await General.GetSolicitudEtapasUpdate(
            {
              "identSolicitudEtapas": solicitudEtapaEvaluacionCrediticia.identSolicitudEtapas,
              "identSolicitud": solicitudEtapaEvaluacionCrediticia.identSolicitud,
              "identDecisionMotor": solicitudEtapaEvaluacionCrediticia.identDecisionMotor,
              "identEtapa": solicitudEtapaEvaluacionCrediticia.identEtapa,
              "identProceso": solicitudEtapaEvaluacionCrediticia.identProceso,
              "identEstadoProceso": reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ? appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado :
                (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos ? appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion :
                  reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado ? appConfigEstadosProcesoEtapaAnalisisCredito.Observado :
                    appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado
                ),
              "indEstado": solicitudEtapaEvaluacionCrediticia.indEstado,
              "identUsuarioModif": appSecurityConfiguration.SessionUserId,
              "ordenProceso": solicitudEtapaEvaluacionCrediticia.ordenProceso
            }
          );
        } else {

          let solicitudEtapasRequest: any = [];
          solicitudEtapasRequest.push({
            identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
            identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia,
            identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Enviado,
            ordenProceso: 2
          });

          if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado) {

            solicitudEtapasRequest.push({
              identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
              identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
              identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
              ordenProceso: 3
            });

          }

          let SolicitudEtapasInsertRequest: any = {
            identSolicitud: Number(solicitudId),
            identUsuarioCreador: appSecurityConfiguration.SessionUserId,
            identDecisionMotor: solicitudEtapaEvaluacionCrediticia.identDecisionMotor,
            solicitudEtapasRequest: solicitudEtapasRequest
          }

          await General.GetSolicitudEtapasInsert(SolicitudEtapasInsertRequest)


          await General.GetSolicitudEtapasUpdate(
            {
              "identSolicitudEtapas": solicitudEtapaEvaluacionCrediticia.identSolicitudEtapas,
              "identSolicitud": solicitudEtapaEvaluacionCrediticia.identSolicitud,
              "identDecisionMotor": solicitudEtapaEvaluacionCrediticia.identDecisionMotor,
              "identEtapa": solicitudEtapaEvaluacionCrediticia.identEtapa,
              "identProceso": solicitudEtapaEvaluacionCrediticia.identProceso,
              "identEstadoProceso": reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ? appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado :
                (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos ? appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion :
                  reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado ? appConfigEstadosProcesoEtapaAnalisisCredito.Observado :
                    appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado
                ),
              "indEstado": solicitudEtapaEvaluacionCrediticia.indEstado,
              "identUsuarioModif": appSecurityConfiguration.SessionUserId,
              "ordenProceso": solicitudEtapaEvaluacionCrediticia.ordenProceso
            }
          );
        }

      } else {

        await General.GetSolicitudEtapasUpdate(
          {
            "identSolicitudEtapas": solicitudEtapaEvaluacionCrediticia.identSolicitudEtapas,
            "identSolicitud": solicitudEtapaEvaluacionCrediticia.identSolicitud,
            "identDecisionMotor": solicitudEtapaEvaluacionCrediticia.identDecisionMotor,
            "identEtapa": solicitudEtapaEvaluacionCrediticia.identEtapa,
            "identProceso": solicitudEtapaEvaluacionCrediticia.identProceso,
            "identEstadoProceso": reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ? appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado :
              (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos ? appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion :
                reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado ? appConfigEstadosProcesoEtapaAnalisisCredito.Observado :
                  appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado
              ),
            "indEstado": solicitudEtapaEvaluacionCrediticia.indEstado,
            "identUsuarioModif": appSecurityConfiguration.SessionUserId,
            "ordenProceso": solicitudEtapaEvaluacionCrediticia.ordenProceso
          }
        );

        let esAprobadoAutomatico: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.AprobadoAutomatico).length > 0;
        let esZonaGris: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.ZonaGris).length > 0;
        let esRechazado: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.Rechazado).length > 0;

        let solicitudEtapasRequest: any = [];

        let SolicitudEtapasInsertRequest: any = {
          identSolicitud: Number(solicitudId),
          identUsuarioCreador: appSecurityConfiguration.SessionUserId
        }
        if (esAprobadoAutomatico) {

          solicitudEtapasRequest = [];
          solicitudEtapasRequest.push({
            identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
            identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
            identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
            ordenProceso: 1
          });

          SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.AprobadoAutomatico
          SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
        }
        if (esZonaGris) {

          solicitudEtapasRequest = [];

          solicitudEtapasRequest.push({
            identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
            identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion,
            identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
            ordenProceso: 1
          });

          solicitudEtapasRequest.push({
            identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
            identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
            identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
            ordenProceso: 2
          });

          SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.ZonaGris
          SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
        }
        if (esRechazado) {

          solicitudEtapasRequest = [];

          solicitudEtapasRequest.push({
            identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
            identProceso: appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion,
            identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
            ordenProceso: 1
          });

          solicitudEtapasRequest.push({
            identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
            identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
            identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
            ordenProceso: 2
          });

          SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.Rechazado
          SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;

        }

        General.GetSolicitudEtapasInsert(SolicitudEtapasInsertRequest)

      }

      await postMnSaveGestor(
        esReevaluarValidoAlGuardar?.newMen ?? 0,
        esReevaluarValidoAlGuardar?.newCfc ?? 0
      );

      setReqResultadoEvaluacion({
        ...reqResultadoEvaluacion,
        idenT_RESULTADO: 0,
        idenT_DESTINO_CREDITO: 0,
        comentario: "",
        numerO_VISOR: null
      });



      handleNewResultadoHistorico();

      if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos) {

        if (idPersonaCompraPara) {
          //Reporte contrato Persona Compra Para : 77        
          await generarDescargaContrato(idSolicitud,
            Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
            appConfigReportes.CartaConfirmacionCompraPara,
            false,
            "",
          );
        }
        //Soltero
        else if (idEstadoCivilTitular === appConfigKey.keyIdEstadoCivilSoltero) {
          //Reporte contrato Soltero : 74
          await generarDescargaContrato(idSolicitud,
            Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
            appConfigReportes.CartaConfirmacionSoltero,
            false,
            "",
          );
        }
        else if (idEstadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado || idEstadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente) {
          //Reporte contrato Casado : 75
          await generarDescargaContrato(idSolicitud,
            Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
            appConfigReportes.CartaConfirmacionCasado,
            false,
            "",
          );
        }
        else if (idEstadoCivilTitular === appConfigKey.keyIdEstadoCivilViudo) {
          //Reporte contrato Soltero : 74 (Viudo)
          await generarDescargaContrato(idSolicitud,
            Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
            appConfigReportes.CartaConfirmacionSoltero,
            false,
            "",
          );
        }
        else if (idEstadoCivilTitular === appConfigKey.keyIdEstadoCivilDivorciado) {
          //Reporte contrato Divorciado : 78
          await generarDescargaContrato(idSolicitud,
            Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
            appConfigReportes.CartaConfirmacionDivorciado,
            false,
            "",
          );
        }
      }

      if (fueraRango &&
        (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
          reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos)) {

        const body = {
          idenT_SOLICITUD: Number(solicitudId),
          iD_ESTADO_SOLICITUD: appConfigKey.keyIdEstadoSolicitudEnviandoAnalisisCreditoAutonomia,
          idenT_USUARIO_MODIF: appSecurityConfiguration.SessionUserId,
          descL_OBSERVACION: "",
          movimientO_ID: 0,
        };

        RequestManager.GetSolicitudCambiarEstadoById(body);
      }
    })
      .then(async (result: any) => {

        if (esReevaluarValidoAlGuardar?.responseMotorAfterStepThree) {
          await Promise.all([
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepOne,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepOne),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepOne)
            ),
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepTwo,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepTwo),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepTwo)
            ),
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepThree,
              JSON.stringify(
                esReevaluarValidoAlGuardar.requestMotorAfterStepThree
              ),
              JSON.stringify(
                esReevaluarValidoAlGuardar.responseMotorAfterStepThree
              )
            )
          ])
        }
        else if (esReevaluarValidoAlGuardar) {
          await Promise.all([
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepOne,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepOne),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepOne)
            ),
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepTwo,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepTwo),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepTwo)
            )
          ])
        }

        // GENERAR CARTA
        if (esTitularJuridico && !idPersonaCompraPara && reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos) {
          if (!fueraRango) {
            const usuarioSession = Number(localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0);
            await generarDescargaCarta(idSolicitud, usuarioSession, appConfigReportes.CartaConfirmacionEmpresa, false, "");
          }
        } else if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
          (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobadoConDocumentos)) {

          if (!fueraRango) {
            const usuarioSession = Number(localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0);
            await generarDescargaCarta(idSolicitud, usuarioSession, appConfigReportes.CartaPreAprobacion, false, "");
          }

        }

        setShow(true);

        toast.success(appConfigKey.keyMsjSaveGestorsuccess, {
          duration: appConfigKey.keyDurationToast,
        });

        handleSaveDialogClose();

        navigate(`/BandejaSolicitudes/${tipo}`);

      })
      .catch(async (error) => {
        setShow(true);
        if (error.response?.data) {
          let listExceptions: any = error.response.data.exceptions;
          if (listExceptions?.length > 0) {
            toast.error(appConfigKey.keyMsjErrorEnResultado, {
              duration: appConfigKey.keyDurationToast,
            });
          } else {
            toast.error(appConfigKey.keyMsjErrorEnServicio, {
              duration: appConfigKey.keyDurationToast,
            });
          }
        } else {
          toast.error(appConfigKey.keyMsjErrorEnServicio, {
            duration: appConfigKey.keyDurationToast,
          });
        }
      })
      .finally(() => {
        setShow(true);
      });
  };

  const asociarDocumentoDigital = async (
    documentoId: any,
    tipoDocumentoId: number,
    nombre: any,
    archivoCodificado: any,
    fechaEmision: any,
    fechaVencimiento: any,
    estadoAdjunto: number,
    fulldata: any,
  ) => {

    try {
      let idSolicitud = Number(solicitudId);
      let asociarBTRequest = new BTAsociarDocumentoDigitalRequest();
      asociarBTRequest.tipoDocumentoId = tipoDocumentoId;
      asociarBTRequest.nombre = nombre;
      asociarBTRequest.archivoCodificado = archivoCodificado;
      asociarBTRequest.fechaEmision = fechaEmision;
      asociarBTRequest.fechaVencimiento = fechaVencimiento;

      let upsertSantiRequest = new TMnDocumentacionDocumentoInsertRequest();
      upsertSantiRequest.idenT_DOCUMENTACION_DOCUMENTO = 0;
      upsertSantiRequest.idenT_SOLICITUD = idSolicitud;
      upsertSantiRequest.idenT_TIPO_LISTADO = 2;
      upsertSantiRequest.descC_TIPO_LISTADO = "CHECKLIST2";
      upsertSantiRequest.idenT_TIPO_DOCUMENTO = 65;
      upsertSantiRequest.descC_TIPO_DOCUMENTO = "Carta Confirmación";
      upsertSantiRequest.idenT_ESTADO_ADJUNTO = appConfigKey.keyIdEstadoDocumentoAdjuntado;
      upsertSantiRequest.descC_ESTADO_ADJUNTO = appConfigKey.keyDescripEstadoDocumentoAdjuntado;
      upsertSantiRequest.descC_NOMBRE_ARCHIVO = GeneralHelper.GetNombreArchivo(nombre);
      upsertSantiRequest.descC_EXTENSION_ARCHIVO = `.${GeneralHelper.GetExtensionArchivo(nombre)}`;
      // upsertSantiRequest.idenT_DOCUMENTO_BT = responseAsociarDocumentoDigital?.data?.content?.documentoId;
      upsertSantiRequest.idenT_USUARIO = Number(appSecurityConfiguration.SessionUserId);

      await DocumentManager.CargarDocumentoBTSanti(asociarBTRequest, upsertSantiRequest, idSolicitud);
    } catch (error: any) {
      toast.error(`${error?.message ?? error}`, { duration: appConfigKey.keyDurationToast });
    }

  };

  const asociarDocumentoDigitalCartaAprobacion = async (
    documentoId: any,
    tipoDocumentoId: number,
    nombre: any,
    archivoCodificado: any,
    fechaEmision: any,
    fechaVencimiento: any,
    estadoAdjunto: number,
    fulldata: any,
  ) => {

    try {
      let idSolicitud = Number(solicitudId);
      let asociarBTRequest = new BTAsociarDocumentoDigitalRequest();
      asociarBTRequest.tipoDocumentoId = tipoDocumentoId;
      asociarBTRequest.nombre = nombre;
      asociarBTRequest.archivoCodificado = archivoCodificado;
      asociarBTRequest.fechaEmision = fechaEmision;
      asociarBTRequest.fechaVencimiento = fechaVencimiento;

      let upsertSantiRequest = new TMnDocumentacionDocumentoInsertRequest();
      upsertSantiRequest.idenT_DOCUMENTACION_DOCUMENTO = 0;
      upsertSantiRequest.idenT_SOLICITUD = idSolicitud;
      upsertSantiRequest.idenT_TIPO_LISTADO = 2;
      upsertSantiRequest.descC_TIPO_LISTADO = "CHECKLIST2";
      upsertSantiRequest.idenT_TIPO_DOCUMENTO = tipoDocumentoId;
      upsertSantiRequest.descC_TIPO_DOCUMENTO = "Carta Aprobación";
      upsertSantiRequest.idenT_ESTADO_ADJUNTO = appConfigKey.keyIdEstadoDocumentoAdjuntado;
      upsertSantiRequest.descC_ESTADO_ADJUNTO = appConfigKey.keyDescripEstadoDocumentoAdjuntado;
      upsertSantiRequest.descC_NOMBRE_ARCHIVO = GeneralHelper.GetNombreArchivo(nombre);
      upsertSantiRequest.descC_EXTENSION_ARCHIVO = `.${GeneralHelper.GetExtensionArchivo(nombre)}`;
      // upsertSantiRequest.idenT_DOCUMENTO_BT = responseAsociarDocumentoDigital?.data?.content?.documentoId;
      upsertSantiRequest.idenT_USUARIO = Number(appSecurityConfiguration.SessionUserId);

      await DocumentManager.CargarDocumentoBTSanti(asociarBTRequest, upsertSantiRequest, idSolicitud);
    } catch (error: any) {
      toast.error(`${error?.message ?? error}`, { duration: appConfigKey.keyDurationToast });
    }

  };

  function fileToBase64(file: any) {
    return new Promise<string>((resolve, reject) => {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String: string = reader.result.split(",")[1];
        resolve(base64String);
      };
      reader.onerror = (error: any) => reject(error);
    });
  }

  function obtenerFechaActual(addYear: number) {
    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, "0"); // El mes es zero-based, por lo que se suma 1
    const dia = String(fecha.getDate()).padStart(2, "0");
    const fechaActual = `${anio + addYear}-${mes}-${dia}`;
    return fechaActual;
  }

  const generarDescargaContrato = async (
    solicitudId: number,
    usuarioId: number,
    reporteId: number,
    bpo: boolean,
    data: any,
  ) => {
    const response: any = await descargarReporte(
      solicitudId,
      usuarioId,
      reporteId,
      bpo
    );
    if (!response) {
      toast.error("Error al descargar el documento.", {
        duration: appConfigKey.keyDurationToast,
      });
      return;
    }

    var fileName = null;
    var header = response?.headers["content-disposition"];
    if (header) {
      var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(header);
      if (matches != null && matches[1]) {
        // fileName = matches[1].replace(/['"UTF\-8]/g, "");
        fileName = matches[1].replace(/UTF-8['"]*/g, '');
        fileName = decodeURI(fileName);

        const tipoDocumentoId = 65;
        const fileNames = fileName;
        const archivo = await fileToBase64(response?.data);
        const fechaActual = obtenerFechaActual(0);
        const fechaVencimiento = ""; //obtenerFechaActual(1);
        const estadoAdjunto = 98;
        const documentoId = 65;

        await asociarDocumentoDigital(
          documentoId,
          tipoDocumentoId,
          fileNames,
          archivo,
          fechaActual,
          fechaVencimiento,
          estadoAdjunto,
          data,
        );
      }
    }

    if (!fileName) {
      fileName = `${data.genericA_DESCM_CAMPO}_${solicitudId}.docx`;
    }

    FileHelper.downloadFileFromBlob(response?.data, fileName);
  };

  const generarDescargaCarta = async (
    solicitudId: number,
    usuarioId: number,
    reporteId: number,
    bpo: boolean,
    data: any,
  ) => {

    const response: any = await descargarReporte(solicitudId, usuarioId, reporteId, bpo);

    if (!response) {
      toast.error("Error al descargar el documento.", { duration: appConfigKey.keyDurationToast });
      return;
    }

    var fileName = null;

    var header = response?.headers["content-disposition"];

    if (header) {

      var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;

      var matches = filenameRegex.exec(header);

      if (matches != null && matches[1]) {
        // fileName = matches[1].replace(/['"UTF\-8]/g, "");
        fileName = matches[1].replace(/UTF-8['"]*/g, '');
        fileName = decodeURI(fileName);

        const tipoDocumentoId = 20;
        const fileNames = fileName;
        const archivo = await fileToBase64(response?.data);
        const fechaActual = obtenerFechaActual(0);
        const fechaVencimiento = "";
        const estadoAdjunto = 98;
        const documentoId = 20;

        await asociarDocumentoDigitalCartaAprobacion(
          documentoId,
          tipoDocumentoId,
          fileNames,
          archivo,
          fechaActual,
          fechaVencimiento,
          estadoAdjunto,
          data,
        );

      }
    }

    if (!fileName) {
      fileName = `${data.genericA_DESCM_CAMPO}_${solicitudId}.docx`;
    }

    FileHelper.downloadFileFromBlob(response?.data, fileName);

  };

  const descargarReporte = async (
    solicitudId: number,
    usuarioId: number,
    reporteId: number,
    bpo: boolean
  ) => {
    try {
      const responseObtenerReporte = await ReportManager.ObtenerReporte({
        idSolicitud: solicitudId,
        idUsuario: usuarioId,
        idReporte: reporteId,
        indBPO: bpo,
      });

      if (responseObtenerReporte.status === 200) {
        return responseObtenerReporte;
      }
    } catch (error) {
      return null;
    }
  };

  const handleVerClasifPorIntegrante = async (
    tipoIntegrante: string,
    numeroDocumento: string,
    tipoDocumento: string
  ) => {

    if (tipoIntegrante === appConfigKey.keyDescripTipoIntegranteConyuge && esSeparadoBienes) {
      return
    }
    //setShow(false);
    setInformacionEntidad(InformacioEntidadDefault);
    setClasificacion(0);
    setTipoIntegranteClasif(tipoIntegrante?.toUpperCase());
    const dtoClasificacion: ClasificacionDto = {
      solicitud: String(solicitudId),
      tipo_integrante: tipoIntegrante,
      documento: numeroDocumento,
      tipo_documento: tipoDocumento,
    };
    const reqClasificacion: reqClasificacionDto = {
      request: dtoClasificacion,
    };

    setEsVisibleSDR(true);
    if (tipoIntegrante === appConfigKey.keyDescripTipoIntegranteTitular) {
      setDefaultValueTab(0);
    } else if (
      tipoIntegrante === appConfigKey.keyDescripTipoIntegranteConyuge
    ) {
      setDefaultValueTab(1);
      if (
        conyuge.ingresos.idcategoriaLaboral === appConfigKey.keyIdSinCategoria
      ) {
        setEsVisibleSDR(false);
      }
    } else {
      setEsVisibleSDR(false);
    }

    await getWorkflowClasificacion(reqClasificacion)
      .then((response: any) => { })
      .finally(() => { });
  };

  const NewModelicaData = (response: any, step: number) => {
    let mensajeModelica = "";

    try {
      if (!response) {
        mensajeModelica = `Llamada ${step}: ${appConfigKey.keyMsjErrorEnConsultaMotor}`;
        return mensajeModelica;
      }

      const { data } = response;

      if (!data?.isValid) {
        mensajeModelica = `Llamada ${step}: ${appConfigKey.keyMsjErrorEnConsultaMotor}`;
        return mensajeModelica;
      }

      const responseModelica = JSON.parse(data.content);
      if (!responseModelica.response?.Politica_Creditos) {
        mensajeModelica = `Llamada ${step}: ${responseModelica.response?.error?.message}`;
        return mensajeModelica;
      }
    } catch (error) {
      mensajeModelica = `Llamada ${step}: ${appConfigKey.keyMsjErrorEnConsultaMotor}`;
      return mensajeModelica;
    }
  };

  const ModellicaMotorEvaluacion = async (body: any) => {
    const response: any = await General.Modelica(body);
    return response;
  };

  const GetMnEvaluacionSolicitud = async (identSolicitud: any) => {
    const response: any = await Lista.GetMnEvaluacionSolicitud(identSolicitud);
    return response;
  };

  const getRequestYResponseModellicaMotor = async () => {
    if (typeof solicitud == "number") return;
    if (solicitud.esTitularJuridico) return;
    if (titular?.ingresos?.idcategoriaLaboral === appConfigKey.keyId3raCategoríaPerRUC) return;

    const tempEvaluacionSolicitud = await GetMnEvaluacionSolicitud(solicitudId);
    if (tempEvaluacionSolicitud.length > 0) {

      if (tempEvaluacionSolicitud.length <= 1) {
        toast.error(appConfigKey.keyMsjErrorObtenerEvaluacionMotor, { duration: appConfigKey.keyDurationToast });
      }

      for (let evaluacion of tempEvaluacionSolicitud) {
        let request = evaluacion?.jsoN_REQUEST
          ? JSON.parse(evaluacion.jsoN_REQUEST)
          : null;
        let response = evaluacion?.jsoN_RESPONSE
          ? JSON.parse(evaluacion.jsoN_RESPONSE)
          : null;

        if (nvlRiesgo === null && response?.response?.Politica_Creditos?.decision_Credito?.nivel_Riesgo) {
          setnvlRiesgo(response.response.Politica_Creditos.decision_Credito.nivel_Riesgo);
        }

        if (
          evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepOne
        ) {
          setRequestMotorBeforceStepOne(request);
          setResponseMotorBeforceStepOne(response);
        }

        if (
          evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo
        ) {

          setRequestMotorBeforceStepTwo(request);
          setResponseMotorBeforceStepTwo(response);
          setResponseMotorMotivo(null)

          let tempDecision_Credito: any = response?.response?.Politica_Creditos?.decision_Credito;
          if (tempDecision_Credito?.decision === appConfigKey.CodigoEvaluacionZonaGris) {
            let motivos: any = tempDecision_Credito?.mesa_Creditos?.motivo_Derivacion;
            let listaMotivos: any = [];
            if (motivos) {
              for (let motivo of motivos) {
                listaMotivos.push(motivo.detalle)
              }
            }
            setResponseMotorMotivo(listaMotivos)
          }

          if (tempDecision_Credito?.decision === appConfigKey.CodigoEvaluacionRechazado) {

            let tempMotivo_Rechazo: any = response?.response?.Politica_Creditos?.motivo_Rechazo;
            let motivos: any = tempMotivo_Rechazo?.Detalle;
            let listaMotivos: any = [];
            if (motivos) {
              for (let motivo of motivos) {
                listaMotivos.push(motivo.interviniente + ': ' + motivo.descripcion)
              }
            }
            setResponseMotorMotivo(listaMotivos)
          }
        }

        if (
          evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepThree
        ) {
          setRequestMotorBeforceStepThree(request);
          setResponseMotorBeforceStepThree(response);
        }
      }
    } else {
      toast.error(appConfigKey.keyMsjErrorObtenerEvaluacionMotor, { duration: appConfigKey.keyDurationToast });
    }
  };

  const getUsuarioCargo = async () => { };

  const handleCloseReleaseExceptionDialog = () => {
    setOpenReleaseExceptionDialog(false);
  };
  const handleLiberarExcepcion = () => {
    setOpenReleaseExceptionDialog(true);
  }

  const obtenerConfigAccionesPorSolicitud = async () => {
    setIsVisibleVerSustentoExcepcion(false);
    setIsVisibleAprobadoConDocumentacion(true);
    const response = await Lista.GetSolicitudEtapas(null, Number(solicitudId));
    if (response.length > 0) {
      let solicitudConExcepcion: any = response.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion);
      if (solicitudConExcepcion.length > 0) {
        setIsVisibleVerSustentoExcepcion(true);
      }
      let solicitudConAutonomia: any = response.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia);
      if (solicitudConAutonomia.length > 0) {
        setIsVisibleAprobadoConDocumentacion(false);
      }

    }
  }

  const InitDefaultEvaluacionCrediticia = async () => {

    await Promise.all([
      obtenerConfigAccionesPorSolicitud(),
      getObtenerTipoDeuda(),
      getObtenerSegmento(),
      getMnEvaluacionScoringGestor(),
    ])

  };

  useEffect(() => {
    if (solicitud && titular?.ingresos?.idPersona > 0) {
      getRequestYResponseModellicaMotor();  
    }
  }, [solicitud, titular]);

  useEffect(() => {
    //Matriz de evaluación
    if (tabsPosition == 1) { 
      getObtenerGiroNegocio();
    }
  }, [tabsPosition])

  useEffect(() => {
    InitDefaultEvaluacionCrediticia();
  }, []);


  return (
    <div>
      <Toaster
        position="top-center"
        toastOptions={{ style: { width: "500px", margin: "0 auto" } }}
        richColors
        closeButton
      />
      <MainLayout />
      <CardTitleStart Ventana={title} identSolicitud={solicitudId} />
      <CardTitleSummary
        solicitud={solicitud}
        isVisibleVerSustentoExcepcion={isVisibleVerSustentoExcepcion}
        handleLiberarExcepcion={handleLiberarExcepcion} />
      <CardAlerts
        resultadoFinancimaiento={resultadoFinanciamiento}
        solicitud={solicitud}
      ></CardAlerts>
      {!show && <LoadingProgress />}

      {
        <div className="row g-2 mb-2 cardAnalystProfile">
          <div className="col-lg-9">
            <CardTitleScore
              scoring={nvlRiesgo}
              setScoring={setnvlRiesgo}
              resultadoEvaluacionResumen={resultadoEvaluacionResumen}
              setResultadoEvaluacionResumen={setResultadoEvaluacionResumen}
              motorMotivos={responseMotorMotivo}
            />
            {esVisibleResultReevaluar && (
              <CardResultReevaluar
                responseMotorAfterStepTwo={responseMotorAfterStepTwo}
                responseMotorBeforceStepTwo={responseMotorBeforceStepTwo}
                listaSegmento={listaSegmento}
                newMEN={newMEN}
                newCuota={newCuota}
                solicitud={solicitud}
              />
            )}

            <div className="row mb-2">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 bottom-right" >
                <Button
                  size="md"
                  color="danger"
                  disabled={esModoLectura}
                  style={{ marginRight: "35px" }}
                  sx={{ borderRadius: 24, width: 3 / 10 }}
                  onClick={handleReevaluar}
                >
                  Reevaluar
                </Button>
              </div>
            </div>
            <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
              <Tabs value={tabsPosition} onChange={handleTabsPosition}>
                {tabsHeader.sort((a, b) => a.order - b.order).map((header, index) => (
                  header?.visible ? (
                    <Tab
                      key={index}
                      value={header.value}
                      label={header.label}
                      className="header-tab-documentation"
                    />
                  ) : null
                ))}
              </Tabs>
            </div>
            {tabsPosition === 0 && (
              <>
                <ScoringTitular
                  titular={titular}
                  titularBeforce={titularBeforce}
                  setTitular={setTitular}
                  handleReevaluar={handleReevaluar}
                  esModoLectura={esModoLectura}
                  esTitularJuridico={esTitularJuridico}
                />
                {((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
                  (estadoCivilTitular ===
                    appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
                  categoriaLaboralConyuge !==
                  appConfigKey.keyIdSinCategoria && (
                    <ScoringConyuge
                      conyuge={conyuge}
                      setConyuge={setConyuge}
                      conyugeBeforce={conyugeBeforce}
                      handleReevaluar={handleReevaluar}
                      showConyugue={showConyugue}
                      esModoLectura={esModoLectura}
                    />
                  )}
                <ScoringCliente
                  posicionCliente={posicionCliente}
                  setPosicionCliente={setPosicionCliente}
                  show={show}
                />
                <ScoringClasificacionSBS
                  clasificacion={clasificacion}
                  tipoIntegranteClasif={tipoIntegranteClasif}
                />
                {esVisibleSDR && (
                  <ScoringDatosReevaluacion
                    datosReevaluacionTitular={datosReevaluacionTitular}
                    setDatosReevaluacionTitular={setDatosReevaluacionTitular}
                    datosReevaluacionConyuge={datosReevaluacionConyuge}
                    setDatosReevaluacionConyuge={setDatosReevaluacionConyuge}
                    estadoCivilTitular={estadoCivilTitular}
                    handleReevaluar={handleReevaluar}
                    listaTipoDeuda={listaTipoDeuda}
                    datosReevaluacionOrigenTitular={
                      datosReevaluacionOrigenTitular
                    }
                    datosReevaluacionOrigenConyuge={
                      datosReevaluacionOrigenConyuge
                    }
                    defaultValueTab={defaultValueTab}
                    esModoLectura={esModoLectura}
                    clasificacionTitular={clasificacionTitular}
                    setClasificacionTitular={setClasificacionTitular}
                    clasificacionConyuge={clasificacionConyuge}
                    setClasificacionConyuge={setClasificacionConyuge}
                    categoriaLaboralConyuge={categoriaLaboralConyuge}
                    handleVerClasifPorIntegrante={handleVerClasifPorIntegrante}
                    members={members}
                    clasificacionTitularOriginal={clasificacionTitularOriginal}
                    clasificacionConyugeOriginal={clasificacionConyugeOriginal}
                    setShow={setShow}
                    esSeparadoBienes={esSeparadoBienes}
                  />
                )}
                <ScoringInformacionEntidadesSBS
                  clasificacion={clasificacion}
                  informacionEntidad={informacionEntidad}
                  tipoIntegranteClasif={tipoIntegranteClasif}
                />
              </>
            )}
            {tabsPosition === 1 && informacionTercera && (
              <>
                <MatrizDatosCredito
                  capacidadPagoTabla={capacidadPagoTabla ?? []}
                  onCapacidadPagoTablaChange={buildMatrizRegimen}
                  perdidaGananciaTabla={perdidaGananciaTabla ?? []}
                  onPerdidaGananciaTablaChange={buildMatrizRegimen}
                  informacionTercera={informacionTercera}
                  setInformacionTercera={setInformacionTercera}
                  esModoLectura={esModoLectura}
                  restoreMatriz={restoreMatriz}
                  listaGiroNegocio={listaGiroNegocio}
                />
                <MatrizBalanceGeneral
                  balanceGeneralTabla={balanceGeneralTabla ?? []}
                  onBalanceGeneralTablaChange={buildMatrizRegimen}
                  esModoLectura={esModoLectura}
                />
                <MatrizFlujoCaja
                  flujoCajaTabla={flujoCajaTabla ?? []}
                  onFlujoCajaTablaChange={buildMatrizRegimen}
                  esModoLectura={esModoLectura}
                />
              </>

            )}
            {tabsPosition === 2 && (
              <>
                <FinanciamientoDatosCondiciones
                  datosCondicionFinanciamiento={datosCondicionFinanciamiento}
                  datosCondicionFinanciamientoOriginal={
                    datosCondicionFinanciamientoOriginal
                  }
                  resultadoFinanciamiento={resultadoFinanciamiento}
                  setDatosCondicionFinanciamiento={
                    setDatosCondicionFinanciamiento
                  }
                  setShow={setShow}
                  setResult={setResultadoFinanciamiento}
                  esModoLectura={esModoLectura}
                  cuotas={cuotas}
                  solicitud={solicitud}
                  statecuotaDoble={stateCuotaDoble}
                />
              </>
            )}
            {tabsPosition === 3 && (
              <>
                <ResultadosDocumentos
                  solicitud={solicitud}
                  esModoLectura={esModoLectura}
                  setDocumentos={setDocumentos}
                />
                <ResultadosEvaluacion
                  handleNewResultadoHistorico={handleNewResultadoHistorico}
                  solicitud={solicitud}
                  esModoLectura={esModoLectura}
                  reqResultadoEvaluacion={reqResultadoEvaluacion}
                  setReqResultadoEvaluacion={setReqResultadoEvaluacion}
                  titular={titular}
                  isVisibleAprobadoConDocumentacion={isVisibleAprobadoConDocumentacion}
                  isVisibleAprobado={isVisibleAprobado}
                />

                <div className="row mb-2 align-items-center ">
                  <div className="col-lg-6 cardObjets"></div>
                  <div className="col-lg-6 cardObjets bottom-right">
                    <Button
                      size="md"
                      color="danger"
                      endDecorator={<KeyboardArrowRight />}
                      style={{ marginRight: "35px" }}
                      sx={{ borderRadius: 24, width: 3 / 10 }}
                      onClick={handleSaveDialogOpen}
                      disabled={esModoLectura}
                    >
                      Guardar
                    </Button>
                  </div>
                </div>

                <ResultadosHistorial
                  flgResultadosHistorial={flgResultadosHistorial}
                />
              </>
            )}

            <div className="row g-2 mb-2 align-items-center ">
              <div className="col-lg-6 cardObjets"></div>
              <div className="col-lg-6 cardObjets bottom-right"></div>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ position: "sticky", top: "0" }}>
              <CardDebst
                debts={debts}
                men={newMEN}
                tipoCambio={tipoCambio}
                montoCuota={resultadoFinanciamiento?.montoCuota || 0}
                esTitularJuridico={esTitularJuridico}
                titular={titular}
                datosCondicionFinanciamiento={datosCondicionFinanciamiento}

              />
              {[0, 1].includes(tabsPosition) && (
                <CardMembers
                  members={members}
                  handleVerClasifPorIntegrante={handleVerClasifPorIntegrante}
                />
              )}
              {[2, 3].includes(tabsPosition) && (
                <CardResult result={resultadoFinanciamiento} />
              )}
            </div>
          </div>
        </div>
      }

      <SaveDialog open={saveDialogOpen} handleClose={handleSaveDialogClose} />
      <ReleaseExceptionDialog
        data={{
          IdSolicitud: Number(solicitudId)
        }}
        open={openReleaseExceptionDialog}
        setEsModoLectura={setEsModoLectura}
        solicitud={solicitud}
        setSolicitud={setSolicitud}
        handleTabsPositionReset={handleTabsPositionReset}
        handleClose={handleCloseReleaseExceptionDialog}
      />
    </div>
  );
};

export default CreditEvaluation;
