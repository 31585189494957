import { useContext, useEffect, useState } from "react";
import { StepThreeContext } from "../3.10.-base/providers/StepThreeProvider";
import { StepsContext } from "../3.10.-base/providers";
import { CondicionesFinancimaientoInterface, CondicionesFinancimaientoInterfaceDefault } from "./Interfaces";
import TitleCard from "../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { DateInput, MoneyInput, NumberDecimalInput, NumberInput, SelectInput, SimpleInput, SwitchInput } from "../3.40.-SolicitudeStepOne/components/elements/inputs";
import JsonHelper from "../../../helpers/JsonHelper";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { appConfigKey, appConfigTea, appDefaultValues } from "../../../../config/Config";
import {
  Button,
  IconButton
} from "@mui/joy";
import PencilIcon from '@mui/icons-material/Edit';
import CambiarTeaOpen from "./components/cambiarTeaOpen/index";
import { useParams } from 'react-router-dom';
import { BsPencilFill } from "react-icons/bs";
import FormLabel from "@mui/joy/FormLabel";
//import { General } from "./../3.10.-base/obtenerServicios";

import { BanTotalComisiones } from "../../../constant/BanTotalComisiones";
import { BantotalCIPrecioSelect } from "../../../constant/BantotalTipoDescuento";
import { MotorTipoMonedaCodigos } from "../../../constant/MotorTipoMoneda";
import { toast } from "sonner";
import { CalcularInicialConBonoRequest, calcularPorcentajeConBono } from "../../../helpers/CalculosConBonoHelper";
import ParameterHelper from "../../../helpers/ParameterHelper";

const SolicitudeStepThree = () => {

  const { solicitudId } = useParams();

  const {
    condicionesFinanciamientoViewModel,
    setNewCondicionesFinanciamiento,

    getObtenerCampanias,
    getObtenerComisiones,
    getObtenerComisionesCuotas,
    getObtenerSeguroVehicular,
    getObtenerPlazosMeses,
    getObtenerTipoCoberturaDesg,

    listaProductos,
    listaSeguroVehicular,
    listaPlazosMeses,
    listaTipoCoberturaDesg,
    listaCampania,
    listaComisiones,
    listaComisionesCoutas,
    listaComisionesSeleccionas,
    listaComisionesCuotasSeleccionas,

    setListaComisionesSeleccionas,
    setListaComisionesCuotasSeleccionas,

    validarActivacionCuotaBallon,
    activarCuotaBallon,

    valorCuotaDoble,
    disableCuotaDoble,

    obtenerTEA,

    isCambiarTeaOpen,
    handleCambiarTeaDialogClose,
    updateTea,
    cambiarTasa,

    activarSelectCIPrecio, setActivarSelectCIPrecio,
    activarCuotaBallon50, setActivarCuotaBallon50,
    activarCuotaBallonFlex, setActivarCuotaBallonFlex
  } = useContext(StepThreeContext);

  const {
    clickNext,
    viewModelStepOneFinal,
    viewModelStepTwoFinal,
    listCIPrecio, setListCIPrecio,
    listProveedorGPS,
    listDiaPago,
    readonlyForm,
    setProductoSeleccionado,
    setStepsCampaniaId,
    setStepsCIPrecio,
    setClickOnObtenerTEA,
    solicitudData,
    isTitularJuridico,
    stepsListaCampanias,
    stepsCIPrecio,
    stepsCampaniaId,
    stepsMontoBono
  } = useContext(StepsContext);

  const [viewModel, setViewModel] = useState<CondicionesFinancimaientoInterface>(CondicionesFinancimaientoInterfaceDefault);
  const [nuevaTea, setNuevaTea] = useState<string>("");
  const [visibleTipoCoberturaDesg, setVisibleTipoCoberturaDesg] = useState<boolean>(false);

  const eventoPrimeraCarga = () => {
    setViewModel(condicionesFinanciamientoViewModel);
    //setNuevaTea(condicionesFinanciamientoViewModel.tea);
    ////myowasp(nuevaTea);
    //alert(nuevaTea);  
  };

  const eventoProducto = () => {
    if (viewModel.identProducto !==0 && listaProductos.length > 0) {     
      const esProductoHabilitado = listaProductos.some((producto: any) => producto.id === viewModel.identProducto)
      console.log(esProductoHabilitado);
      console.log(viewModel);
      
      if (!esProductoHabilitado) {
        setViewModel((prevState) => ({
          ...prevState,
          identProducto: 0
        }))
      }
    }
  }

  const eventoContinuar = () => {
    if (clickNext !== 0) {
      setNewCondicionesFinanciamiento(viewModel);
    }
  };

  const eventoActualizarCamposVisibles = () => {
    if (viewModelStepOneFinal) {
      setVisibleTipoCoberturaDesg(viewModelStepOneFinal.newTitularData.TipoDocumentoId != appConfigKey.keyIdRUC);
    }
  }

  const eventoCuotaFlex = () => {

    if (!readonlyForm && viewModelStepTwoFinal && activarCuotaBallonFlex) {
      const requestCalculoInicial = new CalcularInicialConBonoRequest();
      requestCalculoInicial.set_divisa(Number(viewModelStepTwoFinal.MonedaId));
      requestCalculoInicial.set_tipoCambio(Number(viewModelStepTwoFinal.TipoCambio));
      requestCalculoInicial.set_porcentajeInicial(Number(viewModelStepTwoFinal.Inicial));
      requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)));
      requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares)));
      requestCalculoInicial.set_tipoDescuento(Number(stepsCIPrecio));
      requestCalculoInicial.set_montoBono(Number(stepsMontoBono));

      if (stepsCIPrecio) {
        const campaniaEncontrada = stepsListaCampanias.find(
          (x: any) => x.id === stepsCampaniaId
        );

        if (campaniaEncontrada !== undefined) {
          requestCalculoInicial.set_montoBono(Number(campaniaEncontrada.montoBono));
        }
      }
      setViewModel(prevState => ({
        ...prevState,
        CuotaBallon: (100 - calcularPorcentajeConBono(requestCalculoInicial)).toFixed(2)
      }));
    }
  }

  useEffect(() => eventoPrimeraCarga(), [condicionesFinanciamientoViewModel]);
  useEffect(() => eventoContinuar(), [clickNext]);
  useEffect(() => eventoActualizarCamposVisibles(), [viewModelStepOneFinal])
  useEffect(() => eventoCuotaFlex(), [viewModelStepTwoFinal, activarCuotaBallonFlex, stepsCIPrecio])
  useEffect(() => eventoProducto(), [listaProductos, viewModel.identProducto])
  interface ProveedorGPS {
    id: number; // O el tipo correcto para identificador de proveedor GPS
    // Otros campos que tenga tu objeto ProveedorGPS
  }

  return (
    <div>
      {/* Condiciones del financiamiento */}
      <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
        <TitleCard title={"CONDICIONES DEL FINANCIAMIENTO"} />
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 cardObjets">
            {readonlyForm ? (
              <SimpleInput title={"Producto"} value={solicitudData.descC_PRODUCTO} disabled={true} />
            ) : (
              <SelectInput
                name={"identProducto"}
                title={"Producto"}
                options={listaProductos}
                value={viewModel.identProducto}
                onChange={(event: any, newValue: any) => {

                  // const { keyCodigoProductoPlan5012M, keyCOdigoProductoPlan5024M, keyCodigoProductoCuotaFlex } = appConfigKey;

                  /*if (newValue === keyCodigoProductoPlan5012M || newValue === keyCOdigoProductoPlan5024M) {
                    setActivarCuotaBallon50(true);
                  } else {
                    setActivarCuotaBallon50(false);
                  }*/

                  if (ParameterHelper.GetCalProductBalloon().includes(newValue)) {
                    setActivarCuotaBallonFlex(true);
                  } else {
                    setActivarCuotaBallonFlex(false);
                  }

                  setViewModel({
                    ...viewModel,
                    identProducto: newValue,
                    identSeguroVehicular: 0,
                    identPlazosMeses: 0,
                    identTipoCoberturaDesg: 0,
                    identCampania: 0,
                    identCIPrecio: '0',
                    CuotaBallon: ''
                  });

                  setProductoSeleccionado(newValue);
                  getObtenerSeguroVehicular(newValue);
                  getObtenerPlazosMeses(newValue);
                  getObtenerTipoCoberturaDesg(newValue);

                  const montoVehiculo = viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorSoles ?
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)) :
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares));

                  const montoInicial = viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorSoles ?
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoInicialSoles)) :
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoInicialDolares));

                  const jsonData = JsonHelper.crearJsonRequestObtenerCampanias(
                    viewModelStepTwoFinal.SucursalId,
                    viewModelStepTwoFinal.VendedorId,
                    viewModelStepTwoFinal.ConcesionarioId,
                    viewModelStepTwoFinal.EstadoVehiculoId,
                    Number(montoVehiculo),
                    Number(montoInicial),
                    GeneralHelper.ObtenerFechaActual(),
                    appConfigKey.seguroObligatorioNO
                  );

                  getObtenerCampanias(newValue, viewModelStepTwoFinal.VersionId, jsonData);

                  getObtenerComisiones(newValue);
                  getObtenerComisionesCuotas(newValue);
                  validarActivacionCuotaBallon(Number(newValue));

                }}
              />
            )}
          </div>
          <div className="col-lg-2 cardObjets">
            {readonlyForm ? (
              <SimpleInput title={"Plazo en meses"} value={solicitudData.indeT_PLAZO} disabled={true} />
            ) : (
              <SelectInput
                name={"identPlazosMeses"}
                title={"Plazo en meses"}
                options={listaPlazosMeses}
                value={viewModel.identPlazosMeses}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identPlazosMeses: newValue })}
              />
            )}

          </div>
          <div className="col-lg-2 cardObjets">
            {readonlyForm ? (
              <SimpleInput title={"Día pago"} value={solicitudData.descC_DIA_PAGO} disabled={true} />
            ) : (
              <SelectInput
                name={"identDiaPago"}
                title={"Día pago"}
                options={listDiaPago}
                value={viewModel.identDiaPago}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({
                  ...viewModel, identDiaPago: newValue,
                  diaPago: newValue > 0 ? listDiaPago.filter((x: any) => x.id === newValue)[0].description : '',
                  identCampania: 0,
                  identCIPrecio: '0'
                })}
              />
            )}
          </div>
          <div className="col-lg-2 cardObjets">
            <SwitchInput
              name={"esCuotasDobles"}
              title={"Cuotas dobles (Enero, Agosto)"}
              checked={viewModel.esCuotasDobles}
              // disabled={readonlyForm || ((viewModelStepOneFinal?.newIngresosTitularData?.identCategoriaLaboral !== appConfigKey.keyCodigoCategoriaLaboralCinco) ||
              //                            (viewModelStepOneFinal?.newIngresosTitularData?.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && 
              //                             viewModelStepOneFinal?.newIngresosTitularData?.anualizado === true)
              disabled={readonlyForm || disableCuotaDoble || isTitularJuridico}
              onChange={(event: any) => setViewModel({ ...viewModel, esCuotasDobles: event.target.checked })}
            />
          </div>
          <div className="col-lg-2 cardObjets">
            {(activarCuotaBallon || activarCuotaBallonFlex) && (
              <NumberDecimalInput
                startDecorator={"%"}
                title={"Cuota Ballon"}
                placeholder=""
                value={viewModel.CuotaBallon}
                disabled={readonlyForm}
                numberOfDecimals={2}
                longNumber={5}
                required={false}
                onChange={(event: any) => setViewModel({ ...viewModel, CuotaBallon: event.target.value })}
              />
            )}

            {activarCuotaBallon50 && (
              <NumberDecimalInput
                startDecorator={"%"}
                title={"Cuota Ballon"}
                value={50}
                disabled={true}
                required={false}
              />
            )}
          </div>
        </div>

        {/* <div className="col-lg-2 cardObjets"> */}
        {/* <SwitchInput
              name={"esSeguroVehicularEndosado"}
              title={"Seguro vehicular endosado"}
              checked={viewModel.esSeguroVehicularEndosado}
              disabled={readonlyForm}
              onChange={(event: any) => {
                setViewModel({
                  ...viewModel, esSeguroVehicularEndosado: event.target.checked,
                  identSeguroVehicular: 0
                })

                if (event.target.checked) {
                  let addComision = listaComisiones.filter((x: any) => x.codigo === appConfigKey.keyCodigoComisionesComisionEndoso);
                  listaComisionesSeleccionas.push(addComision[0])
                  setListaComisionesSeleccionas(listaComisionesSeleccionas);
                } else {
                  setListaComisionesSeleccionas(listaComisionesSeleccionas.filter((x: any) => x.codigo !== appConfigKey.keyCodigoComisionesComisionEndoso));
                }
              }}

            /> */}
        {/* </div> */}
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 cardObjets">
            {readonlyForm ? (
              <SimpleInput title={"Proveedor de Seguro Vehicular"} value={solicitudData.descC_SEGURO_VEHICULAR} disabled={true} />
            ) : (
              <SelectInput
                required={viewModel.esSeguroVehicularEndosado ? false : true}
                disabled={(viewModel.esSeguroVehicularEndosado ? true : false) || readonlyForm}
                name={"identSeguroVehicular"}
                title={"Proveedor de Seguro Vehicular"}
                options={listaSeguroVehicular}
                value={viewModel.identSeguroVehicular}
                onChange={(event: any, newValue: any) => {

                  setViewModel({
                    ...viewModel, identSeguroVehicular: newValue,
                    identCampania: 0,
                    identCIPrecio: '0',
                    tea: ''
                  });

                  const montoVehiculo = viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorSoles ?
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)) :
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares));

                  const montoInicial = viewModelStepTwoFinal.MonedaId === appConfigKey.keyIdentificadorSoles ?
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoInicialSoles)) :
                    Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoInicialDolares));

                  const jsonData = JsonHelper.crearJsonRequestObtenerCampanias(
                    viewModelStepTwoFinal.SucursalId,
                    viewModelStepTwoFinal.VendedorId,
                    viewModelStepTwoFinal.ConcesionarioId,
                    viewModelStepTwoFinal.EstadoVehiculoId,
                    Number(montoVehiculo),
                    Number(montoInicial),
                    GeneralHelper.ObtenerFechaActual(),
                    viewModel.identSeguroVehicular > 0 ? appConfigKey.seguroObligatorioSI : appConfigKey.seguroObligatorioNO
                  );

                  getObtenerCampanias(viewModel.identProducto, viewModelStepTwoFinal.VersionId, jsonData);

                }}
              />
            )}
          </div>
          <div className="col-lg-2 cardObjets">
            <NumberDecimalInput
              startDecorator={"%"}
              name={"costoSeguroVehicular"}
              title={"Tasa seguro vehicular"}
              placeholder=""
              value={viewModel.costoSeguroVehicular}
              disabled={readonlyForm}
              longNumber={5}
              onChange={(event: any) => setViewModel({ ...viewModel, costoSeguroVehicular: event.target.value })}
            />
          </div>
          {visibleTipoCoberturaDesg &&
            (
              <div className="col-lg-4 cardObjets">
                {readonlyForm ? (
                  <SimpleInput title={"Tipo Cobertura desg."} value={solicitudData.descC_COBERTURA_SEGURO_DESGRAVAMENT} disabled={true} />
                ) : (
                  <SelectInput
                    name={"identTipoCoberturaDesg"}
                    title={"Tipo Cobertura desg."}
                    options={listaTipoCoberturaDesg}
                    value={viewModel.identTipoCoberturaDesg}
                    disabled={readonlyForm}
                    onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identTipoCoberturaDesg: newValue })}
                  />
                )}
              </div>
            )
          }
          <div className="col-lg-2 cardObjets">
            <SwitchInput
              name={"esIncluirPortes"}
              title={"Incluir portes"}
              checked={viewModel.esIncluirPortes}
              disabled={readonlyForm}
              onChange={(event: any) => {
                setViewModel({ ...viewModel, esIncluirPortes: event.target.checked })
                if (event.target.checked) {
                  const tempComision = listaComisionesSeleccionas.find((codigo: number) => codigo === BanTotalComisiones.PORTES);
                  if (tempComision !== undefined) {
                    return;
                  }
                  listaComisionesSeleccionas.push(BanTotalComisiones.PORTES);
                  setListaComisionesSeleccionas(listaComisionesSeleccionas);
                  return;
                }
                const tempListaComisiones = listaComisionesSeleccionas.filter((codigo: number) => codigo !== BanTotalComisiones.PORTES);
                setListaComisionesSeleccionas(tempListaComisiones);
              }}
            />
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">

          <div className="col-lg-1 cardObjets">
            <SwitchInput
              name={"esGPS"}
              title={"GPS"}
              checked={viewModel.esGPS}
              disabled={readonlyForm}
              onChange={(event: any) => {
                setViewModel({ ...viewModel, esGPS: event.target.checked, identProveedorGPS: 0 });
              }}
            />
          </div>
          <div className="col-lg-3 cardObjets">
            {readonlyForm ? (
              <SimpleInput title={"Proveedor GPS"} value={solicitudData.descC_PROVEEDOR_GPS} disabled={true} />
            ) : (
              <SelectInput
                required
                //disabled={(viewModel.esGPS ? false : true) || readonlyForm}
                name={"identProveedorGPS"}
                title={"Proveedor GPS"}
                options={
                  viewModel.esGPS
                    ? listProveedorGPS.filter((row: ProveedorGPS) => {
                      //console.log("Filtrando cuando es GPS true:", row.id);
                      return row.id !== 4;
                    })
                    : listProveedorGPS.filter((row: ProveedorGPS) => {
                      //console.log("Filtrando cuando es GPS false:", row.id);
                      return row.id === 4;
                    })
                }
                value={viewModel.identProveedorGPS}
                onChange={(event: any, newValue: any) => {
                  setViewModel({ ...viewModel, identProveedorGPS: newValue });
                }}
              />
            )}
          </div>
          <div className="col-lg-2 cardObjets">
            <SwitchInput
              name={"esGastoRegistrales"}
              title={"Gastos registrales"}
              checked={viewModel.esGastoRegistrales}
              disabled={readonlyForm}
              onChange={(event: any) => {
                setViewModel({ ...viewModel, esGastoRegistrales: event.target.checked })
                if (event.target.checked) {
                  const tempComision = listaComisionesSeleccionas.find((codigo: number) => codigo === BanTotalComisiones.GASTOS_REGISTRALES);
                  if (tempComision !== undefined) {
                    return;
                  }
                  listaComisionesSeleccionas.push(BanTotalComisiones.GASTOS_REGISTRALES);
                  setListaComisionesSeleccionas(listaComisionesSeleccionas);
                  return;
                }
                const tempListaComisiones = listaComisionesSeleccionas.filter((codigo: number) => codigo !== BanTotalComisiones.GASTOS_REGISTRALES);
                setListaComisionesSeleccionas(tempListaComisiones);
              }}
            />
          </div>
          <div className="col-lg-2 cardObjets">
            <SwitchInput
              name={"esGastoNotariales"}
              title={"Gastos notariales"}
              checked={viewModel.esGastoNotariales}
              disabled={readonlyForm}
              onChange={(event: any) => {
                setViewModel({ ...viewModel, esGastoNotariales: event.target.checked })
                if (event.target.checked) {
                  const tempComision = listaComisionesSeleccionas.find((codigo: number) => codigo === BanTotalComisiones.GASTOS_NOTARIALES);
                  if (tempComision !== undefined) {
                    return;
                  }
                  listaComisionesSeleccionas.push(BanTotalComisiones.GASTOS_NOTARIALES);
                  setListaComisionesSeleccionas(listaComisionesSeleccionas);
                  return;
                }
                const tempListaComisiones = listaComisionesSeleccionas.filter((codigo: number) => codigo !== BanTotalComisiones.GASTOS_NOTARIALES);
                setListaComisionesSeleccionas(tempListaComisiones);
              }}
            />
          </div>

          <div className="col-lg-2 cardObjets">
            <SwitchInput
              name={"esGastosDeliveryFirmas"}
              title={"Gastos delivery firmas"}
              checked={viewModel.esGastosDeliveryFirmas}
              disabled={readonlyForm}
              onChange={(event: any) => {
                setViewModel({ ...viewModel, esGastosDeliveryFirmas: event.target.checked })
                if (event.target.checked) {
                  const tempComision = listaComisionesSeleccionas.find((codigo: number) => codigo === BanTotalComisiones.DELIVERY_FIRMAS);
                  if (tempComision !== undefined) {
                    return;
                  }
                  listaComisionesSeleccionas.push(BanTotalComisiones.DELIVERY_FIRMAS);
                  setListaComisionesSeleccionas(listaComisionesSeleccionas);
                  return;
                }
                const tempListaComisiones = listaComisionesSeleccionas.filter((codigo: number) => codigo !== BanTotalComisiones.DELIVERY_FIRMAS);
                setListaComisionesSeleccionas(tempListaComisiones);
              }}
            />
          </div>
          <div className="col-lg-2 cardObjets">
            <SwitchInput
              name={"esInfDerechoEndosoProc"}
              title={"Inf. derecho endoso y proc"}
              checked={viewModel.esInfDerechoEndosoProc}
              disabled={readonlyForm}
              onChange={(event: any) => setViewModel({ ...viewModel, esInfDerechoEndosoProc: event.target.checked })}
            />
          </div>
          {false && <div className="col-lg-2 cardObjets">
            <DateInput
              name={"primerVencimiento"}
              title={"1er vencimiento"}
              value={viewModel.primerVencimiento}
              disabled={readonlyForm}
              onChange={(date: any | null) => {
                setViewModel({ ...viewModel, primerVencimiento: date === null ? '' : date.format("YYYY-MM-DD") });
              }}
            />
          </div>}
        </div>
        <div className="row g-2 mb-2 align-items-center">

          <div className="col-lg-3 cardObjets">
            {readonlyForm ? (
              ((solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA === 1) ||
                (solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA === 2) ||
                (solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA === 3)) ? (
                <SimpleInput title={"Campaña"} value={solicitudData.descC_CAMPANA} disabled={true} />
              ) : (
                <></>
              )
            ) : (
              <SelectInput
                required={false}
                name={"identCampania"}
                title={"Campaña"}
                options={listaCampania}
                value={viewModel.identCampania}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  let tipoDescuento = 0;
                  let tasa = 0;
                  if (newValue > 0) {
                    setActivarSelectCIPrecio(true);
                    tipoDescuento = listaCampania.filter((x: any) => x.id === newValue)[0].tipoDescuento;
                    var nuevaListaOpciones = BantotalCIPrecioSelect(tipoDescuento);
                    setListCIPrecio(nuevaListaOpciones);
                    if (tipoDescuento == 3) {
                      tasa = listaCampania.filter((x: any) => x.id === newValue)[0].tasa;
                      cambiarTasa(tipoDescuento, tasa, viewModel, setViewModel);
                    } else {
                      cambiarTasa(tipoDescuento, nuevaTea, viewModel, setViewModel);
                    }
                  } else {
                    setActivarSelectCIPrecio(false);
                  }
                  setViewModel({
                    ...viewModel,
                    identCampania: newValue,
                    tipoDescuento: tipoDescuento,
                    identCIPrecio: (tipoDescuento == 3) ? '3' : '0',
                    tea: (tipoDescuento == 3) ? tasa.toString() : nuevaTea.toString(),
                  });
                  setStepsCampaniaId(Number(newValue));
                  setStepsCIPrecio(Number(0))
                }}
              />
            )}
          </div>
          <div className="col-lg-3 cardObjets">
            {activarSelectCIPrecio === appDefaultValues.BooleanDefaultTrue &&
              <SelectInput
                name={"identCIPrecio"}
                title={"CI/Precio"}
                options={listCIPrecio}
                disabled={readonlyForm}
                value={viewModel.identCIPrecio === '' ? '0' : viewModel.identCIPrecio}
                onChange={(event: any, newValue: any) => {
                  setViewModel({ ...viewModel, identCIPrecio: newValue });
                  setStepsCIPrecio(Number(newValue));
                }}
              />
            }
            {readonlyForm && solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA === 1 && (
              <SimpleInput title={"CI/Precio"} value={'Precio del vehículo.'} disabled={true} />
            )}
            {readonlyForm && solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA === 2 && (
              <SimpleInput title={"CI/Precio"} value={'Cuota inicial.'} disabled={true} />
            )}
            {readonlyForm && solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA === 3 && (
              <SimpleInput title={"CI/Precio"} value={'Tasa.'} disabled={true} />
            )}
          </div>
          {false && <div className="col-lg-2 cardObjets">
            <SelectInput
              name={"identGastosDeliveryFirmas"}
              title={"Gastos delivery de firmas"}
              options={[]}
              value={viewModel.identGastosDeliveryFirmas}
              disabled={readonlyForm}
              onChange={(event: any, newValue: any) => {
                setViewModel({
                  ...viewModel, identGastosDeliveryFirmas: newValue,
                })
              }}
            />
          </div>}
          <div className="col-lg-2 cardObjets">
          </div>
          <div className="col-lg-2 cardObjets">
          </div>
          <div className="col-lg-2 cardObjets">
            <SwitchInput
              disabled={true}
              name={"esActivacionViaEmailOrSms"}
              title={"Activación vía Email/SMS"}
              checked={viewModel.esActivacionViaEmailOrSms}
              onChange={(event: any) => setViewModel({ ...viewModel, esActivacionViaEmailOrSms: event.target.checked })}

            />
          </div>
          {/* <div className="col-lg-2 cardObjets">
            <SwitchInput
              disabled={true}
              name={"esKitMantenimiento"}
              title={"Kit mantenimiento"}
              checked={viewModel.esKitMantenimiento}
              onChange={(event: any) => setViewModel({ ...viewModel, esKitMantenimiento: event.target.checked })}
            />
          </div> */}


        </div>
        <div className="row g-2 mb-2 align-items-center">

          <div className="col-lg-2 cardObjets">
            <Button disabled={readonlyForm || isTitularJuridico} color="danger" style={{ marginRight: "35px", width: '90%', marginTop: '30px' }} sx={{ borderRadius: 10, width: 3 / 10 }} onClick={() => {
              obtenerTEA(nuevaTea, setNuevaTea, viewModel, setViewModel);
              setClickOnObtenerTEA(true);
            }}>
              <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                <div style={{ width: 'inherit' }}>Obtener TEA</div>
              </div>
            </Button>
          </div>

          <div className="col-lg-2 cardObjets">
            <MoneyInput
              startDecorator={"%"}
              name={"tea"}
              title={"TEA"}
              placeholder=""
              value={viewModel.tea}
              disabled//={readonlyForm}
              longNumber={3}
              onChange={(event: any) => {
                const tempTEA = event.target.value;

                if (appConfigKey.keyIdentificadorDolares === viewModelStepTwoFinal.MonedaId) {
                  if (tempTEA > appConfigTea.ValorMaximoDolares) {
                    toast.error("El valor máximo en soles para la TEA es 77.50%", { duration: appConfigKey.keyDurationToast });
                    return;
                  }
                }

                if (appConfigKey.keyIdentificadorSoles === viewModelStepTwoFinal.MonedaId) {
                  if (tempTEA > appConfigTea.ValorMaximoSoles) {
                    toast.error("El valor máximo en dólares para la TEA es 96.32%", { duration: appConfigKey.keyDurationToast });
                    return;
                  }
                }
                setViewModel({ ...viewModel, tea: tempTEA });
              }}
            />
          </div>

          <div className="col-lg-1 cardObjets">
            <FormLabel> &nbsp;</FormLabel>
            <Button
              color="danger"
              size="md"
              disabled={readonlyForm}
              sx={{ borderRadius: 27, width: 2 / 4 }}
              onClick={() => {
                isCambiarTeaOpen(viewModel, setViewModel);
              }}>
              <BsPencilFill />
            </Button>
          </div>

          {false && <div className="col-lg-4 cardObjets">
            <SelectInput
              name={"identTipoInclusionPortes"}
              title={"Tipo inclusión de portes."}
              disabled={true}
              options={[]}
              value={viewModel.identTipoInclusionPortes}
              onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identTipoInclusionPortes: newValue })}
            />
          </div>}



        </div>
        <CambiarTeaOpen
          tea={viewModel.tea}
          idSolicitud={solicitudId}
          open={viewModel.cambiarTeaDialogOpen}
          handleClose={() => { handleCambiarTeaDialogClose(viewModel, setViewModel) }}
          updateTea={(data: string) => { updateTea(data, setNuevaTea, viewModel, setViewModel) }}
        />
      </div>
    </div>
  );

};

export default SolicitudeStepThree;