import React, { useContext, useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { ObtenerListas } from "../15.10.-base/obtenerServicios";
import { DateInput, NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { Combo, DatosAccionistas, DatosAccionistasDefault } from "../15.10.-base/interfaces"
import { appConfigTipoPersonaTercera, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { InformeComercialContext } from "../15.10.-base/providers/informeComercialProvider";
import { AxiosError } from "axios";

export const AccionistasAuditor = (DatosInformeComercial: any) => {
    const {
        IdSolicitud,
    } = useContext(InformeComercialContext);

    const [listTipoDocumento, setListTipoDocumento] = useState<any[]>([]);
    const [listPaises, setListPaises] = useState<any[]>([]);
    const [listEstadoCivil, setListEstadoCivil] = useState([]);
    const [listProfesion, setListProfesion] = useState([]);
    const [listGenero, setListGenero] = useState([]);
    const [dataAccionistas, setDataAccionistas] = useState<DatosAccionistas>(DatosAccionistasDefault);
    const [dataListAccionistas, setDataListAccionistas] = useState<DatosAccionistas[]>([]);
    const [flgShow, setFlgShow] = useState<boolean>(false);
    const [longNumber, setLongNumber] = useState<any>(8);

    const InitListas = async () => {
        await ObtenerListas.TipoDocumento().then(response => {
            setListTipoDocumento(response);
        });
        await ObtenerListas.Pais().then(response =>{
            setListPaises(response)
        });
        await ObtenerListas.EstadoCiviles().then(response =>{
            setListEstadoCivil(response)
        });
        await ObtenerListas.Profesion().then(response =>{
            setListProfesion(response)
        });
        await ObtenerListas.IdentidadesGenero().then(response =>{
            setListGenero(response);
        });
      };
      
    const onClickNuevo = () => {
        setFlgShow(true);
        setDataAccionistas(DatosAccionistasDefault);
    }
    const onClickCancelar = () => {
        setFlgShow(false);
        setDataAccionistas(DatosAccionistasDefault);
    }
    const GuardarAccionistas = async () => {
        let resultado;
        let bodyInsert = {
            "identInformeComercial": dataAccionistas.identInformeComercial == 0 ? DatosInformeComercial.dataInformeComercial.identInformeComercial : dataAccionistas.identInformeComercial,
            "identPersona": dataAccionistas.identPersona == null ? 0 : dataAccionistas.identPersona,
            "idTipoDocumento": dataAccionistas.idTipoDocumento,
            "descTipoDocumento": dataAccionistas.descTipoDocumento,
            "nroDocumento": dataAccionistas.nroDocumento,
            "rucPnn": dataAccionistas.rucPnn,
            "razonSocial": dataAccionistas.razonSocial,
            "nombres": dataAccionistas.nombres,
            "apellidoPaterno": dataAccionistas.apellidoPaterno,
            "apellidoMaterno": dataAccionistas.apellidoMaterno,
            "idEstadoCivil": dataAccionistas.idEstadoCivil,
            "descEstadoCivil": dataAccionistas.descEstadoCivil,
            "fechaNacimiento": dataAccionistas.fechaNacimiento,
            "idGenero": GeneralHelper.ObtenerGeneroPorDefecto(dataAccionistas.idGenero),
            "descGenero": dataAccionistas.descGenero,
            "idPaisNacimiento": dataAccionistas.idPaisNacimiento,
            "descPaisNacimiento": dataAccionistas.descPaisNacimiento,
            "idPaisResidencia": dataAccionistas.idPaisResidencia,
            "descPaisResidencia": dataAccionistas.descPaisResidencia,
            "idDistrito": dataAccionistas.idDistrito,
            "descDistrito": dataAccionistas.descDistrito,
            "direccion": dataAccionistas.direccion,
            "telefono1": dataAccionistas.telefono1,
            "telefono2": dataAccionistas.telefono2,
            "correo": dataAccionistas.correo,
            "idProfesion": dataAccionistas.idProfesion,
            "descProfesion": dataAccionistas.descProfesion,
            "idCargo": dataAccionistas.idCargo,
            "descCargo": dataAccionistas.descCargo,
            // "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaAccionista,
            // "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaAccionista,
            "aporteSoles": GeneralHelper.QuitarComasAMiles(dataAccionistas.aporteSoles),
            "aportePorcentaje": dataAccionistas.aportePorcentaje,
            "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
        }
        let bodyUpdate = {
            "identInformeComercialPersona": dataAccionistas.identInformeComercialPersona,
            "identInformeComercial": dataAccionistas.identInformeComercial == 0 ? DatosInformeComercial.dataInformeComercial.identInformeComercial : dataAccionistas.identInformeComercial,
            "identPersona": dataAccionistas.identPersona == null ? 0 : dataAccionistas.identPersona,
            "idTipoDocumento": dataAccionistas.idTipoDocumento,
            "descTipoDocumento": dataAccionistas.descTipoDocumento,
            "nroDocumento": dataAccionistas.nroDocumento,
            "rucPnn": dataAccionistas.rucPnn,
            "razonSocial": dataAccionistas.razonSocial,
            "nombres": dataAccionistas.nombres,
            "apellidoPaterno": dataAccionistas.apellidoPaterno,
            "apellidoMaterno": dataAccionistas.apellidoMaterno,
            "idEstadoCivil": dataAccionistas.idEstadoCivil,
            "descEstadoCivil": dataAccionistas.descEstadoCivil,
            "fechaNacimiento": dataAccionistas.fechaNacimiento,
            "idGenero": GeneralHelper.ObtenerGeneroPorDefecto(dataAccionistas.idGenero),
            "descGenero": dataAccionistas.descGenero,
            "idPaisNacimiento": dataAccionistas.idPaisNacimiento,
            "descPaisNacimiento": dataAccionistas.descPaisNacimiento,
            "idPaisResidencia": dataAccionistas.idPaisResidencia,
            "descPaisResidencia": dataAccionistas.descPaisResidencia,
            "idDistrito": dataAccionistas.idDistrito,
            "descDistrito": dataAccionistas.descDistrito,
            "direccion": dataAccionistas.direccion,
            "telefono1": dataAccionistas.telefono1,
            "telefono2": dataAccionistas.telefono2,
            "correo": dataAccionistas.correo,
            "idProfesion": dataAccionistas.idProfesion,
            "descProfesion": dataAccionistas.descProfesion,
            "idCargo": dataAccionistas.idCargo,
            "descCargo": dataAccionistas.descCargo,
            // "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaAccionista,
            // "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaAccionista,
            "aporteSoles": GeneralHelper.QuitarComasAMiles(dataAccionistas.aporteSoles),
            "aportePorcentaje": dataAccionistas.aportePorcentaje,
            "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
        }

        try {
            const response = dataAccionistas.identInformeComercialPersona == 0 ? 
            await RequestManager.MnInformeComercialPersonaAccionistaInsert(bodyInsert) : 
            await RequestManager.MnInformeComercialPersonaAccionistaUpdate(bodyUpdate);
        if (response.status === 200) {
          resultado = response.data.isValid;
          if (resultado === true) {
            if(dataAccionistas.identInformeComercialPersona == 0)
                {toast.success("Registro guardado correctamente", { duration: 5000 });}
            else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
          }
          else{
            toast.error("Ocurrió un error", { duration: 5000 });
          }
        };
        CargaInicial();

        setFlgShow(false);
        setDataAccionistas(DatosAccionistasDefault);            
        } catch (error: AxiosError | any) {            
            if (error.response && error.response.data.status === 400 && error.response.data.errors) {
                const validationErrors = error.response.data.errors;
                if (validationErrors) {
                    for (const [field, messages] of Object.entries(validationErrors)) {                            
                        let message = messages as string[]; // Type assertion
                        toast.error(`${field}: ${message}`, { duration: 5000 });
                    }
                } else {
                    toast.error("Ocurrió un error", { duration: 5000 });
                }
            }
        }
    }

    const CargaInicial = async () => {
        const response = await RequestManager.GetMnInformeComercialPersonaAccionista({ "identInformeComercial": DatosInformeComercial.dataInformeComercial.identInformeComercial });
        if (response.status === 200) {
            setDataListAccionistas(response.data.content);
        }
    }

    const handleClickViewDataAccionistas = async (value: any) =>  {
        setFlgShow(true);
        const response = await RequestManager.GetMnInformeComercialPersonaAccionistaById({ "identInformeComercialPersona": value });
        if (response.status === 200) {
            setDataAccionistas(response.data.content);
        }
    }

    const handleClickDeleteAccionistas = async (value: any) =>  {
        setFlgShow(false);
        setDataAccionistas(DatosAccionistasDefault);
        const response = await RequestManager.MnInformeComercialPersonaAccionistaDelete({ "identInformeComercialPersona": value });
        if (response.status === 200) {
            setDataListAccionistas(response.data.content);
            CargaInicial();
        }
    }

    const changeValueTipoDocumento = (e: any) => {
        let id = e.target.value as number;
        let text = '';
        if (id > 0) {
          for (let i = 0; i < listTipoDocumento.length; i++) {
            if (listTipoDocumento[i].id === id) {
                text = listTipoDocumento[i].description;
                break;
            }
          }
        }
        setDataAccionistas({ ...dataAccionistas, idTipoDocumento: id, descTipoDocumento: text,nroDocumento: appDefaultValues.StringEmpty });
        if(id === 172 || id === 173){
            setLongNumber(12);
        }
        else if(id === 174){
            setLongNumber(20);
        }
        else{
            setLongNumber(8)
        }
    }

    const changeValuePaisNacimiento = (e: any) => {
        let id = e.target.value as number;
        let text = '';
        if (id > 0) {
          for (let i = 0; i < listPaises.length; i++) {
            if (listPaises[i].id === id) {
                text = listPaises[i].description;
                break;
            }
          }
        }
        setDataAccionistas({ ...dataAccionistas, idPaisNacimiento: id, descPaisNacimiento: text});
    }

    const changeValuePaisResidencia = (e: any) => {
        let id = e.target.value as number;
        let text = '';
        if (id > 0) {
          for (let i = 0; i < listPaises.length; i++) {
            if (listPaises[i].id === id) {
                text = listPaises[i].description;
                break;
            }
          }
        }
        setDataAccionistas({ ...dataAccionistas, idPaisResidencia: id, descPaisResidencia: text});
    }
    
    useEffect(() => {
        InitListas();
        CargaInicial();
    }, [DatosInformeComercial]);
    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>II. DATOS DE ACCIONISTAS</AccordionSummary>
            <AccordionDetails>
            <>

            <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "8%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DOC.</th>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", }}>NRO DOCUMENTO</th>
                                <th style={{ fontSize: "12px", width: "12%", backgroundColor: "#E1E1E1", }}>RUC</th>
                                <th style={{ fontSize: "12px", width: "12%", backgroundColor: "#E1E1E1", }}>NOMBRE O RAZÓN SOCIAL</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>AP. PATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>AP. MATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS NACIMIENTO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS RESIDENCIA</th>
                                <th style={{ fontSize: "12px", width: "8%", backgroundColor: "#E1E1E1", }}>APORTE S/.</th>
                                <th style={{ fontSize: "12px", width: "8%", backgroundColor: "#E1E1E1", }}>APORTE %</th>
                                <th style={{ fontSize: "12px", width: "8%", backgroundColor: "#E1E1E1", }}>ESTADO </th>
                                <th style={{ fontSize: "12px", width: "8%", backgroundColor: "#E1E1E1", }}>ACCIONES </th>
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListAccionistas.length > 0 && dataListAccionistas.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialPersona}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descTipoDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nroDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.rucPnn}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {(row.idTipoDocumento === 174 ? row.razonSocial : row.nombres)}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoPaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoMaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisNacimiento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisResidencia}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.AgregarComasAMiles(row.aporteSoles)}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.aportePorcentaje}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            <td>
                                <Dropdown key={row.identInformeComercialPersona}>
                                  <Dropdown.Toggle
                                    as={CustomDivToggle}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <BsThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickViewDataAccionistas(
                                              row.identInformeComercialPersona
                                            )
                                          }
                                        >
                                          Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickDeleteAccionistas(
                                              row.identInformeComercialPersona
                                            )
                                          }
                                        >
                                          Eliminar
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            </tr>
                                )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<SimbolAdd />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            // disabled={props.disabled}
                            onClick={onClickNuevo}>Nuevo
                        </Button>
                    </div>
                </div>
                { flgShow && <div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            title={"Tipo de documento"}
                            options={listTipoDocumento}
                            value={dataAccionistas.idTipoDocumento}
                            onChange={(event: any) => {
                                changeValueTipoDocumento(event);
                                // setDataAccionistas({ ...dataAccionistas, idTipoDocumento: event.target.value, nroDocumento: appDefaultValues.StringEmpty });
                            }}
                        />
                    </div>
                    {(dataAccionistas.idTipoDocumento === 172 || dataAccionistas.idTipoDocumento === 173) ?
                        <div className="col-lg-2 cardObjets">
                            <SimpleInput
                                title={"Nro. documento"}
                                placeholder=""
                                longNumber={longNumber}
                                required={true}
                                value={dataAccionistas.nroDocumento.trim()}
                                onChange={(event: any) => {
                                    setDataAccionistas({ ...dataAccionistas, nroDocumento: event.target.value});
                                }}
                        />
                        </div>
                        :
                        <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Nro. documento"}
                            placeholder=""
                            longNumber={longNumber}
                            required={true}
                            value={dataAccionistas.nroDocumento.trim()}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, nroDocumento: event.target.value});
                            }}
                        />
                        </div>
                    }
                    <div className="col-lg-4 cardObjets">
                        <NumberInput
                            title={"RUC PNN"}
                            placeholder=""
                            value={dataAccionistas.rucPnn}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, rucPnn: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            title={"Nombre / Razón Social"}
                            placeholder="Ingresar nombre"
                            value={dataAccionistas.idTipoDocumento === 174 ? dataAccionistas.razonSocial : dataAccionistas.nombres}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                if(dataAccionistas.idTipoDocumento === 174){
                                    setDataAccionistas({ ...dataAccionistas, razonSocial: event.target.value, nombres: "-"});
                                }
                                else{
                                    setDataAccionistas({ ...dataAccionistas, nombres: event.target.value, razonSocial: "-"});
                                }
                            }}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Apellido Paterno"}
                            placeholder="Ingresar apellido"
                            value={dataAccionistas.apellidoPaterno}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, apellidoPaterno: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Apellido Materno"}
                            placeholder="Ingresar apellido"
                            value={dataAccionistas.apellidoMaterno}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, apellidoMaterno: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            title={"Estado Civil"}
                            options={listEstadoCivil}
                            value={dataAccionistas.idEstadoCivil}
                            // disabled={readonlyForm}
                            // onChange={(event: any, newValue: any) => {
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, idEstadoCivil: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <DateInput
                            title={"Fecha de nacimiento"}
                            value={dataAccionistas.fechaNacimiento}
                            // disabled={solicitudId !== undefined || readonlyForm}
                            onChange={(date: any | null) => setDataAccionistas({ ...dataAccionistas, fechaNacimiento: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"Género"}
                            options={listGenero}
                            value={dataAccionistas.idGenero}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, idGenero: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"País de nacimiento"}
                            options={listPaises}
                            value={dataAccionistas.idPaisNacimiento}
                            // disabled={readonlyForm}
                            // onChange={(event: any, newValue: any) => {
                            onChange={(event: any, newValue: any) => {
                                changeValuePaisNacimiento(event);
                                // setDataAccionistas({ ...dataAccionistas, idPaisNacimiento: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"País de residencia"}
                            options={listPaises}
                            value={dataAccionistas.idPaisResidencia}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                changeValuePaisResidencia(event);
                            }}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            title={"Dirección"}
                            placeholder="Ejm. Av. La Marina"
                            value={dataAccionistas.direccion}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, direccion: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Teléfono 1"}
                            placeholder=""
                            longNumber={9}
                            required={true}
                            value={dataAccionistas.telefono1}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, telefono1: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Teléfono 2"}
                            placeholder=""
                            longNumber={9}
                            required={true}
                            value={dataAccionistas.telefono2}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, telefono2: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            title={"Correo"}
                            placeholder="Ingresar email"
                            value={dataAccionistas.correo}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, correo: event.target.value});
                            }}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                            <SelectInput
                                title={"Profesión"}
                                options={listProfesion}
                                value={dataAccionistas.idProfesion}
                                onChange={(event: any, newValue: any) => {
                                    setDataAccionistas({ ...dataAccionistas, idProfesion: event.target.value});
                                }}
                            />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Aporte S/"}
                            placeholder=""
                            required = {false}
                            value={GeneralHelper.AgregarComasAMiles(dataAccionistas.aporteSoles)}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, aporteSoles: event.target.value});
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Aporte %"}
                            placeholder=""
                            value={dataAccionistas.aportePorcentaje}
                            // disabled={readonlyForm}
                            onChange={(event: any) => {
                                setDataAccionistas({ ...dataAccionistas, aportePorcentaje: event.target.value});
                            }}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Save />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={GuardarAccionistas}
                            // onClick={props.onClick}>{props.title}
                            // disabled={!chkRequerido}
                            >Guardar
                        </Button>
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Cancel />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            // disabled={props.disabled}
                            // onClick={props.onClick}>{props.title}
                            onClick={onClickCancelar}>Cancelar
                        </Button>
                    </div>
            </div>
                </div>}
                
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
        
        
   
    </div>
  );
};

export default AccionistasAuditor;