import { FormLabel, Switch, Typography, Tooltip } from "@mui/joy";
import icoQuestion from '../../../../../../assets/media/icons/ico_question.svg';
import React from "react";

export const SwitchInput = (props: any) => {
    return (
        <>
            <FormLabel sx={{ display: 'flex', alignItems: 'center' }}>
                {props.title}
                {props.tooltip && (
                    <Tooltip title={props.tooltip} placement="top">
                        <img src={icoQuestion} />
                    </Tooltip>
                )}
            </FormLabel>
            <Switch
                color={props.checked ? "success" : "neutral"}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled === undefined || props.disabled === null ? false : props.disabled}
                slotProps={{
                    track: {
                        children: (
                            <React.Fragment>
                                <Typography component="span" level="inherit" sx={{ ml: "10px" }}>Si</Typography>
                                <Typography component="span" level="inherit" sx={{ mr: "8px" }}>No</Typography>
                            </React.Fragment>
                        ),
                    }
                }}
                sx={{ "--Switch-thumbSize": "27px", "--Switch-trackWidth": "64px", "--Switch-trackHeight": "31px" }} />
        </>
    );
};