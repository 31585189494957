import { useEffect, useState } from "react";
import LoadingProgress from "../../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import { Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ReactComponent as CloseIcon } from './../../../../../assets/media/icons/ico-close-modal.svg'
import { FormControl, FormLabel, Textarea } from "@mui/joy";
import ButtonGeneric from "./DocumentationButton";
import { General, ObtenerListas } from "../obtenerServicios";
import ValidacionRequestExceptionHelper from "../helpers/ValidacionRequestExceptionHelper";
import { toast } from "sonner";
import MasterManager from "../../../../services/origination/wapimaster.service";
import { appConfigEstadosProcesoEtapaAnalisisCredito, appConfigKey, appConfigProcesoEtapaAnalisisCredito, appSecurityConfiguration } from "../../../../../config/Config";

const RequestExceptionDialog = (props: any) => {

    const [dataExceptionRequest, setDataExceptionRequest] = useState([]);
    const [requestAddSolicitudExcepcion, setRequestAddSolicitudExcepcion] = useState<any>({
        identSolicitud: 0,
        sustento: '',
        identUsuarioCreador: 0
    });
    const [showLoading, setShowLoading] = useState(false);

    const GetSolicitudExcepcionLiberacionByIdSolicitud = async (identSolicitud: number) => {
        const response: any = await ObtenerListas.GetSolicitudExcepcionLiberacionByIdSolicitud(identSolicitud);
        if (response.length > 0) {
            setDataExceptionRequest(response);
        }
    };
    const GetSolicitudExceptionByIdSolicitud = async (identSolicitud: number) => {
        const response: any = await General.GetSolicitudExceptionByIdSolicitud(identSolicitud);
        if (response) {
            setRequestAddSolicitudExcepcion({
                ...requestAddSolicitudExcepcion,
                identSolicitud : response?.identSolicitud,
                sustento: response?.sustento,
                identUsuarioCreador : appSecurityConfiguration.SessionUserId
            });
        }
    };
    const GetMnEvaluacionSolicitud = async (identSolicitud: any) => {
        let evaluaciones = [];
        try {
            let response = await MasterManager.GetMnEvaluacionSolicitud(identSolicitud);
            if (response && response.status === 200) {
                evaluaciones = response.data.content;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return evaluaciones;
    }

    const handleDerivar = async () => {
        const validacionRequestException =
            !ValidacionRequestExceptionHelper.NewReqAddSolicitudExcepcion(requestAddSolicitudExcepcion);

        if (validacionRequestException) { return }

        setShowLoading(true);

        let responseMotorBeforceStepTwo: any = null;
        const tempEvaluacionSolicitud = await GetMnEvaluacionSolicitud(props?.data?.IdSolicitud);
        if (tempEvaluacionSolicitud?.length > 0) {
            for (let evaluacion of tempEvaluacionSolicitud) {
                if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
                    responseMotorBeforceStepTwo = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;
                    break;
                }
            }
        }

        if (!responseMotorBeforceStepTwo) {
            setShowLoading(false);
            return;
        }

        const tempSolicitudValidarUsuario = await General.GetSolicitudExcepcionValidarUsuarioByIdSolicitud(props?.data?.IdSolicitud);
        if (!tempSolicitudValidarUsuario) {
            toast.error("No se encuentra Analista Gestor para derivar a excepción la solicitud.");
            setShowLoading(false);
            return
        }


        const listaSolicitudEtapas = await ObtenerListas.GetSolicitudEtapas(null, Number(props?.data?.IdSolicitud));
        let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)

        if (listaSolicitudEtapaRechazado.length > 0) {
            toast.error("No se puede Solicitar Excepción una solicitud RECHAZADA.");
            setShowLoading(false);
            return
        }

        let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado)
        if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
            toast.error("No se puede Solicitar Excepción una solicitud APROBADA.");
            setShowLoading(false);
            return
        }

        let listaSolicitudEtapaAprobadoConDocumentos = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
        if (listaSolicitudEtapaAprobadoConDocumentos.length > 0) {
            toast.error("No se puede Solicitar Excepción una solicitud APROBADA CON DOCUMENTOS.");
            setShowLoading(false);
            return
        }

        let listaSolicitudSolicitarExpcecionEtapaEnviado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado &&
            x.identProceso === appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion)
        if (listaSolicitudSolicitarExpcecionEtapaEnviado.length > 0) {
            toast.error("No se puede Solicitar Excepción por que ya fue ENVIADO.");
            setShowLoading(false);
            return
        }

        let listaSolicitudSolicitarExpcecionEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado &&
            x.identProceso === appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion)
        if (listaSolicitudSolicitarExpcecionEtapaAprobado.length > 0) {
            toast.error("No se puede Solicitar Excepción una solicitud APROBADA.");
            setShowLoading(false);
            return
        }

        let solicitudSolicitarExpcecionEtapaAprobado = listaSolicitudEtapas.find((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion)
        await General.GetSolicitudEtapasUpdate(
            {
                "identSolicitudEtapas": solicitudSolicitarExpcecionEtapaAprobado.identSolicitudEtapas,
                "identSolicitud": solicitudSolicitarExpcecionEtapaAprobado.identSolicitud,
                "identDecisionMotor": solicitudSolicitarExpcecionEtapaAprobado.identDecisionMotor,
                "identEtapa": solicitudSolicitarExpcecionEtapaAprobado.identEtapa,
                "identProceso": solicitudSolicitarExpcecionEtapaAprobado.identProceso,
                "identEstadoProceso": appConfigEstadosProcesoEtapaAnalisisCredito.Enviado,
                "indEstado": solicitudSolicitarExpcecionEtapaAprobado.indEstado,
                "identUsuarioModif": appSecurityConfiguration.SessionUserId,
                "ordenProceso": solicitudSolicitarExpcecionEtapaAprobado.ordenProceso
            }
        );


        let decision = responseMotorBeforceStepTwo?.response?.Politica_Creditos?.decision_Credito?.decision;
        if (decision) {

            let identResultado: number = 0;
            if (decision === appConfigKey.CodigoEvaluacionAprobado) {
                identResultado = appConfigKey.keyIdResultadoAprobado
            }
            if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                identResultado = appConfigKey.keyIdResultadoRechazado
            }
            if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
                identResultado = appConfigKey.keyIdResultadoZonaGris
            }

            await General.GetMnResultadoEvaluacionInsert(
                {
                    "idenT_SOLICITUD": props?.data?.IdSolicitud,
                    "idenT_RESULTADO": identResultado,
                    "idenT_TIPO_RESULTADO": appConfigKey.KeyIdTipoResultadoSinObservaciones,
                    "idenT_DESTINO_CREDITO": props?.solicitud?.idenT_DESTINO_CREDITO,
                    "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
                }
            );
        }

        const response: any = await General.AddSolicitudExcepcion(requestAddSolicitudExcepcion);
        if (!response) {
            toast.error("Ocurrió un error con el servicio para derivar a excepción la solicitud.");
        } else {
            toast.success("Se envió solicitud con excepción a mesa de créditos.");
            setRequestAddSolicitudExcepcion(null);
            props.handleObtenerDocumentos();
            props.handleObtenerConfigAccionesPorSolicitud();
            props.handleClose();
        }
        setShowLoading(false);
    }

    const InitDefaultRequestException = async () => {
        setShowLoading(true);
        await Promise.all(
            [GetSolicitudExcepcionLiberacionByIdSolicitud(Number(props?.data?.IdSolicitud)),
            GetSolicitudExceptionByIdSolicitud(Number(props?.data?.IdSolicitud))]);
        setShowLoading(false);
    }

    useEffect(() => {
        if (props?.open) {
            InitDefaultRequestException();
        }
    }, [props?.open]);

    return (
        <>
            {showLoading && <LoadingProgress />}
            <Dialog style={{ zIndex: 1000 }} open={props.open} sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    alignContent: 'center',
                    width: '600px'
                }
            }}>
                {showLoading && <LoadingProgress />}

                <div className="request-exception-dialog-content">
                    <button className="btn btn-sm change-rate-close-btn" onClick={props.handleClose}>
                        <CloseIcon />
                    </button>
                    <div className='request-exception-dialog-title'>SOLICITAR EXCEPCIÓN</div>
                    <div className="textAlignRigth">
                        <FormControl>
                            <FormLabel>
                                Sustento excepción <span style={{ color: "red" }}> * </span>
                            </FormLabel>
                            <Textarea
                                name="comentario"
                                placeholder="Ingresar texto"
                                minRows={5}
                                sx={{ mb: 1 }}
                                value={requestAddSolicitudExcepcion?.sustento}
                                onChange={(event: any) => {
                                    setRequestAddSolicitudExcepcion({
                                        ...requestAddSolicitudExcepcion,
                                        identSolicitud: Number(props.data?.IdSolicitud),
                                        sustento: event.target.value,
                                        identUsuarioCreador: Number(
                                            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
                                        )
                                    });
                                }}
                            />
                        </FormControl>
                    </div>
                    <div className="change-rate-dialog-btn-save">
                        <div style={{ textAlign: 'center' }}>
                            <ButtonGeneric text={'Derivar'} handlerOnClick={handleDerivar} />
                        </div>
                    </div>
                    <div className="textAlignRigth cardObjets">
                        <div>
                            <FormControl>
                                <FormLabel>
                                    <span className='change-rate-label'>Historial</span>
                                </FormLabel>
                            </FormControl>
                        </div>
                    </div>
                    <div className='container-table' style={{ maxHeight: "150px" }}>
                        <TableContainer component={Paper}
                            sx={{
                                '& .MuiTable-root': {
                                    backgroundColor: '#E1E1E1',
                                }
                            }}>
                            <Table sx={
                                {
                                    '& .MuiTableHead-root': {
                                        backgroundColor: '#E1E1E1',
                                    },
                                    '& .MuiTableCell-root': {
                                        fontSize: '11px',
                                        padding: '7px',
                                    }
                                }
                            }>
                                <TableHead>
                                    <TableRow className='change-rate-table-container'>
                                        <TableCell style={{ minWidth: '130px', textAlign: 'center' }}><strong>FECHA</strong></TableCell>
                                        <TableCell style={{ minWidth: '70px' }}><strong>USUARIO</strong></TableCell>
                                        <TableCell style={{ minWidth: '100px' }}><strong>RESULTADO</strong></TableCell>
                                        <TableCell style={{ minWidth: '150px' }}><strong>COMENTARIO</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataExceptionRequest?.map((row: any, index) => (
                                        <TableRow
                                            key={index} className={
                                                index % 2 === 0 ? 'change-rate-table-impar' : 'change-rate-table-par'
                                            }>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.fecha}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.usuario}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.resultado}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.comentario}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>

                </div>

            </Dialog>
        </>);


}
export default RequestExceptionDialog;