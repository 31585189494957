import { useEffect, useState } from "react";
import { SelectInput, SimpleInput } from "../../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import TitleCard from "../../../../3.40.-SolicitudeStepOne/components/elements/titleCard";

const TitularDomicilioCard = (props: any) => {

    const {
        data,
        listTipoVivienda,
        listTiempoResidencia,
        setTitularDomicilioFinal,
        clickSubmit
    } = props;

    const [viewModel, setViewModel] = useState({
        nombreVia: "",
        numDomicilio: "",
        intDptoPiso: "",
        manzana: "",
        lote: "",
        nombreAgrupacion: "",
        referencia: "",
        identTipoVivienda: 0,
        identTiempoResidencia: 0,
    });

    useEffect(() => {
        if (clickSubmit !== 0) {

            const findTipoVivienda: any = listTipoVivienda.find((tipoVivienda: any) => tipoVivienda.id === viewModel.identTipoVivienda);
            const descripcionTipoVivienda = findTipoVivienda == undefined ? "" : findTipoVivienda.description;

            const findTiempoResidencia: any = listTiempoResidencia.find((tiempoResidencia: any) => tiempoResidencia.id === viewModel.identTiempoResidencia);
            const descripcionTiempoResidencia = findTiempoResidencia == undefined ? "" : findTiempoResidencia.description;

            setTitularDomicilioFinal({
                ...viewModel,
                descripcionTipoVivienda,
                descripcionTiempoResidencia
            });
            
        }
    }, [clickSubmit]);

    useEffect(() => {
        if (data !== null && data !== undefined) {
            setViewModel({
                ...viewModel,
                nombreVia: data.descM_NOMBRE_VIA,
                numDomicilio: data.descC_NUMERO,
                intDptoPiso: data.descC_INTERIOR_DEPARTAMENTO_PISO,
                manzana: data.descC_MANZANA,
                lote: data.descC_LOTE,
                nombreAgrupacion: data.descM_NOMBRE_AGRUPACION,
                referencia: data.descL_REFERENCIA,
                identTipoVivienda: data.iD_TIPO_VIVIENDA,
                identTiempoResidencia: data.iD_TIEMPO_RESIDENCIA,
            });
        }

    }, [data]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DOMICILIO TITULAR"} obligatorio={true} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"NombreVia"}
                        title={"Nombre de vía"}
                        required={true}
                        value={viewModel.nombreVia}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, nombreVia: event.target.value });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"numDomicilio"}
                        title={"Número"}
                        required={true}
                        value={viewModel.numDomicilio}
                        longNumber={5}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, numDomicilio: event.target.value })
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"IntDptoPiso"}
                        required={false}
                        title={"Int/Dpto/Piso"}
                        value={viewModel.intDptoPiso}
                        maxLength={15}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, intDptoPiso: event.target.value });
                        }}

                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"manzana"}
                        required={false}
                        title={"Manzana"}
                        maxLength={15}
                        value={viewModel.manzana}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, manzana: event.target.value });
                        }}

                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"lote"}
                        required={false}
                        title={"Lote"}
                        value={viewModel.lote}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, lote: event.target.value });
                        }}
                        maxLength={15}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreAgrupacion"}
                        title={"Nombre agrupación"}
                        required={false}
                        placeholder=""
                        value={viewModel.nombreAgrupacion}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, nombreAgrupacion: event.target.value });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"referencia"}
                        required={false}
                        title={"Referencia"}
                        placeholder=""
                        value={viewModel.referencia}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, referencia: event.target.value });
                        }}
                    />

                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identTipoVivienda"}
                        required={true}
                        title={"Tipo de vivienda"}
                        options={listTipoVivienda}
                        value={viewModel.identTipoVivienda}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, identTipoVivienda: newValue });
                        }}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identTiempoResidencia"}
                        required={true}
                        title={"Tiempo de residencia"}
                        options={listTiempoResidencia}
                        value={viewModel.identTiempoResidencia}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, identTiempoResidencia: newValue });
                        }}
                    />
                </div>
            </div>
        </div>
    );

};

export default TitularDomicilioCard;