import { useEffect, useState } from "react";
import { appConfigKey } from "../../../../../../../config/Config";
import { NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput } from "../../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import TitleCard from "../../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { General, ObtenerListas } from "../../../obtenerServicios";
import GeneralHelper from "../../../../../../helpers/GeneralHelper";

const ConyugeDatosLaboralesCard = (props: any) => {

    const {
        titularData,
        conyugeData,
        listActividadEconomica,
        listPais,
        listProfesion,
        setConyugeLaboralesFinal,
        clickSubmit
    } = props;

    const [viewModel, setViewModel] = useState({
        identActividadEconomica: 0,
        identPais: 0,
        identDepartamentos: 0,
        identProvincias: 0,
        identDistritos: 0,
        nombreVia: "",
        numeroVia: "",
        intDptoPiso: "",
        manzana: "",
        lote: "",
        nombreAgrupacion: "",
        referencia: "",
        telefono: "",
        anexo: "",
        correo: "",
        identCargoLaboral: 0,
        descripcionCargo: "",
    });

    const [listaProvinciasDepartamentosConyuge, setlistaProvinciasDepartamentos] = useState([]);
    const [listaCiudadesLocalidadesConyuge, setlistaCiudadesLocalidades] = useState([]);
    const [listaBarriosColoniasConyuge, setListaBarriosColonias] = useState([]);

    const getObtenerDepartamentos = (paisId: any) => {
        ObtenerListas.EstadosProvinciasDepartamentos(paisId).then(response => {
            setlistaProvinciasDepartamentos(response);
        });
    };

    const getObtenerCiudadesLocalidades = (paisId: any, departamentoId: any) => {
        ObtenerListas.CiudadesLocalidades(paisId, departamentoId).then(response => {
            setlistaCiudadesLocalidades(response);
        });
    };

    const getObtenerBarriosColonias = (paisId: any, departamentoId: any, provinciaId: any) => {
        ObtenerListas.BarriosColonias(paisId, departamentoId, provinciaId).then(response => {
            setListaBarriosColonias(response);
        });
    };

    const initListas = async () => {

        const dataIngresos = await General.Ingresos(conyugeData.idenT_PERSONA);
        const ingresoPrincipal = dataIngresos.find((x: any) => x.inD_INGRESO_PRINCIPAL === true);
        const dataEmpresa: any = await General.Empresa(ingresoPrincipal.idenT_EMPRESA);
        let departamentoId = 0;
        let provinciaId = 0;

        if (dataEmpresa.idenT_UBIGEO !== 1) {
            departamentoId = GeneralHelper.ObtenerDepartamentoId(dataEmpresa.idenT_UBIGEO);
            provinciaId = GeneralHelper.ObtenerProvinciaId(dataEmpresa.idenT_UBIGEO);
        } else {
            departamentoId = 1;
            provinciaId = 1;
        }

        Promise.all([
            ObtenerListas.EstadosProvinciasDepartamentos(dataEmpresa.idenT_PAIS),
            ObtenerListas.CiudadesLocalidades(dataEmpresa.idenT_PAIS, departamentoId),
            ObtenerListas.BarriosColonias(dataEmpresa.idenT_PAIS, departamentoId, provinciaId)
        ]).then(response => {
            
            const departamentos = response[0];
            const provincias = response[1];
            const distritos = response[2];

            setlistaProvinciasDepartamentos(departamentos);
            setlistaCiudadesLocalidades(provincias);
            setListaBarriosColonias(distritos);

        });

        setViewModel({ 
            ...viewModel, 
            identPais: dataEmpresa.idenT_PAIS,
            identDepartamentos: departamentoId,
            identProvincias: provinciaId,
            identDistritos: dataEmpresa.idenT_UBIGEO,
            nombreVia: dataEmpresa.descM_NOMBRE_VIA,
            numeroVia: dataEmpresa.descC_NUMERO,
            intDptoPiso: dataEmpresa.descC_INTERIOR_DEPARTAMENTO_PISO,
            lote: dataEmpresa.descC_LOTE,
            nombreAgrupacion: dataEmpresa.descM_NOMBRE_AGRUPACION,
            referencia: dataEmpresa.descL_REFERENCIA,
            telefono: dataEmpresa.descC_TELEFONO,
            anexo: dataEmpresa.descC_ANEXOS,
            correo: dataEmpresa.descC_CORREO,
            identCargoLaboral: conyugeData.idenT_CARGO_LABORAL,
            descripcionCargo: conyugeData.descL_DESCRIPCION_CARGO,
            identActividadEconomica: dataEmpresa.iD_ACTIVIDAD_ECONOMICA
        });

    }

    useEffect(() => {
        initListas();
    }, []);

    useEffect(() => {
        if (clickSubmit !== 0) {

            const findPais: any = listPais.find((pais: any) => pais.id === viewModel.identPais);
            const descripcionPais = findPais?.description;

            const findCategoriaLaboral: any = listProfesion.find((profesion: any) => profesion.id === viewModel.identCargoLaboral);
            const descripcionCategoriaLaboral = findCategoriaLaboral?.description;

            const findActividadEconomica = listActividadEconomica.find((actividadEconomica: any) => actividadEconomica.id === viewModel.identActividadEconomica);
            const descripcionActividadEconomica = findActividadEconomica?.description;
            
            const findDepartamento: any = listaProvinciasDepartamentosConyuge.find((departamento: any) => departamento.id === viewModel.identDepartamentos);
            const descripcionDepartamento = findDepartamento?.description;

            const findProvincia: any = listaCiudadesLocalidadesConyuge.find((provincia: any) => provincia.id === viewModel.identProvincias);
            const descripcionProvincia = findProvincia?.description;

            const findDistrito: any = listaBarriosColoniasConyuge.find((distrito: any) => distrito.id === viewModel.identDistritos);
            const descripcionDistrito = findDistrito?.description;

            setConyugeLaboralesFinal({
                ...viewModel,
                descripcionPais,
                descripcionCategoriaLaboral,
                descripcionActividadEconomica,
                empresaUbigeoDescripcion: `${descripcionDistrito} / ${descripcionProvincia} / ${descripcionDepartamento}`,
                empresaUbigeo: Number(viewModel.identDistritos),
            });
        }
    }, [clickSubmit]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={`DATOS LABORALES DEL ${titularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? "CÓNYUGE" : "CONVIVIENTE"}`} obligatorio={true} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        value={viewModel.identActividadEconomica}
                        name={"identActividadEconomica"}
                        title={"Actividad económica"}
                        options={listActividadEconomica}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identActividadEconomica: newValue
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        name={"identPais"}
                        title={"País"}
                        options={listPais}
                        value={viewModel.identPais}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identPais: newValue,
                                identDepartamentos: 0,
                                identProvincias: 0,
                                identDistritos: 0
                            })
                            getObtenerDepartamentos(newValue);
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identDepartamentos"}
                        title={"Departamentos"}
                        options={listaProvinciasDepartamentosConyuge}
                        value={viewModel.identDepartamentos}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identDepartamentos: newValue,
                                identProvincias: 0,
                                identDistritos: 0
                            });
                            
                            getObtenerCiudadesLocalidades(
                                viewModel.identPais,
                                newValue
                            );
                        }}
                    />
                </div>

            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identProvincias"}
                        title={"Provincias"}
                        options={listaCiudadesLocalidadesConyuge}
                        value={viewModel.identProvincias}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identProvincias: newValue,
                                identDistritos: 0
                            });
                            getObtenerBarriosColonias(
                                viewModel.identPais,
                                viewModel.identDepartamentos,
                                newValue
                            );
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        value={viewModel.identDistritos}
                        name={"identDistritos"}
                        title={"Distritos"}
                        options={listaBarriosColoniasConyuge}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, identDistritos: newValue })
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreVia"}
                        title={"Nombre de vía"}
                        required={true}
                        value={viewModel.nombreVia}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, nombreVia: event.target.value });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"numeroVia"}
                        title={"Número"}
                        required={true}
                        value={viewModel.numeroVia}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                numeroVia: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"intDptoPiso"}
                        title={"Int/Dpto/Piso"}
                        required={false}
                        value={viewModel.intDptoPiso}
                        maxLength={15}
                        onChange={(event: any) => {
                            setViewModel({ ...viewModel, intDptoPiso: event.target.value });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"manzana"}
                        title={"Manzana"}
                        required={false}
                        maxLength={15}
                        placeholder=""
                        value={viewModel.manzana}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                manzana: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"lote"}
                        title={"Lote"}
                        required={false}
                        value={viewModel.lote}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                lote: event.target.value
                            });
                        }}
                        maxLength={15}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreAgrupacion"}
                        title={"Nombre agrupación"}
                        required={false}
                        value={viewModel.nombreAgrupacion}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                nombreAgrupacion: event.target.value
                            });
                        }}
                    />
                </div>
            </div>

            <div className="row g-2 mb-2 align-items-center">

                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"referencia"}
                        title={"Referencia"}
                        required={false}
                        value={viewModel.referencia}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                referencia: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"telefono"}
                        title={"Teléfono"}
                        required={true}
                        value={viewModel.telefono}
                        longNumber={9}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                telefono: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"anexo"}
                        title={"Anexo"}
                        required={false}
                        value={viewModel.anexo}
                        longNumber={9}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                anexo: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"correo"}
                        title={"Correo"}
                        required={true}
                        value={viewModel.correo}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                correo: event.target.value
                            });
                        }}
                    />
                </div>
            </div>

            <div className="row g-2 mb-2 align-items-center">

                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identCargoLaboral"}
                        title={"Cargo laboral"}
                        required={true}
                        options={listProfesion}
                        value={viewModel.identCargoLaboral}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identCargoLaboral: newValue
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"descripcionCargo"}
                        title={"Descripción cargo"}
                        required={false}
                        value={viewModel.descripcionCargo}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                descripcionCargo: event.target.value
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );

};

export default ConyugeDatosLaboralesCard;