import { appConfigKey } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";

const ValidacionStepFourHelper = {
    NewCronograma: (newCronograma: any) => {
        const {
            listaCronograma
        } = newCronograma;

        if (newCronograma.listaCronograma === null ||
            newCronograma.listaCronograma === undefined ||
            newCronograma.listaCronograma.length <= 0)
            return false;

        return true;
    },

    GetCuota: (newCronograma: any, direct: boolean = false) => {
        let cuotaValor = 0;
        let lista = [];

        if (direct) {
            lista = newCronograma;
        } else {
            lista = newCronograma?.listaCronograma;
        }

        if (lista && lista?.length > 0) {
            let cuota = lista.find((c: any) => {
                const mes = Number(c?.fVence.split('/')[1]);
                return mes !== 1 && mes !== 8;
            });

            cuotaValor = cuota?.pagoTotal ? GeneralHelper.ParseNumber(cuota.pagoTotal) : 0;
        }

        return cuotaValor;
    },

    ModelicaMEMEnviarAEvaluacion: (memValor: any, listaConograma: any, cma: string): boolean => { 
        let result: boolean = false;
        try {
            let memValorTmp = GeneralHelper.ParseNumber(memValor);
            let cuotaValor = ValidacionStepFourHelper.GetCuota(listaConograma, true);
            let diferencia = (cuotaValor - memValorTmp);
            
            //MEM del cliente bajo, verificar posibilidad de envío a mesa de crédito
            if (diferencia > 0 && cma == appConfigKey.keySinCMA) {
                result = true;
            }
        } catch (_) {
        }
        return result;
    },

    ModelicaMEM: (memValor: any, newCronograma: any, tieneDerivacionActivo: boolean) => {
        let mensajeModelica;
        let mensajes = [];

        let cuotaValor = ValidacionStepFourHelper.GetCuota(newCronograma)

        if (memValor && memValor < cuotaValor) {
            let memAValorFormat = `${appConfigKey.keySimboloSoles} ${GeneralHelper.AgregarComasAMiles(memValor.toFixed(2))}`;
            let cuotaValorFormat = `${appConfigKey.keySimboloSoles} ${GeneralHelper.AgregarComasAMiles(cuotaValor.toFixed(2))}`;
            mensajes.push(`MEM ${memAValorFormat} es menor que la cuota ${cuotaValorFormat}`);
        }


        if (mensajes.length > 0) {
            if (tieneDerivacionActivo) {
                mensajes.unshift(appConfigKey.keyMsjModelicaObservado.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoMEM));
            } else {
                mensajes.unshift(appConfigKey.keyMsjModelicaObservadoCuota.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoMEM));
            }

            mensajeModelica = mensajes?.map((msg: any, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {(index > 0 ? '- Motivo: ' : '') + msg}
                </div>
            ));
        }

        return mensajeModelica;
    },

    ModelicaDecisionRechazado: (response: any) => {
        let mensajeModelica;
        let decision = response?.Politica_Creditos?.decision_Credito?.decision;
        let motivoRechazo = response?.Politica_Creditos?.motivo_Rechazo;
        let mensajes = [];

        const msgDecision = '- Decisión del crédito:';
        const msgMotivo = '- Motivo:';

        if (decision === appConfigKey.CodigoEvaluacionRechazado) {

            mensajes.push(`${msgDecision} ${appConfigKey.CodigoEvaluacionRechazadoDescripcion}`);

            if (motivoRechazo?.Detalle) {
                const detalleRechazo = motivoRechazo.Detalle.map((det: any, index: number) => `${det?.descripcion} ${(det?.interviniente ? ' del ' + det?.interviniente : '')}`);
                mensajes.push(`${msgMotivo} ${detalleRechazo.join(", ")}`);
            }
        }

        if (mensajes.length > 0) {

            if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                mensajes.unshift(appConfigKey.keyMsjModelicaObservadoCuota.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoDecision));
            }

            mensajeModelica = mensajes?.map((msg: any, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {msg}
                </div>
            ));
        }

        return mensajeModelica;
    },

    ModelicaDecisionRechazadoContinuar: (response: any) => {
        let decision = response?.Politica_Creditos?.decision_Credito?.decision;
        let motivoRechazo = response?.Politica_Creditos?.motivo_Rechazo;

        if (decision == appConfigKey.CodigoEvaluacionRechazado) {
            let motivoRechazoDet: any = motivoRechazo?.Detalle;
            // Verificación del código RCH_9999
            const tieneCodigoRCH = motivoRechazoDet?.some((motivo: any) => motivo.codigo_CMA === appConfigKey.keyRCH_9999);

            // Primera validación: Solo existe el codigo_CMA "RCH_9999"
            if (motivoRechazoDet?.length === 1 && tieneCodigoRCH) {
                return true;
            }
            // Segunda validación: Existen otros CMA
            else if (motivoRechazoDet?.length >= 1) {
                return true;
            }
            // No cumple: No puede continuar
            else {
                return false;
            }
        }
        return false;
    },
}
export default ValidacionStepFourHelper;