import { appConfigKey } from '../../../../../../config/Config';
import './styles.scss';
import { Dialog } from "@mui/material";
import DOMPurify from 'dompurify';

interface ValidarRequestDialogProps {
    open: boolean;
    text: string;
    type?: string;  // Hacemos que `type` sea opcional
    handleClose: () => void;
    handleStart: () => void;
}

const ValidarRequestDialog = ({
    open,
    text = '',
    type,
    handleClose,
    handleStart
}: ValidarRequestDialogProps) => {
    // Sanitizar el texto para evitar XSS
    const sanitizedText = DOMPurify.sanitize(text);
    const sanitizedDetail = type === appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion
        ? DOMPurify.sanitize(appConfigKey.keyTextDetailEvalCrediticiaExcepcion.replace('[DATA]', sanitizedText.toLowerCase()))
        : DOMPurify.sanitize(appConfigKey.keyTextDetailDefaultProceso.replace('[DATA]', sanitizedText.toLowerCase()));

    return (
        <Dialog open={open} maxWidth="sm">
            <div className="validar-request-dialog-content">
                <div className="validar-request-dialog-title">
                    {`EMPEZAR ${sanitizedText.toUpperCase()}`}
                </div>
                <div className="validar-request-dialog-list-content">
                    {sanitizedDetail}
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="validar-request-dialog-btn-cancelar cursor-pointer" onClick={handleClose}>
                            Cancelar
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="validar-request-dialog-btn-empezar cursor-pointer" onClick={handleStart}>
                            Empezar
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ValidarRequestDialog;
