import MasterManager from "../../../services/origination/wapimaster.service";

export const General = {
    UsuarioStatusUpdate: async (identUsuario: number, estado: boolean) => {
        try {
            const response: any = await MasterManager.GetMnUsuarioStatusUpdate(identUsuario, estado);
            const { isValid } = response.data;
            if (response) {
                return isValid;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA ACTUALIZAR EL ESTADO DE GESTION DE EQUIPO.');
            return false;
        }
    },

    UsuarioAsignacionInsert: async (data: any) => {
        try {
            const response: any = await MasterManager.GetMnUsuarioAsignacionInsert(data);
            const { isValid } = response.data;
            if (response) {
                return isValid;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA REGISTRAR LA ASIGNACION.');
            return false;
        }
    }
}

export const ObtenerListas = {

    UsuarioAsignacion: async () => {
        const lista: any = [];
        try {

            const response = await MasterManager.GetMnUsuarioAsignacion();
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LISTA DE GESTION DE EQUIPO.');
        }
        return lista;
    },

    UsuarioAsignacionById: async (identUsuario: number) => {
        const lista: any = [];
        try {

            const response = await MasterManager.GetMnUsuarioAsignacionById(identUsuario);
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LISTA DE CATEGORIAS POR ASINGACION DE USUARIO.');
        }
        return lista;
    },

    ObtenerIdPerfil: async () => {
        const lista: any = [];
        try {
            const response: any = await MasterManager.GetMnUsuarioSesion();
            if (response?.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }

        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTEBER PERFIL.');
            return false;
        }
        return lista;
    }

}