import { MnUsuarioDto } from "../../../../models/MasterManager/usuarios-response.model";
import { SelectItem } from "../../../../models/Shared/select-item.model";

export interface UserModel {
  identUsuario: number;
  identPerfil: number;
  apellidoMaterno: string;
  apellidoPaterno: string;
  nombres: string;
  idTipoDocumento: number;
  nroDocumento: string;
  username: string;
  dominioId: number;
  emailInterno: string;
  telefono: string;
  telefonoMovil: string;
  esBloqueado: boolean;
  esActivo: boolean;
  identCargo: number;
  cargo: string;
  password: string;
  esCambiarPassword: boolean;
}

export interface UserModelFilter {
  tipoDocumentoId: number;
  nroDocumento: string;
  nombreCompleto: string;
  estadoId: number;
}
export interface UserModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  tiposDocumento: SelectItem[];
  userToEdit?: MnUsuarioDto | null;
}

export const userModelDefault: UserModel = {
  identUsuario: 0,
  identPerfil: 0,
  apellidoMaterno: "",
  apellidoPaterno: "",
  nombres: "",
  idTipoDocumento: 0,
  nroDocumento: "",
  username: "",
  dominioId: 0,
  emailInterno: "",
  telefono: "",
  telefonoMovil: "",
  esBloqueado: false,
  esActivo: true,
  identCargo: 0,
  cargo: "",
  password: "",
  esCambiarPassword: true,
};

export const userModelFilterDefault: UserModelFilter = {
  tipoDocumentoId: 0,
  nroDocumento: "",
  nombreCompleto: "",
  estadoId: 0
}