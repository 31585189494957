import { Dialog } from "@mui/material";
import { ReactComponent as CloseIcon } from './../../../../../../assets/media/icons/ico-close-modal.svg'
import { FormControl, Textarea } from "@mui/joy";
import "./index.scss";

interface CommentModalProps {
    open: boolean;
    comentario?: string;
    handleClose?: () => void;
}

const CommentModal = ({ open, comentario, handleClose }: CommentModalProps) => {

    return (
        <Dialog open={open} sx={{
            '& .MuiDialog-paper': {
                borderRadius: '15px',
                alignContent: 'center',
                width: '600px'
            }
        }}>
            <div className="comment-modal-content">
                <button className="btn btn-sm comment-modal-close-btn" onClick={handleClose}>
                    <CloseIcon />
                </button>
                <div className='comment-modal-dialog-title'>COMENTARIO</div>
                <div className="text-end">
                    <FormControl>
                        <Textarea
                            readOnly
                            name="comentario"
                            placeholder=""
                            minRows={5}
                            sx={{ mb: 1 }}
                            value={comentario}
                        />
                    </FormControl>
                </div>
            </div>
        </Dialog>
    );


}
export default CommentModal;