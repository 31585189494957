import axios, { AxiosRequestConfig, CancelToken } from 'axios';
import { appConfig } from '../../../config/Config';
import jwt_decode from 'jwt-decode';
import { reqClasificacionDto } from '../../models/ManagerProfile/ClasificacionDto';
import { MnIntegracionSolicitudRequest } from '../../models/RequestManager/integracion-solicitud-request.model';
import { ResultResponse } from '../../models/Shared/result-response.model';
import { MnIntegracionSolicitudDto } from '../../models/RequestManager/integracion-solicitud-response.model';
import { TMnReiniciarDatosSolicitudRequest } from '../../models/RequestManager/solicitud-request.model';
import { TMnSolicitudAuditDto } from '../../models/RequestManager/solicitud-response.model';
import { MnValidarListaNegraYGesintelRequest } from '../../models/RequestManager/solicitud-validar-request.model';
import { MnValidarListaNegraYGesintelDto } from '../../models/RequestManager/solicitud-validar-response.model';
import { MnIntegracionCotizacionRequest } from '../../models/RequestManager/integracion-cotizacion-request.model';
import { MnIntegracionCotizacionDto } from '../../models/RequestManager/integracion-cotizacion-response.model';
import { GenerarMnFormatoRegimenRequest, MnFormatoRegimenByIdRequest, MnFormatoRegimenUpdateRequest } from '../../models/RequestManager/solicitud-formato-regimen-request.model';
import { MnFormatoRegimenByIdDto, MnFormatoRegimenDto } from '../../models/RequestManager/solicitud-formato-regimen-response.model';
import { VentasDeclaradasPorSolicitudRequest } from '../../models/RequestManager/solicitud-ventas-declaradas-request';
import { VentasDeclaradasPorSolicitudResponse } from '../../models/RequestManager/solicitud-ventas-declaradas-response';
import { MnInformeTerceraRequest, MnSaveInformeTerceraRequest } from '../../models/RequestManager/solicitud-informel-tercera-request.model';
import { MnInformeTerceraResponse } from '../../models/RequestManager/solicitud-informe-tercera-response.model';
import { MnFormatoGenerarRequest } from '../../models/RequestManager/solicitud-formato-regimen-nueva-request.model';
import { MnFormatoAuditorByIdRequest, MnFormatoAuditorRequest } from '../../models/RequestManager/solicitud-formato-auditor-request.model';
import { MnFormatoAuditorResponse } from '../../models/RequestManager/solicitud-formato-auditor-response.model';

const baseUrl = appConfig.apiUrlwapirequest + 'api/RequestManager/';
const RequestManager = {

  GetMnBandejaSolicitudes: async function (data: any, cancelToken?: CancelToken) {

    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      cancelToken: cancelToken
    }
    let url = baseUrl + "GetMnBandejaSolicitudes";
    return axios.post(url, data, config)
  },

  GetMnBandejaSolicitudesAuditor: async function (data: any, cancelToken?: CancelToken) {

    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      cancelToken: cancelToken
    }
    let url = baseUrl + "GetMnBandejaSolicitudesAuditor";
    return axios.post(url, data, config)
  },
  
  GetMnBandejaSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnBandejaSolicitud";
    return axios.post(url, data, config)
  },

  GetMnBandejaSolicitud_v2: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    //myowasp(appConfig.clientIDwapirequest);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnBandejaSolicitud_v2";
    return axios.post(url, data, config)
  },

  GetMnGenerateSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnGenerateSolicitud";
    return axios.post(url, data, config)
  },

  GetMnBandejaSolicitud_v3: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnBandejaSolicitud_v3";
    return axios.post(url, data, config)
  },

  GetMnEvaluacionScoringGestor: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnEvaluacionScoringGestor";
    return axios.post(url, data, config)
  },

  GetMnDatosFinanciamiento: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetDatosSolicitudBpo";
    return axios.post(url, data, config)
  },

  GetMnResultadoRevision: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnResultadoRevision";
    return axios.post(url, data, config)
  },

  GetMnResultadoRevisionInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnResultadoRevisionInsert";
    return axios.post(url, data, config)
  },

  GetMnBienInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnBienInsert";
    return axios.post(url, data, config)
  },

  GetMnGenerateActualizacionSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnGenerateActualizacionSolicitud";
    return axios.post(url, data, config)
  },

  GetMnGenerateActualizacionSolicitudV2: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnGenerateActualizacionSolicitudV2";
    return axios.post(url, data, config)
  },

  GetMnGenerateObservacionSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnGenerateObservacionSolicitud";
    return axios.post(url, data, config)
  },

  GetSolicitudCambiarEstadoById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetSolicitudCambiarEstadoById";
    return axios.post(url, data, config)
  },

  GetUmbralAutonomia: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetUmbralAutonomia";
    return axios.post(url, data, config)
  },

  GetMnSolicitudEstado: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudEstado";
    return axios.post(url, data, config)
  },

  GetMnSolicitudCargaMasiva: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudCargaMasiva";
    return axios.post(url, data, config)
  },

  GetMnIngresosPersonaHistorico: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnIngresosPersonaHistorico";
    return axios.post(url, data, config)
  },

  GetMnSolicitudRCC: async function (request: reqClasificacionDto) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudRCC";
    const jsonString = JSON.stringify(request);
    const json = JSON.parse(jsonString);

    return axios.post(url, json, config)
  },

  GetMnSimularOfertasVehicularBySolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSimularOfertasVehicularBySolicitud";
    const jsonString = JSON.stringify(data);
    const json = JSON.parse(jsonString);
    return axios.post(url, json, config)
  },

  SaveGestor: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "SaveGestorV2";
    return axios.post(url, data, config)
  },

  GetMnBien: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnBien";
    return axios.post(url, data, config)
  },

  GetMnTipoResultado: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnTipoResultado";
    return axios.post(url, data, config)
  },

  GetMnIntegracionCotizacionInsert: async function (data: MnIntegracionCotizacionRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnIntegracionCotizacionInsert";
    return axios.post<ResultResponse<MnIntegracionCotizacionDto[]>>(url, data, config)
  },

  GetMnIntegracionSolicitudInsert: async function (data: MnIntegracionSolicitudRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnIntegracionSolicitudInsert";
    return axios.post<ResultResponse<MnIntegracionSolicitudDto[]>>(url, data, config)
  },

  GetMnOfertaConsumoPrestamo: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnOfertaConsumoPrestamo";
    return axios.post(url, data, config)
  },

  GetMnReiniciarDatosSolicitud: async function (data: TMnReiniciarDatosSolicitudRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnReiniciarDatosSolicitud";
    return axios.post<ResultResponse<TMnSolicitudAuditDto>>(url, data, config)
  },

  GetValidarListaNegraYGesintel: async function (data: MnValidarListaNegraYGesintelRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetValidarListaNegraYGesintel";
    return axios.post<ResultResponse<MnValidarListaNegraYGesintelDto>>(url, data, config)
  },


  GetValidacionSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetValidacionSolicitud";
    return axios.post(url, data, config)
  },

  AddSolicitudException: async function (data: any) {

    const account = appConfig.msalInstance.getActiveAccount();

    if (!account) {
      throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount.");
    }

    const acquireTokenSilentBody = {
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    };

    const response = await appConfig.msalInstance.acquireTokenSilent(acquireTokenSilentBody);

    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    };

    let url = baseUrl + "AddSolicitudExcepcion";

    return axios.post(url, data, config);

  },

  GetMnActualizarDatosAdicionales: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnActualizarDatosAdicionales";
    return axios.post(url, data, config)
  },

  GetSolicitudExceptionByIdSolicitud: async function (data: any) {

    const account = appConfig.msalInstance.getActiveAccount();

    if (!account) {
      throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount.");
    }

    const acquireTokenSilentBody = {
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    };

    const response = await appConfig.msalInstance.acquireTokenSilent(acquireTokenSilentBody);

    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    };

    let url = baseUrl + "GetSolicitudExcepcionByIdSolicitud";

    return axios.post(url, data, config);

  },

  GetSolicitudExcepcionValidarUsuarioByIdSolicitud: async function (data: any) {

    const account = appConfig.msalInstance.getActiveAccount();

    if (!account) {
      throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount.");
    }

    const acquireTokenSilentBody = {
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    };

    const response = await appConfig.msalInstance.acquireTokenSilent(acquireTokenSilentBody);

    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    };

    let url = baseUrl + "GetSolicitudExcepcionValidarUsuarioByIdSolicitud";

    return axios.post(url, data, config);

  },
  GetConsultaUsuarioGestionSolicitudes: async function () {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetConsultaUsuarioGestionSolicitudes";
    return axios.post(url, null, config)
  },

  GetConsultaUsuariosReasignarGestionSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetConsultaUsuariosReasignarGestionSolicitud";
    return axios.post(url, data, config)
  },
  
  GetConsultaBandejaGestionSolicitudes: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetConsultaBandejaGestionSolicitudes";
    return axios.post(url, data, config)
  },

  GetUsuarioReasignarGestionSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetUsuarioReasignarGestionSolicitud";
    return axios.post(url, data, config)
  },
  
  GetSolicitudExcepcionLiberacionById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetSolicitudExcepcionLiberacionById";
    return axios.post(url, data, config)
  },
  GetSolicitudExcepcionLiberacionByIdSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetSolicitudExcepcionLiberacionByIdSolicitud";
    return axios.post(url, data, config)
  },

  AddSolicitudExcepcion: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "AddSolicitudExcepcion";
    return axios.post(url, data, config)
  },

  GetSolicitudExcepcionLiberacionInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetSolicitudExcepcionLiberacionInsert";
    return axios.post(url, data, config)
  },

  GetSolicitudEtapas: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetSolicitudEtapas";
    return axios.post(url, data, config)
  },

  
  GetSolicitudEtapasInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetSolicitudEtapasInsert";
    return axios.post(url, data, config)
  },

  GetSolicitudEtapasUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetSolicitudEtapasUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialByIdSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialById";
    return axios.post(url, data, config)
  },

  MnInformeComercialUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialPersonaAccionista: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialPersonaAccionista";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialPersonaAccionistaById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialPersonaAccionistaById";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaAccionistaInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaAccionistaInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaAccionistaUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaAccionistaUpdate";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaAccionistaDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaAccionistaDelete";
    return axios.post(url, data, config)
  },
  

  GetInformeComercialPersona: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetInformeComercialPersona";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialPersonaById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialPersonaById";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaUpdate";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaDelete";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialParticipacionPatrimonialById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialParticipacionPatrimonialById";
    return axios.post(url, data, config)
  },

  GetInformeComercialParticipacionPatrimonial: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetInformeComercialParticipacionPatrimonial";
    return axios.post(url, data, config)
  },

  MnInformeComercialParticipacionPatrimonialDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialParticipacionPatrimonialDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialParticipacionPatrimonialInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialParticipacionPatrimonialInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialParticipacionPatrimonialUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialParticipacionPatrimonialUpdate";
    return axios.post(url, data, config)
  },
  
  GetMnInformeComercialCargosOtrasEntidadesById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialCargosOtrasEntidadesById";
    return axios.post(url, data, config)
  },

  GetInformeComercialCargosOtrasEntidades: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialCargosOtrasEntidades";
    return axios.post(url, data, config)
  },

  MnInformeComercialCargosOtrasEntidadesDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialCargosOtrasEntidadesDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialCargosOtrasEntidadesInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialCargosOtrasEntidadesInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialCargosOtrasEntidadesUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialCargosOtrasEntidadesUpdate";
    return axios.post(url, data, config)
  },

  GenerarMnFormatoByRegimen: async function (data: GenerarMnFormatoRegimenRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GenerarMnFormatoByRegimen";
    return axios.post<ResultResponse<MnFormatoRegimenDto>>(url, data, config)
  },

  UpdateMnFormatoRegimen: async function (data: MnFormatoRegimenUpdateRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "UpdateMnFormatoRegimen";
    return axios.post<ResultResponse<MnFormatoRegimenDto>>(url, data, config)
  },

  GetMnFormatoRegistroById: async function (data: MnFormatoRegimenByIdRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnFormatoRegistroById";
    return axios.post<ResultResponse<MnFormatoRegimenByIdDto>>(url, data, config)
  },

  GetMnInformeComercialProduccionById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialProduccionById";
    return axios.post(url, data, config)
  },

  GetInformeComercialProduccion: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetInformeComercialProduccion";
    return axios.post(url, data, config)
  },

  MnInformeComercialProduccionDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProduccionDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialProduccionInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProduccionInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialProduccionUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProduccionUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialMaquinariasById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialMaquinariasById";
    return axios.post(url, data, config)
  },

  GetInformeComercialMaquinarias: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetInformeComercialMaquinarias";
    return axios.post(url, data, config)
  },

  MnInformeComercialMaquinariasDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialMaquinariasDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialMaquinariasInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialMaquinariasInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialMaquinariasUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialMaquinariasUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialProveedoresById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialProveedoresById";
    return axios.post(url, data, config)
  },

  GetInformeComercialProveedores: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetInformeComercialProveedores";
    return axios.post(url, data, config)
  },

  MnInformeComercialProveedoresDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProveedoresDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialProveedoresInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProveedoresInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialProveedoresUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProveedoresUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialClientesById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialClientesById";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialClientes: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialClientes";
    return axios.post(url, data, config)
  },

  MnInformeComercialClientesDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialClientesDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialClientesInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialClientesInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialClientesUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialClientesUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialGrupoEconomicoById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialGrupoEconomicoById";
    return axios.post(url, data, config)
  },

  GetInformeComercialGrupoEconomico: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetInformeComercialGrupoEconomico";
    return axios.post(url, data, config)
  },

  MnInformeComercialGrupoEconomicoDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialGrupoEconomicoDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialGrupoEconomicoInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialGrupoEconomicoInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialGrupoEconomicoUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialGrupoEconomicoUpdate";
    return axios.post(url, data, config)
  },
  // Informe Comercial Registro
  GetMnInformeComercialRegistroByIdSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialRegistroByIdSolicitud";
    return axios.post(url, data, config)
  },

  MnInformeComercialRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialRegistroUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialPersonaAccionistaRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialPersonaAccionistaRegistro";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialPersonaAccionistaRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialPersonaAccionistaRegistroById";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaAccionistaRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaAccionistaRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaAccionistaRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaAccionistaRegistroUpdate";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaAccionistaRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaAccionistaRegistroDelete";
    return axios.post(url, data, config)
  },
  

  GetInformeComercialPersonaRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialPersonaRegistro";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialPersonaRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialPersonaRegistroById";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaRegistroUpdate";
    return axios.post(url, data, config)
  },

  MnInformeComercialPersonaRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialPersonaRegistroDelete";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialParticipacionPatrimonialRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialParticipacionPatrimonialRegistroById";
    return axios.post(url, data, config)
  },

  GetInformeComercialParticipacionPatrimonialRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialParticipacionPatrimonialRegistro";
    return axios.post(url, data, config)
  },

  MnInformeComercialParticipacionPatrimonialRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialParticipacionPatrimonialRegistroDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialParticipacionPatrimonialRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialParticipacionPatrimonialRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialParticipacionPatrimonialRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialParticipacionPatrimonialRegistroUpdate";
    return axios.post(url, data, config)
  },
  
  GetMnInformeComercialCargosOtrasEntidadesRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialCargosOtrasEntidadesRegistroById";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialCargosOtrasEntidadesRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialCargosOtrasEntidadesRegistro";
    return axios.post(url, data, config)
  },

  MnInformeComercialCargosOtrasEntidadesRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialCargosOtrasEntidadesRegistroDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialCargosOtrasEntidadesRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialCargosOtrasEntidadesRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialCargosOtrasEntidadesRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialCargosOtrasEntidadesRegistroUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialProduccionRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialProduccionRegistroById";
    return axios.post(url, data, config)
  },

  GetInformeComercialProduccionRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialProduccionRegistro";
    return axios.post(url, data, config)
  },

  MnInformeComercialProduccionRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProduccionRegistroDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialProduccionRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProduccionRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialProduccionRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProduccionRegistroUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialMaquinariasRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialMaquinariasRegistroById";
    return axios.post(url, data, config)
  },

  GetInformeComercialMaquinariasRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialMaquinariasRegistro";
    return axios.post(url, data, config)
  },

  MnInformeComercialMaquinariasRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialMaquinariasRegistroDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialMaquinariasRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialMaquinariasRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialMaquinariasRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialMaquinariasRegistroUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialProveedoresRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialProveedoresRegistroById";
    return axios.post(url, data, config)
  },

  GetInformeComercialProveedoresRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialProveedoresRegistro";
    return axios.post(url, data, config)
  },

  MnInformeComercialProveedoresRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProveedoresRegistroDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialProveedoresRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProveedoresRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialProveedoresRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialProveedoresRegistroUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialClientesRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialClientesRegistroById";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialClientesRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialClientesRegistro";
    return axios.post(url, data, config)
  },

  MnInformeComercialClientesRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialClientesRegistroDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialClientesRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialClientesRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialClientesRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialClientesRegistroUpdate";
    return axios.post(url, data, config)
  },

  GetMnInformeComercialGrupoEconomicoRegistroById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialGrupoEconomicoRegistroById";
    return axios.post(url, data, config)
  },

  GetInformeComercialGrupoEconomicoRegistro: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnInformeComercialGrupoEconomicoRegistro";
    return axios.post(url, data, config)
  },

  MnInformeComercialGrupoEconomicoRegistroDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialGrupoEconomicoRegistroDelete";
    return axios.post(url, data, config)
  },

  MnInformeComercialGrupoEconomicoRegistroInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialGrupoEconomicoRegistroInsert";
    return axios.post(url, data, config)
  },

  MnInformeComercialGrupoEconomicoRegistroUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnInformeComercialGrupoEconomicoRegistroUpdate";
    return axios.post(url, data, config)
  },
  GetVentasDeclaradasPorSolicitud: async function (data: VentasDeclaradasPorSolicitudRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetVentasDeclaradasPorSolicitud";
    return axios.post<ResultResponse<VentasDeclaradasPorSolicitudResponse>>(url, data, config)
  },

  GetMnEvaluacionScoringTercera: async function (data: MnInformeTerceraRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnEvaluacionScoringTercera";
    return axios.post<ResultResponse<MnInformeTerceraResponse>>(url, data, config)
  },

  SaveMnEvaluacionScoringTercera: async function (data: MnSaveInformeTerceraRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "SaveMnEvaluacionScoringTercera";
    return axios.post<ResultResponse<MnInformeTerceraResponse>>(url, data, config)
  },

  MnFormatoNuevaVersion: async function (data: MnFormatoGenerarRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "MnFormatoNuevaVersion";
    return axios.post<ResultResponse<number>>(url, data, config)
  },

  GetMnFormatoAuditorById: async function (data: MnFormatoAuditorByIdRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnFormatoAuditorById";
    return axios.post<ResultResponse<MnFormatoAuditorResponse>>(url, data, config)
  },

  UpdateMnFormatoAuditor: async function (data: MnFormatoAuditorRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "UpdateMnFormatoAuditor";
    return axios.post<ResultResponse<MnFormatoAuditorResponse>>(url, data, config)
  },
  
  GetMnFormatoSolicitudData: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapirequest}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnFormatoSolicitudData";
    return axios.post(url, data, config)
  },
};
export default RequestManager;
