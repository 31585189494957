import { Button, IconButton, Link, Table } from "@mui/joy";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import { useParams } from "react-router-dom";
import IconDownload from '../../../../assets/media/icons/ico_download.svg'
import React from "react";
import MasterManager from "../../../services/origination/wapimaster.service";
import { appConfigKey, appSecurityConfiguration } from "../../../../config/Config";
import IconAproved from '../../../../assets/media/icons/ico-aproved.svg';
import icoDenided from '../../../../assets/media/icons/ico-denided.svg';
import DocumentManager from "../../../services/business/document.service";
import { toast } from "sonner";

export const ResultadosEvaluacion = (props: any) => {
  const { resultadosEvaluacion } = props;
  const { solicitudId } = useParams();

  function renderStatus(status: number) {
    let position = 0;
    const listStatus = [appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
    (<> {appConfigKey.keyDescripEstadoDocumentoAdjuntado} <img src={IconAproved} /></>),
    (<> {appConfigKey.keyDescripEstadoDocumentoConforme} <img src={IconAproved} /></>),
    (<> {appConfigKey.keyDescripEstadoDocumentoObservado} <img src={icoDenided} /></>)];

    switch (status) {
      case appConfigKey.keyIdEstadoDocumentoPorAdjuntar:
        position = 0;
        break;
      case appConfigKey.keyIdEstadoDocumentoAdjuntado:
        position = 1;
        break;
      case appConfigKey.keyIdEstadoDocumentoConforme:
        position = 2;
        break;
      case appConfigKey.keyIdEstadoDocumentoObservado:
        position = 3;
        break;
    }

    return listStatus[position];
  }
  function addStyleStatus(status: number) {
    let style = '';
    switch (status) {
      case appConfigKey.keyIdEstadoDocumentoAdjuntado:
        style = 'color-aproved';
        break;
      case appConfigKey.keyIdEstadoDocumentoConforme:
        style = 'color-aproved';
        break;
      case appConfigKey.keyIdEstadoDocumentoObservado:
        style = 'color-denided';
        break;
      default: style = '';
        break;
    }
    return style;
  }


  const handleDownloadFile = async (event: any, data: any) => {
    event.preventDefault();
    try {
      DocumentManager.DescargarDocumentoBT(data.idenT_DOCUMENTACION_DOCUMENTO, Number(solicitudId))
    } catch (error: any) {
      toast.error(`${error.message ?? error}`, { duration: appConfigKey.keyDurationToast });
    }
  };

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">DOCUMENTOS</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-12 tableContainer-resultado">
          <Table borderAxis="none" className="style-table align-top" stripe={'odd'}>
            <thead>
              <tr>
                <th style={{ width: "350px", backgroundColor: "#E1E1E1", }}>DOCUMENTO</th>
                <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                <th style={{ width: "350px", backgroundColor: "#E1E1E1", }}>ARCHIVO</th>
                <th style={{ width: "100px", backgroundColor: "#E1E1E1", }}>ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              {
                resultadosEvaluacion.map((row: any) => {
                  return (
                    <tr key={row.idenT_DOCUMENTO_BT}>
                      <td>{row.descC_TIPO_DOCUMENTO}</td>
                      <td><div className={addStyleStatus(row.idenT_ESTADO_ADJUNTO)}>{renderStatus(row.idenT_ESTADO_ADJUNTO)}</div></td>
                      <td style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{row.descC_NOMBRE_ARCHIVO}</td>
                      {/* <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}><IconButton href="https://www.google.com.pe/" target="_blank"></DownloadIcon></IconButton></td> */}
                      <td style={{ textAlign: "left" }} >
                        <img style={{ paddingLeft: '5px', width: '23px', cursor: "pointer" }} src={IconDownload} onClick={(e) => handleDownloadFile(e, row)} />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ResultadosEvaluacion;