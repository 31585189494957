import { appConfigKey, appDefaultValues } from "../../config/Config";
import GeneralHelper from "./GeneralHelper";

export class CalcularInicialConBonoRequest {

    private _montoVehiculoSoles: Number;
    private _montoVehiculoDolares: Number;
    private _montoBono: Number;
    private _tipoCambio: Number;
    private _porcentajeInicial: Number;
    private _divisa: Number;
    private _tipoDescuento: Number;

    constructor() {
        this._montoVehiculoSoles = appDefaultValues.NumberDefault;
        this._montoVehiculoDolares = appDefaultValues.NumberDefault;
        this._montoBono = appDefaultValues.NumberDefault;
        this._tipoCambio = appDefaultValues.NumberDefault;
        this._porcentajeInicial = appDefaultValues.NumberDefault;
        this._divisa = appDefaultValues.NumberDefault;
        this._tipoDescuento = appDefaultValues.NumberDefault;
    }

    public get_montoVehiculoSoles(): Number {
        return this._montoVehiculoSoles;
    }

    public set_montoVehiculoSoles(_montoVehiculoSoles: Number): void {
        this._montoVehiculoSoles = _montoVehiculoSoles;
    }

    public get_montoVehiculoDolares(): Number {
        return this._montoVehiculoDolares;
    }

    public set_montoVehiculoDolares(_montoVehiculoDolares: Number): void {
        this._montoVehiculoDolares = _montoVehiculoDolares;
    }

    public get_montoBono(): Number {
        return this._montoBono;
    }

    public set_montoBono(_montoBono: Number): void {
        this._montoBono = _montoBono;
    }

    public get_tipoCambio(): Number {
        return this._tipoCambio;
    }

    public set_tipoCambio(_tipoCambio: Number): void {
        this._tipoCambio = _tipoCambio;
    }

    public get_porcentajeInicial(): Number {
        return this._porcentajeInicial;
    }

    public set_porcentajeInicial(_porcentajeInicial: Number): void {
        this._porcentajeInicial = _porcentajeInicial;
    }

    public get_divisa(): Number {
        return this._divisa;
    }

    public set_divisa(_divisa: Number): void {
        this._divisa = _divisa;
    }

    public get_tipoDescuento(): Number {
        return this._tipoDescuento;
    }

    public set_tipoDescuento(_tipoDescuento: Number): void {
        this._tipoDescuento = _tipoDescuento;
    }

}

export const calcularMontoVehiculoConBono = (request: CalcularInicialConBonoRequest) => {

    let tempMontoBono: Number = appDefaultValues.NumberDefault;
    let tempMontoVehiculo: Number = appDefaultValues.NumberDefault;

    if (appConfigKey.keyIdentificadorDolares === request.get_divisa()) {

        if (Number(request.get_tipoDescuento()) === 1) {
            tempMontoBono = Number(request.get_montoBono());
        }

        tempMontoVehiculo = Number(request.get_montoVehiculoDolares()) - Number(tempMontoBono);

    } else {

        if (Number(request.get_tipoDescuento()) === 1) {
            tempMontoBono = Number(request.get_montoBono()) * Number(request.get_tipoCambio());
        }

        tempMontoVehiculo = Number(request.get_montoVehiculoSoles()) - Number(tempMontoBono);

    }

    return tempMontoVehiculo;

};

export const calcularMontoVehiculoOriginal = (request: CalcularInicialConBonoRequest) => {

    let tempMontoVehiculo: Number = appDefaultValues.NumberDefault;

    if (appConfigKey.keyIdentificadorDolares === request.get_divisa()) {
        tempMontoVehiculo = Number(request.get_montoVehiculoDolares());

    } else {
        tempMontoVehiculo = Number(request.get_montoVehiculoSoles());
    }

    return tempMontoVehiculo;

};

export const calcularInicialConBono = (request: CalcularInicialConBonoRequest) => {

    let tempMontoBono: Number = appDefaultValues.NumberDefault;
    let tempMontoVehiculo: Number = appDefaultValues.NumberDefault;

    if (appConfigKey.keyIdentificadorDolares === request.get_divisa()) {
        if (Number(request.get_tipoDescuento()) === 1) {
            tempMontoBono = Number(request.get_montoBono());
        }
        tempMontoVehiculo = Number(request.get_montoVehiculoDolares()) - Number(tempMontoBono);
    } else {
        if (Number(request.get_tipoDescuento()) === 1) {
            tempMontoBono = Number(request.get_montoBono()) * Number(request.get_tipoCambio());
        }
        tempMontoVehiculo = Number(request.get_montoVehiculoSoles()) - Number(tempMontoBono);
    }

    const tempPorcentaje = Number(request.get_porcentajeInicial()) / 100;

    return Number(tempMontoVehiculo) * tempPorcentaje;

};

export const calcularInicialOriginal = (request: CalcularInicialConBonoRequest) => {

    let tempMontoVehiculo: Number = appDefaultValues.NumberDefault;

    if (appConfigKey.keyIdentificadorDolares === request.get_divisa()) {
        tempMontoVehiculo = Number(request.get_montoVehiculoDolares());
    } else {
        tempMontoVehiculo = Number(request.get_montoVehiculoSoles());
    }

    const tempPorcentaje = Number(request.get_porcentajeInicial()) / 100;

    return Number(tempMontoVehiculo) * tempPorcentaje;

};

export const calcularValorVehiculoDolaresOriginal = (request: CalcularInicialConBonoRequest) => {
    return Number(request.get_montoVehiculoDolares());
};

export const calcularValorVehiculoDolares = (request: CalcularInicialConBonoRequest) => {
    let tempMontoBono = appDefaultValues.NumberDefault;
    if (Number(request.get_tipoDescuento()) === 1) {
        tempMontoBono = Number(request.get_montoBono());
    }
    return Number(request.get_montoVehiculoDolares()) - Number(tempMontoBono);
};

export const calcularValorVehiculoBallon = (request: CalcularInicialConBonoRequest) => {
    let tempMontoBono = calcularValorVehiculoDolares(request);
    return request.get_divisa() === appConfigKey.keyIdentificadorDolares
        ? tempMontoBono
        : tempMontoBono * Number(request.get_tipoCambio());
};

export const calcularValorInicialDolaresOriginal = (request: CalcularInicialConBonoRequest) => {
    let tempMontoVehiculoDolares = calcularValorVehiculoDolaresOriginal(request);
    const tempPorcentaje = Number(request.get_porcentajeInicial()) / 100;
    return Number(tempMontoVehiculoDolares) * tempPorcentaje;
};

export const calcularValorInicialDolares = (request: CalcularInicialConBonoRequest) => {
    let tempMontoVehiculoDolares = calcularValorVehiculoDolares(request);
    const tempPorcentaje = Number(request.get_porcentajeInicial()) / 100;
    return Number(tempMontoVehiculoDolares) * tempPorcentaje;
};

export const calcularMontoFinanciarBono = (request: CalcularInicialConBonoRequest) => {
    const inicial: Number = calcularInicialConBono(request);
    const montoVehiculo: Number = calcularMontoVehiculoConBono(request);
    return Number(montoVehiculo) - Number(inicial);
};

export const calcularMontoFinanciarOriginal = (request: CalcularInicialConBonoRequest) => {
    const inicial: Number = calcularInicialOriginal(request);
    const montoVehiculo: Number = calcularMontoVehiculoOriginal(request);
    return Number(montoVehiculo) - Number(inicial);
};

export const calcularMontoFinanciarPrecioBono = (request: CalcularInicialConBonoRequest) => {
    const inicial: Number = calcularInicialOriginal(request);
    const montoVehiculo: Number = calcularMontoVehiculoConBono(request);
    return Number(montoVehiculo) - Number(inicial);
};

export const calcularMontoFinanciarBonoYSimbolo = (request: CalcularInicialConBonoRequest) => {
    const montoFinanciar = calcularMontoFinanciarBono(request);
    const tempMoneda = appConfigKey.keyIdentificadorDolares === Number(request.get_divisa()) ? '$' : 'S/';
    return `${tempMoneda} ${GeneralHelper.AgregarComasAMiles(montoFinanciar.toFixed(2))}`;
};

export const calcularMontoFinanciarPrecioBonoYSimbolo = (request: CalcularInicialConBonoRequest) => {
    const montoFinanciar = calcularMontoFinanciarPrecioBono(request);
    const tempMoneda = appConfigKey.keyIdentificadorDolares === Number(request.get_divisa()) ? '$' : 'S/';
    return `${tempMoneda} ${GeneralHelper.AgregarComasAMiles(montoFinanciar.toFixed(2))}`;
};

export const calcularMontoVehiculoConBonoYSimbolo = (request: CalcularInicialConBonoRequest) => {
    const montoVehiculo = calcularMontoVehiculoConBono(request);
    const tempMoneda = appConfigKey.keyIdentificadorDolares === Number(request.get_divisa()) ? '$' : 'S/';
    return `${tempMoneda} ${GeneralHelper.AgregarComasAMiles(montoVehiculo.toFixed(2))}`;
};

export const calcularInicialConBonoYSimbolo = (request: CalcularInicialConBonoRequest) => {
    const montoInicial = calcularInicialConBono(request);
    const tempMoneda = appConfigKey.keyIdentificadorDolares === Number(request.get_divisa()) ? '$' : 'S/';
    return `${tempMoneda} ${GeneralHelper.AgregarComasAMiles(montoInicial.toFixed(2))}`;
};

export const calcularInicialOriginalYSimbolo = (request: CalcularInicialConBonoRequest) => {
    const montoInicial = calcularInicialOriginal(request);
    const tempMoneda = appConfigKey.keyIdentificadorDolares === Number(request.get_divisa()) ? '$' : 'S/';
    return `${tempMoneda} ${GeneralHelper.AgregarComasAMiles(montoInicial.toFixed(2))}`;
};

export const calcularPorcentajeConBono = (request: CalcularInicialConBonoRequest) => {
    let tempPorcentajeOriginal = Number(request.get_porcentajeInicial());
    if (Number(request.get_tipoDescuento()) === 1) {
        const tempMontoInicialOriginal = Number(calcularValorInicialDolaresOriginal(request));
        tempPorcentajeOriginal = Number((tempMontoInicialOriginal / calcularValorVehiculoDolares(request)) * 100);
    }

    return tempPorcentajeOriginal;
}

export const calcularPorcentajeConBonoYSimbolo = (request: CalcularInicialConBonoRequest) => {
    return `${calcularPorcentajeConBono(request).toFixed(2)} %`;
}