import { createContext, useContext, useEffect, useState } from "react";
import { StepsContext } from "./StepsProvider";
import ValidacionStepFiveHelper from "../helpers/ValidacionStepFiveHelper";
import { appConfigDecisionMotor, appConfigEstadosProcesoEtapaAnalisisCredito, appConfigEtapaAnalisisCredito, appConfigKey, appConfigProcesoEtapaAnalisisCredito, appConfigTipoPersonaTercera, appDefaultValues, appSecurityConfiguration } from "../../../../../config/Config";
import { General, ObtenerListas } from "../obtenerServicios";
import JsonHelper from "../../../../helpers/JsonHelper";
import { BTCrearPersonaFisicaDatosLaborales, BTCrearPersonaFisicaDomicilio, BTCrearPersonaFisicaDomicilios, BTCrearPersonaFisicaInformacionDatosLaborales, BTCrearPersonaFisicaRequest } from "../../../../models/bantotal";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import RequestManager from "../../../../services/origination/wapirequest.service";
import { toast } from "sonner";
import { DatosAdicionalesTitularEmpresaInterface, DatosAdicionalesTitularEmpresaInterfaceDefault } from "../../3.80.-SolicitudeStepFive/components/DatosAdicionalesTitularEmpresaCard/interfaces";
import { ConvertidorHelper } from "../../3.80.-SolicitudeStepFive/helpers/ConvertidorHelper";
import { MnPersonaEmpresaAdicionalAuditDto } from "../../../../models/MasterManager/persona-empresa-datos-adicional-response.model";
import { MnPersonaEmpresaAdicionalRequest } from "../../../../models/MasterManager/persona-empresa-datos-adicional-request.model";

export const StepFiveContext = createContext<any>({});

export const StepFiveProvider = ({ children }: any) => {

    const {
        setClickNext,
        step,
        setBtnContinuarLoading,
        setViewModelStepFiveFinal,
        setStep,
        solicitudData,
        listTipoDocumento,
        listEstadoCivil,
        listIdentidadesGenero,
        listMagnitud,
        listActividadEconomica,
        listCategoriaLaboral,
        listRegimen,
        listMoneda,
        listProfesion,
        listPais,
        listCIPrecio,
        listProveedorGPS,
        listNivelEducacion,
        listTipoVivienda,
        listTiempoResidencia,
        listaEstadosVehiculos,
        viewModelStepOneFinal,
        viewModelStepTwoFinal,
        viewModelStepThreeFinal,
        viewModelStepFourFinal,
        responseStepOne, responseStepTwo, responseStepThree,
        requestStepOne, requestStepTwo, requestStepThree,
        setLoadingSteps,
        personaUId,
        TCEA,
        listaDomicilio,
        conyugeUId,
        stepsTotalFinanciamiento,
        setViewModelStepOneFinal,
        validacionModelicaDecisionRCH,
        isTitularJuridico,
        retrocedioAlQuintoPaso,
        readonlyForm,
        memValor,
    } = useContext(StepsContext);

    const [datosAdicionalesTitularEmpresaData, setDatosAdicionalesTitularEmpresaData] = useState<DatosAdicionalesTitularEmpresaInterface | null>(null);

    const [newDatosAdicionalesTitularData, setNewDatosAdicionalesTitularData] = useState<any>(null);
    const [newDomicilioTitularData, setNewDomicilioTitularData] = useState<any>(null);
    const [newDatosLaboralesAdicionalesTitularData, setNewDatosLaboralesAdicionalesTitularData] = useState<any>(null);
    const [newDatosAdicionalesConyugeData, setNewDatosAdicionalesConyugeData] = useState<any>(null);
    const [newDatosLaboralesAdicionalesConyugeData, setNewDatosLaboralesAdicionalesConyugeData] = useState<any>(null);
    const [listaProvinciasDepartamentos, setListaProvinciasDepartamentos] = useState([]);
    const [listaCiudadesLocalidades, setListaCiudadesLocalidades] = useState([]);
    const [listaBarriosColonias, setListaBarriosColonias] = useState([]);
    const [listaProvinciasDepartamentosConyuge, setListaProvinciasDepartamentosConyuge] = useState([]);
    const [listaCiudadesLocalidadesConyuge, setListaCiudadesLocalidadesConyuge] = useState([]);
    const [listaBarriosColoniasConyuge, setListaBarriosColoniasConyuge] = useState([]);
    const [newDatosTitularEmpresaData, setNewDatosTitularEmpresaData] = useState<any>(null);
    const [newDatosAdicionalesTitularEmpresaData, setNewDatosAdicionalesTitularEmpresaData] = useState<any>(null);

    const getObtenerDepartamentos = async (paisId: number) => {
        const response = await ObtenerListas.EstadosProvinciasDepartamentos(paisId);
        setListaProvinciasDepartamentos(response);
    };

    const getObtenerCiudadesLocalidades = async (paisId: number, departamentoId: number) => {
        const response = await ObtenerListas.CiudadesLocalidades(paisId, departamentoId);
        setListaCiudadesLocalidades(response);
    };

    const getObtenerBarriosColonias = async (paisId: number, departamentoId: number, provinciaId: number) => {
        const response = await ObtenerListas.BarriosColonias(paisId, departamentoId, provinciaId);
        setListaBarriosColonias(response);
    };

    const getObtenerDepartamentosConyuge = async (paisId: number) => {
        const response = await ObtenerListas.EstadosProvinciasDepartamentos(paisId);
        setListaProvinciasDepartamentosConyuge(response);
    };

    const getObtenerCiudadesLocalidadesConyuge = async (paisId: number, departamentoId: number) => {
        const response = await ObtenerListas.CiudadesLocalidades(paisId, departamentoId);
        setListaCiudadesLocalidadesConyuge(response);
    };

    const getObtenerBarriosColoniasConyuge = async (paisId: number, departamentoId: number, provinciaId: number) => {
        const response = await ObtenerListas.BarriosColonias(paisId, departamentoId, provinciaId);
        setListaBarriosColoniasConyuge(response);
    };
    const GenerateActualizacionSolicitudV2 = async (body: any) => {
        const response = await General.GenerateActualizacionSolicitudV2(body);
        return response;
    };

    const GenerateActualizacionSolicitud = async (body: any) => {
        const response = await General.GenerateActualizacionSolicitud(body);
        if (response) {
            if (response?.data.isValid) {
                setStep(step + 1);
            }
        }
        setLoadingSteps(false);
    };

    const unserializeAgrupador4 = async (texto: string) => {
        var datos = texto.split("|")
            .map(x => {
                return { key: x.split("=")[0], value: x.split("=")[1] }
            });
    }

    const serializeAgrupador4 = async (interior: string, manzana: string, lote: string) => {
        var datos = new Array();
        if (interior.length > 0) {
            datos.push("INT=" + interior);
        }
        if (manzana.length > 0) {
            datos.push("MZ=" + manzana);
        }
        if (lote.length > 0) {
            datos.push("LT=" + lote);
        }
        return datos.join("|");
    }

    const ActualizarPersonaFisica = async (tempViewModelStepFiveFinal: any) => {

        let profesionDescripcion = appDefaultValues.StringEmpty;
        let nivelEstucativoDescripcion = appDefaultValues.StringEmpty;
        let nacionalidadDescripcion = appDefaultValues.StringEmpty;

        const nombres = viewModelStepOneFinal.newTitularData.Nombres.split(' ');

        const tipoDocumentoDescripcion = listTipoDocumento.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.TipoDocumentoId).description;

        if (tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identProfesion !== 0) {
            profesionDescripcion = listProfesion.find((item: any) => item.id === tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identProfesion).description;
        }

        if (tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identNivelEducacion !== 0) {
            nivelEstucativoDescripcion = listNivelEducacion.find((item: any) => item.id === tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identNivelEducacion).description;
        }

        if (tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identNacionalidad !== 0) {
            nacionalidadDescripcion = listPais.find((item: any) => item.id === tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identNacionalidad).description;
        }

        const estadoCivilDescripcion = listEstadoCivil.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.EstadoCivilId).description;
        const descripcionDocimilio = listaDomicilio.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.Domicilio).description;

        const primerDomicilio = new BTCrearPersonaFisicaDomicilio();
        primerDomicilio.setDireccion(descripcionDocimilio);
        primerDomicilio.setCodigoId(appConfigKey.CodigoDomicilio);
        primerDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
        primerDomicilio.setAgrupador1(!tempViewModelStepFiveFinal.newDomicilioTitularData.nombreAgrupacion ? appConfigKey.DescripAgrupadorOtros : tempViewModelStepFiveFinal.newDomicilioTitularData.nombreAgrupacion);
        primerDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
        primerDomicilio.setAgrupador2(!tempViewModelStepFiveFinal.newDomicilioTitularData.nombreVia ? appConfigKey.DescripAgrupadorAvenida : tempViewModelStepFiveFinal.newDomicilioTitularData.nombreVia);
        primerDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
        primerDomicilio.setAgrupador3(!tempViewModelStepFiveFinal.newDomicilioTitularData.numDomicilio ? appConfigKey.DescripAgrupadorNumero : tempViewModelStepFiveFinal.newDomicilioTitularData.numDomicilio);
        primerDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
        let primer_agrupador4 = await serializeAgrupador4(tempViewModelStepFiveFinal.newDomicilioTitularData.intDptoPiso,
            tempViewModelStepFiveFinal.newDomicilioTitularData.manzana,
            tempViewModelStepFiveFinal.newDomicilioTitularData.lote);
        primerDomicilio.setAgrupador4(!primer_agrupador4 ? appConfigKey.DescripAgrupadorInterior : primer_agrupador4);
        primerDomicilio.setReferencia(!tempViewModelStepFiveFinal.newDomicilioTitularData.referencia ? appConfigKey.Referencia : tempViewModelStepFiveFinal.newDomicilioTitularData.referencia);

        primerDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
        primerDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
        primerDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));

        const segundoDomicilio = new BTCrearPersonaFisicaDomicilio();
        segundoDomicilio.setDireccion(descripcionDocimilio);
        segundoDomicilio.setCodigoId(appConfigKey.CodigoDomicilioLaboral);
        segundoDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
        segundoDomicilio.setAgrupador1(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.nombreAgrupacion ? appConfigKey.DescripAgrupadorOtros : tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.nombreAgrupacion);
        segundoDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
        segundoDomicilio.setAgrupador2(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.nombreVia ? appConfigKey.DescripAgrupadorAvenida : tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.nombreVia);
        segundoDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
        segundoDomicilio.setAgrupador3(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.numeroVia ? appConfigKey.DescripAgrupadorNumero : tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.numeroVia);
        segundoDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
        let segundo_agrupador4 = await serializeAgrupador4(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.intDptoPiso,
            tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.manzana,
            tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.lote);
        segundoDomicilio.setAgrupador4(!segundo_agrupador4 ? appConfigKey.DescripAgrupadorInterior : segundo_agrupador4);
        segundoDomicilio.setReferencia(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.referencia ? appConfigKey.Referencia : tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.referencia);

        segundoDomicilio.setPaisId(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.identPais);
        segundoDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.identDistritos)));
        segundoDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.identDistritos)));
        segundoDomicilio.setDistritoId(Number(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.identDistritos));

        const listaDomicilios: BTCrearPersonaFisicaDomicilio[] = [];
        listaDomicilios.push(primerDomicilio);
        listaDomicilios.push(segundoDomicilio);

        const domicilios = new BTCrearPersonaFisicaDomicilios();
        domicilios.setSdtJSPSsBTDomicilio(listaDomicilios);

        const ingresosNetos = GeneralHelper.QuitarComasAMiles(viewModelStepOneFinal.newIngresosTitularData.ingresosNetos);

        const datoLaboral = new BTCrearPersonaFisicaDatosLaborales();
        datoLaboral.setNombre(viewModelStepOneFinal.newIngresosTitularData.razonSocial);
        datoLaboral.setRucEmpleador(viewModelStepOneFinal.newIngresosTitularData.ruc);
        datoLaboral.setIngresoNeto(Number(ingresosNetos));
        datoLaboral.setMonedaIngreso(viewModelStepOneFinal.newIngresosTitularData.identTipoMoneda);
        datoLaboral.setFechaIni(viewModelStepOneFinal.newIngresosTitularData.fechaIngresoLaboral);
        datoLaboral.setOcupacionId(viewModelStepOneFinal.newIngresosTitularData.identCategoriaLaboral);
        datoLaboral.setActividadLaboralId(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.identActividadEconomica > 0 ? tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.identActividadEconomica : appConfigKey.CodigoActiviadLaboral);

        const listaDatoLaboral: BTCrearPersonaFisicaDatosLaborales[] = [];
        listaDatoLaboral.push(datoLaboral);

        viewModelStepOneFinal.newIngresosTitularData.listaOtrosTrabajos.map((item: any) => {
            const tempDatoLaboral = new BTCrearPersonaFisicaDatosLaborales();
            const ingresosNetos = GeneralHelper.QuitarComasAMiles(item.ingresosNetos);
            tempDatoLaboral.setNombre(item.razonSocial);
            tempDatoLaboral.setRucEmpleador(item.ruc);
            tempDatoLaboral.setIngresoNeto(Number(ingresosNetos));
            tempDatoLaboral.setMonedaIngreso(item.identTipoMoneda);
            tempDatoLaboral.setFechaIni(item.fechaIngresoLaboral);
            tempDatoLaboral.setOcupacionId(item.identCategoriaLaboral);
            tempDatoLaboral.setActividadLaboralId(item.actividadEconomicaId);
            listaDatoLaboral.push(tempDatoLaboral);
        });

        const informacionDatosLaborales = new BTCrearPersonaFisicaInformacionDatosLaborales();
        informacionDatosLaborales.setSdtJSPSsBTDatosLaborales(listaDatoLaboral);

        const nuevaPersonaFisica = new BTCrearPersonaFisicaRequest();
        nuevaPersonaFisica.setDomicilios(domicilios);
        nuevaPersonaFisica.setDatosLaborales(informacionDatosLaborales);
        nuevaPersonaFisica.setNroDocumento(viewModelStepOneFinal.newTitularData.NumeroDocumento.trim());
        nuevaPersonaFisica.setCorreoElectronico(viewModelStepOneFinal.newTitularData.Correo);
        nuevaPersonaFisica.setFechaNacimiento(viewModelStepOneFinal.newTitularData.FechaNacimiento);
        nuevaPersonaFisica.setTelefonoCelular(viewModelStepOneFinal.newTitularData.Celular);
        nuevaPersonaFisica.setPrimerNombre(nombres[0].trim());
        if (nombres.length > 1) {
            let segundNombre: string = "";
            for (let i = 0; i < nombres.length; i++) {
                if (i > 0) {
                    segundNombre = segundNombre + ' ' + nombres[i].trim();
                }
            }
            nuevaPersonaFisica.setSegundoNombre(segundNombre.trim());
        }
        nuevaPersonaFisica.setPrimerApellido(viewModelStepOneFinal.newTitularData.ApellidoPaterno);
        nuevaPersonaFisica.setSegundoApellido(viewModelStepOneFinal.newTitularData.ApellidoMaterno);
        nuevaPersonaFisica.setSexo(viewModelStepOneFinal.newTitularData.GeneroId);
        nuevaPersonaFisica.setNacionalidadId(tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identNacionalidad);
        nuevaPersonaFisica.setNacionalidad(nacionalidadDescripcion);
        nuevaPersonaFisica.setEstadoCivilId(String(viewModelStepOneFinal.newTitularData.EstadoCivilId));
        nuevaPersonaFisica.setEstadoCivil(estadoCivilDescripcion);
        nuevaPersonaFisica.setTipoDocumentoId(viewModelStepOneFinal.newTitularData.TipoDocumentoId);
        nuevaPersonaFisica.setTipoDocumento(tipoDocumentoDescripcion);
        nuevaPersonaFisica.setNivelEducativoId(tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identNivelEducacion);
        nuevaPersonaFisica.setNivelEducativo(nivelEstucativoDescripcion);
        nuevaPersonaFisica.setProfesionId(tempViewModelStepFiveFinal.newDatosAdicionalesTitularData.identProfesion);
        nuevaPersonaFisica.setProfesion(profesionDescripcion);
        nuevaPersonaFisica.setPaisDocumentoId(appConfigKey.PaisDocumentoId);
        nuevaPersonaFisica.setPaisDocumento(appConfigKey.PaisDocumentoDescripcion);
        if (viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
            nuevaPersonaFisica.setConyugeUId(conyugeUId);
        } else {
            nuevaPersonaFisica.setConyugeUId(0);
        }

        await General.BTActualizaPersonaFisica(nuevaPersonaFisica, personaUId);

        if (viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {

            let profesionDescripcion = appDefaultValues.StringEmpty;
            let nacionalidadDescripcion = appDefaultValues.StringEmpty;

            const nombres = viewModelStepOneFinal.newConyugeData.Nombres.split(' ');

            const tipoDocumentoDescripcion = listTipoDocumento.find((item: any) => item.id === viewModelStepOneFinal.newConyugeData.TipoDocumentoId).description;

            if (tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.identProfesion !== 0) {
                const tempProfesion = listProfesion.find((item: any) => item.id === tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.identProfesion);
                if (tempProfesion !== undefined) {
                    profesionDescripcion = tempProfesion.description;
                }
            }

            if (tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.identNacionalidad !== 0) {
                nacionalidadDescripcion = listPais.find((item: any) => item.id === tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.identNacionalidad).description;
            }

            const estadoCivilDescripcion = listEstadoCivil.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.EstadoCivilId).description;
            const descripcionDocimilio = listaDomicilio.find((item: any) => item.id === viewModelStepOneFinal.newTitularData.Domicilio).description;

            const primerDomicilio = new BTCrearPersonaFisicaDomicilio();
            primerDomicilio.setDireccion(descripcionDocimilio);
            primerDomicilio.setCodigoId(appConfigKey.CodigoDomicilio); //Viviendanda / Laboral / Correspondencia.
            primerDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
            primerDomicilio.setAgrupador1(!tempViewModelStepFiveFinal.newDomicilioTitularData.nombreAgrupacion ? appConfigKey.DescripAgrupadorOtros : tempViewModelStepFiveFinal.newDomicilioTitularData.nombreAgrupacion);
            primerDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
            primerDomicilio.setAgrupador2(!tempViewModelStepFiveFinal.newDomicilioTitularData.nombreVia ? appConfigKey.DescripAgrupadorAvenida : tempViewModelStepFiveFinal.newDomicilioTitularData.nombreVia);
            primerDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
            primerDomicilio.setAgrupador3(!tempViewModelStepFiveFinal.newDomicilioTitularData.numDomicilio ? appConfigKey.DescripAgrupadorNumero : tempViewModelStepFiveFinal.newDomicilioTitularData.numDomicilio);
            primerDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
            let primer_agrupador4 = await serializeAgrupador4(tempViewModelStepFiveFinal.newDomicilioTitularData.intDptoPiso,
                tempViewModelStepFiveFinal.newDomicilioTitularData.manzana,
                tempViewModelStepFiveFinal.newDomicilioTitularData.lote);
            primerDomicilio.setAgrupador4(!primer_agrupador4 ? appConfigKey.DescripAgrupadorInterior : primer_agrupador4);
            primerDomicilio.setReferencia(!tempViewModelStepFiveFinal.newDomicilioTitularData.referencia ? appConfigKey.Referencia : tempViewModelStepFiveFinal.newDomicilioTitularData.referencia);

            primerDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
            primerDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
            primerDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
            primerDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));

            const segundoDomicilio = new BTCrearPersonaFisicaDomicilio();
            segundoDomicilio.setDireccion(descripcionDocimilio);
            segundoDomicilio.setCodigoId(appConfigKey.CodigoDomicilioLaboral);
            segundoDomicilio.setAgrupador1Id(appConfigKey.CodigoAgrupadorOtros);
            segundoDomicilio.setAgrupador1(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.nombreAgrupacion ? appConfigKey.DescripAgrupadorOtros : tempViewModelStepFiveFinal.newDomicilioTitularData.nombreAgrupacion);
            segundoDomicilio.setAgrupador2Id(appConfigKey.CodigoAgrupadorAvenida);
            segundoDomicilio.setAgrupador2(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.nombreVia ? appConfigKey.DescripAgrupadorAvenida : tempViewModelStepFiveFinal.newDomicilioTitularData.nombreVia);
            segundoDomicilio.setAgrupador3Id(appConfigKey.CodigoAgrupadorNumero);
            segundoDomicilio.setAgrupador3(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.numeroVia ? appConfigKey.DescripAgrupadorNumero : tempViewModelStepFiveFinal.newDomicilioTitularData.numeroVia);
            segundoDomicilio.setAgrupador4Id(appConfigKey.CodigoAgrupadorInterior);
            let segundo_agrupador4 = await serializeAgrupador4(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.intDptoPiso,
                tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.manzana,
                tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.lote);
            segundoDomicilio.setAgrupador4(!segundo_agrupador4 ? appConfigKey.DescripAgrupadorInterior : segundo_agrupador4);
            segundoDomicilio.setReferencia(!tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.referencia ? appConfigKey.Referencia : tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesTitularData.referencia);

            segundoDomicilio.setPaisId(appConfigKey.PaisDocumentoId);
            segundoDomicilio.setDepartamentoId(GeneralHelper.ObtenerDepartamentoId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
            segundoDomicilio.setProvinciaId(GeneralHelper.ObtenerProvinciaId(Number(viewModelStepOneFinal.newTitularData.Domicilio)));
            segundoDomicilio.setDistritoId(Number(viewModelStepOneFinal.newTitularData.Domicilio));

            const listaDomicilios: BTCrearPersonaFisicaDomicilio[] = [];
            listaDomicilios.push(primerDomicilio);
            listaDomicilios.push(segundoDomicilio);

            const domicilios = new BTCrearPersonaFisicaDomicilios();
            domicilios.setSdtJSPSsBTDomicilio(listaDomicilios);

            const informacionDatosLaborales = new BTCrearPersonaFisicaInformacionDatosLaborales();
            if (viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {

                const ingresosNetos = GeneralHelper.QuitarComasAMiles(viewModelStepOneFinal.newIngresosTitularData.ingresosNetos);
                const datoLaboral = new BTCrearPersonaFisicaDatosLaborales();
                datoLaboral.setNombre(viewModelStepOneFinal.newIngresosConyugeData.razonSocial);
                datoLaboral.setRucEmpleador(viewModelStepOneFinal.newIngresosConyugeData.ruc);
                datoLaboral.setIngresoNeto(Number(ingresosNetos));
                datoLaboral.setMonedaIngreso(viewModelStepOneFinal.newIngresosConyugeData.identTipoMoneda);
                datoLaboral.setFechaIni(viewModelStepOneFinal.newIngresosConyugeData.fechaIngresoLaboral);
                datoLaboral.setOcupacionId(viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral);
                datoLaboral.setActividadLaboralId(tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesConyugeData.identActividadEconomica > 0 ? tempViewModelStepFiveFinal.newDatosLaboralesAdicionalesConyugeData.identActividadEconomica : appConfigKey.CodigoActiviadLaboral);

                const listaDatoLaboral: BTCrearPersonaFisicaDatosLaborales[] = [];
                listaDatoLaboral.push(datoLaboral);

                viewModelStepOneFinal.newIngresosConyugeData.listaOtrosTrabajos.map((item: any) => {
                    const tempDatoLaboral = new BTCrearPersonaFisicaDatosLaborales();
                    const ingresosNetos = GeneralHelper.QuitarComasAMiles(item.ingresosNetos);
                    tempDatoLaboral.setNombre(item.razonSocial);
                    tempDatoLaboral.setRucEmpleador(item.ruc);
                    tempDatoLaboral.setIngresoNeto(Number(ingresosNetos));
                    tempDatoLaboral.setMonedaIngreso(item.identTipoMoneda);
                    tempDatoLaboral.setFechaIni(item.fechaIngresoLaboral);
                    tempDatoLaboral.setOcupacionId(item.identCategoriaLaboral);
                    tempDatoLaboral.setActividadLaboralId(item.actividadEconomicaId);
                    listaDatoLaboral.push(tempDatoLaboral);
                });
                informacionDatosLaborales.setSdtJSPSsBTDatosLaborales(listaDatoLaboral);
            } else {
                const datoLaboral = new BTCrearPersonaFisicaDatosLaborales();
                datoLaboral.setOcupacionId(viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral);
                datoLaboral.setActividadLaboralId(appConfigKey.CodigoActiviadLaboral);
                const listaDatoLaboral: BTCrearPersonaFisicaDatosLaborales[] = [];
                listaDatoLaboral.push(datoLaboral);
                informacionDatosLaborales.setSdtJSPSsBTDatosLaborales(listaDatoLaboral);
            }

            const nuevaPersonaFisica = new BTCrearPersonaFisicaRequest();
            nuevaPersonaFisica.setDomicilios(domicilios);
            nuevaPersonaFisica.setDatosLaborales(informacionDatosLaborales);
            nuevaPersonaFisica.setNroDocumento(viewModelStepOneFinal.newConyugeData.NumeroDocumento.trim());
            nuevaPersonaFisica.setCorreoElectronico(!tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.correo ? viewModelStepOneFinal.newConyugeData.Correo : tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.correo);
            nuevaPersonaFisica.setFechaNacimiento(viewModelStepOneFinal.newConyugeData.FechaNacimiento);
            nuevaPersonaFisica.setTelefonoCelular(appConfigKey.TelefPorDefecto);
            nuevaPersonaFisica.setPrimerNombre(nombres[0]);
            if (nombres.length > 1) {
                let segundNombre: string = "";
                for (let i = 0; i < nombres.length; i++) {
                    if (i > 0) {
                        segundNombre = segundNombre + ' ' + nombres[i].trim();
                    }
                }
                nuevaPersonaFisica.setSegundoNombre(segundNombre.trim());
            }
            nuevaPersonaFisica.setPrimerApellido(viewModelStepOneFinal.newConyugeData.ApellidoPaterno);
            nuevaPersonaFisica.setSegundoApellido(viewModelStepOneFinal.newConyugeData.ApellidoMaterno);
            nuevaPersonaFisica.setSexo(viewModelStepOneFinal.newConyugeData.GeneroId);
            nuevaPersonaFisica.setNacionalidadId(tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.identNacionalidad);
            nuevaPersonaFisica.setNacionalidad(nacionalidadDescripcion);
            nuevaPersonaFisica.setEstadoCivilId(String(viewModelStepOneFinal.newTitularData.EstadoCivilId));
            nuevaPersonaFisica.setEstadoCivil(estadoCivilDescripcion);
            nuevaPersonaFisica.setTipoDocumentoId(viewModelStepOneFinal.newConyugeData.TipoDocumentoId);
            nuevaPersonaFisica.setTipoDocumento(tipoDocumentoDescripcion);
            nuevaPersonaFisica.setProfesionId(tempViewModelStepFiveFinal.newDatosAdicionalesConyugeData.identProfesion);
            nuevaPersonaFisica.setProfesion(profesionDescripcion);
            nuevaPersonaFisica.setPaisDocumentoId(appConfigKey.PaisDocumentoId);
            nuevaPersonaFisica.setPaisDocumento(appConfigKey.PaisDocumentoDescripcion);
            nuevaPersonaFisica.setConyugeUId(personaUId);

            await General.BTActualizaPersonaFisica(nuevaPersonaFisica, conyugeUId);
        }


    };

    const resetSendClick = () => {
        setNewDatosAdicionalesTitularData(null);
        setNewDomicilioTitularData(null);
        setNewDatosTitularEmpresaData(null);
        setNewDatosAdicionalesTitularEmpresaData(null);
        setNewDatosLaboralesAdicionalesTitularData(null);
        setNewDatosAdicionalesConyugeData(null);
        setNewDatosLaboralesAdicionalesConyugeData(null);
        setNewDatosTitularEmpresaData(null);
        setNewDatosAdicionalesTitularEmpresaData(null);
        setClickNext(0);
        setBtnContinuarLoading(false);
        setLoadingSteps(false);
    };

    const CargaInicialInformeComercial = async () => {
        const response = await RequestManager.GetMnInformeComercialRegistroByIdSolicitud({ "identSolicitud": solicitudData.idenT_SOLICITUD, "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId) });
        let dataInforme = [];
        let dataAccionistas = [];
        let porcentaje = 0;
        let cantGerencia = 0;
        let cantRepresentantes = 0;
        if (response.status === 200) {
            dataInforme = response.data.content;
        }
        const responseAccionistas = await RequestManager.GetMnInformeComercialPersonaAccionistaRegistro({ "identInformeComercial": dataInforme.identInformeComercial });
        if (responseAccionistas.status === 200) {
            dataAccionistas = responseAccionistas.data.content;
        }
        dataAccionistas.map(function (x: any) {
            porcentaje += x.aportePorcentaje;
        })
        // DatosGerencia
        const responseGerencia = await RequestManager.GetInformeComercialPersonaRegistro({ "identInformeComercial": dataInforme.identInformeComercial, 
                                                                                            "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaGerencia });
        if (responseGerencia.status === 200) {
            cantGerencia = responseGerencia.data.content.length;
        }

        // RepresentanteLegal
        const responseRepresentante = await RequestManager.GetInformeComercialPersonaRegistro({ "identInformeComercial": dataInforme.identInformeComercial, 
                                                                                    "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaRepresentanteLegal });
        if (response.status === 200) {
            cantRepresentantes = responseRepresentante.data.content.length;
        }

        if (porcentaje !== 100) {
            toast.error("Actualmente hay un total de " + porcentaje + "% de participación de accionistas. Por favor revise el informe comercial.", { duration: appConfigKey.keyDurationToast });
            return false;
        }
        if (cantGerencia === 0) {
            toast.error("Debe registrar al menos un registro: Informe Comercial - IV. Datos de la Gerencia y Ejecutivos principales.");
            return false;
        }

        if (porcentaje !== 100) {
            toast.error("Debe registrar al menos un registro: Informe Comercial - XI. Representantes Legales.");
            return false;
        }
        return true;
    }

    const SaveStepFive = async () => {
        try {
            const isDatosAdicionalesValidos = viewModelStepOneFinal.newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC ? newDatosAdicionalesTitularEmpresaData !== null : newDatosAdicionalesTitularData !== null;
            if (isDatosAdicionalesValidos && step === 5) {
                if (isTitularJuridico) {
                    if (viewModelStepOneFinal.newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC) {
                        const validarNewDatosAdicionalesTitularData3era = !ValidacionStepFiveHelper.NewDatosAdicionalesTitularData3era(newDatosTitularEmpresaData);
                        if (validarNewDatosAdicionalesTitularData3era) {
                            setNewDatosTitularEmpresaData(null);
                            resetSendClick();
                            return;
                        }

                        const validarNewDatosAdicionalesEmpresaTitularData3era = !ValidacionStepFiveHelper.NewDatosAdicionalesTitularEmpresaData3era(newDatosAdicionalesTitularEmpresaData);
                        if (validarNewDatosAdicionalesEmpresaTitularData3era) {
                            setNewDatosAdicionalesTitularEmpresaData(null);
                            resetSendClick();
                            return;
                        }
                    }

                    let flg = await CargaInicialInformeComercial();
                    if (!flg) {
                        setClickNext(0);
                        setBtnContinuarLoading(false);
                        setLoadingSteps(false);
                        resetSendClick();
                        return;
                    }
                }
                // const validarNewDatosAdicionalesTitularData = !ValidacionStepFiveHelper.NewDatosAdicionalesTitularData(newDatosAdicionalesTitularData);

                // if (validarNewDatosAdicionalesTitularData) {
                //     setNewDatosAdicionalesTitularData(null);
                //     resetSendClick();
                //     return;
                // }

                // const validarNewDatosDomicilioTitularData = !ValidacionStepFiveHelper.NewDatosDomicilioTitulardata(newDomicilioTitularData);

                // if (validarNewDatosDomicilioTitularData) {
                //     setNewDomicilioTitularData(null);
                //     resetSendClick();
                //     return;
                // }

                // const validarNewDatosLaboralesAdicionalesTitularData = !ValidacionStepFiveHelper.NewDatosLaboralesAdicionalesTitularData(newDatosLaboralesAdicionalesTitularData);

                // if (validarNewDatosLaboralesAdicionalesTitularData) {
                //     setNewDatosAdicionalesTitularData(null);
                //     resetSendClick();
                //     return;
                // }

                // if (viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {

                //     const validarNewDatosAdicionalesConyugeData = !ValidacionStepFiveHelper.NewDatosAdicionalesConyugeData(newDatosAdicionalesConyugeData);

                //     if (validarNewDatosAdicionalesConyugeData) {
                //         setNewDatosAdicionalesConyugeData(null);
                //         resetSendClick();
                //         return;
                //     }

                //     if (viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {

                //         const validarNewDatosLaboralesAdicionalesConyugeData = !ValidacionStepFiveHelper.NewDatosLaboralesAdicionalesConyugeData(viewModelStepOneFinal.newIngresosConyugeData, newDatosLaboralesAdicionalesConyugeData);

                //         if (validarNewDatosLaboralesAdicionalesConyugeData) {
                //             setNewDatosAdicionalesTitularData(null);
                //             resetSendClick();
                //             return;
                //         }

                //     }

                // }
                const tempViewModelStepFiveFinal = {
                    newDatosAdicionalesTitularData: newDatosAdicionalesTitularData,
                    newDomicilioTitularData: newDomicilioTitularData,
                    newDatosLaboralesAdicionalesTitularData: newDatosLaboralesAdicionalesTitularData,
                    newDatosAdicionalesConyugeData: newDatosAdicionalesConyugeData,
                    newDatosLaboralesAdicionalesConyugeData: newDatosLaboralesAdicionalesConyugeData,
                    newDatosTitularEmpresaData: newDatosTitularEmpresaData,
                    newDatosAdicionalesTitularEmpresaData: newDatosAdicionalesTitularEmpresaData,
                };

                setViewModelStepFiveFinal(tempViewModelStepFiveFinal);
                if (viewModelStepOneFinal.newTitularData.TipoDocumentoId != appConfigKey.keyIdRUC) {
                    //Actualizar ActividadEconomicaId del titular y cónyuge (unificado con datos adicionales)
                    setViewModelStepOneFinal(
                        (prevState: any) => (prevState ? {
                            ...prevState,
                            newTitularData: prevState.newTitularData ? {
                                ...prevState.newTitularData,
                                ActividadEconomicaId: newDatosLaboralesAdicionalesTitularData?.identActividadEconomica ?? 0
                            } : 0,
                            newConyugeData: prevState.newConyugeData ? {
                                ...prevState.newConyugeData,
                                ActividadEconomicaId: newDatosLaboralesAdicionalesConyugeData?.identActividadEconomica ?? 0
                            } : 0
                        } : 0)
                    );
                }

                setNewDatosAdicionalesTitularData(null);
                setNewDomicilioTitularData(null);
                setNewDatosTitularEmpresaData(null);
                setNewDatosAdicionalesTitularEmpresaData(null);
                setNewDatosLaboralesAdicionalesTitularData(null);
                setNewDatosAdicionalesConyugeData(null);
                setNewDatosLaboralesAdicionalesConyugeData(null);
                setClickNext(0);
                setBtnContinuarLoading(false);

                let listaCampania = viewModelStepThreeFinal.listas.tempListaCampania;
                let listaPlazosMeses = viewModelStepThreeFinal.listas.tempListaPlazosMeses;
                let listaTipoCoberturaDesg = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg;

                const listasTemp = {
                    listTipoDocumento,
                    listEstadoCivil,
                    listIdentidadesGenero,
                    listMagnitud,
                    listActividadEconomica,
                    listCategoriaLaboral,
                    listRegimen,
                    listMoneda,
                    listProfesion,
                    listPais,
                    listCIPrecio,
                    listProveedorGPS,
                    listNivelEducacion,
                    listTipoVivienda,
                    listTiempoResidencia,
                    listaEstadosVehiculos,
                    listaCampania,
                    listaPlazosMeses,
                    listaTipoCoberturaDesg
                }

                const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
                    step,
                    solicitudData,
                    viewModelStepOneFinal.newTitularData,
                    viewModelStepOneFinal.newConyugeData,
                    viewModelStepOneFinal.newIngresosTitularData,
                    viewModelStepOneFinal.newIngresosConyugeData,
                    viewModelStepOneFinal.newPersonaCompraParaData,
                    viewModelStepOneFinal.newPersonaCompraParaConyugeData,
                    viewModelStepOneFinal.newFiadorData,
                    viewModelStepOneFinal.newObservacionData,
                    viewModelStepTwoFinal,
                    viewModelStepThreeFinal.formulario,
                    viewModelStepFourFinal,
                    newDatosAdicionalesTitularData,
                    newDomicilioTitularData,
                    newDatosLaboralesAdicionalesTitularData,
                    newDatosAdicionalesConyugeData,
                    newDatosLaboralesAdicionalesConyugeData,
                    listasTemp,
                    appSecurityConfiguration.SessionUserId,
                    TCEA,
                    isTitularJuridico,
                    newDatosTitularEmpresaData,
                    newDatosAdicionalesTitularEmpresaData,
                )

                RequestGenerateActualizacionSolicitudV2.mnSolicitudInsertUpdateRequest.monT_CALC_FINANCIAMIENTO = stepsTotalFinanciamiento;

                const response = await GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2);
                if (response) {
                    if (response?.data?.isValid) {
                        
                        const decision_credito = responseStepTwo?.response?.Politica_Creditos?.decision_Credito;

                        let esAprobadoAutomatico: boolean = isTitularJuridico ? false : decision_credito?.decision === appConfigKey.CodigoEvaluacionAprobado;
                        let esZonaGris: boolean = isTitularJuridico ? true : decision_credito?.decision === appConfigKey.CodigoEvaluacionZonaGris;
                        let esRechazado: boolean = isTitularJuridico ? false : decision_credito?.decision === appConfigKey.CodigoEvaluacionRechazado;
                        let memInferiorACuota = ValidacionStepFiveHelper.ModelicaMEMEnviarAEvaluacion(memValor, viewModelStepThreeFinal?.cronograma?.listaConograma);
                        if (esAprobadoAutomatico || esZonaGris || esRechazado) {
                            await RequestManager.GetSolicitudCambiarEstadoById({
                                "idenT_SOLICITUD": solicitudData.idenT_SOLICITUD,
                                "iD_ESTADO_SOLICITUD": memInferiorACuota ? appConfigKey.keyIdEstadoPendiente : esAprobadoAutomatico ? appConfigKey.keyIdEstadoSolicitudRegistroSolicitud : appConfigKey.keyIdEstadoPendiente,
                                "idenT_USUARIO_MODIF": appSecurityConfiguration.SessionUserId,
                                "descL_OBSERVACION": "",
                                "movimientO_ID": 0
                            });
                        }

                        const request = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudReconfirmacion(
                            (step + 1),
                            solicitudData,
                            esAprobadoAutomatico ? appConfigKey.keyCodigoEstadoReconfirmacionAprobado : null,
                            '',
                            appSecurityConfiguration.SessionUserId,
                        );
                        let updateSuccess = await GenerateActualizacionSolicitudV2(request).then(async (response) => {
                            if (response?.status == 200 && response?.data?.isValid && response?.data?.content) {
                                return true
                            } else {
                                return false
                            }
                        });

                        if (!updateSuccess) {
                            toast.error(appConfigKey.keyMsjErrorGuardarSolicitud, { duration: appConfigKey.keyDurationToast });
                            resetSendClick();
                            return;
                        }

                        let solicitudEtapasRequest: any = [];
                        let SolicitudEtapasInsertRequest: any = {
                            identSolicitud: solicitudData.idenT_SOLICITUD,
                            identUsuarioCreador: appSecurityConfiguration.SessionUserId
                        }

                        if (esAprobadoAutomatico) {

                            solicitudEtapasRequest = [];
                            solicitudEtapasRequest.push({
                                identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                                identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                                identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                ordenProceso: 1
                            });

                            SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.AprobadoAutomatico
                            SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
                        }
                        if (esZonaGris) {

                            solicitudEtapasRequest = [];
                            
                            solicitudEtapasRequest.push({
                                identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
                                identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion,
                                identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                ordenProceso: 1
                            });

                            solicitudEtapasRequest.push({
                                identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                                identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                                identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                ordenProceso: 2
                            });

                            SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.ZonaGris
                            SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
                        }
                        if (esRechazado) {

                            solicitudEtapasRequest = [];
                            let motivoRechazoDet: any = responseStepTwo?.response?.Politica_Creditos?.motivo_Rechazo?.Detalle;
                            // Verificación del código RCH_9999
                            const tieneCodigoRCH = motivoRechazoDet?.some((motivo: any) => motivo.codigo_CMA === appConfigKey.keyRCH_9999);

                            // Primera validación: Solo existe el codigo_CMA "RCH_9999" -> Enviar a Evaluación
                            if (motivoRechazoDet?.length === 1 && tieneCodigoRCH) {
                                solicitudEtapasRequest.push({
                                    identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
                                    identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion,
                                    identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                    ordenProceso: 1
                                });

                                solicitudEtapasRequest.push({
                                    identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                                    identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                                    identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                    ordenProceso: 2
                                });
                            }
                            // Segunda validación: Existen otros CMA -> Solicitar Excepción
                            else if (motivoRechazoDet?.length >= 1) {
                                solicitudEtapasRequest.push({
                                    identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
                                    identProceso: appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion,
                                    identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                    ordenProceso: 1
                                });

                                solicitudEtapasRequest.push({
                                    identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                                    identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                                    identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                    ordenProceso: 2
                                });
                            }

                            SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.Rechazado
                            SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;

                        }

                        if ((esAprobadoAutomatico || esZonaGris) && memInferiorACuota) {
                            solicitudEtapasRequest = [];
                            solicitudEtapasRequest.push({
                                identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
                                identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion,
                                identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                ordenProceso: 1
                            });

                            solicitudEtapasRequest.push({
                                identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                                identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                                identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                                ordenProceso: 2
                            });
                            SolicitudEtapasInsertRequest.identDecisionMotor = esAprobadoAutomatico ? appConfigDecisionMotor.AprobadoAutomatico : appConfigDecisionMotor.ZonaGris
                            SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
                        }

                        await General.GetSolicitudEtapasInsert(SolicitudEtapasInsertRequest)
                        setStep(step + 1);
                        resetSendClick();
                    } else {
                        toast.error(appConfigKey.keyMsjErrorGuardarSolicitud, { duration: appConfigKey.keyDurationToast });
                        resetSendClick();
                    }
                } else {
                    toast.error(appConfigKey.keyMsjErrorGuardarSolicitud, { duration: appConfigKey.keyDurationToast });
                    resetSendClick();
                }
                // ActualizarPersonaFisica(tempViewModelStepFiveFinal);

            }
        } catch (e) {
            resetSendClick();
            toast.error(appConfigKey.keyMsjSolicitudActualizacionError, { duration: appConfigKey.keyDurationToast });
        }
    }

    const cargarMiembros = async (solicitud: any = null) => {
        const solicitudSource = solicitud ? solicitud : solicitudData;

        if (solicitudSource) {
            let datosAdicionalesTitularEmpresaSource: DatosAdicionalesTitularEmpresaInterface = DatosAdicionalesTitularEmpresaInterfaceDefault;
            const datosAdicionalesRequest = new MnPersonaEmpresaAdicionalRequest();
            datosAdicionalesRequest.identPersona = solicitudSource.idenT_PERSONA_TITULAR;
            await General.GetPersonaEmpresaDatosAdicional(datosAdicionalesRequest).then((response: any) => {
                datosAdicionalesTitularEmpresaSource = ConvertidorHelper.ObtenerDatosAdicionalesTitularEmpresa(response);
                setDatosAdicionalesTitularEmpresaData(datosAdicionalesTitularEmpresaSource);
            });
            
        }
    }

    const eventoCargarSolicitud = () => {
        if (retrocedioAlQuintoPaso || readonlyForm) {
            return;
        }
        
        cargarMiembros();

    };
    const cargarSolicitudDataFormato = async() =>{
        let datosAdicionalesTitularEmpresaSource: DatosAdicionalesTitularEmpresaInterface = DatosAdicionalesTitularEmpresaInterfaceDefault;
        
        await RequestManager.GetMnFormatoSolicitudData({ "IdentSolicitud": solicitudData.idenT_SOLICITUD}).then((response: any) => {
            datosAdicionalesTitularEmpresaSource.patrimonio = response.data.content.patrimonio;
            datosAdicionalesTitularEmpresaSource.capitalSocialActual = response.data.content.capitalSocialActual;
            setDatosAdicionalesTitularEmpresaData(datosAdicionalesTitularEmpresaSource);
        })
    }
    useEffect(() =>{
        if(!isTitularJuridico){
            return;
        }
        cargarSolicitudDataFormato();
    })

    useEffect(() => {
        if (validacionModelicaDecisionRCH) {
            toast.error(validacionModelicaDecisionRCH, { duration: appConfigKey.keyDurationToast });
        }
    }, [validacionModelicaDecisionRCH]);

    useEffect(() => {
        SaveStepFive();
    }, [newDatosAdicionalesTitularData,
        newDomicilioTitularData,
        newDatosLaboralesAdicionalesTitularData,
        newDatosAdicionalesConyugeData,
        newDatosLaboralesAdicionalesConyugeData,
        setNewDatosAdicionalesConyugeData,
        newDatosTitularEmpresaData,
        newDatosAdicionalesTitularEmpresaData,
    ]);

    useEffect(() => eventoCargarSolicitud(), [solicitudData]);

    return (
        <StepFiveContext.Provider value={{
            newDatosAdicionalesTitularData,
            setNewDatosAdicionalesTitularData,
            newDomicilioTitularData,
            setNewDomicilioTitularData,
            newDatosLaboralesAdicionalesTitularData,
            setNewDatosLaboralesAdicionalesTitularData,
            newDatosAdicionalesConyugeData,
            setNewDatosAdicionalesConyugeData,
            newDatosLaboralesAdicionalesConyugeData,
            setNewDatosLaboralesAdicionalesConyugeData,
            listaProvinciasDepartamentos,
            listaCiudadesLocalidades,
            listaBarriosColonias,
            newDatosTitularEmpresaData,
            setNewDatosTitularEmpresaData,
            newDatosAdicionalesTitularEmpresaData,
            setNewDatosAdicionalesTitularEmpresaData,
            getObtenerDepartamentos,
            getObtenerCiudadesLocalidades,
            getObtenerBarriosColonias,
            listaProvinciasDepartamentosConyuge,
            listaCiudadesLocalidadesConyuge,
            listaBarriosColoniasConyuge,
            getObtenerDepartamentosConyuge,
            getObtenerCiudadesLocalidadesConyuge,
            getObtenerBarriosColoniasConyuge,
            datosAdicionalesTitularEmpresaData,
        }}>
            {children}
        </StepFiveContext.Provider>
    );
};

