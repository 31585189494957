import { Button, Divider, FormControl, FormLabel, IconButton, Input, Select } from "@mui/joy";
import { Dialog, DialogTitle } from "@mui/material";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import RequestManager from "../../../../services/origination/wapirequest.service";
import { toast } from "sonner";
import { appConfigKey, appConfigSolicitudEtapa } from "../../../../../config/Config";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Option from "@mui/joy/Option";
import { IListTablaGenerica } from "../../../../../assets/interface/IListTablaGenerica";
import MasterManager from "../../../../services/origination/wapimaster.service";
import ServicesProvider from "../../../../services/support/wapiservicesprovider";
import LoadingProgress from "../../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import icoCheck from '../../../../../assets/media/icons/ico-check.svg';
import icoNoCheck from '../../../../../assets/media/icons/ico_no_red.svg';
import CloseIcon from '@mui/icons-material/Close';
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { MnValidarListaNegraYGesintelRequest } from "../../../../models/RequestManager/solicitud-validar-request.model";
import WindowHelper from "../../../../helpers/WindowHelper";
import { getRandomInt } from "../../../../helpers/RandomHelper";
import { appConfig } from "../../../../../config/Config";

const RegistrarBienDialog = (props: any) => {

    const [optionsColor, setOptionsColor] = useState<IListTablaGenerica[]>([]);
    const [optionsMoneda, setOptionsMoneda] = useState<any[]>([]);
    const { solicitudId } = useParams();
    const [btnActivar, setBtnActivar] = React.useState(true);
    const [numeroSerie, setNumeroSerie] = React.useState('')
    const [requestBien, setRequestBien] = React.useState({
        idSubTipoBien: "",
        idNumeroSerie: "",
        idNumeroMotor: "",
        idPlaca: "",
        idColor: 0,
        idMoneda: 0
    })
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataBien, setDataBien] = useState<any[]>([]);
    const [numeroMotor, setNumeroMotor] = useState('')
    const [numeroPlaca, setNumeroPlaca] = useState('')
    const [idColor, setIdColor] = useState(0)
    const [idMoneda, setIdMoneda] = useState(0)
    const [btnGuardar, setBtnGuardar] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);
    const [ico, setIco] = React.useState('icoCheck');
    const [Message, setMessage] = React.useState('');
    const [flg, setFlg] = React.useState(false);
    const [idPerfilSession, setIdPerfilSession] = useState<number>(0);
    const [disabledControles, setDisabledControles] = React.useState(false);

    const [inputState, setInputstate] = React.useState(false);


    const changeValue = (e: any) => {
        let { name, value, type } = e.target;
        if (type == "number" && value == "") {
            value = 0;
        }
        if (type == "select") {
            value = parseInt(value);
        }
        if ((type = "date" && value == "Invalid Date")) {
            value = null;
        }
        if (name == "idNumeroSerie") {
            const newValue = value?.toUpperCase()
            setNumeroSerie(newValue);
        }
        if (name == "idNumeroMotor") {
            const newValue = value?.toUpperCase()
            setNumeroMotor(newValue);
        }
        if (name == "idPlaca") {
            const newValue = value?.toUpperCase()
            setNumeroPlaca(newValue);
        }
        if (name == "idColor") {
            setIdColor(value);
        }
        if (name == "idMoneda") {
            setIdMoneda(value);
        }
        setRequestBien({
            ...requestBien,
            [name]: value,
        });
    }

    let navigate = useNavigate();

    function generarValorAleatorioNumerico(longitud: number): string {
        let resultado = '';

        for (let i = 0; i < longitud; i++) {
            const digitoAleatorio = getRandomInt(0,9);
            resultado += digitoAleatorio.toString();
        }

        return resultado;
    }
    const Guardar = async () => {
        let subTipoBien = String(requestBien.idSubTipoBien);
        let numSerie = String(requestBien.idNumeroSerie == '' ? numeroSerie : requestBien.idNumeroSerie);
        let numMotor = String(requestBien.idNumeroMotor == '' ? numeroMotor : requestBien.idNumeroMotor);
        let placa = String(requestBien.idPlaca == '' ? numeroPlaca : requestBien.idPlaca);
        let color = Number(requestBien.idColor == 0 ? idColor : requestBien.idColor);
        let moneda = Number(requestBien.idMoneda == 0 ? idMoneda : requestBien.idMoneda);

        // if(subTipoBien == ""){
        //     toast.error(appConfigKey.KeyMsjCampoOblSubTipoBien, {duration: 4000});
        //     return;
        // }
        if (numSerie == "") {
            toast.error(appConfigKey.KeyMsjCampoOblSerie, { duration: 4000 });
            return;
        }
        if (numMotor == "") {
            toast.error(appConfigKey.KeyMsjCampoOblMotor, { duration: 4000 });
            return;
        }
        if (placa == "" && props.datosRegistroBien.indUso == "USADO") {
            toast.error(appConfigKey.KeyMsjCampoOblPlaca, { duration: 4000 });
            return;
        }
        if (color == 0) {
            toast.error(appConfigKey.KeyMsjCampoOblColor, { duration: 4000 });
            return;
        }
        if (moneda == 0) {
            toast.error(appConfigKey.KeyMsjCampoOblMoneda, { duration: 4000 });
            return;
        }

        let resultado;

        var body = {
            idenT_SOLICITUD: solicitudId,
            // suB_TIPO_BIEN: requestBien.idSubTipoBien,
            suB_TIPO_BIEN: "",
            numerO_SERIE: numSerie,
            numerO_MOTOR: numMotor,
            placa: placa,
            idenT_COLOR: color,
            idenT_MONEDA_DESEMBOLSO: moneda,
            idenT_USUARIO_CREADOR: localStorage.getItem('UserMn')
        };

        const response = await RequestManager.GetMnBienInsert(body);
        setIsLoading(true);
        if (response.status === 200) {
            resultado = response.data.isValid;
            if (resultado === true) {
                toast.success("Se genero el bien con éxito", { duration: 6000 });
            }
        };
        setIsLoading(false);
        GetMnEstadoSolicitud();
        props.cargarHistorial();
    }
    const GetMnEstadoSolicitud = async () => {
        let resultado;
        let idPerfil;
        idPerfil = await GetMnIdPerfil();
        var body = {
            idenT_SOLICITUD: solicitudId,
        };
        const response = await RequestManager.GetMnSolicitudEstado(body);
        if (response.status === 200) {
            resultado = response.data.content;
            if (resultado.iD_ESTADO_SOLICITUD === 92) {
                setBtnActivar(false);
            } 
            else if (resultado.iD_ESTADO_SOLICITUD == 84 && idPerfil === 15){
                setInputstate(true);
                setBtnActivar(false);
            }
            else if (resultado.iD_ESTADO_SOLICITUD === 86){
                setInputstate(true);
            }
            else if (resultado.iD_ESTADO_SOLICITUD === 49 || resultado.iD_ESTADO_SOLICITUD === 88) {
                setInputstate(true);
            }
            else{
                setBtnActivar(true);
                setInputstate(false);
            }
        };
    }
    const ActivarSolicitud = async () => {
        setIsLoading(true);
        const errorValidacionMsgDefault = 'Falló la consulta a Lista Negra o Gesintel.';
        const errorMsgDefault = 'Falló la activación de solicitud.';

        try {
            //Validación Bantotal y Gesintel
            const success = await RequestManager.GetValidarListaNegraYGesintel(
                {
                    identSolicitud: Number(solicitudId),
                    identEtapa: appConfigSolicitudEtapa.Formalizacion,
                    identUsuario: Number(localStorage.getItem('UserMn')),
                    actualizarBD: true,
                    miembros: null,
                } as MnValidarListaNegraYGesintelRequest
            ).then((response) => {

                let errorMsg = GeneralHelper.ObtenerExceptionError(response, errorValidacionMsgDefault);
                if (errorMsg) {
                    toast.error(errorMsg, { duration: appConfigKey.keyDurationToast });
                    return false;
                }
                const content = response.data.content;

                //Miembros observados
                if (content.resultado == 1 || content.miembrosObservados?.length > 0) {
                    toast.error(content.mensaje, { duration: appConfigKey.keyDurationToast });
                    props.handleClose();
                    props.onSolicitudObservada(content);
                    WindowHelper.ScrollToTop({ instant: false });
                    return false;
                }

                return true;

            }).catch((error) => {
                let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, errorValidacionMsgDefault);
                if (errorMsg) toast.error(errorMsg, { duration: appConfigKey.keyDurationToast });
                return false;
            });

            if (!success) return;

            var body = {
                idenT_SOLICITUD: solicitudId,
                idenT_USUARIO: localStorage.getItem('UserMn')
            };

            await RequestManager.GetUmbralAutonomia(body).then((response) => {
                let resultado, flgOk;

                let errorMsg = GeneralHelper.ObtenerExceptionError(response, errorMsgDefault);
                if (errorMsg) {
                    toast.error(errorMsg, { duration: appConfigKey.keyDurationToast });
                    return;
                }

                if (response.status === 200) {
                    resultado = response.data.content;
                    if (resultado.idenT_RESPUESTA === 1) {
                        flgOk = 1;
                        setFlg(true);
                        setIco(icoCheck);
                        setMessage(resultado.desC_RESPUESTA);
                        // setMessage("LA SOLICITUD CON NÚMERO 596 FUE ACTIVADA CON ÉXITO._NRO MOVIMIENTO: 457_NRO CUENTA: 64816_NRO OPERACIÓN ID: 349312");
                    }
                    else {
                        flgOk = 0;
                        setFlg(false);
                        setIco(icoNoCheck);
                        // setMessage("LA SOLICITUD CON NÚMERO 596 FUE ACTIVADA CON ÉXITO._NRO MOVIMIENTO: 457_NRO CUENTA: 64816_NRO OPERACIÓN ID: 349312");
                        setMessage(resultado.desC_RESPUESTA);
                    }
                };
                GetMnEstadoSolicitud();
                setIsLoading(false);
                props.handleClose();
                setOpenModal(true);
                props.cargarHistorial();
                if (flgOk === 1) {
                    const timer = setTimeout(() => {
                        setOpenModal(false);
                        navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaPorBpoSeguimiento}`);
                    }, 9000);
                    return () => clearTimeout(timer);
                }
            }).catch((error) => {
                let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, errorMsgDefault);
                if (errorMsg) toast.error(errorMsg, { duration: appConfigKey.keyDurationToast });

            });

        } catch (error) {
            toast.error(errorMsgDefault, { duration: appConfigKey.keyDurationToast });
        } finally {
            setIsLoading(false);
        }
    }

    const GetGetMnTablaGenericaDefinicion = async (dat: string) => {
        var body = {
            v_LIST_IDENT_TABLA: dat,
        };
        var response: any = await MasterManager.GetMnTablaGenericaDefinicion(
            body
        ).then((result: any) => {
            var data = result.data.content;
            setOptionsColor(data);
        });
    };

    const cargarListaMonedasFinanciamiento = async () => {
        let listTipoMonedaFinanciamiento: any = [];
        let response: any = null;
        try {
            response = await ServicesProvider.ObtenerMonedas(null)
                .then((response: any) => {
                    response.data.content.sdtMonedas.sBTMoneda.forEach((element: any) => {
                        listTipoMonedaFinanciamiento.push({ id: element.identificador, label: element.descripcion, symbol: element.simbolo });
                    })
                    setOptionsMoneda(listTipoMonedaFinanciamiento);
                })
        } catch {
            setOptionsMoneda(listTipoMonedaFinanciamiento);
        }
    }
    const GetMnRegistroBien = async () => {
        let resultado;
        var body = {
            idenT_SOLICITUD: solicitudId,
        };
        const response = await RequestManager.GetMnBien(body);
        if (response.status === 200) {
            resultado = response.data.content;
            if (response.data.isValid == true) {
                setNumeroSerie(resultado.numerO_SERIE);
                setNumeroMotor(resultado.numerO_MOTOR);
                setNumeroPlaca(resultado.placa);
                setIdColor(resultado.idenT_COLOR);
                setIdMoneda(resultado.idenT_MONEDA_DESEMBOLSO);
            }
            else {
                // setNumeroSerie(generarValorAleatorioNumerico(17));
                setNumeroMotor('');
                setNumeroPlaca('');
                setIdColor(0);
                setIdMoneda(0);
            }
        };
    }
    const CloseModal = () => {
        setOpenModal(false);
    }

    const GetMnIdPerfil = async () => {
        const account = appConfig.msalInstance.getActiveAccount();
        let idPerfil = 0;
        if (![null, undefined].includes(account)) {
            await MasterManager.GetMnUsuarioSesion().then((data) => {
                idPerfil = data.data.content[0].idenT_PERFIL;
            });
        }
        return idPerfil;
    }

    React.useEffect(() => {
        GetMnEstadoSolicitud();
        GetGetMnTablaGenericaDefinicion("24");
        cargarListaMonedasFinanciamiento()
        GetMnRegistroBien();
    }, []);


    return (
        <div>
            <Dialog style={{ zIndex: 1000 }} open={props.open} maxWidth={'sm'} fullWidth={true} sx={{ justifyContent: "center", flexDirection: "row" }}>
                {isLoading && <LoadingProgress />}
                <div className="content-documentation-add-dialog">
                    <div className="content-documentation-add-dialog-title">
                        <h2>VEHÍCULO</h2>
                    </div>
                    <div className="content-documentation-add-dialog-body align-items-center mt-4">
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="color:">Ind. uso:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input placeholder="" value={props.datosRegistroBien.indUso} disabled />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Proveedor:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input placeholder="" value={props.datosRegistroBien.concesionario} disabled />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Marca:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input placeholder="" value={props.datosRegistroBien.marca} disabled />
                                </FormControl>
                            </div>
                        </div>
                        {/* <div className="row g-2 mb-2 ml-2 align-items-center">
                        <div className="col-lg-6">
                            <span className="">SubTipo bien:</span>
                        </div>
                        <div className="col-lg-6">
                            <FormControl>
                                <Input id="idSubTipoBien" name="idSubTipoBien" onChange={(e) => changeValue(e)}
                                    />
                            </FormControl>
                        </div>
                    </div> */}
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Número de Serie:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input id="idNumeroSerie" slotProps={{
                                        input: { maxLength: 17 },
                                    }}
                                        value={numeroSerie} disabled={inputState} name="idNumeroSerie" onChange={(e) => changeValue(e)} />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Número de Motor:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input id="idNumeroMotor" slotProps={{
                                        input: { maxLength: 20 },
                                    }}
                                        value={numeroMotor} disabled={inputState} name="idNumeroMotor" onChange={(e) => changeValue(e)} />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Placa:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input id="idPlaca" slotProps={{
                                        input: { maxLength: 6 },
                                    }}
                                        value={numeroPlaca} disabled={inputState} name="idPlaca" onChange={(e) => changeValue(e)} />
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-3 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Color:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Select
                                        value={idColor}
                                        disabled={inputState}
                                        name="idColor"
                                        onChange={(e, newValue) =>
                                            changeValue({
                                                target: {
                                                    value: newValue,
                                                    name: "idColor",
                                                    type: "select",
                                                },
                                            })
                                        }
                                        indicator={<KeyboardArrowDown />}>
                                        <Option value="0">--Seleccione--</Option>
                                        {optionsColor.map((option) => (
                                            <Option value={option.genericA_VALO_CAMPO}>
                                                {option.genericA_DESCM_CAMPO}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <Divider orientation="horizontal"> </Divider>
                        <div className="row g-2 mb-2 ml-2 mt-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Moneda:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Select
                                        value={idMoneda}
                                        name="idMoneda"
                                        disabled={inputState}
                                        onChange={(e, newValue) =>
                                            changeValue({
                                                target: {
                                                    value: newValue,
                                                    name: "idMoneda",
                                                    type: "select",
                                                },
                                            })
                                        }
                                        indicator={<KeyboardArrowDown />}>
                                        <Option value="0">--Seleccione--</Option>
                                        {optionsMoneda.map((option) => (
                                            <Option value={option.id}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="content-documentation-add-dialog-footer">
                        <Button size="md" color="danger" onClick={props.handleClose} sx={{ borderRadius: 24, width: 4 / 10 }}>
                            <div>Cancelar</div>
                        </Button>
                        <Button size="md" color="danger" onClick={() => Guardar()} sx={{ borderRadius: 24, width: 4 / 10 }} disabled={inputState}>
                            <div>Guardar</div>
                        </Button>
                    </div>
                    <br></br>
                    <div className="text-center">
                        <Button size="md" color="danger" onClick={() => ActivarSolicitud()} sx={{ borderRadius: 24, width: 6 / 10 }} disabled={btnActivar}>
                            <div>Activar solicitud</div>
                        </Button>
                    </div>
                </div>
            </Dialog>

            <Dialog open={openModal} className="dialog-style-alert">
                <DialogTitle>
                    <div style={{ textAlign: 'end' }}>
                        <IconButton onClick={CloseModal} style={{ backgroundColor: 'rgb(236, 0, 0)' }}>
                            <CloseIcon style={{ color: 'white' }} />
                        </IconButton>
                    </div>
                    <div className="p-3 p-md-4 mb-4 mb-md-4">
                        <div className="row g-2 mb-2">
                            <div className="col-lg-12 dialog-text-AlignCenter">
                                <img src={ico} />
                            </div>
                        </div>
                        <div className="row g-2 mb-2 align-items-center">
                            <div className="col-lg-12 dialog-text-AlignCenter" >
                                {flg ? (
                                    <>
                                        <p className=""> {Message.split("_")[0]} </p>
                                        <p className=""> {Message.split("_")[1]} </p>
                                        <p className=""> {Message.split("_")[2]} </p>
                                        <p className=""> {Message.split("_")[3]} </p>
                                    </>
                                ) : (
                                    <span className=""> {Message} </span>
                                )}
                            </div>
                        </div>
                        <div className="row g-2 mb-2">
                            <div className="col-lg-12 right-button">

                            </div>
                        </div>
                    </div>

                    {/* <div style={{ textAlign: 'end' }}>
                <QuatitionBtnRedirect solicitudId={this.state.solicitudId} />
              </div> */}

                </DialogTitle>
            </Dialog>
        </div>
    );
}

export default RegistrarBienDialog;