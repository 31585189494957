import { appConfigKey, appConfigRegExp } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import ParameterHelper from "../../../../helpers/ParameterHelper";
import { validarIncludeText, validarLongitud, validarRegExp, validarSelect, validarText } from "../../../../helpers/ValidarHelper";
import { UserModel } from "../interfaces/IUsersManagement";

const ValidacionHelper = {

    ValidarMnUsuarioInsert(user: UserModel): boolean {
        const {
            identPerfil,
            apellidoPaterno,
            apellidoMaterno,
            nombres,
            idTipoDocumento,
            nroDocumento,
            username,
            dominioId,
            identCargo,
            cargo,
            password,
            emailInterno,
        } = user;

        if (!validarSelect(identPerfil, 'Perfil')) {
            return false;
        }

        if (ParameterHelper.GetAllAutonomyProfiles().includes(identPerfil)) {
            if (!validarSelect(identCargo, 'Rol')) {
                return false;
            }
            if (!validarText(cargo, 'Rol')) {
                return false;
            }
        }

        if (!validarText(apellidoPaterno, 'Apellido Paterno')) {
            return false;
        }

        if (!validarText(apellidoMaterno, 'Apellido Materno')) {
            return false;
        }

        if (!validarText(nombres, 'Nombres')) {
            return false;
        }

        if (!validarSelect(idTipoDocumento, 'Tipo Documento')) {
            return false;
        }

        if (!validarText(nroDocumento, 'Número Documento')) {
            return false;
        } else if (idTipoDocumento === appConfigKey.keyIdDNI) {
            if (!validarLongitud(nroDocumento, 'Número Documento', 8)) {
                return false;
            }
        }

        if (!validarText(username, 'Usuario')) {
            return false;
        } else {
            const sufijo = GeneralHelper.GetSufijo();
            if (sufijo && !validarIncludeText(username, "El Usuario no puede tener el sufijo repetido.", sufijo)) {
                return false;
            }
        }

        if (!validarSelect(dominioId, 'Dominio')) {
            return false;
        }

        if (!validarText(password, 'Contraseña')) {
            return false;
        } else {
            if (!validarRegExp(password, "La contraseña debe tener al menos 8 caracteres e incluir: una letra mayúscula, una letra minúscula, un número y un símbolo especial (por ejemplo: @ # $ % & * _).", appConfigRegExp.MicrosoftPassword)) {
                return false;
            }
        }

        if (!validarText(emailInterno, 'Correo')) {
            return false;
        } else if (!validarRegExp(emailInterno, "El correo debe estar en formato usuario@dominio.com, usando solo letras, números y los caracteres especiales (. _ ! # ^ ~ -) antes de la '@', seguido de un dominio válido.", appConfigRegExp.MicrosoftUserPrincipal)) {
            return false;
        }

        return true;
    },

    ValidarMnUsuarioEdit(user: UserModel): boolean {
        const {
            identUsuario,
            identPerfil,
            apellidoPaterno,
            apellidoMaterno,
            nombres,
            idTipoDocumento,
            nroDocumento,
            username,
            dominioId,
            identCargo,
            cargo,
            password,
            emailInterno,
        } = user;

        if (!validarSelect(identUsuario, 'Usuario ID')) {
            return false;
        }

        if (!validarSelect(identPerfil, 'Perfil')) {
            return false;
        }

        if (ParameterHelper.GetAllAutonomyProfiles().includes(identPerfil)) {
            if (!validarSelect(identCargo, 'Rol')) {
                return false;
            }
            if (!validarText(cargo, 'Rol')) {
                return false;
            }
        }

        if (!validarText(apellidoPaterno, 'Apellido Paterno')) {
            return false;
        }

        if (!validarText(apellidoMaterno, 'Apellido Materno')) {
            return false;
        }

        if (!validarText(nombres, 'Nombres')) {
            return false;
        }

        if (!validarSelect(idTipoDocumento, 'Tipo Documento')) {
            return false;
        }

        if (!validarText(nroDocumento, 'Número Documento')) {
            return false;
        } else if (idTipoDocumento === appConfigKey.keyIdDNI) {
            if (!validarLongitud(nroDocumento, 'Número Documento', 8)) {
                return false;
            }
        }

        if (!validarText(username, 'Usuario')) {
            return false;
        } else {
            const sufijo = GeneralHelper.GetSufijo();
            if (sufijo && !validarIncludeText(username, "El Usuario no puede tener el sufijo repetido.", sufijo)) {
                return false;
            }
        }

        if (!validarSelect(dominioId, 'Dominio')) {
            return false;
        }

        if (password && password != '') {
            if (!validarRegExp(password, "La contraseña debe tener al menos 8 caracteres e incluir: una letra mayúscula, una letra minúscula, un número y un símbolo especial (por ejemplo: @ # $ % & * _).", appConfigRegExp.MicrosoftPassword)) {
                return false;
            }
        }

        if (!validarText(emailInterno, 'Correo')) {
            return false;
        } else if (!validarRegExp(emailInterno, "El correo debe estar en formato usuario@dominio.com, usando solo letras, números y los caracteres especiales (. _ ! # ^ ~ -) antes de la '@', seguido de un dominio válido.", appConfigRegExp.MicrosoftUserPrincipal)) {
            return false;
        }
        return true;
    },

}

export default ValidacionHelper;