
import { appDefaultValues } from "../../../../../../config/Config"

export interface DatosDetailsStepsThreeCard {
    Producto: string,
    CostoSegVeh: string,
    TasaSegDesg: string,
    VisibleTasaSegDesg: boolean,
    TotalFinanciamiento: string,
    SegVehicular: string,
    TEA: string,
    CuotasDobles: string,
    TotalSegVehicular: string,
    Score: string,
    TCEA: string,
    GPS: string,
    TotalGastos: string,
    CuotaFinal: string,
    Plazo: string,
};


export const DatosDetailsStepsThreeCardDefault: DatosDetailsStepsThreeCard = {

    Producto: appDefaultValues.StringEmpty,
    CostoSegVeh: appDefaultValues.StringEmpty,
    TasaSegDesg: appDefaultValues.StringEmpty,
    VisibleTasaSegDesg: appDefaultValues.BooleanDefault,
    TotalFinanciamiento: appDefaultValues.StringEmpty,
    SegVehicular: appDefaultValues.StringEmpty,
    TEA: appDefaultValues.StringEmpty,
    CuotasDobles: appDefaultValues.StringEmpty,
    TotalSegVehicular: appDefaultValues.StringEmpty,
    Score: appDefaultValues.StringEmpty,
    TCEA: appDefaultValues.StringEmpty,
    GPS: appDefaultValues.StringEmpty,
    TotalGastos: appDefaultValues.StringEmpty,
    CuotaFinal: appDefaultValues.StringEmpty,
    Plazo: appDefaultValues.StringEmpty
};
