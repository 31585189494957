import { AppException } from "../models/Shared/app-exception";

class RsaEncryptionHelper {
    private publicKey: CryptoKey | null = null;

    async loadPublicKey(pem: string): Promise<void> {
        try {
            const binaryDer = this.pemToBinary(pem);
            this.publicKey = await window.crypto.subtle.importKey(
                "spki",                // Formato para claves públicas
                binaryDer,             // Clave binaria
                { name: "RSA-OAEP", hash: { name: "SHA-256" } }, // Algoritmo RSA-OAEP-256
                true,                   // La clave puede ser usada para encriptar
                ["encrypt"]             // Operación que se puede realizar con la clave
            );
        } catch (error) {
            throw new AppException("La clave pública proporcionada no es válida. Por favor, comuniquese con el administrador.");
        }
    }

    private pemToBinary(pem: string): ArrayBuffer {
        const pemHeader = "-----BEGIN PUBLIC KEY-----";
        const pemFooter = "-----END PUBLIC KEY-----";
        const pemContents = pem.replace(pemHeader, "").replace(pemFooter, "").trim();

        const binaryDerString = window.atob(pemContents); // Decodificar base64
        const binaryDer = new ArrayBuffer(binaryDerString.length);
        const binaryDerView = new Uint8Array(binaryDer);
        for (let i = 0; i < binaryDerString.length; i++) {
            binaryDerView[i] = binaryDerString.charCodeAt(i);
        }
        return binaryDer;
    }

    async encryptText(plainText: string): Promise<string> {
        if (!this.publicKey) {
            throw new AppException("La clave pública no ha sido configurada.");
        }

        try {
            const encoder = new TextEncoder();
            const data = encoder.encode(plainText);

            const encryptedData = await window.crypto.subtle.encrypt(
                { name: "RSA-OAEP" },
                this.publicKey,
                data
            );

            const encryptedBase64 = this.arrayBufferToBase64(encryptedData);
            return encryptedBase64;
        } catch (error) {
            throw new AppException("Falló la encriptación. Por favor, comuniquese con el administrador.");
        }
    }

    private arrayBufferToBase64(buffer: ArrayBuffer): string {
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const length = bytes.byteLength;
        for (let i = 0; i < length; i++) {
            binary += String.fromCharCode(bytes[i]);
        }
        return window.btoa(binary);
    }
}

export default RsaEncryptionHelper