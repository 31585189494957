import React from "react";
import simbolSesion from '../../../../../assets/media/icons/simbolSesion.svg'
import "./sessionState.scss";
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import { Button, Menu, MenuItem } from "@mui/joy";
import { appSecurityConfiguration } from "../../../../../config/Config";
import { useNavigate } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import WelcomeName from "./welcomeName";

function SesionState() {
    const { instance } = useMsal();
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        instance.logoutPopup();
        localStorage.removeItem('UserMn');
        localStorage.removeItem('UserProfileMn');
    };
    var user = appSecurityConfiguration.SessionId;
    /*     const account = appConfig.msalInstance.getActiveAccount();
        //myowasp(account);
    
        useEffect(() => {
            const GetMnUsuario = async () => {
                 await Master.GetMnUsuarioSesion().then((data) => {
                    appSecurityConfiguration.SessionId = data.data.content[0].idenT_USUARIO;
                    appSecurityConfiguration.SessionPerfil = data.data.content[0].idenT_PERFIL;
                    appSecurityConfiguration.SessionId = data.data.content[0].username;
                });
            };
            GetMnUsuario();
        },[]);  */

    return (
        <div style={{ display: 'flex', justifyContent: 'end', gap: '10px' }}>
            <div className="headSesionStateSimbol" >
                <img src={simbolSesion} alt="burger" />
            </div>
            <Button
                id="group-demo-button"
                aria-controls={open ? 'group-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="outlined"
                color="neutral"
                onClick={handleClick}
                endDecorator={<ArrowDropDown />}
            >
                <WelcomeName />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                aria-labelledby="basic-demo-button">
                {/*                 <MenuItem >Profile</MenuItem>
                <MenuItem onClick={handleClose}>My account</MenuItem> */}
                <MenuItem onClick={handleLogout}>Cerrar Sesión</MenuItem>
            </Menu>
        </div>
    );
};

export default SesionState;

