import { appConfigKey } from "../../../../config/Config";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { TMnAplicacionDominioRequest } from "../../../models/MasterManager/aplicacion-dominio-request.model";
import { TMnAutonomiaCargoRequest } from "../../../models/MasterManager/autonomia-cargo-request.model";
import { MnUsuarioInsert } from "../../../models/MasterManager/crear-usuario-request.model";
import { MnUsuarioEdit } from "../../../models/MasterManager/editar-usuario-request.model";
import { MnUsuariosRequest } from "../../../models/MasterManager/usuarios-request.model";
import { AppException } from "../../../models/Shared/app-exception";
import MasterManager from "../../../services/origination/wapimaster.service";

export const General = {
  GetUsuarios: async (data: MnUsuariosRequest) => {
    try {
      const response = await MasterManager.GetUsuarios(data);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjListarUsuariosError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return response?.data?.content ?? [];
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjListarUsuariosError);
      throw new AppException(errorMsg);
    }
  },
  InsertUsuario: async (data: MnUsuarioInsert) => {
    try {
      const response = await MasterManager.CreateUsuario(data);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjCrearEditarUsuarioError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return response?.data?.content;
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjCrearEditarUsuarioError);
      throw new AppException(errorMsg);
    }
  },
  EditUsuario: async (data: MnUsuarioEdit) => {
    try {
      const response = await MasterManager.EditUsuario(data);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjCrearEditarUsuarioError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return response?.data?.content;
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjCrearEditarUsuarioError);
      throw new AppException(errorMsg);
    }
  },
  DeleteUsuario: async (identUsuario: number) => {
    try {
      const response = await MasterManager.DeleteUsuario(identUsuario);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjEliminarUsuarioError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return true;
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjEliminarUsuarioError);
      throw new AppException(errorMsg);
    }
  },
  GetDominios: async () => {
    try {
      const response = await MasterManager.GetMnAplicacionDominio();

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjCargarDominiosError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return response?.data?.content ?? [];
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjCargarDominiosError);
      throw new AppException(errorMsg);
    }
  },
  GetPerfiles: async () => {
    try {
      const response = await MasterManager.GetMnPerfil();

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjCargarPerfilesError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return response?.data?.content ?? [];
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjCargarPerfilesError);
      throw new AppException(errorMsg);
    }
  },
  GetAutonomiaCargos: async () => {
    try {
      const response = await MasterManager.GetMnAutonomiaCargos(new TMnAutonomiaCargoRequest());

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjCargarAutonomiaCargosError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return response?.data?.content ?? [];
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjCargarAutonomiaCargosError);
      throw new AppException(errorMsg);
    }
  },
  GetPublicKey: async () => {
    try {
      const response = await MasterManager.GetMnPublicKey();

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, appConfigKey.keyMsjCargarPublicKeyError);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return response?.data?.content!;
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, appConfigKey.keyMsjCargarPublicKeyError);
      throw new AppException(errorMsg);
    }
  },
}