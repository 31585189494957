import { appConfigDocumentoFechaVencimientos, appConfigKey, appDefaultValues, appSecurityConfiguration } from "../../config/Config";
import { TMnDocumentacionDocumentoInsertRequest } from "../models/MasterManager/documentacion-documento-request.model";
import { BTActualizarDocumentoDigitalRequest, BTAsociarDocumentoDigitalRequest } from "../models/ServicesProviderManager/documento-digital-request.model";
import DocumentManager from "../services/business/document.service";
import FileHelper from "./FileHelper";
import GeneralHelper from "./GeneralHelper";
import TimeHelper from "./TimeHelper";

const UploadHelper = {
    uploadFile: async (fileUploaded: any, data: any, solicitudId: number) => {
        const tipoDocumentoId = Number(data.idenT_TIPO_DOCUMENTO);
        const fileName = fileUploaded.name;
        const archivo = await FileHelper.convertFileToBase64(fileUploaded);
        const fechaActual = TimeHelper.ObtenerFechaActual(0);
        const fechaVencimiento = appConfigDocumentoFechaVencimientos.includes(tipoDocumentoId) ? TimeHelper.ObtenerFechaActual(1) : appDefaultValues.StringEmpty;
        const estadoAdjunto = data.idenT_ESTADO_ADJUNTO;
        // const documentoId = estadoAdjunto === appConfigKey.keyIdEstadoDocumentoPorAdjuntar ? data.idenT_TIPO_DOCUMENTO : data.idenT_DOCUMENTO_BT

        const santiDocumentoId: number = Number(data.idenT_DOCUMENTACION_DOCUMENTO);

        let upsertSantiRequest = new TMnDocumentacionDocumentoInsertRequest()
        upsertSantiRequest.idenT_DOCUMENTACION_DOCUMENTO = santiDocumentoId;
        upsertSantiRequest.idenT_SOLICITUD = data.idenT_SOLICITUD;
        upsertSantiRequest.idenT_TIPO_LISTADO = data.idenT_TIPO_LISTADO;
        upsertSantiRequest.descC_TIPO_LISTADO = data.descC_TIPO_LISTADO;
        upsertSantiRequest.idenT_TIPO_DOCUMENTO = data.idenT_TIPO_DOCUMENTO;
        upsertSantiRequest.descC_TIPO_DOCUMENTO = data.descC_TIPO_DOCUMENTO;
        upsertSantiRequest.idenT_ESTADO_ADJUNTO = appConfigKey.keyIdEstadoDocumentoAdjuntado;
        upsertSantiRequest.descC_ESTADO_ADJUNTO = appConfigKey.keyDescripEstadoDocumentoAdjuntado;
        upsertSantiRequest.descC_NOMBRE_ARCHIVO = GeneralHelper.GetNombreArchivo(fileName);
        upsertSantiRequest.descC_EXTENSION_ARCHIVO = `.${GeneralHelper.GetExtensionArchivo(fileName)}`;
        // requestSanti.idenT_DOCUMENTO_BT = 0; //No es necesario "CargarDocumentoBTSanti" lo asigna.
        upsertSantiRequest.idenT_USUARIO = Number(appSecurityConfiguration.SessionUserId);

        if (estadoAdjunto === appConfigKey.keyIdEstadoDocumentoPorAdjuntar) {
            let asociarBTRequest = new BTAsociarDocumentoDigitalRequest();
            asociarBTRequest.tipoDocumentoId = tipoDocumentoId;
            asociarBTRequest.nombre = fileName;
            asociarBTRequest.archivoCodificado = archivo;
            asociarBTRequest.fechaEmision = fechaActual;
            asociarBTRequest.fechaVencimiento = fechaVencimiento;
            await DocumentManager.CargarDocumentoBTSanti(asociarBTRequest, upsertSantiRequest, solicitudId);
        } else {
            let actualizarBTRequest = new BTActualizarDocumentoDigitalRequest();
            // requestBT.documentoId = 0; //No es necesario "ActualizarDocumentoBTSanti" lo asigna.
            actualizarBTRequest.nombre = fileName;
            actualizarBTRequest.archivoCodificado = archivo;
            actualizarBTRequest.fechaEmision = fechaActual;
            actualizarBTRequest.fechaVencimiento = fechaVencimiento;
            await DocumentManager.ActualizarDocumentoBTSanti(actualizarBTRequest, upsertSantiRequest, solicitudId);
        }
    }
}

export default UploadHelper;