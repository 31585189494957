import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button } from "@mui/joy";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import HistoricoTeaResquestDialog from './financiamientoHistoricoTea';
import MasterManager from "../../../services/origination/wapimaster.service";
import { HistoricoTea, clsHistoricoTeaDefault } from "../../../models/ManagerProfile/HistoricoTea";
import { useParams } from "react-router-dom";
import RequestManager from '../../../services/origination/wapirequest.service';
import { appConfigKey, appSecurityConfiguration } from "../../../../config/Config";
import { Toaster, toast } from "sonner";
import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import GeneralHelper from "../../../helpers/GeneralHelper";
import ParameterHelper from "../../../helpers/ParameterHelper";

export const FinanciamientoDatosCondiciones = ({ datosCondicionFinanciamiento, datosCondicionFinanciamientoOriginal, resultadoFinanciamiento, setDatosCondicionFinanciamiento, setShow, setResult, esModoLectura, cuotas, solicitud,statecuotaDoble }: any) => {

  const { solicitudId } = useParams();
  var idUserSession = appSecurityConfiguration.SessionUserId;

  const HandlePorcentajeInicial = async (e: any) => {
    if (/^\d*\.?\d{0,4}$/.test(e.target.value)) {
      const newCuotaInicial = Math.round(((datosCondicionFinanciamiento.montoVehiculoBono * e.target.value) / 100));
      setDatosCondicionFinanciamiento({
        ...datosCondicionFinanciamiento,
        porcentajeInicial: e.target.value,
        cuotaInicial: newCuotaInicial
      });
    }
  }

  const [historicoTea, setHistoricoTea] = useState<HistoricoTea[]>([])
  const [esModoLecturaDatosCondFinan, setEsModoLecturaDatosCondFinan] = React.useState(false);
  const [esModoLecturaDatosCondFinanCuoDoble, setEsModoLecturaDatosCondFinanCuoDoble] = React.useState(false);
  const [esVisibleCuotaBallon, setEsVisibleCuotaBallon] = React.useState(false);

  const cargarListaTea = async () => {
    var body = {
      "idenT_SOLICITUD": solicitudId
    };

    setHistoricoTea([]);
    await MasterManager.GetMnSolicitudHistoricoCambiarTasa(body)
      .then((response: any) => {
        let HistoricoTeaListTemp: HistoricoTea[] = [];
        response?.data?.content?.forEach((item: HistoricoTea) => {
          HistoricoTeaListTemp.push(item);
        })
        setHistoricoTea(HistoricoTeaListTemp);
        setHistoricoTeaRequestDialogOpen(true);
      })
  }

  const [historicoTeaRequestDialogOpen, setHistoricoTeaRequestDialogOpen] = React.useState<boolean>(false);

  const handleHistoricoTeaRequestDialogClose = () => {
    setHistoricoTeaRequestDialogOpen(false);
  };

  const handleHistoricoTeaRequestDialogOpen = () => {
    cargarListaTea();
  };

  const GetMnSimularOfertasVehicularBySolicitud = async () => {
    var body = {
      "identSolicitud": solicitudId,
      "tea": datosCondicionFinanciamiento.tea || 0,
      "inicial": datosCondicionFinanciamiento.cuotaInicialBono || 0,
      "plazo": datosCondicionFinanciamiento.cuota || 0,
      "cuotasDobles": datosCondicionFinanciamiento.indicadorCuotaDoble,
      "comentario": datosCondicionFinanciamiento.comentario || "",
      "identActualizarBD": false,
      "idenT_USUARIO_CREADOR": idUserSession
    };
    setShow(false);
    await RequestManager.GetMnSimularOfertasVehicularBySolicitud(body)
      .then(async (response) => {
        const result = {
          montoFinanciamiento: response.data.content.montoFinanciamiento,
          montoCuota: response.data.content.valorCuota,
          totalGastos: response.data.content.totalGastos,
          TCEA: `${response.data.content.cft} %`,
          totalFinanciamiento: response.data.content.totalFinanciamiento,
          cuotaDoble: response.data.content.valorCuotaDoble
        };
        return result;
      })
      .then(async (response: any) => {
        setResult(response);
        setShow(true);
        toast.success(appConfigKey.keyMsjRecalcularFinanciamiento, { duration: appConfigKey.keyDurationToast });
      })
      .catch(error => {
        setResult({
          montoFinanciamiento: 'N/D',
          montoCuota: 'N/D',
          totalGastos: 'N/D',
          TCEA: 'N/D',
          totalFinanciamiento: 'N/D',
          cuotaDoble: 0
        });
        setShow(true);
        console.log(error.message);
        toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
      })
  };

  const HandleReevaluarTea = () => {
    GetMnSimularOfertasVehicularBySolicitud();
  }

  const VerificarVisibilidadCuotaBallon = () => {
    const { idProducto } = solicitud;
    const { cuotaBallon } = datosCondicionFinanciamiento;
    if (idProducto) {
      if (ParameterHelper.GetAllProductBalloon().includes(idProducto) || cuotaBallon > 0) {
        setEsVisibleCuotaBallon(true);
      } else {
        setEsVisibleCuotaBallon(false);
      }
    }
  }

  useEffect(() => {
    setEsModoLecturaDatosCondFinan(!esModoLectura ? false : esModoLectura);
    VerificarVisibilidadCuotaBallon();
    if(!statecuotaDoble && esModoLectura){
      setEsModoLecturaDatosCondFinanCuoDoble(!esModoLectura ? false : esModoLectura);
    }else{
      setEsModoLecturaDatosCondFinanCuoDoble(!statecuotaDoble ? false : statecuotaDoble);
    }
  }, [])

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">DATOS Y CONDICIONES DE FINANCIAMIENTO</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-3 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>Moneda</FormLabel>
            <Input disabled
              name="moneda"
              placeholder="Soles"
              value={datosCondicionFinanciamiento.moneda}
              onChange={(event: any) => {
                setDatosCondicionFinanciamiento({ ...datosCondicionFinanciamiento, moneda: event.target.value });
              }}
            />
          </LocalizationProvider>
        </div>
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel>
              Porcentaje inicial %<span style={{ color: "red" }}> * </span>
            </FormLabel>
            <Input
              name="porcentajeInicial"
              placeholder="Ej: 00000000"
              value={datosCondicionFinanciamiento.porcentajeInicial}
              disabled={esModoLecturaDatosCondFinan}
              // onChange={(event: any) => {
              //   setDatosCondicionFinanciamiento({...datosCondicionFinanciamiento, porcentajeInicial: event.target.value });
              // }}
              onChange={HandlePorcentajeInicial}
            />
          </FormControl>
        </div>
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel>
              Cuotas <span style={{ color: "red" }}> * </span>
            </FormLabel>
            {/*<Input
              name="cuotas"
              placeholder="Ej: 00000000"
              value={datosCondicionFinanciamiento.cuota}
              disabled={esModoLecturaDatosCondFinan}
              onChange={(event: any) => {
                setDatosCondicionFinanciamiento({ ...datosCondicionFinanciamiento, cuota: event.target.value });
              }}
            />*/}

            <Select
              name="cuotas"
              className={"select-input-card"}
              disabled={esModoLecturaDatosCondFinan}
              onChange={(event: any, newValue: any) => {
                setDatosCondicionFinanciamiento({ ...datosCondicionFinanciamiento, cuota: event.target.value });
              }}
              value={datosCondicionFinanciamiento.cuota}
              IconComponent={props => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
            >
              {cuotas.map((data: any, index: any) => (
                <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
              ))}
            </Select>

          </FormControl>
        </div>
        <div className="col-lg-3 cardObjets">
          <FormLabel>Cuota doble (Agosto - Enero)</FormLabel>
          <Switch
            color={datosCondicionFinanciamiento.indicadorCuotaDoble ? "success" : "neutral"}
            checked={esModoLecturaDatosCondFinanCuoDoble === false ? false : datosCondicionFinanciamiento.indicadorCuotaDoble}
            disabled={esModoLecturaDatosCondFinanCuoDoble}
            onChange={(event) => setDatosCondicionFinanciamiento({ ...datosCondicionFinanciamiento, indicadorCuotaDoble: event.target.checked })}
            slotProps={{
              track: {
                children: (
                  <React.Fragment>
                    <Typography
                      component="span"
                      level="inherit"
                      sx={{ ml: "10px" }}
                    >
                      Si
                    </Typography>
                    <Typography
                      component="span"
                      level="inherit"
                      sx={{ mr: "8px" }}
                    >
                      No
                    </Typography>
                  </React.Fragment>
                ),
              },
            }}
            sx={{
              "--Switch-thumbSize": "27px",
              "--Switch-trackWidth": "64px",
              "--Switch-trackHeight": "31px",
            }}
          />
        </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">

        <div className="col-lg-3">
          <div className="row g-2 mb-2 align-items-center">
            <div className="col-lg-6 cardObjets">
              <FormControl>
                <FormLabel>
                  TEA <span style={{ color: "red" }}> * </span>
                </FormLabel>
                <Input
                  name="tea"
                  placeholder="Ej: 00000000"
                  value={datosCondicionFinanciamiento.teaString}
                  disabled={esModoLecturaDatosCondFinan}
                  onChange={(event: any) => {
                    if (/^\d*\.?\d{0,3}$/.test(event.target.value)) {
                      setDatosCondicionFinanciamiento({ ...datosCondicionFinanciamiento, teaString: event.target.value, tea: Number(event.target.value) });
                    }
                  }
                  }
                />
              </FormControl>
            </div>
            <div className="col-lg-6 cardButtonItem">
              <FormControl>

                <FormLabel>
                </FormLabel>
                <Button
                  size="md"
                  color="danger"
                  variant="outlined"
                  sx={{
                    borderRadius: 24,
                    borderColor: "red",
                    fontWeight: 650,
                    width: '100%'
                  }}
                  onClick={handleHistoricoTeaRequestDialogOpen}
                >
                  Histórico TEA
                </Button>
              </FormControl>
            </div>
          </div>
        </div>



        <div className="col-lg-6 cardObjets">
          <FormControl>
            <FormLabel>
              Comentario <span style={{ color: "red" }}> {datosCondicionFinanciamientoOriginal.tea === datosCondicionFinanciamiento.tea ? '' : '*'} </span>
            </FormLabel>
            <Input
              name="comentario"
              placeholder=""
              value={datosCondicionFinanciamiento.comentario}
              disabled={esModoLecturaDatosCondFinan}
              onChange={(event: any) => {
                setDatosCondicionFinanciamiento({ ...datosCondicionFinanciamiento, comentario: event.target.value });
              }}
            />
          </FormControl>
        </div>

        {
          datosCondicionFinanciamiento?.indicadorCuotaDoble && <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Monto cuota doble</FormLabel>
              <FormLabel>{GeneralHelper.NumeroConCommas(resultadoFinanciamiento?.cuotaDoble)}</FormLabel>
            </FormControl>
          </div>
        }
        {/* <div className="col-lg-3 cardObjets">
          <Button
            size="md"
            color="danger"
            variant="outlined"
            disabled={esModoLecturaDatosCondFinan}
            sx={{
              borderRadius: 24,
              borderColor: "red",
              fontWeight: 650,
              width: 1 / 2,
            }}
            onClick={HandleReevaluarTea}
          >
            Reevaluar
          </Button>
        </div>*/}
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Monto vehículo</FormLabel>
              <FormLabel>{GeneralHelper.NumeroConCommas(datosCondicionFinanciamiento.montoVehiculo ? datosCondicionFinanciamiento.montoVehiculo.toFixed(2) : 0)}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Cuota inicial</FormLabel>
              <FormLabel>{GeneralHelper.NumeroConCommas(datosCondicionFinanciamiento.cuotaInicial ? datosCondicionFinanciamiento.cuotaInicial.toFixed(2) : 0)}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Producto</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.producto}</FormLabel>
            </FormControl>
          </div>

          {esVisibleCuotaBallon &&
            <div className="col-lg-3 cardObjets">
              <FormControl>
                <FormLabel>Cuota ballon</FormLabel>
                <FormLabel>{datosCondicionFinanciamiento.cuotaBallon ? datosCondicionFinanciamiento.cuotaBallon + ' %' : ''}</FormLabel>
              </FormControl>
            </div>
          }

          {datosCondicionFinanciamiento?.ciPrecio === appConfigKey.KeyPrecioVehiculo && <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Monto vehículo Campaña</FormLabel>
              <FormLabel>{GeneralHelper.NumeroConCommas(datosCondicionFinanciamiento.montoVehiculoBono ? datosCondicionFinanciamiento.montoVehiculoBono.toFixed(2) : 0)}</FormLabel>
            </LocalizationProvider>
          </div>}

          {datosCondicionFinanciamiento.campania && <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Campaña</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.campania}</FormLabel>
            </FormControl>
          </div>}

          {datosCondicionFinanciamiento?.ciPrecio > 0 && <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>CI/Precio</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.ciPrecioDescripcion}</FormLabel>
            </LocalizationProvider>
          </div>}

          {esVisibleCuotaBallon &&
            <div className="col-lg-3 cardObjets">
              <FormControl>
                <FormLabel>Monto Cuota Ballon</FormLabel>
                <FormLabel>{GeneralHelper.NumeroConCommas(datosCondicionFinanciamiento.montoCuotaBallon ? datosCondicionFinanciamiento.montoCuotaBallon.toFixed(2) : 0)}</FormLabel>
              </FormControl>
            </div>
          }

        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Monto asegurado</FormLabel>
              <FormLabel>{GeneralHelper.NumeroConCommas(datosCondicionFinanciamiento.montoAsegurado)}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Seguro vehículo endosado</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.seguroVehicularEndosado}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>GPS</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.gps}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Tipo GPS</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.tipoGps}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Gastos inclusión GPS</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.gastosInclusionGps}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Gastos registrales</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.gastosRegistrales}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Gastos notariales</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.gastosNotariales}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Gastos delivery firmas</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.gastosDeliveryFirmas}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Concesionario</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.concesionario}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Sucursal</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.sucursal}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Marca</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.marca}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>Modelo</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.modelo}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Vendedor</FormLabel>
              <FormLabel>{datosCondicionFinanciamiento.vendedor}</FormLabel>
            </LocalizationProvider>
          </div>
        </div>
      </div>

      <HistoricoTeaResquestDialog
        open={historicoTeaRequestDialogOpen}
        handleClose={handleHistoricoTeaRequestDialogClose}
        historicoTea={historicoTea}
      />
    </div>
  );
};

export default FinanciamientoDatosCondiciones;
