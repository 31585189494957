import { useEffect, useState } from "react";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { Button, Divider, FormControl, FormLabel, Modal, ModalClose, ModalDialog, Typography } from "@mui/joy"
import { MenuItem, Select } from "@mui/material"
import { General } from "../obtenerServicios";
import { toast } from "sonner";
import { appConfigKey, appConfigRegExp } from "../../../../../config/Config";
import { UserModel, userModelDefault, UserModalProps } from "../interfaces/IUsersManagement";
import { SwitchInput } from "../../../1.-shared/1.7.-component/1.7.3.-input/switchInput";
import ConvertidorHelper from "../helpers/ConvertidorHelper";
import { SimpleInput } from "../../../1.-shared/1.7.-component/1.7.3.-input/simpleInput";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import LoadingProgress from "../../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import { SelectItem } from "../../../../models/Shared/select-item.model";
import { AppException } from "../../../../models/Shared/app-exception";
import { SimpleSecretInput } from "../../../1.-shared/1.7.-component/1.7.3.-input/simpleSecretInput";
import ValidacionHelper from "../helpers/ValidacionHelper";
import RsaEncryptionHelper from "../../../../helpers/RsaEncryptionHelper";
import { NumberInput } from "../../../1.-shared/1.7.-component/1.7.3.-input/numberInput";
import ParameterHelper from "../../../../helpers/ParameterHelper";

enum Modo {
  Lectura = 'Lectura',
  Nuevo = 'Nuevo',
  Editar = 'Editar'
}

export const NewUserModal: React.FC<UserModalProps> = ({ open, setOpen, onSuccess, tiposDocumento, userToEdit = null }) => {

  const [usuario, setUsuario] = useState<UserModel>({ ...userModelDefault });
  const [dominios, setDominios] = useState<SelectItem[]>([]);
  const [perfiles, setPerfiles] = useState<SelectItem[]>([]);
  const [cargos, setCargos] = useState<SelectItem[]>([]);
  const [modo, setModo] = useState(Modo.Lectura);
  const [loading, setLoading] = useState(false);
  const [cambiarPasswordVisible, setCambiarPasswordVisible] = useState(false);
  const [rolVisible, setRolVisible] = useState(false);

  const loadModel = () => {
    if (!open) {
      setUsuario({ ...userModelDefault });
      setModo(Modo.Lectura);
      setCambiarPasswordVisible(false);
      return;
    }

    if (userToEdit) {
      let model = ConvertidorHelper.ObtenerUserModel(userToEdit)
      setUsuario(model);
      setModo(Modo.Editar);
    } else {
      setUsuario({ ...userModelDefault });
      setModo(Modo.Nuevo);
      setCambiarPasswordVisible(true);
    }
  }

  const handleDominioDefault = () => {
    //Asignar por defecto el primer dominio en usuario nuevo
    if (modo === Modo.Nuevo && dominios.length > 0) {
      setUsuario((prev: any) => { return { ...prev, dominioId: dominios[0].id }; });
    }
  }

  const getDominios = async () => {
    if (!open) {
      return;
    }

    setDominios([]);

    try {
      let content = await General.GetDominios();

      let dominios = ConvertidorHelper.ObtenerDominiosModel(content)
      setDominios(dominios);

    } catch (error: any) {
      if (error instanceof AppException) {
        toast.error(error.message, { duration: appConfigKey.keyDurationToast });
      }
      else {
        toast.error(appConfigKey.keyMsjCargarDominiosError, { duration: appConfigKey.keyDurationToast });
      }
    }
  }

  const getPerfiles = async () => {
    if (!open) {
      return;
    }

    setPerfiles([]);

    try {
      let content = await General.GetPerfiles();

      let dominios = ConvertidorHelper.ObtenerPerfilesModel(content)
      setPerfiles(dominios);

    } catch (error: any) {
      if (error instanceof AppException) {
        toast.error(error.message, { duration: appConfigKey.keyDurationToast });
      }
      else {
        toast.error(appConfigKey.keyMsjCargarPerfilesError, { duration: appConfigKey.keyDurationToast });
      }
    }
  }

  const GetAutonomiaCargos = async () => {
    if (!open) {
      return;
    }

    setCargos([]);

    try {
      let content = await General.GetAutonomiaCargos();

      let cargos = ConvertidorHelper.ObtenerAutonomiaCargosModel(content)
      setCargos(cargos);

    } catch (error: any) {
      if (error instanceof AppException) {
        toast.error(error.message, { duration: appConfigKey.keyDurationToast });
      }
      else {
        toast.error(appConfigKey.keyMsjCargarAutonomiaCargosError, { duration: appConfigKey.keyDurationToast });
      }
    }
  }

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    handleUsuario();
  }

  const handleUsuario = async () => {
    setLoading(true);
    try {
      let usuarioTemp = { ...usuario };
      let response;

      if (modo === Modo.Editar) {

        if (!ValidacionHelper.ValidarMnUsuarioEdit(usuarioTemp)) {
          setLoading(false);
          return;
        }

      } else if (modo === Modo.Nuevo) {

        if (!ValidacionHelper.ValidarMnUsuarioInsert(usuarioTemp)) {
          setLoading(false);
          return;
        }

      }

      if (usuarioTemp.password && usuarioTemp.password !== '') {
        const publicKey = await General.GetPublicKey();
        const cryptography = new RsaEncryptionHelper();
        await cryptography.loadPublicKey(publicKey);

        usuarioTemp.password = await cryptography.encryptText(usuarioTemp.password);
      }

      if (modo === Modo.Editar) {

        const request = await ConvertidorHelper.ObtenerMnUsuarioEdit(usuarioTemp);
        response = await General.EditUsuario(request);

      } else if (modo === Modo.Nuevo) {

        const request = await ConvertidorHelper.ObtenerMnUsuarioInsert(usuarioTemp);
        response = await General.InsertUsuario(request);

      }

      setLoading(false);
      toast.success(
        modo === Modo.Editar ? appConfigKey.KeyMsjEditadoExitoso :
          modo === Modo.Nuevo ? appConfigKey.KeyMsjCreadoExitoso : '',
        { duration: appConfigKey.keyDurationToast }
      );

      setOpen(false);
      onSuccess?.();
    } catch (error: any) {
      setLoading(false);
      if (error instanceof AppException) {
        toast.error(error.message, { duration: appConfigKey.keyDurationToast });
      }
      else {
        toast.error(appConfigKey.keyMsjCrearEditarUsuarioError, { duration: appConfigKey.keyDurationToast });
      }
    }
  };

  const getDominioDescripcion = (dominios: any, dominioId: number) => {
    return dominios?.find((d: SelectItem) => d.id === dominioId)?.description ?? '';
  }

  const handleUsuarioChange = (event: any) => {
    event.preventDefault?.();
    let newUsername = event.target.value?.trim();
    let dominioValue = ('@' + getDominioDescripcion(dominios, usuario.dominioId)).trim();
    let newEmail = newUsername + GeneralHelper.GetSufijo() + dominioValue
    setUsuario((prev: any) => { return { ...prev, username: newUsername, emailInterno: newEmail } });
  }

  const handleDominioChange = (event: any, _newValue: any) => {
    event.preventDefault?.();
    let newUsername = usuario.username?.trim();
    let dominioId = Number(event.target.value);
    let dominioValue = ('@' + getDominioDescripcion(dominios, dominioId)).trim();
    let newEmail = newUsername + GeneralHelper.GetSufijo() + dominioValue
    setUsuario((prev: any) => { return { ...prev, dominioId: dominioId, emailInterno: newEmail } });
  }

  const handlePasswordChange = (event: any) => {
    event.preventDefault?.();
    let password = event.target.value?.trim();
    setUsuario((prev: any) => { return { ...prev, password: password } })

    if (modo === Modo.Editar) {
      let visible = password?.length > 0;
      setCambiarPasswordVisible(visible);
      if (!visible) {
        setUsuario((prev: any) => { return { ...prev, esCambiarPassword: false } })
      }
    }
  }

  useEffect(() => {
    const loadData = async () => {
      setLoading(true);
      try {
        loadModel();
        await Promise.all([
          getPerfiles(),
          getDominios()
        ]);
      } catch (_) {
      }
      setLoading(false);
    };
    loadData();
  }, [open])

  useEffect(() => {
    handleDominioDefault();
  }, [modo, dominios])

  useEffect(() => {
    if (ParameterHelper.GetAllAutonomyProfiles().includes(usuario.identPerfil)) {
      GetAutonomiaCargos();
      setRolVisible(true);
    } else {
      setRolVisible(false);
    }

    if (modo == Modo.Nuevo) {
      setUsuario((prev: any) => { return { ...prev, identCargo: 0 }; });
    }

  }, [modo, usuario.identPerfil])

  return (
    <>
      {loading && <LoadingProgress />}
      <Modal open={open} onClose={() => setOpen(false)}>
        <ModalDialog>
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: 'bolder', mb: 3, textTransform: 'uppercase' }}
          >
            {modo === Modo.Lectura ? "Usuario" : modo === Modo.Editar ? 'Editar Usuario' : 'Nuevo Usuario'}
          </Typography>
          <Divider className='mb-4' />
          <form
            style={{ maxHeight: '730px', overflowY: 'scroll', overflowX: 'hidden' }}
            onSubmit={(event: React.FormEvent<HTMLFormElement>) => onSubmit(event)}
          >
            <div className='row p-2 gy-2'>
              <div className="row">

              </div>
              <FormControl className='col-sm-12 col-lg-6'>
                <FormLabel>Perfil<span style={{ color: "red" }}> * </span></FormLabel>
                <Select
                  name="identPerfil"
                  placeholder="Selecciona un perfil"
                  className={"select-input-card"}
                  disabled={modo !== Modo.Nuevo}
                  onChange={(event: any, _newValue: any) => setUsuario((prev: any) => { return { ...prev, identPerfil: event.target.value } })}
                  value={usuario.identPerfil}
                  IconComponent={(props: any) => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
                >
                  <MenuItem value={0}>Seleccione</MenuItem>
                  {perfiles.map((data: SelectItem, index: any) => (
                    <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              {rolVisible && (
                <FormControl className='col-sm-12 col-lg-6'>
                  <FormLabel>Rol<span style={{ color: "red" }}> * </span></FormLabel>
                  <Select
                    name="identCargo"
                    placeholder="Selecciona un rol"
                    className={"select-input-card"}
                    disabled={modo === Modo.Lectura}
                    onChange={(event: any, _newValue: any) => {
                      const value = event.target.value;
                      const description = cargos.find(x => x.id == value)?.description ?? "";
                      setUsuario((prev: any) => { return { ...prev, identCargo: value, cargo: description } })
                    }}
                    value={usuario.identCargo}
                    IconComponent={(props: any) => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
                  >
                    <MenuItem value={0}>Seleccione</MenuItem>
                    {cargos.map((data: SelectItem, index: any) => (
                      <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
              <div className="col-sm-12 col-lg-6">
                <SimpleInput
                  name="apellidoPaterno"
                  title={"Apellido Paterno"}
                  required={modo !== Modo.Lectura}
                  disabled={modo === Modo.Lectura}
                  value={usuario.apellidoPaterno ?? ''}
                  maxLength={100}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, apellidoPaterno: e.target.value } })}
                />
              </div>
              <div className="col-sm-12 col-lg-6">
                <SimpleInput
                  name="apellidoMaterno"
                  title={"Apellido Materno"}
                  required={modo !== Modo.Lectura}
                  disabled={modo === Modo.Lectura}
                  value={usuario.apellidoMaterno ?? ''}
                  maxLength={100}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, apellidoMaterno: e.target.value } })}
                />
              </div>
              <div className="col-sm-12 col-lg-6">
                <SimpleInput
                  name="nombres"
                  title={"Nombres"}
                  required={modo !== Modo.Lectura}
                  disabled={modo === Modo.Lectura}
                  value={usuario.nombres ?? ''}
                  maxLength={200}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, nombres: e.target.value } })}
                />
              </div>
              <FormControl className='col-sm-12 col-lg-6'>
                <FormLabel>Tipo Documento <span style={{ color: "red" }}> * </span></FormLabel>
                <Select
                  name="idTipoDocumento"
                  className={"select-input-card"}
                  onChange={(event: any, _newValue: any) => setUsuario((prev: any) => { return { ...prev, idTipoDocumento: event.target.value } })}
                  value={usuario.idTipoDocumento}
                  IconComponent={(props: any) => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
                >
                  <MenuItem value={0}>Seleccione</MenuItem>
                  {tiposDocumento.map((data: any, index: any) => (
                    <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="col-sm-12 col-lg-6">
                <NumberInput
                  name="nroDocumento"
                  title={"Número Documento"}
                  required={modo !== Modo.Lectura}
                  disabled={modo === Modo.Lectura}
                  value={usuario.nroDocumento ?? ''}
                  maxLength={12}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, nroDocumento: e.target.value } })}
                />
              </div>
              <div className="col-sm-12 col-lg-6 mt-0">
                <div className="row">
                  <SimpleInput
                    className="col-sm-12 col-lg-8 mt-2"
                    name="username"
                    title={"Usuario"}
                    required={modo !== Modo.Lectura}
                    disabled={modo === Modo.Lectura}
                    regex={appConfigRegExp.CaracteresMicrosoftUser}
                    value={usuario.username ?? ''}
                    maxLength={30}
                    onChange={handleUsuarioChange}
                  />
                  {GeneralHelper.GetSufijo() && (
                    <SimpleInput
                      className="col-sm-12 col-lg-4 mt-2"
                      name="sufijo"
                      title={"Sufijo"}
                      required={false}
                      disabled={true}
                      value={GeneralHelper.GetSufijo()}
                      onChange={handleUsuarioChange}
                    />
                  )}
                </div>
              </div>
              <FormControl className='col-sm-12 col-lg-6'>
                <FormLabel>Dominio<span style={{ color: "red" }}> * </span></FormLabel>
                <Select
                  name="dominioId"
                  className={"select-input-card"}
                  disabled={modo !== Modo.Nuevo}
                  onChange={handleDominioChange}
                  value={usuario.dominioId}
                  IconComponent={(props: any) => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
                >
                  <MenuItem value={0}>Seleccione</MenuItem>
                  {dominios.map((data: SelectItem, index: any) => (
                    <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                  ))}
                </Select>
              </FormControl>
              <div className="col-sm-12 col-lg-6">
                <SimpleSecretInput
                  name="password"
                  title={"Contraseña"}
                  required={modo === Modo.Nuevo}
                  disabled={modo === Modo.Lectura}
                  placeholder={modo !== Modo.Nuevo ? ConvertidorHelper.PasswordPlaceHolder : "Ingrese contraseña"}
                  regex={appConfigRegExp.CaracteresMicrosoftPassword}
                  value={usuario.password ?? ''}
                  maxLength={30}
                  onChange={handlePasswordChange}
                />
              </div>
              {cambiarPasswordVisible && (
                <div className="col-sm-12 col-lg-6">
                  <SwitchInput
                    name={"esCambiarPassword"}
                    title={"Forzar cambio de contraseña"}
                    tooltip={"Obliga al usuario a actualizar su contraseña en el próximo inicio de sesión."}
                    checked={usuario.esCambiarPassword}
                    disabled={modo === Modo.Lectura}
                    onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, esCambiarPassword: e.target.checked } })}
                  />
                </div>
              )}
              <div className="col-12">
                <SimpleInput
                  name="emailInterno"
                  title={"Correo"}
                  required={modo !== Modo.Lectura}
                  disabled={true}
                  value={usuario.emailInterno ?? ''}
                // onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, emailInterno: e.target.value }})}
                />
              </div>
              <div className="col-sm-12 col-lg-6">
                <NumberInput
                  name="telefono"
                  title={"Teléfono"}
                  required={false}
                  disabled={modo === Modo.Lectura}
                  type={"number"}
                  value={usuario.telefono ?? ''}
                  maxLength={9}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, telefono: e.target.value } })}
                />
              </div>
              <div className="col-sm-12 col-lg-6">
                <NumberInput
                  name="telefonoMovil"
                  title={"Teléfono Móvil"}
                  required={false}
                  disabled={modo === Modo.Lectura}
                  type={"number"}
                  value={usuario.telefonoMovil ?? ''}
                  maxLength={9}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, telefonoMovil: e.target.value } })}
                />
              </div>
              <div className="col-6 col-sm-6 col-lg-2">
                <SwitchInput
                  name={"esBloqueado"}
                  title={"Bloqueado"}
                  checked={usuario.esBloqueado}
                  disabled={modo !== Modo.Editar}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, esBloqueado: e.target.checked } })}
                />
              </div>
              <div className="col-6 col-sm-6 col-lg-2">
                <SwitchInput
                  name={"esActivo"}
                  title={"Activo"}
                  checked={usuario.esActivo}
                  disabled={modo !== Modo.Editar}
                  onChange={(e: any) => setUsuario((prev: any) => { return { ...prev, esActivo: e.target.checked } })}
                />
              </div>
              {modo !== Modo.Lectura && (
                <div className='row justify-content-center mt-4'>
                  <Button className='col-4' type="submit" color='danger'>{modo !== Modo.Nuevo ? 'Guardar Cambios' : 'Crear Usuario'}</Button>
                </div>
              )}

            </div>
          </form>
        </ModalDialog>
      </Modal>
    </>
  )
}
