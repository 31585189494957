import { appConfigKey, appDefaultValues } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { validarCorreo, validarSelect, validarText } from "../../../../helpers/ValidarHelper";
import { TMnSolicitudPersonaPorValidar } from "../../../../models/MasterManager/solicitud-persona-observada-response.model";
import { ValidacionSolicitudObservada } from "../interfaces/solicitudObservadaInterface";
import { ValidationMemberStepTwoDataInteface } from "../interfaces/validationMember";

const ValidacionStepTwoHelper = {
    NewDatosFinanciamiento: (newDatosFinanciamiento: any) => {
        const {
            ConcesionarioId,
            DestinoCreditoId,
            EstadoVehiculoId,
            Inicial,
            MarcaId,
            ModeloId,
            MonedaId,
            MontoInicialDolares,
            MontoInicialSoles,
            MontoVehiculoDolares,
            MontoVehiculoSoles,
            SucursalId,
            TipoCambio,
            UsoVehiculoId,
            VehiculoYear,
            VendedorEmail,
            VendedorId,
            VersionId
        } = newDatosFinanciamiento;

        if (!validarSelect(EstadoVehiculoId, 'Estado del vehículo')) {
            return false;
        }

        if (!validarSelect(VehiculoYear, "Año del vehículo")) {
            return false;
        }

        if (!validarSelect(ConcesionarioId, "Concesionario")) {
            return false;
        }

        if (!validarSelect(SucursalId, "Sucursal")) {
            return false;
        }

        if (!validarSelect(VendedorId, "Vendedor")) {
            return false;
        }

        if (!validarCorreo(VendedorEmail, "Email vendedor")) {
            return false;
        }

        if (!validarSelect(MonedaId, "Moneda")) {
            return false;
        }

        if (!validarText(TipoCambio, "Tipo de cambio")) {
            return false;
        }

        if (!validarText(MontoVehiculoDolares, "Monto del vehículo $")) {
            return false;
        }

        if (!validarText(MontoVehiculoSoles, "Monto del vehículo S/")) {
            return false;
        }

        if (!validarText(Inicial, "Inicial %")) {
            return false;
        }

        if (!validarText(MontoInicialDolares, "Monto Incial $")) {
            return false;
        }

        if (!validarText(MontoInicialSoles, "Monto Incial S/")) {
            return false;
        }

        if (!validarSelect(UsoVehiculoId, "Uso del vehículo")) {
            return false;
        }

        if (!validarSelect(DestinoCreditoId, "Destino de crédito")) {
            return false;
        }

        if (!validarSelect(MarcaId, "Marca")) {
            return false;
        }

        if (!validarSelect(ModeloId, "Modelo")) {
            return false;
        }

        if (!validarSelect(VersionId, "Versión")) {
            return false;
        }

        return true;
    },

    ModelicaDecision: (response: any) => {
        let mensajeModelica;

        let jsonResponseModelica = JSON.parse(response?.data?.content);

        let decision = jsonResponseModelica?.response?.Politica_Creditos?.decision_Credito?.decision;
        let mesaCreditos = jsonResponseModelica?.response?.Politica_Creditos?.decision_Credito?.mesa_Creditos;
        let motivoRechazo = jsonResponseModelica?.response?.Politica_Creditos?.motivo_Rechazo;
        let mensajes = [];

        const msgDecision = '- Decisión del crédito:';
        const msgMotivo = '- Motivo:';

        //if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
        if (mesaCreditos.derivacion === appConfigKey.CodigoDerivacion) {
            //mensajes.push(`${msgDecision} ${appConfigKey.CodigoEvaluacionZonaGrisDescripcion}`);

            if (mesaCreditos?.motivo_Derivacion) {
                const detalleZonaGris = mesaCreditos?.motivo_Derivacion.map((det: any, index: number) => `${det?.detalle}`);
                //mensajes.push(`${msgMotivo} ${detalleZonaGris.join(", ")}`);
                mensajes.push(`${detalleZonaGris.join(", ")}`);
            }
        }

        if (decision === appConfigKey.CodigoEvaluacionRechazado) {
            mensajes.push(`${msgDecision} ${appConfigKey.CodigoEvaluacionRechazadoDescripcion}`);

            if (motivoRechazo?.Detalle) {
                const detalleRechazo = motivoRechazo.Detalle.map((det: any, index: number) => `${det?.descripcion} ${(det?.interviniente ? ' del ' + det?.interviniente : '')}`);
                mensajes.push(`${msgMotivo} ${detalleRechazo.join(", ")}`);
            }
        }

        if (mensajes.length > 0) {

            if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                mensajes.unshift(appConfigKey.keyMsjModelicaObservado.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoDecision));
            }

            mensajeModelica = mensajes?.map((msg: any, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {msg}
                </div>
            ));

        }

        return mensajeModelica;
    },

    ModelicaDecisionResultado: (response: any) => {
        let decision = response?.Politica_Creditos?.decision_Credito?.decision;
        let motivoRechazo = response?.Politica_Creditos?.motivo_Rechazo;
        let mensajes = [];

        const msgMotivo = 'Motivo:';

        if (decision === appConfigKey.CodigoEvaluacionRechazado) {

            if (motivoRechazo?.Detalle) {
                motivoRechazo.Detalle.map((det: any, index: number) =>
                    mensajes.push(`- ${det?.descripcion} ${(det?.interviniente ? ' del ' + det?.interviniente : '')}`)
                );
            }
        }

        if (mensajes.length > 0) {

            if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                mensajes.unshift(msgMotivo);
            }
        }

        return mensajes;
    },

    GetMembersStepTwoData: (
        solicitudData: any,
        executiveData: any,
        partnerData: any,
        newDatosFinanciamiento: any,
        newTitularData: any,
        newConyugeData: any,
        newIngresosTitularData: any,
        newIngresosConyugeData: any,
        newPersonaCompraParaData: any,
        newPersonaCompraParaConyugeData: any,
        newFiadorData: any
    ) => {
        let validateMembers: ValidationMemberStepTwoDataInteface[] = []
        let IdSolicitud = solicitudData?.idenT_SOLICITUD;

        if (newTitularData) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionTitular,
                TipoDocumento: newTitularData.TipoDocumentoId,
                NumeroDocumento: newTitularData.NumeroDocumento,
                RazonSocial: newTitularData.TipoDocumentoId == appConfigKey.keyIdRUC ?
                    GeneralHelper.ConcatWS(' ', newTitularData.Nombres, newTitularData.ApellidoPaterno, newTitularData.ApellidoMaterno) :
                    appDefaultValues.StringEmpty
            } as ValidationMemberStepTwoDataInteface
            validateMembers.push(member);
        }

        if (newConyugeData &&
            newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado &&
            !newTitularData.SeparacionBienes
        ) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionConyuge,
                TipoDocumento: newConyugeData.TipoDocumentoId,
                NumeroDocumento: newConyugeData.NumeroDocumento,
                RazonSocial: newConyugeData.TipoDocumentoId == appConfigKey.keyIdRUC ?
                    GeneralHelper.ConcatWS(' ', newConyugeData.Nombres, newConyugeData.ApellidoPaterno, newConyugeData.ApellidoMaterno) :
                    appDefaultValues.StringEmpty
            } as ValidationMemberStepTwoDataInteface
            validateMembers.push(member);
        }

        if (newPersonaCompraParaData && GeneralHelper.ParseString(newPersonaCompraParaData.NumeroDocumento) !== '') {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionCompraPara,
                TipoDocumento: newPersonaCompraParaData.TipoDocumentoId,
                NumeroDocumento: newPersonaCompraParaData.NumeroDocumento,
                RazonSocial: newPersonaCompraParaData.TipoDocumentoId == appConfigKey.keyIdRUC ?
                    newPersonaCompraParaData.RazonSocial :
                    appDefaultValues.StringEmpty
            } as ValidationMemberStepTwoDataInteface
            validateMembers.push(member);
        }

        if (GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado &&
            GeneralHelper.ParseString(newPersonaCompraParaConyugeData.NumeroDocumento) !== '') {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionCompraParaConyuge,
                TipoDocumento: newPersonaCompraParaConyugeData.TipoDocumentoId,
                NumeroDocumento: newPersonaCompraParaConyugeData.NumeroDocumento,
                RazonSocial: appDefaultValues.StringEmpty
            } as ValidationMemberStepTwoDataInteface
            validateMembers.push(member);
        }

        if (newFiadorData && newFiadorData.ListaFiadores && newFiadorData.ListaFiadores.length > 0) {
            for (let rowFiador of newFiadorData.ListaFiadores) {
                if (rowFiador) {
                    let member = {
                        IdSolicitud: IdSolicitud,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionFiador,
                        TipoDocumento: rowFiador.TipoDocumentoId,
                        NumeroDocumento: rowFiador.NumeroDocumento,
                        RazonSocial: rowFiador.TipoDocumentoId == appConfigKey.keyIdRUC ?
                            GeneralHelper.ConcatWS(' ', rowFiador.Nombres, rowFiador.ApellidoPaterno, rowFiador.ApellidoMaterno) :
                            appDefaultValues.StringEmpty
                    } as ValidationMemberStepTwoDataInteface
                    validateMembers.push(member);
                    if (rowFiador.Conyuge && rowFiador.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado) {
                        let member = {
                            IdSolicitud: IdSolicitud,
                            TipoRelacionId: appConfigKey.KeyIdTipoRelacionFiadorConyuge,
                            TipoDocumento: rowFiador.Conyuge.TipoDocumentoId,
                            NumeroDocumento: rowFiador.Conyuge.NumeroDocumento,
                            RazonSocial: rowFiador.Conyuge.TipoDocumentoId == appConfigKey.keyIdRUC ?
                                GeneralHelper.ConcatWS(' ', rowFiador.Conyuge.Nombres, rowFiador.Conyuge.ApellidoPaterno, rowFiador.Conyuge.ApellidoMaterno) :
                                appDefaultValues.StringEmpty
                        } as ValidationMemberStepTwoDataInteface
                        validateMembers.push(member);
                    }
                }
            }
        }

        if (newIngresosTitularData) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                TipoDocumento: appConfigKey.keyIdRUC,
                NumeroDocumento: newIngresosTitularData.ruc,
                RazonSocial: newIngresosTitularData.razonSocial
            } as ValidationMemberStepTwoDataInteface
            validateMembers.push(member);

            // if (GeneralHelper.ParseBoolean(newIngresosTitularData.tieneTrabajoAnterior)) {
            //     let member = {
            //         IdSolicitud: IdSolicitud,
            //         TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
            //         TipoDocumento: appConfigKey.keyIdRUC.toString(),
            //         NumeroDocumento: newIngresosTitularData.rucAnterior
            //     } as ValidationMemberStepOneDataInteface
            //     validateMembers.push(member);
            // }

            if (newIngresosTitularData.listaOtrosTrabajos && newIngresosTitularData.listaOtrosTrabajos.length > 0) {
                for (let rowOtroTrabajo of newIngresosTitularData.listaOtrosTrabajos) {
                    let member = {
                        IdSolicitud: IdSolicitud,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                        TipoDocumento: appConfigKey.keyIdRUC,
                        NumeroDocumento: rowOtroTrabajo.ruc,
                        RazonSocial: rowOtroTrabajo.razonSocial
                    } as ValidationMemberStepTwoDataInteface
                    validateMembers.push(member);
                }
            }
        }

        if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado &&
            !newTitularData.SeparacionBienes &&
            newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                TipoDocumento: appConfigKey.keyIdRUC,
                NumeroDocumento: newIngresosConyugeData.ruc,
                RazonSocial: newIngresosConyugeData.razonSocial
            } as ValidationMemberStepTwoDataInteface
            validateMembers.push(member);

            // if (GeneralHelper.ParseBoolean(newIngresosConyugeData.tieneTrabajoAnterior)) {
            //     let member = {
            //         IdSolicitud: IdSolicitud,
            //         TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
            //         TipoDocumento: appConfigKey.keyIdRUC.toString(),
            //         NumeroDocumento: newIngresosConyugeData.rucAnterior
            //     } as ValidationMemberStepOneDataInteface
            //     validateMembers.push(member);
            // }

            if (newIngresosConyugeData.listaOtrosTrabajos && newIngresosConyugeData.listaOtrosTrabajos.length > 0) {
                for (let rowOtroTrabajo of newIngresosConyugeData.listaOtrosTrabajos) {
                    let member = {
                        IdSolicitud: IdSolicitud,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                        TipoDocumento: appConfigKey.keyIdRUC,
                        NumeroDocumento: rowOtroTrabajo.ruc,
                        RazonSocial: rowOtroTrabajo.razonSocial
                    } as ValidationMemberStepTwoDataInteface
                    validateMembers.push(member);
                }
            }

        }

        if (executiveData) {
            let member = {
                IdSolicitud: IdSolicitud,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionEjecutivo,
                TipoDocumento: executiveData.iD_TIPO_DOCUMENTO,
                NumeroDocumento: executiveData.nrO_DOCUMENTO,
                RazonSocial: executiveData.iD_TIPO_DOCUMENTO == appConfigKey.keyIdRUC ?
                    GeneralHelper.ConcatWS(' ', executiveData.nombres, executiveData.apellidO_PATERNO, executiveData.apellidO_MATERNO) :
                    appDefaultValues.StringEmpty
            } as ValidationMemberStepTwoDataInteface
            validateMembers.push(member);
        }

        if (partnerData) {
            if (partnerData.datosPartner) {
                let concesionarioMember = {
                    IdSolicitud: IdSolicitud,
                    TipoRelacionId: appConfigKey.KeyIdTipoRelacionConcesionario,
                    TipoDocumento: partnerData.datosPartner.tipoDocumento,
                    NumeroDocumento: partnerData.datosPartner.numeroDocumento,
                    RazonSocial: partnerData.datosPartner.tipoDocumento == appConfigKey.keyIdRUC ?
                        newDatosFinanciamiento.Concesionario :
                        appDefaultValues.StringEmpty
                } as ValidationMemberStepTwoDataInteface
                validateMembers.push(concesionarioMember);
            }

            if (partnerData.datosVendedor) {
                let vendedorMember = {
                    IdSolicitud: IdSolicitud,
                    TipoRelacionId: appConfigKey.KeyIdTipoRelacionVendedor,
                    TipoDocumento: partnerData.datosVendedor.tipoDocumento,
                    NumeroDocumento: partnerData.datosVendedor.numeroDocumento,
                    RazonSocial: partnerData.datosVendedor.tipoDocumento == appConfigKey.keyIdRUC ?
                        newDatosFinanciamiento.Vendedor :
                        appDefaultValues.StringEmpty
                } as ValidationMemberStepTwoDataInteface
                validateMembers.push(vendedorMember);
            }
        }

        //Remover miembros repetidos
        const claves = new Set<string>();
        validateMembers = validateMembers.filter(m => {
            const clave = `${m.IdPersona}-${m.TipoRelacionId}-${m.TipoDocumento}-${m.NumeroDocumento}`;
            if (!claves.has(clave)) {
                claves.add(clave);
                return true;
            }
            return false;
        });

        //Remover miembros inválidos
        validateMembers = validateMembers.filter(m =>
            m.TipoDocumento &&
            m.NumeroDocumento
        );

        return validateMembers;
    },

    GetMembersEquals: (validateMembers: ValidationMemberStepTwoDataInteface[], validateOtherMembers: TMnSolicitudPersonaPorValidar[]): ValidationMemberStepTwoDataInteface[] => {
        return validateMembers.filter(item1 =>
            validateOtherMembers.some(item2 =>
                item1.TipoRelacionId === item2.idenT_TIPO_RELACION &&
                item1.TipoDocumento === item2.iD_TIPO_DOCUMENTO &&
                item1.NumeroDocumento?.trim() === item2.codL_NUMERO_DOCUMENTO?.trim()
            )
        );
    },

    NewBlackListGesintel: (BlackListGesintel: any): ValidacionSolicitudObservada | null => {
        const { blackListGesintel } = BlackListGesintel;
        let entityBlackListGesintel: ValidacionSolicitudObservada | null = null;
        let detalle: string[] = [];

        if (blackListGesintel && blackListGesintel.length > 0) {

            //Obtener detalle de observación por miembro
            blackListGesintel.forEach((member: any) => {
                let tipoRelacionDescription = "";
                let observacionMensaje = "";

                if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionTitular) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionTitularDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEmpresa) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionEmpresaDescripcion2} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiador) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiadorConyuge) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorConyugeDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraPara) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraParaConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEjecutivo) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionEjecutivoDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConcesionario) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConcesionarioDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionVendedor) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionVendedorDescripcion;
                }

                if (member.bantotalObservado && member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa} / ${appConfigKey.keyMsjBaseGesintel}`
                } else if (member.bantotalObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa}`
                } else if (member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseGesintel}`
                }

                detalle.push(`${tipoRelacionDescription} ${observacionMensaje}`)
            });

            if ((blackListGesintel.filter((x: any) => x.esObservadoPlaftYRiesgo).length > 0) ||
                (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0 &&
                    blackListGesintel.filter((x: any) => x.esObservadoPlaft).length > 0)) {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT y RIESGOS'),
                    detalle: detalle,
                    observadoPlaft: true,
                    observadoRiesgo: true
                }

            } else if (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0) {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'RIESGOS'),
                    detalle: detalle,
                    observadoPlaft: false,
                    observadoRiesgo: true,
                }

            } else {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT'),
                    detalle: detalle,
                    observadoPlaft: true,
                    observadoRiesgo: false,
                }
            }
        }

        return entityBlackListGesintel;
    }
};

export default ValidacionStepTwoHelper;