import { useContext, useEffect, useState } from "react";
import { appConfigKey, appDefaultValues } from "../../../../../../config/Config";
import { FiadorCard, FiadorPersonaInterface } from "../../../3.10.-base/interfaces/fiadorInterface";
import { ButtonAdd } from "../elements/button";
import { DateInput, NumberInput, SelectInput, TextInput } from "../elements/inputs";
import TitleCard from "../elements/titleCard";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";
import { Button, IconButton, Tooltip } from "@mui/joy";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

const FiadoresCard = () => {

    const {
        listTipoDocumento,
        listIdentidadesGenero,
        listProfesion,
        listPais,
        listEstadoCivil,
        clickNext,
        step,
        messageCustomerIsBlackListGesintel,
        readonlyForm
    } = useContext(StepsContext);

    const {
        fiadorData,
        setNewFiadorData
    } = useContext(StepOneContext);

    const [datosFiador, setDatosFiador] = useState<FiadorCard>({
        ListaFiadores: [],
        Loading: true
    });

    const handlerBtnOtrosConyugeFiadoresTitular = () => {
        const data = { ...datosFiador };
        data.ListaFiadores.push({
            PersonaId: 0,
            ApellidoMaterno: "",
            ApellidoPaterno: "",
            EstadoCivilId: 0,
            GeneroId: "",
            NacionalidadId: 0,
            Nombres: "",
            NumeroDocumento: "",
            PaisRecidenciaId: 0,
            TipoDocumentoId: 0,
            ProfesionId: 0,
            FechaNacimiento: null,
            Conyuge: {
                PersonaId: 0,
                Nombres: "",
                ApellidoMaterno: "",
                ApellidoPaterno: "",
                GeneroId: "",
                NacionalidadId: 0,
                NumeroDocumento: "",
                PaisRecidenciaId: 0,
                TipoDocumentoId: 0,
                ProfesionId: 0,
                FechaNacimiento: null
            }
        });
        setDatosFiador(data);
    };

    const deleteOtrosIngresosTitular = (index: number) => {
        const data = { ...datosFiador };
        data.ListaFiadores.splice(index, 1);
        setDatosFiador(data);
    }

    useEffect(() => {
        if (fiadorData !== 0) {
            setDatosFiador(fiadorData);
        }
    }, [fiadorData]);

    useEffect(() => {
        if (clickNext !== 0 && step === 1) {
            setNewFiadorData(datosFiador);
        }
    }, [clickNext]);

    return (
        <>
            <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
                <TitleCard title={"DATOS DEL FIADOR"} obligatorio={false} />

                {datosFiador.ListaFiadores.map((data: FiadorPersonaInterface, index: any) => (
                    <div key={index} className={index == 0 ? "row g-2 mb-2 align-items-center" : "row g-2 mb-2 align-items-center borderOtherIncome"}>
                        <div className="col-lg-2 cardObjets">
                            <SelectInput
                                title={"Tipo de documento"}
                                options={listTipoDocumento}
                                value={data.TipoDocumentoId}
                                disabled={readonlyForm}
                                onChange={(event: any, newValue: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].TipoDocumentoId = Number(newValue);
                                    tempListaFiadores[index].NumeroDocumento = appDefaultValues.StringEmpty;
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                            />
                        </div>

                        <div className="col-lg-2 cardObjets">
                            {data.TipoDocumentoId === appConfigKey.keyCodigoDNI ? (
                                <NumberInput
                                    title={"Número de documento"}
                                    placeholder=""
                                    longNumber={8}
                                    value={data.NumeroDocumento.trim()}
                                    disabled={readonlyForm}
                                    onChange={(event: any) => {
                                        const tempListaFiadores = datosFiador.ListaFiadores;
                                        tempListaFiadores[index].NumeroDocumento = String(event.target.value);
                                        setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                    }}
                                />
                            ) : (
                                <NumberInput
                                    title={"Número de documento"}
                                    placeholder=""
                                    value={data.NumeroDocumento.trim()}
                                    disabled={readonlyForm}
                                    onChange={(event: any) => {
                                        const tempListaFiadores = datosFiador.ListaFiadores;
                                        tempListaFiadores[index].NumeroDocumento = String(event.target.value);
                                        setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                    }}
                                />
                            )}
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <TextInput
                                title={"Nombres"}
                                placeholder=""
                                value={data.Nombres}
                                disabled={readonlyForm}
                                onChange={(event: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].Nombres = String(event.target.value);
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <TextInput
                                title={"Apellido Paterno"}
                                placeholder=""
                                value={data.ApellidoPaterno}
                                disabled={readonlyForm}
                                onChange={(event: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].ApellidoPaterno = String(event.target.value);
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <TextInput
                                title={"Apellido Materno"}
                                placeholder=""
                                value={data.ApellidoMaterno}
                                disabled={readonlyForm}
                                onChange={(event: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].ApellidoMaterno = String(event.target.value);
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                            />
                        </div>

                        <div className="col-lg-2 cardObjets">
                            <DateInput
                                title={"Fecha de Nacimiento"}
                                value={data.FechaNacimiento}
                                disabled={readonlyForm}
                                onChange={(date: any | null) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].FechaNacimiento = date === null ? '' : date;
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                            />
                        </div>

                        <div className="col-lg-2 cardObjets">
                            <SelectInput
                                title={"Género"}
                                value={data.GeneroId}
                                options={listIdentidadesGenero}
                                disabled={readonlyForm}
                                onChange={(event: any, newValue: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].GeneroId = newValue;
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <SelectInput
                                title={"Profesión"}
                                value={data.ProfesionId}
                                disabled={readonlyForm}
                                onChange={(event: any, newValue: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].ProfesionId = newValue;
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                                options={listProfesion}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <SelectInput
                                title={"Nacionalidad"}
                                value={data.NacionalidadId}
                                disabled={readonlyForm}
                                options={listPais}
                                onChange={(event: any, newValue: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].NacionalidadId = newValue;
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <SelectInput
                                title={"Pais de residencia"}
                                value={data.PaisRecidenciaId}
                                disabled={readonlyForm}
                                onChange={(event: any, newValue: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].PaisRecidenciaId = newValue;
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                }}
                                options={listPais}
                            />
                        </div>

                        <div className="col-lg-3 cardObjets">
                            <SelectInput
                                title={"Estado Civil"}
                                value={data.EstadoCivilId}
                                disabled={readonlyForm}
                                onChange={(event: any, newValue: any) => {
                                    const tempListaFiadores = datosFiador.ListaFiadores;
                                    tempListaFiadores[index].EstadoCivilId = newValue;
                                    setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                    //myowasp(`El nuevo valor del estado civil es: ${newValue}`);
                                }}
                                options={listEstadoCivil}
                            />
                        </div>

                        {(appConfigKey.keyCodigoEstadoCivilCasado === Number(data.EstadoCivilId) || 
                          appConfigKey.keyIdEstadoCivilConviviente === Number(data.EstadoCivilId)) && (
                            <div className="row g-2 mb-2 align-items-center">

                                <div className="col-lg-2 cardObjets">
                                    <SelectInput
                                        title={"Tipo de doc. Cónyugue"}
                                        value={data.Conyuge.TipoDocumentoId}
                                        disabled={readonlyForm}
                                        onChange={(event: any, newValue: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.TipoDocumentoId = Number(newValue);
                                            tempListaFiadores[index].Conyuge.NumeroDocumento = appDefaultValues.StringEmpty;
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                        options={listTipoDocumento}
                                    />
                                </div>

                                <div className="col-lg-2 cardObjets">
                                    {data.Conyuge.TipoDocumentoId === appConfigKey.keyCodigoDNI ? (
                                        <NumberInput
                                            title={"Número de documento"}
                                            placeholder=""
                                            longNumber={8}
                                            value={data.Conyuge.NumeroDocumento}
                                            disabled={readonlyForm}
                                            onChange={(event: any) => {
                                                const tempListaFiadores = datosFiador.ListaFiadores;
                                                tempListaFiadores[index].Conyuge.NumeroDocumento = String(event.target.value);
                                                setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                            }}
                                        />
                                    ) : (
                                        <NumberInput
                                            title={"Número de documento"}
                                            placeholder=""
                                            value={data.Conyuge.NumeroDocumento}
                                            disabled={readonlyForm}
                                            onChange={(event: any) => {
                                                const tempListaFiadores = datosFiador.ListaFiadores;
                                                tempListaFiadores[index].Conyuge.NumeroDocumento = String(event.target.value);
                                                setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                            }}
                                        />
                                    )}
                                </div>

                                <div className="col-lg-4 cardObjets">
                                    <TextInput
                                        title={"Nombres"}
                                        placeholder=""
                                        value={data.Conyuge.Nombres}
                                        disabled={readonlyForm}
                                        onChange={(event: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.Nombres = String(event.target.value);
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                                <div className="col-lg-4 cardObjets">
                                    <TextInput
                                        title={"Apellido Paterno"}
                                        placeholder=""
                                        value={data.Conyuge.ApellidoPaterno}
                                        disabled={readonlyForm}
                                        onChange={(event: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.ApellidoPaterno = String(event.target.value);
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                                <div className="col-lg-4 cardObjets">
                                    <TextInput
                                        title={"Apellido Materno"}
                                        placeholder=""
                                        value={data.Conyuge.ApellidoMaterno}
                                        disabled={readonlyForm}
                                        onChange={(event: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.ApellidoMaterno = String(event.target.value);
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                                <div className="col-lg-2 cardObjets">
                                    <DateInput
                                        title={"Fecha de Nacimiento"}
                                        value={data.Conyuge.FechaNacimiento}
                                        disabled={readonlyForm}
                                        onChange={(date: any | null) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.FechaNacimiento = date === null ? '' : date;
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                                <div className="col-lg-2 cardObjets">
                                    <SelectInput
                                        title={"Género"}
                                        value={data.Conyuge.GeneroId}
                                        options={listIdentidadesGenero}
                                        disabled={readonlyForm}
                                        onChange={(event: any, newValue: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.GeneroId = newValue;
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                                <div className="col-lg-4 cardObjets">
                                    <SelectInput
                                        title={"Profesión"}
                                        value={data.Conyuge.ProfesionId}
                                        options={listProfesion}
                                        disabled={readonlyForm}
                                        onChange={(event: any, newValue: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.ProfesionId = newValue;
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                                <div className="col-lg-4 cardObjets">
                                    <SelectInput
                                        title={"Nacionalidad"}
                                        value={data.Conyuge.NacionalidadId}
                                        options={listPais}
                                        disabled={readonlyForm}
                                        onChange={(event: any, newValue: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.NacionalidadId = newValue;
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                                <div className="col-lg-4 cardObjets">
                                    <SelectInput
                                        title={"Pais de residencia"}
                                        value={data.Conyuge.PaisRecidenciaId}
                                        disabled={readonlyForm}
                                        options={listPais}
                                        onChange={(event: any, newValue: any) => {
                                            const tempListaFiadores = datosFiador.ListaFiadores;
                                            tempListaFiadores[index].Conyuge.PaisRecidenciaId = newValue;
                                            setDatosFiador({ ...datosFiador, ListaFiadores: tempListaFiadores });
                                        }}
                                    />
                                </div>

                            </div>
                        )}
                        {index >= 0 && <div className="col-lg-1 cardObjets center-button">
                            <Tooltip title="Eliminar" placement="bottom">
                                <Button
                                    disabled={readonlyForm}
                                    onClick={() => deleteOtrosIngresosTitular(index)}
                                    size="sm"
                                    variant="solid"
                                    style={{
                                        borderRadius: 100,
                                        backgroundColor: '#444444',
                                        width: '30px',
                                        height: '30px'
                                    }}
                                >
                                    <IconButton style={{
                                        backgroundColor: '#444444',
                                        color: 'white'
                                    }}>
                                        <DeleteForeverIcon />
                                    </IconButton>
                                </Button>
                            </Tooltip>
                        </div>}
                    </div>
                ))}

                <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <ButtonAdd
                            disabled={readonlyForm}
                            onClick={handlerBtnOtrosConyugeFiadoresTitular}
                            title={"Otro fiador"} />
                    </div>
                </div>
            </div>
        </>
    );
};

export default FiadoresCard;
