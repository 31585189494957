export class MnUsuarioInsert {
  idenT_PERFIL: number = 0;
  apellidO_MATERNO: string = "";
  apellidO_PATERNO: string = "";
  nombres: string = "";
  iD_TIPO_DOCUMENTO: number = 0;
  nrO_DOCUMENTO: string = "";
  username: string = "";
  emaiL_INTERNO: string = "";
  telefono: string = "";
  telefonO_MOVIL: string = "";
  usU_CREACION: string = "";
  idenT_CARGO: number | null = null;
  descC_CARGO: string | null = null;
  guiD_IDENTIFICADOR: string | null = null;
  iD_DOMINIO: number = 0;
  password: string = "";
  forcE_CHANGE_PASSWORD: boolean = true;
}