import { FormControl, FormLabel, Input } from "@mui/joy";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import './index.scss'
import { useRef, useState } from "react";
import { IconButton } from "@mui/material";

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const SimpleSecretInput = (props: any) => {
    const [showPassword, setShowPassword] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const toggleShowPassword = () => {
        setShowPassword((prev) => {
            const cursorPosition = inputRef.current?.selectionStart || 0;

            const newShowPassword = !prev;

            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.setSelectionRange(cursorPosition, cursorPosition);
                }
            }, 0);

            return newShowPassword;
        });
    };

    const handleSecretChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (props.regex) {
            if (!props.regex.test(value)) {
                e.preventDefault();
                return;
            }
        }

        if (props.onChange) {
            props.onChange(e);
        }
    };

    const inputProps = {
        ref: inputRef,
        ...(props.maxLength && {
            maxLength: props.maxLength,
        }),
    };

    return (
        <FormControl className={props.className}>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={handleSecretChange}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
                type={showPassword ? 'text' : 'password'}
                endDecorator={
                    <IconButton
                        onClick={toggleShowPassword}
                        onMouseDown={(event) => event.preventDefault()}
                        edge="end"
                    >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                }
                slotProps={{
                    input: inputProps,
                }}
            />
        </FormControl>
    );
};