import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Button } from "@mui/joy";
import HistoricoTeaResquestDialog from './financiamientoHistoricoTea';
import MasterManager from "../../../services/origination/wapimaster.service";
import { HistoricoTea } from "../../../models/ManagerProfile/HistoricoTea";
import { useParams } from "react-router-dom";
import { appConfigKey, appSecurityConfiguration } from "../../../../config/Config";
import GeneralHelper from "../../../helpers/GeneralHelper";
import RegimenTablas from "../../1.-shared/1.6.-table/1.6.1.-regimen/RegimenTablas";
import RatioRequestDialog from "./financiamientoRatio";
import { MoneyInput } from "../13.10.-base/components/MoneyInput";
import { ConvertidorTablaHelper } from "../../1.-shared/1.6.-table/1.6.1.-regimen/helpers/ConvertidorTablaHelper";
import { IconButton, Tooltip } from "@mui/material";
import RestorePageIcon from '@mui/icons-material/RestorePage';
import { SelectAutocompleteInput } from "../../1.-shared/1.7.-component/1.7.3.-input/selectAutocompleteInput";

export const MatrizDatosCredito = ({
  capacidadPagoTabla,
  onCapacidadPagoTablaChange,
  perdidaGananciaTabla,
  onPerdidaGananciaTablaChange,
  informacionTercera,
  setInformacionTercera,
  esModoLectura,
  restoreMatriz,
  listaGiroNegocio,
}: any) => {

  const { solicitudId } = useParams();
  var idUserSession = appSecurityConfiguration.SessionUserId;

  const HandleMargen = async (e: any) => {
    if (/^\d*\.?\d{0,4}$/.test(e.target.value)) {
      setInformacionTercera({
        ...informacionTercera,
        estadoGananciaPerdidas: { ...informacionTercera.estadoGananciaPerdidas, margenGiroNegocio: Math.round(e.target.value / 100) },
      });
    }
  }

  const HandleBlurIngresoAdicional = async (e: any) => {
    if (!informacionTercera) return;
    let ingresoAdicional = GeneralHelper.QuitarComasAMiles(e.target.value);
    if (!ingresoAdicional) {
      setInformacionTercera({
        ...informacionTercera,
        datosVentasMensuales: { ...informacionTercera.datosVentasMensuales, ingresosAdicionales: '0' },
      });
    }
  }

  const HandleIngresoAdicional = async (e: any) => {
    e.preventDefault();
    if (!informacionTercera || !capacidadPagoTabla) return;
    let ingresoAdicional = GeneralHelper.QuitarComasAMiles(e.target.value);
    ConvertidorTablaHelper.ModificarParametroTabla(capacidadPagoTabla, 'ingresoAdicional', ingresoAdicional);
    onCapacidadPagoTablaChange?.();

    setInformacionTercera({
      ...informacionTercera,
      datosVentasMensuales: { ...informacionTercera.datosVentasMensuales, ingresosAdicionales: ingresoAdicional },
    });
  }

  const HandleBlurCostoVenta = async (e: any) => {
    if (!informacionTercera) return;
    let costoVenta = GeneralHelper.QuitarPorcentaje(e.target.value);
    if (!costoVenta) {
      setInformacionTercera({
        ...informacionTercera,
        estadoGananciaPerdidas: { ...informacionTercera.estadoGananciaPerdidas, costosVenta: '0' },
      });
    }
  }

  const HandleCostoVenta = async (e: any) => {

    e.preventDefault();
    if (!informacionTercera || !capacidadPagoTabla) return;
    ConvertidorTablaHelper.ModificarParametroTabla(capacidadPagoTabla, 'costoVentaReferencial', (e.target.value / 100));
    onCapacidadPagoTablaChange?.();

    setInformacionTercera({
      ...informacionTercera,
      estadoGananciaPerdidas: { ...informacionTercera.estadoGananciaPerdidas, costoVentaReferencial: (e.target.value / 100) },
    });

  }

  const handleGiroNegocio = (e: any, newValue: any) => {
    e.preventDefault();
    if (!informacionTercera) return;
    
    let margen = 0;
    const giroNegocio = listaGiroNegocio.find((x: any) => x.id == newValue)
    if (giroNegocio && capacidadPagoTabla) {
      margen = giroNegocio.margen;
      ConvertidorTablaHelper.ModificarParametroTabla(capacidadPagoTabla, 'costoVentaReferencial', margen);
      onCapacidadPagoTablaChange?.();
    }
    setInformacionTercera({
      ...informacionTercera,
      estadoGananciaPerdidas: {
        ...informacionTercera.estadoGananciaPerdidas,
        giroNegocioId: newValue,
        costoVentaReferencial: (1 - margen),
        margenGiroNegocio: margen
      },
    });
  }

  const [historicoTea, setHistoricoTea] = useState<HistoricoTea[]>([])
  const [ratios, setRatios] = useState<any[]>([])
  const [esModoLecturaDatosCredito, setEsModoLecturaDatosCredito] = React.useState(false);

  const cargarListaTea = async () => {
    setHistoricoTeaRequestLoading(true);
    try {
      var body = {
        "idenT_SOLICITUD": solicitudId
      };

      setHistoricoTea([]);
      await MasterManager.GetMnSolicitudHistoricoCambiarTasa(body)
        .then((response: any) => {
          let HistoricoTeaListTemp: HistoricoTea[] = [];
          response?.data?.content?.forEach((item: HistoricoTea) => {
            HistoricoTeaListTemp.push(item);
          })
          setHistoricoTea(HistoricoTeaListTemp);
          setHistoricoTeaRequestDialogOpen(true);
        })
    } catch (error) {
    } finally {
      setHistoricoTeaRequestLoading(false);
    }
  }

  const cargarListaRatios = async () => {
    setRatioRequestLoading(true);
    setRatios([]);
    try {
      var body = {
        "v_LIST_IDENT_TABLA": appConfigKey.keyIdRatios
      };
      await MasterManager.GetMnTablaGenericaDefinicion(body)
        .then((response: any) => {
          let ratios: any[] = [];
          response?.data?.content?.forEach((item: any) => {
            ratios.push({ id: item.genericA_VALO_CAMPO, label: item.genericA_DESCM_CAMPO, formula: item.detallE_CAMPO });
          })
          setRatios(ratios);
          setRatioRequestDialogOpen(true);
        });
    } catch (error) {
    } finally {
      setRatioRequestLoading(false);
    }
  }

  const [historicoTeaRequestDialogOpen, setHistoricoTeaRequestDialogOpen] = React.useState<boolean>(false);
  const [historicoTeaRequestLoading, setHistoricoTeaRequestLoading] = React.useState<boolean>(false);
  const [ratioRequestDialogOpen, setRatioRequestDialogOpen] = React.useState<boolean>(false);
  const [ratioRequestLoading, setRatioRequestLoading] = React.useState<boolean>(false);

  const handleHistoricoTeaRequestDialogClose = () => {
    setHistoricoTeaRequestDialogOpen(false);
  };

  const handleHistoricoTeaRequestDialogOpen = () => {
    cargarListaTea();
  };

  const handleRatioRequestDialogClose = () => {
    setRatioRequestDialogOpen(false);
  };

  const handleRatioRequestDialogOpen = () => {
    cargarListaRatios();
  };

  useEffect(() => {
    setEsModoLecturaDatosCredito(!esModoLectura ? false : esModoLectura);
  }, [])

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion defaultExpanded>
          <AccordionSummary>DATOS DEL CREDITO</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                    <Tooltip title="Restaurar valores">
                      <IconButton
                        aria-label="restorepage"
                        size="large" color="error"
                        disabled={esModoLecturaDatosCredito}
                        style={{ position: "absolute", right: "0", top: "0" }}
                        onClick={restoreMatriz}>
                        <RestorePageIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">

                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Valor del Bien</FormLabel>
                      <FormLabel>{GeneralHelper.NumeroConCommas(informacionTercera?.datosCredito?.valorBien)}</FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Cuota Inicial %</FormLabel>
                      <FormLabel>{GeneralHelper.ObtenerPorcentaje(informacionTercera?.datosCredito?.cuotaInicialPorcentaje, false)}%</FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Cuota Inicial</FormLabel>
                      <FormLabel>{GeneralHelper.NumeroConCommas(informacionTercera?.datosCredito?.cuotaInicial)}</FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Financiamiento %</FormLabel>
                      <FormLabel>{GeneralHelper.ObtenerPorcentaje(informacionTercera?.datosCredito?.financiamientoPorcentaje, false)}%</FormLabel>
                    </FormControl>
                  </div>
                </div>

                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Plazo</FormLabel>
                      <FormLabel>{informacionTercera?.datosCredito?.plazo}</FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Financiamiento</FormLabel>
                      <FormLabel>{GeneralHelper.NumeroConCommas(informacionTercera?.datosCredito?.financiamiento)}</FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <div className="row">
                      <div className="col-lg-4 cardObjets">
                        <FormControl>
                          <FormLabel>TEA %</FormLabel>
                          <FormLabel>{GeneralHelper.ObtenerPorcentaje(informacionTercera?.datosCredito?.tea, false)}</FormLabel>

                        </FormControl>
                      </div>
                      <div className="col-lg-8 pe-lg-4 cardObjets">
                        <Button
                          loading={historicoTeaRequestLoading}
                          size="md"
                          color="danger"
                          variant="outlined"
                          sx={{
                            borderRadius: 24,
                            borderColor: "red",
                            fontWeight: 650,
                            width: '100%'
                          }}
                          onClick={handleHistoricoTeaRequestDialogOpen}
                        >
                          Histórico TEA
                        </Button>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Cuota</FormLabel>
                      <FormLabel>{GeneralHelper.NumeroConCommas(informacionTercera?.datosCredito?.cuota)}</FormLabel>
                    </FormControl>
                  </div>
                </div>

              </div>

              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 textLabelHeadboard">
                  <span className="fw-bold">DATOS DE VENTAS MENSUALES</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 textAlignRigth"></div>
              </div>

              <div className="row g-2 mb-2">

                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <MoneyInput
                        startDecorator={""}
                        title={"Costo de Venta %"}
                        value={GeneralHelper.ObtenerPorcentaje(informacionTercera?.estadoGananciaPerdidas?.costoVentaReferencial)}
                        disabled={esModoLecturaDatosCredito}
                        onBlur={HandleBlurCostoVenta}
                        onChange={HandleCostoVenta}
                      />

                    </FormControl>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <MoneyInput
                      startDecorator={"S/"}
                      title={"Ingresos Adicionales"}
                      value={informacionTercera?.datosVentasMensuales?.ingresosAdicionales}
                      disabled={esModoLecturaDatosCredito}
                      onBlur={HandleBlurIngresoAdicional}
                      onChange={HandleIngresoAdicional}
                    />
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Régimen</FormLabel>
                      <FormLabel>{informacionTercera?.datosTitular?.regimen}</FormLabel>
                    </FormControl>
                  </div>
                  {false && (
                    <div className="col-lg-2 cardObjets">
                      <Button
                        loading={ratioRequestLoading}
                        size="md"
                        color="danger"
                        variant="outlined"
                        sx={{
                          borderRadius: 24,
                          borderColor: "red",
                          fontWeight: 650,
                          width: '100%'
                        }}
                        onClick={handleRatioRequestDialogOpen}
                      >
                        Ver ratio
                      </Button>
                    </div>
                  )}
                  {false && (
                    <div className="col-lg-3 cardObjets">
                      <Button
                        size="md"
                        color="danger"
                        variant="outlined"
                        sx={{
                          borderRadius: 24,
                          borderColor: "red",
                          fontWeight: 650,
                          width: '100%'
                        }}
                        onClick={() => { }}
                      >
                        Reevaluar
                      </Button>
                    </div>
                  )}
                </div>

                <div className="row g-2 mb-2 align-items-center">
                  {/* Capacidad de pago */}
                  {capacidadPagoTabla?.length > 0 && (
                    <RegimenTablas
                      cardClassName="col-lg-12 cardObjets align-top"
                      data={capacidadPagoTabla}
                      readOnly={esModoLecturaDatosCredito}
                      onChange={(tableIndex: number, filaIndex: number, colIndex: number, event: any) => {
                        onCapacidadPagoTablaChange?.();
                      }}
                    />
                  )}
                </div>

              </div>

              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 textLabelHeadboard">
                  <span className="fw-bold">ESTADO DE GANANCIA Y PERDIDAS</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="col-lg-4 textAlignRigth"></div>
              </div>

              <div className="row g-2 mb-2 align-items-center">

                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-3 cardObjets">
                    <SelectAutocompleteInput
                      name={"GiroNegocio"}
                      title={"Giro de Negocio"}
                      options={listaGiroNegocio}
                      value={informacionTercera?.estadoGananciaPerdidas?.giroNegocioId}
                      disabled={esModoLecturaDatosCredito}
                      onChange={handleGiroNegocio}
                    />
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Factor de Conversión %</FormLabel>
                      <FormLabel>{GeneralHelper.ObtenerPorcentaje(informacionTercera?.estadoGananciaPerdidas?.factorConversionProducto)}%</FormLabel>
                    </FormControl>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>
                        Margen %<span style={{ color: "red" }}> * </span>
                      </FormLabel>
                      <Input
                        name="porcentajeInicial"
                        placeholder="Ej: 00000000"
                        value={GeneralHelper.ObtenerPorcentaje(informacionTercera?.estadoGananciaPerdidas?.margenGiroNegocio)}
                        disabled={true}
                        onChange={HandleMargen}
                      />
                    </FormControl>
                  </div>
                </div>

                <div className="row g-2 mb-2 align-items-center">
                  {/* Estado de ganancias y perdidas */}
                  {perdidaGananciaTabla?.length > 0 && (
                    <RegimenTablas
                      cardClassName="col-lg-12 cardObjets align-top"
                      data={perdidaGananciaTabla}
                      readOnly={esModoLecturaDatosCredito}
                      onChange={(tableIndex: number, filaIndex: number, colIndex: number, event: any) => {
                        onPerdidaGananciaTablaChange?.();
                      }}
                    />
                  )}
                </div>

              </div>
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>

      <HistoricoTeaResquestDialog
        open={historicoTeaRequestDialogOpen}
        handleClose={handleHistoricoTeaRequestDialogClose}
        historicoTea={historicoTea}
      />

      <RatioRequestDialog
        open={ratioRequestDialogOpen}
        handleClose={handleRatioRequestDialogClose}
        ratios={ratios}
      />
    </div>
  );
};

export default MatrizDatosCredito;
