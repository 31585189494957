export class MnUsuarioEdit {
    idenT_USUARIO: number = 0;
    idenT_PERFIL: number = 0;
    apellidO_MATERNO: string = "";
    apellidO_PATERNO: string = "";
    nombres: string = "";
    iD_TIPO_DOCUMENTO: number = 0;
    nrO_DOCUMENTO: string = "";
    username: string = "";
    bloqueado: boolean | null = null;
    emaiL_INTERNO: string = "";
    telefono: string = "";
    telefonO_MOVIL: string = "";
    USU_MODIF: string = "";
    inD_ESTADO: boolean | null = null;
    idenT_CARGO: number | null = null;
    descC_CARGO: string | null = null;
    guiD_IDENTIFICADOR: string | null = null;
    iD_DOMINIO: number = 0;
    password: string | null = null;
    forcE_CHANGE_PASSWORD: boolean = false;
}