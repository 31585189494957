import TitleCard from "../elements/titleCard";
import { DateInput, NumberInput, SelectAutocompleteInput, SelectInput, TextInput } from "../elements/inputs";
import { useContext, useEffect, useState } from "react";
import { DatosPersonalesDelConyuge, DatosPersonalesDelConyugeDefault } from "./interfaces";
import TimeHelper from "../../../../../helpers/TimeHelper";
import { appConfigKey } from "../../../../../../config/Config";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";
import { TitularContext } from "../../contexts";

const DatosPersonalesDelConyugeCard = () => {

  const { titularEstadoCivilId } = useContext(TitularContext);

  const {
    listTipoDocumento,
    listIdentidadesGenero,
    listMagnitud,
    listActividadEconomica,
    clickNext,
    messageCustomerIsBlackListGesintel,
    readonlyForm
  } = useContext(StepsContext);

  const {
    conyugeData,
    setNewConyugeData,
    laboresAdicionalesConyugeData,
    setNewLaboresAdicionalesConyugeData,
  } = useContext(StepOneContext);

  const [viewModel, setViewModel] = useState<DatosPersonalesDelConyuge>(DatosPersonalesDelConyugeDefault);

  const eventoCargarDatosConyuge = () => {
    if (conyugeData !== 0) {
      setViewModel(conyugeData);
    }
  };

  const eventoContinuar = () => {
    if (clickNext !== 0) {
      setNewConyugeData(viewModel);
    }
  };

  const eventoCargarLaboresAdicionalesConyugeData = () => {
    if (laboresAdicionalesConyugeData !== 0) {
      setNewLaboresAdicionalesConyugeData(laboresAdicionalesConyugeData);
    }
  };

  const eventoTipoDocumentoSelect = (event: any, newValue: any) => {
    setViewModel((prevState: DatosPersonalesDelConyuge) => {
      let nroDocumento = prevState.NumeroDocumento?.trim() ?? '';
      if (newValue === appConfigKey.keyCodigoDNI && nroDocumento.length > 8) {
        nroDocumento = nroDocumento.substring(0, 8)
      }
      return {
        ...prevState,
        TipoDocumentoId: newValue,
        NumeroDocumento: nroDocumento
      }
    });
  }

  useEffect(() => eventoCargarDatosConyuge(), [conyugeData]);
  useEffect(() => eventoCargarLaboresAdicionalesConyugeData(), [laboresAdicionalesConyugeData]);
  useEffect(() => eventoContinuar(), [clickNext]);

  return (
    <>
      <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
        <TitleCard title={`DATOS PERSONALES DEL ${titularEstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? 'CÓNYUGE' : 'CONVIVIENTE' }`} />

        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-2 cardObjets">
            <SelectInput
              title={"Tipo de doc. cónyuge"}
              options={listTipoDocumento}
              value={viewModel.TipoDocumentoId}
              disabled={readonlyForm}
              onChange={eventoTipoDocumentoSelect}
            />
          </div>
          {viewModel.TipoDocumentoId === appConfigKey.keyCodigoDNI ? (
            <div className="col-lg-2 cardObjets">
              <NumberInput
                title={"Número de documento"}
                placeholder="Ej: 00000000"
                longNumber={8}
                value={viewModel.NumeroDocumento}
                disabled={readonlyForm}
                onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumento: event.target.value })}
              />
            </div>
          ) : (
            <div className="col-lg-2 cardObjets">
              <NumberInput
                title={"Número de documento"}
                placeholder="Ej: 00000000"
                value={viewModel.NumeroDocumento}
                disabled={readonlyForm}
                onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumento: event.target.value })}
              />
            </div>
          )}
          <div className="col-lg-4 cardObjets">
            <TextInput
              title={"Nombres"}
              placeholder="Indique los nombres"
              value={viewModel.Nombres}
              disabled={readonlyForm}
              onChange={(event: any) => setViewModel({ ...viewModel, Nombres: event.target.value })}
            />
          </div>
          <div className="col-lg-4 cardObjets">
            <TextInput
              name={"apellidoPaterno"}
              title={"Apellido Paterno"}
              placeholder={"Indique el Apellido Paterno"}
              value={viewModel.ApellidoPaterno}
              disabled={readonlyForm}
              onChange={(event: any) => setViewModel({ ...viewModel, ApellidoPaterno: event.target.value })}
            />
          </div>
          <div className="col-lg-4 cardObjets">
            <TextInput
              name={"apellidoMaterno"}
              title={"Apellido Materno"}
              placeholder="Indique el Apellido Materno"
              value={viewModel.ApellidoMaterno}
              disabled={readonlyForm}
              onChange={(event: any) => setViewModel({ ...viewModel, ApellidoMaterno: event.target.value })}
            />
          </div>
          <div className="col-lg-2 cardObjets">
            <DateInput
              title={"Fecha de Nacimiento"}
              value={viewModel.FechaNacimiento}
              disabled={readonlyForm}
              onChange={(date: any | null) => setViewModel({ ...viewModel, FechaNacimiento: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
            />
          </div>
          <div className="col-lg-2 cardObjets">
            <SelectInput
              title={"Género"}
              options={listIdentidadesGenero}
              value={viewModel.GeneroId?.toString().trim() === '' ? 0 : viewModel.GeneroId}
              disabled={readonlyForm}
              onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, GeneroId: newValue })}
            />
          </div>
          <div className="col-lg-4 cardObjets">
            <SelectInput
              title={"Magnitud"}
              options={listMagnitud}
              value={viewModel.MagnitudId}
              disabled={readonlyForm}
              onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, MagnitudId: newValue })}
            />
          </div>
          <div className="col-lg-4 cardObjets">
            <SelectAutocompleteInput
              title={"Actividad económica"}
              options={listActividadEconomica}
              value={viewModel.ActividadEconomicaId}
              disabled={readonlyForm}
              onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, ActividadEconomicaId: newValue })}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DatosPersonalesDelConyugeCard;
