import React, { useMemo } from 'react';
import "./index.scss";

interface TextWrapButtonProps {
    text?: string;
    limit?: number;
    onClick?: () => void;
}

const TextWrapButton = ({ text, limit = 100, onClick }: TextWrapButtonProps) => {

    const { croppedText, exceedsLimit } = useMemo(() => {
        if (!limit || !text || text.trim() === '') {
            return { croppedText: text, exceedsLimit: false };
        }
        const exceedsLimit = text.length > limit;
        const croppedText = exceedsLimit ? `${text.substring(0, limit)}... ` : text;
        return { croppedText, exceedsLimit };
    }, [text, limit]);

    if (!text || text.trim() === '') {
        return null;
    }

    return (
        <>
            {croppedText}
            {exceedsLimit && (
                <span className="text-show-more" onClick={onClick}>
                    Ver más
                </span>
            )}
        </>
    );
};

export default TextWrapButton;
