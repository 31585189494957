import { IconButton, Table } from "@mui/joy";
import { Button } from '@mui/joy';
import DownloadIcon from '@mui/icons-material/Download';
import React from "react";
import DocumentsDialogAdd from "../9.10.-base/components/DocumentsDialogAdd";
import DocumentationAddDescription from "../9.10.-base/components/DocumentationAddDescription";
import { ResultadoRevision } from "./9.60.1.-resultadoRevision/resultadoRevision";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import MasterManager from "../../../services/origination/wapimaster.service";
import { useParams } from "react-router-dom";
import IconAproved from '../../../../assets/media/icons/ico-aproved.svg';
import icoDenided from '../../../../assets/media/icons/ico-denided.svg';
import { appConfigDocumentoFechaVencimientos, appConfigKey, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { toast } from "sonner";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../../4.-trayOfRequests/4.30.-CustomDivToogle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import CircularProgress from "@mui/material/CircularProgress";
import GeneralHelper from "../../../helpers/GeneralHelper";
import DocumentManager from "../../../services/business/document.service";
import UploadHelper from "../../../helpers/UploadHelper";

export const RevisionEvaluacion = (props: any) => {
  const [revisionEvaluacion, setRevisionEvaluacion] = React.useState<any>([]);;
  const { solicitudId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [totalDocumentos, setTotalDocumentos] = React.useState<any>([]);
  const [solicitud, setSolicitud] = React.useState<any>();
  const [btnAgregar, setBtnAgregar] = React.useState(false);
  var SessionPerfil = appSecurityConfiguration.SessionPerfil;
  const hiddenFileInput = React.useRef<HTMLInputElement>(document.createElement("input"));
  const [loading, setLoading] = React.useState(false);
  const [userSession, setUserSession] = React.useState(localStorage.getItem('UserMn'));
  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [openDialogAddDescription, setOpenDialogAddDescription] = React.useState(false);
  const [selectedList2, setSelectedList2] = React.useState<any>([]);

  function renderStatus(status: number) {
    let position = 0;
    const listStatus = [appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
    (<> {appConfigKey.keyDescripEstadoDocumentoAdjuntado} <img src={IconAproved} /></>),
    (<> {appConfigKey.keyDescripEstadoDocumentoConforme} <img src={IconAproved} /></>),
    (<> {appConfigKey.keyDescripEstadoDocumentoObservado} <img src={icoDenided} /></>)];

    switch (status) {
      case appConfigKey.keyIdEstadoDocumentoPorAdjuntar:
        position = 0;
        break;
      case appConfigKey.keyIdEstadoDocumentoAdjuntado:
        position = 1;
        break;
      case appConfigKey.keyIdEstadoDocumentoConforme:
        position = 2;
        break;
      case appConfigKey.keyIdEstadoDocumentoObservado:
        position = 3;
        break;
    }

    return listStatus[position];
  }
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  function addStyleStatus(status: number) {
    let style = '';
    switch (status) {
      case appConfigKey.keyIdEstadoDocumentoAdjuntado:
        style = 'color-aproved';
        break;
      case appConfigKey.keyIdEstadoDocumentoConforme:
        style = 'color-aproved';
        break;
      case appConfigKey.keyIdEstadoDocumentoObservado:
        style = 'color-denided';
        break;
      default: style = '';
        break;
    }
    return style;
  }

  const handleChangeSwitch = async (selecteds: any) => {
    for (var i = 0; i < selecteds.length; i++) {

      const body = {
        "idenT_DOCUMENTACION_DOCUMENTO": 0,
        "idenT_SOLICITUD": Number(solicitudId),
        "idenT_TIPO_LISTADO": 2,
        "descC_TIPO_LISTADO": "CHECKLIST2",
        "idenT_TIPO_DOCUMENTO": selecteds[i].codigo,
        "descC_TIPO_DOCUMENTO": selecteds[i].descripcion,
        "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoPorAdjuntar,
        "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
        "descC_NOMBRE_ARCHIVO": null,
        "descC_EXTENSION_ARCHIVO": null,
        "idenT_DOCUMENTO_BT": 0,
        "idenT_USUARIO": Number(localStorage.getItem('UserMn') ?? 0)
      };
      await MasterManager.GetMnDocumentacionDocumentoInsert(body);
    }
    obtenerDocumentos();
    handleDialogClose();
  };

  const obtenerDocumentos = () => {
    MasterManager.GetMnDocumentacionDocumento({
      "idenT_DOCUMENTACION_DOCUMENTO": 0,
      "idenT_SOLICITUD": Number(solicitudId)
    }).then(response => {
      const { content } = response.data;

      const checklist2 = content.filter((x: any) => x.descC_TIPO_LISTADO == "CHECKLIST2");
      setRevisionEvaluacion(checklist2);
      //myowasp(revisionEvaluacion);
    });
  };

  const AddDialogOpen = (event: any) => {
    setOpen(true);
  }

  const handleDialogClose = () => {
    setOpen(false);
  };

  //JRM
  const handleDialogAddDescriptionOpen = (list: any[]) => {
    setSelectedList2(list);
    setOpenDialogAddDescription(true);
  }
  const handleDialogAddDescriptionClose = () => {
    setOpenDialogAddDescription(false);
  };
  const handleAgregarTxt = async (description: string) => {
    const index = selectedList2.findIndex((x: any) => x.codigo === 39);
    const newItems = [...selectedList2];
    var dscp = description === null ? "" : " - " + description;
        newItems[index].descripcion = "Miscelaneos" + dscp;
    setOpenDialogAddDescription(false);
    await handleChangeSwitch(selectedList2);
    setSelectedList2([]);
    obtenerTodosDocumentosPorTipo(revisionEvaluacion);
  }
  //JRM

  const obtenerTodosDocumentosPorTipo = (dataRevisionEvaluacion: any) => {
    ServicesProvider.ObtenerDocumentosPorTipo({
      "filtro": "T",
      "estado": 0
    }).then((response: any) => {
      const content = response.data.content;
      const tipoDocumentoDigital = content?.sBTTipoDocumentoDigital;
      const listDocumentosDigitales = tipoDocumentoDigital.sBTTipoDocumentoDigital;
      const finalTotal: any = [];
      listDocumentosDigitales.map((item: any) => {
        //const finder = dataRevisionEvaluacion.find((x: any) => x.idenT_TIPO_DOCUMENTO !== item.codigo);

        //if (finder === undefined) {
        finalTotal.push(item);
        //}
      });

      if (finalTotal.length > 0) {
        finalTotal.sort((a: any, b: any) => a.descripcion.localeCompare(b.descripcion));
      }
      setTotalDocumentos(finalTotal);
    });
  };

  const handleDownloadFile = async (event: any, data: any) => {
    event.preventDefault();
    try {
      setLoading(true);
      DocumentManager.DescargarDocumentoBT(data.idenT_DOCUMENTACION_DOCUMENTO, Number(solicitudId))
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      toast.error(`${error.message ?? error}`, { duration: appConfigKey.keyDurationToast });
    }
  };

  const eliminarDocumentoOrigination = async (data: any) => {
    let status = false;

    const bodyMaster = {
      "idenT_DOCUMENTACION_DOCUMENTO": data.idenT_DOCUMENTACION_DOCUMENTO
    };
    let result = await MasterManager.GetMnDocumentacionDocumentoDeleteById(bodyMaster);

    if (result?.status !== 200) {
      return status;
    }

    if (!result?.data.isValid) {
      return status;
    }

    return true;
  }

  const eliminarDocumentoBantotal = async (data: any) => {
    let status = false;

    let result = await ServicesProvider.EliminarDocumentoDigital(solicitud?.idenT_OPERACION, data?.idenT_DOCUMENTO_BT);

    if (result?.status !== 200) {
      return status;
    }

    if (!result?.data.isValid) {
      return status;
    }

    return true;
  }

  const handleDeleteFile = async (event: any, data: any) => {
    event.preventDefault();
    try {

      if (data?.idenT_ESTADO_ADJUNTO === appConfigKey.keyIdEstadoDocumentoPorAdjuntar) {

        let statusOrigination = await eliminarDocumentoOrigination(data);

        if (!statusOrigination) {
          toast.error(`Falló la eliminación del documento "${data?.descC_TIPO_DOCUMENTO}"`, { duration: appConfigKey.keyDurationToast });
          return;
        }

      } else {

        if (!solicitud?.idenT_OPERACION) {
          toast.error('La solicitud no se encuentra en Bantotal', { duration: appConfigKey.keyDurationToast });
          return;
        }

        if (!data?.idenT_DOCUMENTO_BT) {
          toast.error(`El documento "${data?.descC_TIPO_DOCUMENTO}" no se encuentra en Bantotal`, { duration: appConfigKey.keyDurationToast });
          return;
        }

        let statusBanTotal = await eliminarDocumentoBantotal(data);

        if (!statusBanTotal) {
          toast.error(`Falló la eliminación del documento "${data?.descC_TIPO_DOCUMENTO}" en Bantotal`, { duration: appConfigKey.keyDurationToast });
          return;
        }

        let statusOrigination = await eliminarDocumentoOrigination(data);

        if (!statusOrigination) {
          toast.error(`Falló la eliminación del documento "${data?.descC_TIPO_DOCUMENTO}"`, { duration: appConfigKey.keyDurationToast });
          return;
        }

      }

      toast.success(`Se eliminó correctamente el documento "${data?.descC_TIPO_DOCUMENTO}"`, { duration: appConfigKey.keyDurationToast });

      obtenerDocumentos();
    } catch (error) {

    }
  };

  const obtenerSolicitud = () => {

    MasterManager.GetMnSolicitud({ idenT_SOLICITUD: solicitudId }).then((response: any) => {
      const tempSolicitud = response.data.content[0];
      setSolicitud(tempSolicitud);
    });
  }

  const GetMnEstadoSolicitud = async () => {
    if (SessionPerfil === 15) {
      setBtnAgregar(true)
    }
    else {
      let resultado;
      var body = {
        idenT_SOLICITUD: solicitudId,
      };
      const response = await RequestManager.GetMnSolicitudEstado(body);
      if (response.status === 200) {
        resultado = response.data.content;
        if (resultado.iD_ESTADO_SOLICITUD === 86 || resultado.iD_ESTADO_SOLICITUD === 92 || 
            resultado.iD_ESTADO_SOLICITUD === 49 || resultado.iD_ESTADO_SOLICITUD === 88) {
          setBtnAgregar(true);
        }
        else { setBtnAgregar(false); }
      };
    }
  }

  const handleChange = async (event: any, data: any) => {
    event.preventDefault();
    setLoading(true);

    const fileUploaded = event.target.files[0];
    await handleFile(fileUploaded, data);

    if (hiddenFileInput.current) {
      hiddenFileInput.current.value = '';
    }

    setLoading(false);
  };

  const handleFile = async (fileUploaded: any, data: any) => {
    try {
      await UploadHelper.uploadFile(fileUploaded, data, Number(solicitudId));
      obtenerDocumentos();
    } catch (error: any) {
        toast.error(`${error?.message ?? error}`, { duration: appConfigKey.keyDurationToast });
    }
  };

  React.useEffect(() => {
    obtenerTodosDocumentosPorTipo(revisionEvaluacion);
    obtenerSolicitud();
    obtenerDocumentos();
    GetMnEstadoSolicitud();
  }, []);

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">DOCUMENTOS</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2">
        <div className="col-lg-12 tableContainer" style={{
          height: revisionEvaluacion.length == 0 ? "5rem" :
            revisionEvaluacion.length < 5 ? "15rem" :
              revisionEvaluacion.length < 10 ? "25rem" : "36rem"
        }}>
          <Table borderAxis="none" className="style-table" stripe={'odd'}
            sx={{
              "--TableCell-headBackground": "transparent",
              "--TableCell-selectedBackground": (theme) =>
                theme.vars.palette.primary.softBg,
              "& thead th:nth-child(1)": { width: "10%" },
              "& thead th:nth-child(2)": { width: "8%" },
              "& thead th:nth-child(3)": { width: "8%" },
              "& thead th:nth-child(4)": { width: "2%" },
              "& tr > *:nth-child(n+3)": {},
            }}>
            <thead>
              <tr>
                <th style={{ backgroundColor: "#E1E1E1" }}>DOCUMENTO</th>
                <th style={{ backgroundColor: "#E1E1E1" }}>ESTADO</th>
                <th style={{ backgroundColor: "#E1E1E1" }}>NOMBRE ARCHIVO</th>
                <th style={{ backgroundColor: "#E1E1E1" }}>ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              {
                revisionEvaluacion.map((row: any, index : number) => {
                  return (
                    <tr key={row.index}>
                      <td>{row.descC_TIPO_DOCUMENTO}</td>
                      <td><div className={addStyleStatus(row.idenT_ESTADO_ADJUNTO)}>{renderStatus(row.idenT_ESTADO_ADJUNTO)}</div></td>
                      <td style={{ overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        <div
                          className={row.descC_NOMBRE_ARCHIVO !== '-' ? 'color-file-link' : ''}
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => handleDownloadFile(e, row)}
                        >{row.descC_NOMBRE_ARCHIVO}</div>
                      </td>
                      <td>
                        <>
                          {!loading ? (
                            <Dropdown key={row.solicitud}>
                              <Dropdown.Toggle
                                as={CustomDivToggle}
                                style={{ cursor: "pointer" }}
                              >
                                <BsThreeDots />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <>
                                  <Dropdown.Item disabled={(SessionPerfil == 15 && row.idenT_TIPO_DOCUMENTO == 74) ? true : (SessionPerfil == 4 && (row.idenT_TIPO_DOCUMENTO == 65)) ? true : false} onClick={handleClick}>Adjuntar</Dropdown.Item>
                                  <input type="file" ref={hiddenFileInput} onChange={(event: any) => handleChange(event, row)} style={{ display: 'none' }} />
                                </>
                                <Dropdown.Item
                                  onClick={(e) =>
                                    handleDeleteFile(e, row)
                                  } disabled={(SessionPerfil == 15 && row.idenT_TIPO_DOCUMENTO == 74) ? true : 
                                              (SessionPerfil == 4 && ((userSession != row.idenT_USUARIO_CREADOR) && (row.idenT_ESTADO_ADJUNTO == 98 || row.idenT_TIPO_DOCUMENTO == 65))) ? true : 
                                              false}>
                                  Borrar
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>)
                            : (
                              <div className="col text-center icon-document-link">
                                <CircularProgress size={12} />
                              </div>

                            )}
                        </>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
      <div className="row g-2 mb-2 align-items-center ">
        <div className="col-lg-6 cardObjets">
        </div>

        <div className="col-lg-6 cardObjets bottom-right">
          <Button size="md" color="danger" startDecorator={<DownloadIcon />} style={{ marginRight: "35px" }}
            sx={{ borderRadius: 24, width: 3 / 10 }} onClick={AddDialogOpen} disabled={btnAgregar}>
            Agregar Nuevo
          </Button>
        </div>
      </div>
      <DocumentsDialogAdd
        open={open}
        handleClose={handleDialogClose}
        tableData={totalDocumentos}
        handleChangeSwitch={handleChangeSwitch}
        handleOpenSecondModal={handleDialogAddDescriptionOpen}
      />
       <DocumentationAddDescription
          open={openDialogAddDescription}
          handleClose={handleDialogAddDescriptionClose}
          handleAgregar={handleAgregarTxt}
          // tableData={totalDocumentos}
          // handleChangeSwitch={handleChangeSwitch}
      />
    </div>
  );
};

export default RevisionEvaluacion;