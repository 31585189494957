export class TMnDocumentacionDocumentoInsertRequest {
    idenT_DOCUMENTACION_DOCUMENTO: number = 0;
    idenT_SOLICITUD: number = 0;
    idenT_TIPO_LISTADO: number = 0;
    descC_TIPO_LISTADO: string = "";
    idenT_TIPO_DOCUMENTO: number = 0;
    descC_TIPO_DOCUMENTO: string = "";
    idenT_ESTADO_ADJUNTO: number = 0;
    descC_ESTADO_ADJUNTO: string = "";
    descC_NOMBRE_ARCHIVO: string | null = null;
    descC_EXTENSION_ARCHIVO: string | null = null;
    idenT_DOCUMENTO_BT: number = 0;
    idenT_USUARIO: number = 0;
}

export class TMnDocumentacionDocumentoRequest {
    idenT_DOCUMENTACION_DOCUMENTO: number = 0;
    idenT_SOLICITUD: number = 0;
}