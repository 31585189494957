import { useContext, useEffect, useState } from "react";
import { DatosDetailsStepsOneCard, DatosDetailsStepsOneCardDefault } from "./interfaces";
import { StepsContext } from "../../providers";
import { appConfigKey } from "../../../../../../config/Config";
import { ConvertidorHelper } from "../../../3.40.-SolicitudeStepOne/helpers/ConvertidorHelper";
import StepOneHelper from "../../../3.40.-SolicitudeStepOne/helpers/StepOneHelper";
import GeneralHelper from "../../../../../helpers/GeneralHelper";

const DetailsStepsOneCard = () => {

  const {
    step,
    viewModelStepOneFinal,
    viewModelStepTwoFinal,
    responseStepOne,
    responseStepTwo,
    responseStepThree,
    productoSeleccionado,
    setMemValor,
    setCMAValor,
    solicitudData,
    clickOnObtenerTEA,
    readonlyForm,
    isTitularJuridico,
  } = useContext(StepsContext);

  const [viewModel, setViewModel] = useState<DatosDetailsStepsOneCard>(DatosDetailsStepsOneCardDefault);

  useEffect(() => {
    //if (readonlyForm) {

    let modelDatosDetailsStepsOneCard: DatosDetailsStepsOneCard = DatosDetailsStepsOneCardDefault;

    if (step > 2) {

      const helper = new StepOneHelper();

      let ingresosTitular: any = helper.obtenerIngresos(viewModelStepOneFinal.newIngresosTitularData, Number(viewModelStepTwoFinal.TipoCambio));

      var validacionPareja = viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
        viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente;

      if (validacionPareja) {

        const ingresosConyuge: any = helper.obtenerIngresos(viewModelStepOneFinal.newIngresosConyugeData, Number(viewModelStepTwoFinal.TipoCambio));

        if (viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {
          ingresosTitular = ingresosTitular + ingresosConyuge;
        }

      }

      ingresosTitular = GeneralHelper.AgregarComasAMiles(ingresosTitular.toFixed(2));

      modelDatosDetailsStepsOneCard.IngresosNetosTitular = helper.agregarDivisa(ingresosTitular, appConfigKey.keyIdentificadorSoles);

    }

    setViewModel({
      ...viewModel,
      IngresosNetosTitular: modelDatosDetailsStepsOneCard.IngresosNetosTitular,
    });

    //}

  }, [step]);

  useEffect(() => {

    let modelDatosDetailsStepsOneCard: DatosDetailsStepsOneCard = DatosDetailsStepsOneCardDefault;

    if (viewModelStepOneFinal !== null) {
      modelDatosDetailsStepsOneCard = ConvertidorHelper.ObtenerDetailsStepsOneCard(viewModelStepOneFinal);
    }

    if (step == 2 && responseStepOne !== null) {
      if (solicitudData.idenT_PRODUCTO && responseStepTwo) {
        modelDatosDetailsStepsOneCard = ConvertidorHelper.ObtenerDetailsMenStepsThreeCardConModellica(modelDatosDetailsStepsOneCard, solicitudData.idenT_PRODUCTO, responseStepTwo);
      } else {
        modelDatosDetailsStepsOneCard = ConvertidorHelper.ObtenerDetailsStepsOneCardConModellica(modelDatosDetailsStepsOneCard, responseStepOne);
      }
    }

    if (step == 3 && responseStepTwo !== null && clickOnObtenerTEA === false) {
      modelDatosDetailsStepsOneCard = ConvertidorHelper.ObtenerDetailsMenStepsThreeCardConModellica(modelDatosDetailsStepsOneCard, solicitudData.idenT_PRODUCTO, responseStepTwo);
      //modelDatosDetailsStepsOneCard = ConvertidorHelper.ObtenerDetailsStepsOneCardConModellica(modelDatosDetailsStepsOneCard, responseStepTwo);
    }

    if (step == 4 && responseStepThree !== null) {
      modelDatosDetailsStepsOneCard = ConvertidorHelper.ObtenerDetailsStepsOneCardConModellica(modelDatosDetailsStepsOneCard, responseStepThree);
    }

    if (step > 2) {

      const helper = new StepOneHelper();

      let ingresosTitular: any = helper.obtenerIngresos(viewModelStepOneFinal.newIngresosTitularData, Number(viewModelStepTwoFinal.TipoCambio));

      var validacionPareja = viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
        viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente;

      if (validacionPareja) {

        const ingresosConyuge: any = helper.obtenerIngresos(viewModelStepOneFinal.newIngresosConyugeData, Number(viewModelStepTwoFinal.TipoCambio));

        if (viewModelStepOneFinal.newIngresosConyugeData.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) {
          ingresosTitular = ingresosTitular + ingresosConyuge;
        }

      }

      ingresosTitular = GeneralHelper.AgregarComasAMiles(ingresosTitular.toFixed(2));

      modelDatosDetailsStepsOneCard.IngresosNetosTitular = helper.agregarDivisa(ingresosTitular, appConfigKey.keyIdentificadorSoles);

    }

    if (readonlyForm) {
      modelDatosDetailsStepsOneCard.MenAPorcentaje = solicitudData.cfm;

      let tempMEM = solicitudData.mem;
      tempMEM = GeneralHelper.AgregarComasAMiles(tempMEM.toFixed(2));
      modelDatosDetailsStepsOneCard.MenAValor = `S/ ${tempMEM}`;

    }

    setMemValor(GeneralHelper.ObtenerMontoNumerico(modelDatosDetailsStepsOneCard.MenAValor));
    setCMAValor(GeneralHelper.ParseString(modelDatosDetailsStepsOneCard.CMA));

    setViewModel({
      ...viewModel,
      EstadoCivilId: modelDatosDetailsStepsOneCard.EstadoCivilId,
      Titular: modelDatosDetailsStepsOneCard.Titular,
      Conyuge: modelDatosDetailsStepsOneCard.Conyuge,
      IngresosNetosTitular: modelDatosDetailsStepsOneCard.IngresosNetosTitular,
      IngresosNetosConyuge: modelDatosDetailsStepsOneCard.IngresosNetosConyuge,
      MenAValor: modelDatosDetailsStepsOneCard.MenAValor,
      MenBValor: modelDatosDetailsStepsOneCard.MenBValor,
      MenAPorcentaje: modelDatosDetailsStepsOneCard.MenAPorcentaje,
      MenBPorcentaje: modelDatosDetailsStepsOneCard.MenBPorcentaje
    });

  }, [viewModelStepOneFinal, responseStepOne, responseStepTwo, responseStepThree, clickOnObtenerTEA]);


  useEffect(() => {

    let modelDatosDetailsStepsOneCard: DatosDetailsStepsOneCard = DatosDetailsStepsOneCardDefault;

    if (step == 3 && responseStepTwo !== null && productoSeleccionado !== 0) {
      modelDatosDetailsStepsOneCard = ConvertidorHelper.ObtenerDetailsMenStepsThreeCardConModellica(modelDatosDetailsStepsOneCard, productoSeleccionado, responseStepTwo);

      setMemValor(GeneralHelper.ObtenerMontoNumerico(modelDatosDetailsStepsOneCard.MenAValor));

      setViewModel({
        ...viewModel,
        MenAValor: modelDatosDetailsStepsOneCard.MenAValor,
        MenAPorcentaje: modelDatosDetailsStepsOneCard.MenAPorcentaje,
      })

    }

  }, [productoSeleccionado]);

  return (
    <>
      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 mb-2 align-items-center item-body-details">

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Titular</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Titular}</span>
                </div>
              </div>
            </div>
            {step > 2 ? (
              <div className="row g-2 mb-2">
                <div className="col-lg-6 col-md-6">
                  <span>Ingresos Netos</span>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="text-long">
                    <span className="text-negrita">{viewModel.IngresosNetosTitular}</span>
                  </div>
                </div>
              </div>
            ) :
              <div className="row g-2 mb-2">
                <div className="col-lg-6 col-md-6">
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="text-long">
                    <span className="text-negrita">&nbsp;</span>
                  </div>
                </div>
              </div>
            }
          </div>

          {viewModel.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado &&
            <div className="col-lg-3 col-md-12">
              <div className="row g-2 mb-2">
                <div className="col-lg-6 col-md-6">
                  <span>Cónyuge</span>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="text-long">
                    <span className="text-negrita">{viewModel.Conyuge}</span>
                  </div>
                </div>
              </div>
              <div className="row g-2 mb-2">
                <div className="col-lg-6 col-md-6">
                  <span></span>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="text-long">
                    <span className="text-negrita">&nbsp;</span>
                  </div>
                </div>
              </div>
            </div>
          }

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>CMA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{!isTitularJuridico ? viewModel.CMA : '-'}</span>
                </div>
              </div>
            </div>

            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span></span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">&nbsp;</span>
                </div>
              </div>
            </div>
          </div>

          {step >= 3 &&
            <div className="col-lg-3 col-md-12">
              <div className="row g-2 mb-2">
                <div className="col-lg-6 col-md-6">
                  <span>CFM {viewModel.MenAPorcentaje}</span>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="text-long">
                    <span className="text-negrita">{!isTitularJuridico ? viewModel.MenAValor ? `MEM ${viewModel.MenAValor}` : '' : '-'}</span>
                  </div>
                </div>
              </div>

              <div className="row g-2 mb-2">
                <div className="col-lg-6 col-md-6">
                  <span></span>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="text-long">
                    <span className="text-negrita">&nbsp;</span>
                  </div>
                </div>
              </div>
            </div>
          }

        </div>
      </div>

    </>
  );
};

export default DetailsStepsOneCard;
