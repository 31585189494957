import FileHelper from "../../helpers/FileHelper";
import GeneralHelper from "../../helpers/GeneralHelper";
import { TMnDocumentacionDocumentoInsertRequest } from "../../models/MasterManager/documentacion-documento-request.model";
import { TMnDocumentacionDocumentoAuditDto } from "../../models/MasterManager/documentacion-documento-response.model";
import { BTActualizarDocumentoDigitalRequest, BTAsociarDocumentoDigitalRequest } from "../../models/ServicesProviderManager/documento-digital-request.model";
import { DocumentoDigitalItem } from "../../models/ServicesProviderManager/documento-digital-response.model";
import { AppException } from "../../models/Shared/app-exception";
import MasterManager from "../origination/wapimaster.service";
import ServicesProvider from "../support/wapiservicesprovider";

const DocumentManager = {

  /**
   * Obtiene el operacionUId de una solicitud
   * @param solicitudId "idenT_SOLICITUD"
   * @returns 
   */
  ObtenerOperacionUId: async function (solicitudId: number) {
    let solicitud;
    try {
      const response = await MasterManager.GetMnSolicitud({
        IDENT_SOLICITUD: solicitudId,
      });

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, `Error en la verificación de operación UID: Solicitud ${solicitudId} no encontrada.`);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      if (!response?.data?.content || response?.data?.content?.length <= 0) {
        throw new AppException(`Error en la verificación de operación UID: Solicitud ${solicitudId} no encontrada.`);
      }

      solicitud = response.data.content[0];
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, `Error en la verificación de operación UID: ${error?.message ?? error}`);
      throw new AppException(errorMsg);
    }

    let operacionUId = solicitud?.idenT_OPERACION;

    if (!operacionUId) {
      throw new AppException(`Error en la verificación de operación UID: Solicitud ${solicitudId} no cuenta con operación UID válida.`);
    }

    return Number(operacionUId);
  },

  /**
   * Obtiene el operacionUId y el documentoBT actual de los documentos
   * @param santiDocumentoId "idenT_DOCUMENTACION_DOCUMENTO"
   * @param solicitudId "idenT_SOLICITUD"
   * @returns 
   */
  ObtenerDocumentoWithOperacionUId: async function (santiDocumentoId: number, solicitudId: number) {
    try {
      const response = await MasterManager.GetMnDocumentacionDocumento({
        idenT_DOCUMENTACION_DOCUMENTO: santiDocumentoId,
        idenT_SOLICITUD: solicitudId,
      });

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, `Error en la verificación de operación UID: Documento no encontrado en la solicitud ${solicitudId}.`);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      if (!response?.data?.content || response?.data?.content?.length <= 0) {
        throw new AppException(`Error en la verificación de operación UID: Documento no encontrado en la solicitud ${solicitudId}.`);
      }

      return response.data.content
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, `Error en la verificación de operación UID: ${error?.message ?? error}`);
      throw new AppException(errorMsg);
    }
  },

  /**
   * Obtiene el operacionUId y el documentoBT actual de un documento
   * @param santiDocumentoId "idenT_DOCUMENTACION_DOCUMENTO"
   * @param solicitudId "idenT_SOLICITUD"
   * @returns 
   */
  ObtenerPrimerDocumentoWithOperacionUId: async function (santiDocumentoId: number, solicitudId: number) {
    if (santiDocumentoId == 0 || solicitudId == 0) {
      throw new AppException(`Error en la verificación de operación UID: El valor "santiDocumentoId" o "solicitudId" no puede ser 0.`);
    }

    const santiDocumentos = await DocumentManager.ObtenerDocumentoWithOperacionUId(santiDocumentoId, solicitudId);

    if (santiDocumentos.length <= 0) {
      throw new AppException(`Error en la verificación de operación UID: Documento no encontrado en la solicitud ${solicitudId}.`);
    }

    return santiDocumentos[0];
  },

  CargarDocumentoBTSanti: async function (dataBT: BTAsociarDocumentoDigitalRequest, dataSanti: TMnDocumentacionDocumentoInsertRequest, solicitudId: number) {

    let result = await DocumentManager.CargarDocumentoBT(dataBT, solicitudId)

    dataSanti.idenT_SOLICITUD = solicitudId;
    dataSanti.idenT_DOCUMENTO_BT = result.documentoId;

    let santiDocumentos: TMnDocumentacionDocumentoAuditDto[] = [];

    if (dataSanti.idenT_DOCUMENTACION_DOCUMENTO > 0) {
      santiDocumentos = await DocumentManager.ActualizarDocumentoSanti(dataSanti)
    } else {
      santiDocumentos = await DocumentManager.CargarDocumentoSanti(dataSanti)
    }

    return {
      ...result,
      santiDocumentos
    }
  },

  CargarDocumentoSanti: async function (data: TMnDocumentacionDocumentoInsertRequest): Promise<TMnDocumentacionDocumentoAuditDto[]> {

    try {

      const response = await MasterManager.GetMnDocumentacionDocumentoInsert(data);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, `Ocurrió un error al asociar el documento a Santi.`);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      if (!response?.data?.content || response?.data?.content?.length <= 0) {
        throw new AppException(`Ocurrió un error al asociar el documento a Santi: No se registró documento.`);
      }

      return response.data.content;
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, `Ocurrió un error al asociar el documento a Santi: ${error?.message ?? error}`);
      throw new AppException(errorMsg);
    }

  },

  CargarDocumentoBT: async function (data: BTAsociarDocumentoDigitalRequest, solicitudId: number) {

    let operacionUId = await DocumentManager.ObtenerOperacionUId(solicitudId);

    try {
      const response = await ServicesProvider.AsociarDocumentoDigital(data, operacionUId);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, `Ocurrió un error al asociar el documento a Bantotal.`);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      if (!response?.data?.content?.documentoId) {
        throw new AppException(`Ocurrió un error al asociar el documento a Bantotal: No se obtuvo el documentoId.`);
      }

      return {
        operacionUId,
        documentoId: response.data.content.documentoId
      };
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, `Ocurrió un error al asociar el documento a Bantotal: ${error?.message ?? error}`);
      throw new AppException(errorMsg);
    }

  },

  ActualizarDocumentoBTSanti: async function (dataBT: BTActualizarDocumentoDigitalRequest, dataSanti: TMnDocumentacionDocumentoInsertRequest, solicitudId: number) {

    if (!dataSanti.idenT_DOCUMENTACION_DOCUMENTO) {
      throw new AppException(`Ocurrió un error al actualizar el documento: No se encontró el id documento. Por favor, comuniquese con el administrador.`);
    }

    let santiDocumentoId = dataSanti.idenT_DOCUMENTACION_DOCUMENTO;
    let result = await DocumentManager.ActualizarDocumentoBT(dataBT, santiDocumentoId, solicitudId)

    dataSanti.idenT_SOLICITUD = solicitudId;
    dataSanti.idenT_DOCUMENTO_BT = result.documentoId;

    let santiDocumentos = await DocumentManager.ActualizarDocumentoSanti(dataSanti)

    return {
      ...result,
      santiDocumentos
    }
  },

  ActualizarDocumentoSanti: async function (data: TMnDocumentacionDocumentoInsertRequest): Promise<TMnDocumentacionDocumentoAuditDto[]> {

    try {

      const response = await MasterManager.GetMnDocumentacionDocumentoUpdate(data);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, `Ocurrió un error al actualizar el documento en Santi.`);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      if (!response?.data?.content || response?.data?.content?.length <= 0) {
        throw new AppException(`Ocurrió un error al actualizar el documento en Santi: No se encontró el documento.`);
      }

      return response.data.content;
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, `Ocurrió un error al actualizar el documento en Santi: ${error?.message ?? error}`);
      throw new AppException(errorMsg);
    }

  },

  ActualizarDocumentoBT: async function (data: BTActualizarDocumentoDigitalRequest, santiDocumentoId: number, solicitudId: number) {
    let santiDocumento = await DocumentManager.ObtenerPrimerDocumentoWithOperacionUId(santiDocumentoId, solicitudId);
    let operacionUId = santiDocumento.idenT_OPERACION;
    let documentoBTId = santiDocumento.idenT_DOCUMENTO_BT;

    try {

      data.documentoId = documentoBTId;
      const response = await ServicesProvider.ActualizarDocumentoDigital(data, operacionUId);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, `Ocurrió un error al actualizar el documento en Bantotal.`);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      return {
        operacionUId,
        documentoId: data.documentoId
      };
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, `Ocurrió un error al actualizar el documento en Bantotal: ${error?.message ?? error}`);
      throw new AppException(errorMsg);
    }

  },

  DescargarDocumentoBT: async function (santiDocumentoId: number, solicitudId: number) {
    let santiDocumento = await DocumentManager.ObtenerPrimerDocumentoWithOperacionUId(santiDocumentoId, solicitudId);
    let operacionUId = santiDocumento.idenT_OPERACION;
    let documentoBTId = santiDocumento.idenT_DOCUMENTO_BT;

    let documento: DocumentoDigitalItem;
    try {
      const response = await ServicesProvider.ObtenerDocumentoDigital({
        "documentoId": documentoBTId
      }, operacionUId);

      let errorMsg = GeneralHelper.ObtenerExceptionError(response, `Error en la descarga de documento.`);
      if (errorMsg) {
        throw new AppException(errorMsg);
      }

      if (!response?.data?.content?.sdtDocumentosDigitales?.documento || response?.data?.content?.sdtDocumentosDigitales?.documento?.length <= 0) {
        throw new AppException(`Error en la descarga de documento: Documento no encontrado.`);
      }

      documento = response.data.content.sdtDocumentosDigitales.documento[0];
    } catch (error: any) {
      let errorMsg = GeneralHelper.ObtenerExceptionError(error?.response, `Error en la descarga de documento: ${error?.message ?? error}`);
      throw new AppException(errorMsg);
    }

    try {
      FileHelper.downloadFileFromBase64(documento.archivo, documento.nombre);
    } catch (error: any) {
      throw new AppException(`Error en la descarga de documento: ${error?.message ?? error}`);
    }

  },

};

export default DocumentManager;
