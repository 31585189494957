import { useContext, useEffect, useState } from "react";
import TitleCard from "../elements/titleCard";
import { DateInput, SelectInput, SimpleInput, SwitchInput, NumberInput, TextInput, SelectAutocompleteInput } from "../elements/inputs";
import TimeHelper from "../../../../../helpers/TimeHelper";
import { DatosPersonalesDelTitular, DatosPersonalesDelTitularDefault } from "./interfaces";
import { TitularContext } from "../../contexts";
import { appConfigKey, appConfigRegExp, appDefaultValues } from "../../../../../../config/Config";
import { useParams } from "react-router-dom";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";

const DatosPersonalesDelTitularCard = () => {

  const { solicitudId } = useParams();

  const {
    listActividadEconomica,
    listEstadoCivil,
    listTipoPersona,
    listTipoDocumento,
    listIdentidadesGenero,
    listMagnitud,
    listRangoVentaAnual,
    listGiroNegocio,
    clickNext,
    messageCustomerIsBlackListGesintel,
    readonlyForm,
    listaDomicilio,
    isTitularJuridico,
    setTitularJuridico
  } = useContext(StepsContext);

  const {
    titularData,
    setNewTitularData
  } = useContext(StepOneContext);

  const { setTitularEstadoCivilId } = useContext(TitularContext);

  const [viewModel, setViewModel] = useState<DatosPersonalesDelTitular>(DatosPersonalesDelTitularDefault);

  const eventoContinuar = () => {
    if (clickNext !== 0) {
      setNewTitularData(viewModel);
    }
  };

  const eventoCargarDatosTitular = () => {
    if (titularData !== 0) {
      setViewModel(titularData);
    }
  };

  useEffect(() => eventoContinuar(), [clickNext]);
  useEffect(() => eventoCargarDatosTitular(), [titularData]);

  return (
    <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
      <TitleCard title={viewModel.TipoDocumentoId == appConfigKey.keyIdRUC ? "DATOS EMPRESA / NEGOCIO" : "DATOS PERSONALES DEL TITULAR"} />
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-2 cardObjets">
          <DateInput
            title={"Fecha Ingreso Solicitud"}
            value={viewModel.FechaSolicitud}
            disabled={solicitudId !== undefined || readonlyForm}
            onChange={(date: any | null) => setViewModel({ ...viewModel, FechaSolicitud: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
          />
        </div>
        <div className="col-lg-2 cardObjets">
          <SelectInput
            title={"Tipo de Persona"}
            options={listTipoPersona}
            value={viewModel.TipoPersonaId}
            disabled={solicitudId !== undefined || readonlyForm}
            onChange={(event: any, newValue: any) => {
              setViewModel({ ...viewModel, TipoPersonaId: newValue })
            }}
          />
        </div>
        <div className="col-lg-2 cardObjets">
          <SelectInput
            title={"Tipo de doc. titular"}
            options={listTipoDocumento}
            value={viewModel.TipoDocumentoId}
            disabled={readonlyForm}
            onChange={(event: any, newValue: any) => {
              setViewModel({ ...viewModel, TipoDocumentoId: newValue, NumeroDocumento: appDefaultValues.StringEmpty });
            }}
          />
        </div>
        <div className="col-lg-2 cardObjets">
          {viewModel.TipoDocumentoId === appConfigKey.keyCodigoDNI ? (
            <NumberInput
              title={"Número de documento"}
              placeholder=""
              longNumber={8}
              value={viewModel.NumeroDocumento.trim()}
              disabled={readonlyForm}
              onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumento: event.target.value })}
            />
          ) : (
            <NumberInput
              title={"Número de documento"}
              placeholder=""
              value={viewModel.NumeroDocumento.trim()}
              disabled={readonlyForm}

              onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumento: event.target.value })}
            />
          )}
        </div>
        <div className="col-lg-4 cardObjets">
          <TextInput
            title={viewModel.TipoDocumentoId == appConfigKey.keyIdRUC ? "Razón social" : "Nombres"}
            placeholder=""
            regex={viewModel.TipoDocumentoId == appConfigKey.keyIdRUC ? appConfigRegExp.RazonSocial : null}
            value={viewModel.Nombres}
            disabled={readonlyForm}
            onChange={(event: any) => setViewModel({ ...viewModel, Nombres: event.target.value })}
          />
        </div>
        {viewModel.TipoDocumentoId != appConfigKey.keyIdRUC &&
          (
            <div className="col-lg-4 cardObjets">
              <TextInput
                title={"Apellido Paterno"}
                placeholder=""
                value={viewModel.ApellidoPaterno}
                disabled={readonlyForm}
                onChange={(event: any) => setViewModel({ ...viewModel, ApellidoPaterno: event.target.value })}
              />
            </div>
          )
        }

        {viewModel.TipoDocumentoId != appConfigKey.keyIdRUC &&
          (
            <div className="col-lg-4 cardObjets">
              <TextInput
                title={"Apellido Materno"}
                placeholder=""
                value={viewModel.ApellidoMaterno}
                disabled={readonlyForm}
                onChange={(event: any) => setViewModel({ ...viewModel, ApellidoMaterno: event.target.value })}
              />
            </div>
          )
        }

        {viewModel.TipoDocumentoId != appConfigKey.keyIdRUC &&
          (
            <div className="col-lg-2 cardObjets">
              <SelectInput
                title={"Estado Civil"}
                options={listEstadoCivil}
                value={viewModel.EstadoCivilId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  setViewModel({ ...viewModel, EstadoCivilId: newValue });
                  setTitularEstadoCivilId(newValue);
                }}
              />
            </div>
          )
        }

        {viewModel.TipoDocumentoId != appConfigKey.keyIdRUC &&
          (
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identGenero"}
                title={"Género"}
                value={viewModel.GeneroId?.toString().trim() === '' ? 0 : viewModel.GeneroId}
                options={listIdentidadesGenero}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, GeneroId: newValue })}
              />
            </div>
          )
        }

        {isTitularJuridico &&
          (
            <div className="col-lg-4 cardObjets">
              <SelectAutocompleteInput
                title={"Actividad económica"}
                options={listActividadEconomica}
                value={viewModel.ActividadEconomicaId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, ActividadEconomicaId: newValue })}
              />
            </div>
          )
        }

        {isTitularJuridico &&
          (
            <div className="col-lg-4 cardObjets">
              <SelectInput
                title={"Magnitud"}
                options={listMagnitud}
                value={viewModel.MagnitudId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, MagnitudId: newValue })}
              />
            </div>
          )
        }

        <div className="col-lg-2 cardObjets">
          <NumberInput
            title={"Celular"}
            placeholder=""
            value={viewModel.Celular}
            longNumber={9}
            disabled={readonlyForm}
            onChange={(event: any) => setViewModel({ ...viewModel, Celular: event.target.value })}
          />
        </div>

        {viewModel.TipoDocumentoId != appConfigKey.keyIdRUC &&
          (
            <div className="col-lg-2 cardObjets">
              <DateInput
                title={"Fecha de nacimiento"}
                value={viewModel.FechaNacimiento}
                disabled={readonlyForm}
                onChange={(date: any | null) => setViewModel({ ...viewModel, FechaNacimiento: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
              />
            </div>
          )
        }

        {viewModel.TipoDocumentoId == appConfigKey.keyIdRUC &&
          (
            <div className="col-lg-2 cardObjets">
              <DateInput
                title={"Fecha inicio actividad"}
                value={viewModel.FechaInicioActividad}
                disabled={readonlyForm}
                onChange={(date: any | null) => setViewModel({ ...viewModel, FechaInicioActividad: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
              />
            </div>
          )
        }

        <div className="col-lg-4 cardObjets">
          <SimpleInput
            title={"Correo"}
            placeholder=""
            value={viewModel.Correo}
            disabled={readonlyForm}
            required={false}
            onChange={(event: any) => setViewModel({ ...viewModel, Correo: event.target.value })}
          />
        </div>
        <div className="col-lg-4 cardObjets">
          {/*<SimpleInput
            title={"Domicilio"}
            placeholder={"Indique el domicilio"}
            value={viewModel.Domicilio}
            disabled={readonlyForm}
            onChange={(event: any) => setViewModel({ ...viewModel, Domicilio: event.target.value })}
          />*/}

          <SelectAutocompleteInput
            name={"Domicilio"}
            title={"Domicilio"}
            value={viewModel.Domicilio}
            options={listaDomicilio}
            disabled={readonlyForm}
            onChange={(event: any, newValue: any) =>
              setViewModel({ ...viewModel, Domicilio: newValue })
            }
          />
        </div>

        {isTitularJuridico &&
          (
            <div className="col-lg-4 cardObjets">
              <SelectInput
                title={"Rango de ventas anual"}
                options={listRangoVentaAnual}
                value={viewModel.RangoVentaAnualId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, RangoVentaAnualId: newValue })}
              />
            </div>
          )
        }

        {isTitularJuridico &&
          (
            <div className="col-lg-4 cardObjets">
              <SelectAutocompleteInput
                name={"Giro de negocio"}
                title={"Giro de negocio"}
                options={listGiroNegocio}
                value={viewModel.GiroNegocioId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, GiroNegocioId: newValue })}
              />
            </div>
          )
        }

        {!isTitularJuridico &&
          (
            <div className="col-lg-4 cardObjets">
              <SelectInput
                title={"Magnitud"}
                options={listMagnitud}
                value={viewModel.MagnitudId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, MagnitudId: newValue })}
              />
            </div>
          )
        }

        {!isTitularJuridico &&
          (
            <div className="col-lg-2 cardObjets">
              <SelectInput
                required={false}
                title={"Doc. Complementario"}
                options={listTipoDocumento}
                value={viewModel.DocumentoComplementarioId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, DocumentoComplementarioId: newValue })}

              />
            </div>
          )
        }

        {!isTitularJuridico &&
          (
            <div className="col-lg-2 cardObjets">
              {viewModel.DocumentoComplementarioId === appConfigKey.keyCodigoDNI ? (
                <NumberInput
                  required={false}
                  title={"Número de Documento"}
                  placeholder=""
                  longNumber={8}
                  value={viewModel.NumeroDocumentoComplementario}
                  disabled={readonlyForm}
                  onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumentoComplementario: event.target.value })}
                />
              ) : (
                <NumberInput
                  required={false}
                  title={"Número de Documento"}
                  placeholder=""
                  value={viewModel.NumeroDocumentoComplementario.trim()}
                  disabled={readonlyForm}
                  onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumentoComplementario: event.target.value })}
                />
              )}
            </div>
          )
        }
        {!isTitularJuridico &&
          (
            <div className="col-lg-4 cardObjets">
              <SelectAutocompleteInput
                title={"Actividad Económica"}
                options={listActividadEconomica}
                value={viewModel.ActividadEconomicaId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, ActividadEconomicaId: newValue })}
              />
            </div>
          )
        }
        {!isTitularJuridico &&
          (
            <div className="col-lg-4 cardObjets">
              <SwitchInput
                title={"Cliente PEP"}
                checked={viewModel?.ClientePep ?? false}
                disabled={readonlyForm}
                onChange={(event: any) => setViewModel({ ...viewModel, ClientePep: event.target.checked })}
              />
            </div>
          )
        }

        {viewModel.EstadoCivilId === appConfigKey.keyCodigoEstadoCivilCasado && (
          <div className="col-lg-4 cardObjets">
            <SwitchInput
              title={"Separación de bienes"}
              checked={viewModel.SeparacionBienes}
              disabled={readonlyForm}
              onChange={(event: any) => setViewModel({ ...viewModel, SeparacionBienes: event.target.checked })}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default DatosPersonalesDelTitularCard;
