import { useEffect, useState } from 'react';
import { Box, Button, Divider, FormControl, FormLabel, IconButton, Input, Modal, ModalDialog, Option, Sheet, Table, Typography, Select as JoySelect } from '@mui/joy';
import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout'
import CardTitleStart from '../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart'
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { BsSearch, BsThreeDots } from "react-icons/bs";
import { NewUserModal } from './components/NewUserModal';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { DialogActions, DialogContent, DialogTitle, MenuItem, Select } from '@mui/material';
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import AddIcon from '@mui/icons-material/Add';
import { MnUsuariosRequest } from '../../../models/MasterManager/usuarios-request.model';
import { General } from './obtenerServicios';
import { Toaster, toast } from "sonner";
import { MnUsuarioDto } from '../../../models/MasterManager/usuarios-response.model';
import { appConfigKey } from '../../../../config/Config';
import { Dropdown } from 'react-bootstrap';
import CustomDivToggle from '../../4.-trayOfRequests/4.30.-CustomDivToogle/CustomDivToggle';
import LoadingProgress from '../../1.-shared/1.4.-loading/loading-progress/loading-progress';
import { AppException } from '../../../models/Shared/app-exception';
import { UserModelFilter, userModelFilterDefault } from './interfaces/IUsersManagement';
import { SimpleInput } from '../../1.-shared/1.7.-component/1.7.3.-input/simpleInput';
import { SelectItem } from '../../../models/Shared/select-item.model';
import { NumberInput } from '../../1.-shared/1.7.-component/1.7.3.-input/numberInput';

export const UsersManagement = () => {

  const [filtro, setFiltro] = useState<UserModelFilter>(userModelFilterDefault);
  const [usuarios, setUsuarios] = useState<MnUsuarioDto[]>([]);
  const [selectedUsuarioId, setSelectedUsuarioId] = useState<number>(0)
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [userToEdit, setUserToEdit] = useState<MnUsuarioDto | null>(null);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [openEliminar, setOpenEliminar] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    ObtenerUsuarios();
  }, []);

  //Consumo APIS
  const ObtenerUsuarios = async () => {

    setLoading(true);
    const usuariosRequest = new MnUsuariosRequest();
    usuariosRequest.iD_TIPO_DOCUMENTO = !filtro.tipoDocumentoId ? null : filtro.tipoDocumentoId;
    usuariosRequest.nrO_DOCUMENTO = !filtro.nroDocumento ? null : filtro.nroDocumento;
    usuariosRequest.nombrE_COMPLETO = !filtro.nombreCompleto ? null : filtro.nombreCompleto;
    usuariosRequest.inD_ESTADO = !filtro.estadoId ? null : filtro.estadoId == 1;
    try {

      const content = await General.GetUsuarios(usuariosRequest);
      setLoading(false);
      setUsuarios(content);
      setPage(0);

    } catch (error: any) {
      setLoading(false);
      if (error instanceof AppException) {
        toast.error(error.message, { duration: appConfigKey.keyDurationToast });
      }
      else {
        toast.error(appConfigKey.keyMsjListarUsuariosError, { duration: appConfigKey.keyDurationToast });
      }
    }

  }

  const BorrarUsuario = async () => {
    try {
      setLoading(true);
      const content = await General.DeleteUsuario(selectedUsuarioId);
      setLoading(false);
      if (content) {
        toast.success(appConfigKey.KeyMsjEliminadoExitoso, { duration: appConfigKey.keyDurationToast });
        setSelectedUsuarioId(0);
        setOpenEliminar(false);
        ObtenerUsuarios();
      } else {
        toast.success(appConfigKey.keyMsjEliminarUsuarioError, { duration: appConfigKey.keyDurationToast });
      }

    } catch (error: any) {
      setLoading(false);
      if (error instanceof AppException) {
        toast.error(error.message, { duration: appConfigKey.keyDurationToast });
      }
      else {
        toast.error(appConfigKey.keyMsjEliminarUsuarioError, { duration: appConfigKey.keyDurationToast });
      }
    }
  }

  const handleOpenNewUser = () => {
    setUserToEdit(null);
    setOpenNewUser(true);
  }

  const handleEditUser = (user: MnUsuarioDto) => {
    setUserToEdit(user);
    setOpenNewUser(true);
  }

  //Función para filtrar la data
  const handleFilter = () => {
    ObtenerUsuarios();
  };

  const tableHeaders = ["N°", "Tipo Documento", "N° Documento", "Nombre", "Usuario", "Bloqueado", "Estado", "Acción"];

  const widthColumns = (index: number) => {
    const width = ["10%", "10%", "15%", "24%", "10%", "8%", "8%", "5%"];
    return width[index];
  };

  // variables de prueba
  const tiposDocumento: SelectItem[] = [
    { id: 171, description: 'DNI' },
    { id: 172, description: 'Carnet Extranjería' },
    { id: 173, description: 'Pasaporte' },
  ]

  const estados: SelectItem[] = [
    { id: 1, description: 'Activo' },
    { id: 2, description: 'Inactivo' },
  ]

  const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
    setRowsPerPage(parseInt(newValue!.toString(), 10));
    setPage(0);
  };

  function labelDisplayedRows({
    from,
    to,
    count,
  }: {
    from: number;
    to: number;
    count: number;
  }) {
    return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const getLabelDisplayedRowsTo = () => {
    if (usuarios.length === -1) {
      return (page + 1) * rowsPerPage;
    }
    return rowsPerPage === -1
      ? usuarios.length
      : Math.min(usuarios.length, (page + 1) * rowsPerPage);
  };

  return (
    <>
      <Toaster position="top-center" richColors closeButton />
      {loading && <LoadingProgress />}
      <MainLayout />
      <CardTitleStart Ventana={"Gestión de Usuarios"} />
      <div className="row g-2 mb-2 align-items-center ">
        <div className="col-lg-12 cardObjets bottom-right cardBtnNewRequest">
          <Button
            size="md"
            color="neutral"
            className="btnNewRequest"
            startDecorator={<AddIcon />}
            variant="outlined"
            onClick={() => handleOpenNewUser()}
            sx={{ borderRadius: 10, width: 1 / 8 }}
          >
            NUEVO USUARIO
          </Button>
        </div>
      </div>
      <NewUserModal
        open={openNewUser}
        setOpen={setOpenNewUser}
        onSuccess={() => ObtenerUsuarios()}
        tiposDocumento={tiposDocumento}
        userToEdit={userToEdit}
      />

      <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card cardContentTrayOfRequest">
        <div className="row g-5 mb-5 align-items-center">
          <div className="col-lg-3">
            <FormControl>
              <FormLabel>Tipo Documento:</FormLabel>
              <Select
                name="tipoDocumentoId"
                placeholder="Selecciona un documento"
                className={"select-input-card"}
                onChange={(event: any, _newValue: any) => setFiltro((prev: UserModelFilter) => { return { ...prev, tipoDocumentoId: event.target.value } })}
                value={filtro.tipoDocumentoId}
                IconComponent={(props: any) => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {tiposDocumento.map((data: SelectItem, index: any) => (
                  <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-3">
            <NumberInput
              name="numDocumento"
              title={"Nº Documento:"}
              placeholder={"Ingresar número"}
              required={false}
              value={filtro.nroDocumento}
              maxLength={12}
              onChange={(e: any) => setFiltro((prev: UserModelFilter) => { return { ...prev, nroDocumento: e.target.value } })}
            />
          </div>

          <div className="col-lg-3">
            <SimpleInput
              name="nombre"
              title={"Nombre:"}
              placeholder={"Ingresar nombre"}
              required={false}
              value={filtro.nombreCompleto}
              maxLength={400}
              onChange={(e: any) => setFiltro((prev: UserModelFilter) => { return { ...prev, nombreCompleto: e.target.value } })}
            />
          </div>

          <div className="col-lg-2">
            <FormControl>
              <FormLabel>Estado:</FormLabel>
              <Select
                name="estadoId"
                placeholder="Selecciona un estado"
                className={"select-input-card"}
                onChange={(event: any, _newValue: any) => setFiltro((prev: UserModelFilter) => { return { ...prev, estadoId: event.target.value } })}
                value={filtro.estadoId}
                IconComponent={(props: any) => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
              >
                <MenuItem value={0}>Seleccione</MenuItem>
                {estados.map((data: SelectItem, index: any) => (
                  <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="col-lg-1">
            <FormLabel> &nbsp;</FormLabel>
            <Button
              size="md"
              color="danger"
              sx={{
                borderRadius: 10,
              }}
              onClick={() => { handleFilter(); }}
            >
              <BsSearch />
            </Button>
          </div>
        </div>

        <div className='row mb-2'>
          <div className="col-lg-12 dialog-text-AlignCenter">
            <span className="fw-bold">LISTA DE USUARIOS</span>
          </div>
        </div>

        <div className="row g-2 mb-2">
          <div className="col-lg-12 tableContainer">
            <Sheet sx={{ overflow: 'auto' }}>
              <Table
                className="style-table"
                borderAxis="xBetween"
                aria-labelledby="tableTitle"
                hoverRow
                sx={{
                  "--TableCell-headBackground": "transparent",
                  "--TableCell-selectedBackground": (theme) =>
                    theme.vars.palette.primary.softBg,
                  "& thead th:nth-child(1)": { width: "10%" },
                  "& thead th:nth-child(2)": { width: "10%" },
                  "& thead th:nth-child(3)": { width: "15%" },
                  "& thead th:nth-child(4)": { width: "20%" },
                  "& thead th:nth-child(5)": { width: "10%" },
                  "& thead th:nth-child(6)": { width: "8%" },
                  "& thead th:nth-child(7)": { width: "8%" },
                  "& thead th:nth-child(8)": { width: "5%" },
                }}
              >
                <thead className="Tablethead">
                  <tr className="headTable">
                    {tableHeaders.map((tableheader: any, index: number) => (
                      <th
                        key={index}
                        style={{
                          width: widthColumns(index),
                          backgroundColor: "#E1E1E1",
                          borderTopLeftRadius: index === 0 ? "10px" : "auto",
                          borderTopRightRadius: index === 7 ? "10px" : "auto",
                          fontWeight: 'bold'
                        }}
                      >
                        {tableheader}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {usuarios
                    .slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    .map((data: MnUsuarioDto, index: number) => (
                      <tr key={data.idenT_USUARIO}>
                        <td>
                          <div>{(page * rowsPerPage + index) + 1}</div>
                        </td>
                        <td>
                          <div>{data.iD_TIPO_DOCUMENTO === 171 ? 'DNI' : data.iD_TIPO_DOCUMENTO === 172 ? 'PASAPORTE' : 'CARNET EXTRANJERÍA'}</div>
                        </td>
                        <td>
                          <div>{data.nrO_DOCUMENTO}</div>
                        </td>
                        <td>
                          <div className='text-uppercase'>{`${data.nombres} ${data.apellidO_PATERNO} ${data.apellidO_MATERNO}`}</div>
                        </td>
                        <td>
                          <div>{data.username}</div>
                        </td>
                        <td>
                          <div className='text-uppercase'>{data.bloqueado ? 'Si' : 'No'}</div>
                        </td>
                        <td>
                          <div className='text-uppercase'>{data.inD_ESTADO ? 'Activo' : 'Inactivo'}</div>
                        </td>
                        <td>
                          <Dropdown key={data.idenT_USUARIO}>
                            <Dropdown.Toggle
                              as={CustomDivToggle}
                              style={{ cursor: "pointer" }}
                            >
                              <BsThreeDots />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <Dropdown.Item onClick={() => handleEditUser(data)}>
                                Editar
                              </Dropdown.Item>
                              {/* <Dropdown.Item
                                onClick={() => {
                                  setSelectedUsuarioId(data.idenT_USUARIO)
                                  setOpenEliminar(true)
                                }}
                              >
                                Eliminar
                              </Dropdown.Item> */}
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td colSpan={8}>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: 2,
                          justifyContent: 'flex-end',
                        }}
                      >
                        <FormControl orientation="horizontal" size="md">
                          <FormLabel>Líneas por páginas:</FormLabel>
                          <JoySelect onChange={handleChangeRowsPerPage} value={rowsPerPage}>
                            <Option value={5}>5</Option>
                            <Option value={10}>10</Option>
                            <Option value={25}>25</Option>
                            <Option value={50}>50</Option>
                            <Option value={100}>100</Option>
                          </JoySelect>
                        </FormControl>
                        <Typography sx={{ textAlign: 'center', minWidth: 80 }}>
                          {labelDisplayedRows({
                            from: usuarios.length === 0 ? 0 : page * rowsPerPage + 1,
                            to: getLabelDisplayedRowsTo(),
                            count: usuarios.length === -1 ? -1 : usuarios.length,
                          })}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                          <IconButton
                            size="sm"
                            color="neutral"
                            variant="outlined"
                            disabled={page === 0}
                            onClick={() => handleChangePage(page - 1)}
                            sx={{ bgcolor: 'background.surface' }}
                          >
                            <KeyboardArrowLeftIcon />
                          </IconButton>
                          <IconButton
                            size="sm"
                            color="neutral"
                            variant="outlined"
                            disabled={
                              usuarios.length !== -1
                                ? page >= Math.ceil(usuarios.length / rowsPerPage) - 1
                                : false
                            }
                            onClick={() => handleChangePage(page + 1)}
                            sx={{ bgcolor: 'background.surface' }}
                          >
                            <KeyboardArrowRightIcon />
                          </IconButton>
                        </Box>
                      </Box>
                    </td>
                  </tr>
                </tfoot>
              </Table >
            </Sheet>
          </div>
        </div>
        {/* MODAL CONFIRMACION */}
        {/* <Modal open={openEliminar} onClose={() => {
          setSelectedUsuarioId(0);
          setOpenEliminar(false);
        }}>
          <ModalDialog variant="outlined" role="alertdialog">
            <DialogTitle>
              <WarningRoundedIcon />
              Confirmación
            </DialogTitle>
            <Divider />
            <DialogContent>
              ¿Esta seguro de eliminar este usuario?
            </DialogContent>
            <DialogActions>
              <Button variant="solid" color="danger" onClick={() => BorrarUsuario()}>
                Eliminar
              </Button>
              <Button variant="plain" color="neutral" onClick={() => {
                setSelectedUsuarioId(0);
                setOpenEliminar(false);
              }}>
                Cancelar
              </Button>
            </DialogActions>
          </ModalDialog>
        </Modal> */}
      </div>
    </>
  )
}