import { Table } from "@mui/joy";
import { useParams } from "react-router-dom";
import MasterManager from "../../../services/origination/wapimaster.service";
import { useEffect, useState } from "react";
import TextWrapButton from "../../1.-shared/1.7.-component/1.7.1.-textButton/textWrapButton";
import CommentModal from "../../1.-shared/1.7.-component/1.7.2.-modal/commentModal";

const ResultadosHistorial = ({ flgResultadosHistorial, selSolicitud }: any) => {
  const { solicitudId } = useParams();
  const [resultadosHistorial, setResultadosHistorial] = useState([]);
  const [openObservationDetailModal, setOpenObservationDetailModal] = useState(false);
  const [rowSelected, setRowSelected] = useState<any>(null);

  const obtenerListHistorialResultadoAnalisis = async () => {
    await MasterManager.GetMnResultadoEvaluacion(selSolicitud || Number(solicitudId))
      .then((response: any) => {
        setResultadosHistorial(response.data.content)
      })
      .catch(error => {
        //myowasp(error.message);
      })
  };

  const handleVerMas = (row: any) => {
    setRowSelected(row);
    setOpenObservationDetailModal(true);
  }

  const handleCloseObservationDetailModal = () => {
    setOpenObservationDetailModal(false);
    setRowSelected(null);
  }

  useEffect(() => {
    obtenerListHistorialResultadoAnalisis();
  }, [flgResultadosHistorial])


  return (
    <div className="rounded-9 p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">HISTORIAL RESULTADO ANÁLISIS</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-12 tableContainer-resultado align-top table-manager-profile-hist">
          <Table borderAxis="none" className="style-table align-top">
            <thead>
              <tr>
                <th style={{ width: "150px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>FECHA/HORA</th>
                <th style={{ width: "100px", backgroundColor: "#E1E1E1", }}>USUARIO</th>
                <th style={{ width: "280px", backgroundColor: "#E1E1E1", }}>ETAPA</th>
                <th style={{ width: "180px", backgroundColor: "#E1E1E1", }}>DECISIÓN</th>
                <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>TIPO DECISIÓN</th>
                <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
              </tr>
            </thead>
            <tbody>
              {
                resultadosHistorial && resultadosHistorial.map((row: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.fechahora}</td>
                      <td> {row.usuario}</td>
                      <td> {row.etapA_RESULTADO}</td>
                      <td> {row.resultado}</td>
                      <td> {
                        row.etapA_RESULTADO !== "EVALUACION CREDITICIA - MOTOR" && row.tipO_RESULTADO
                      }</td>
                      <td> <TextWrapButton text={row.comentario} limit={100} onClick={() => handleVerMas(row)} /></td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
          <CommentModal
            comentario={rowSelected?.comentario}
            open={openObservationDetailModal}
            handleClose={handleCloseObservationDetailModal}
          />
        </div>
      </div>
    </div>
  );
};

export default ResultadosHistorial;