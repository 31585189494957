import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../../../3.10.-base/providers/StepsProvider";
import TitleCard from "../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput } from "../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { DatosLaboralesAdicionalesTitularInterface, DatosLaboralesAdicionalesTitularInterfaceDefault } from "./interfaces";
import { StepFiveContext } from "../../../3.10.-base/providers";

const DatosLaboralesAdicionalesTitularCard = () => {

    const {
        listActividadEconomica,
        listPais,
        listProfesion,
        clickNext,
        step,
        viewModelStepOneFinal,
        readonlyForm,
        viewModelStepFiveFinal
    } = useContext(StepsContext);

    const {
        setNewDatosLaboralesAdicionalesTitularData,
        listaProvinciasDepartamentos,
        listaCiudadesLocalidades,
        listaBarriosColonias,
        getObtenerDepartamentos,
        getObtenerCiudadesLocalidades,
        getObtenerBarriosColonias
    } = useContext(StepFiveContext);

    const [viewModel, setViewModel] = useState<DatosLaboralesAdicionalesTitularInterface>(DatosLaboralesAdicionalesTitularInterfaceDefault);

    useEffect(() => {
        if (viewModelStepOneFinal !== null && viewModelStepFiveFinal === null) {

            setViewModel({
                ...viewModel,
                identActividadEconomica: viewModelStepOneFinal.newTitularData.ActividadEconomicaId,
                identCargoLaboral: viewModelStepOneFinal.newTitularData.CargoLaboralId,
                descripcionCargo: viewModelStepOneFinal.newTitularData.CargoLaboral,
                identPais: viewModelStepOneFinal.newLaboresAdicionalesTitularData.PaisId,
                nombreVia: viewModelStepOneFinal.newLaboresAdicionalesTitularData.NombreVia,
                numeroVia: viewModelStepOneFinal.newLaboresAdicionalesTitularData.NumeroVia,
                intDptoPiso: viewModelStepOneFinal.newLaboresAdicionalesTitularData.IntDptoPiso,
                manzana: viewModelStepOneFinal.newLaboresAdicionalesTitularData.Manzana,
                lote: viewModelStepOneFinal.newLaboresAdicionalesTitularData.Lote,
                nombreAgrupacion: viewModelStepOneFinal.newLaboresAdicionalesTitularData.NombreAgrupacion,
                referencia: viewModelStepOneFinal.newLaboresAdicionalesTitularData.Referencia,
                telefono: viewModelStepOneFinal.newLaboresAdicionalesTitularData.Telefono,
                anexo: viewModelStepOneFinal.newLaboresAdicionalesTitularData.Anexo,
                correo: viewModelStepOneFinal.newLaboresAdicionalesTitularData.Correo,
                identDistritos: viewModelStepOneFinal.newLaboresAdicionalesTitularData.DistritoId,
                identProvincias: viewModelStepOneFinal.newLaboresAdicionalesTitularData.ProvinviaId,
                identDepartamentos: viewModelStepOneFinal.newLaboresAdicionalesTitularData.DepartamentoId
            });

            if (viewModelStepOneFinal.newLaboresAdicionalesTitularData.PaisId > 0) {
                getObtenerDepartamentos(viewModelStepOneFinal.newLaboresAdicionalesTitularData.PaisId)
                if (viewModelStepOneFinal.newLaboresAdicionalesTitularData.DepartamentoId > 0) {
                    getObtenerCiudadesLocalidades(viewModelStepOneFinal.newLaboresAdicionalesTitularData.PaisId,
                        viewModelStepOneFinal.newLaboresAdicionalesTitularData.DepartamentoId)
                    if (viewModelStepOneFinal.newLaboresAdicionalesTitularData.ProvinviaId > 0) {
                        getObtenerBarriosColonias(viewModelStepOneFinal.newLaboresAdicionalesTitularData.PaisId,
                            viewModelStepOneFinal.newLaboresAdicionalesTitularData.DepartamentoId,
                            viewModelStepOneFinal.newLaboresAdicionalesTitularData.ProvinviaId)
                    }
                }
            }

        }
    }, [viewModelStepOneFinal]);

    useEffect(() => {

        if (viewModelStepFiveFinal !== null) {

            const { newDatosLaboralesAdicionalesTitularData } = viewModelStepFiveFinal;

            if (newDatosLaboralesAdicionalesTitularData.identPais > 0) {
                
                getObtenerDepartamentos(newDatosLaboralesAdicionalesTitularData.identPais);

                if (newDatosLaboralesAdicionalesTitularData.identDepartamentos > 0) {
                    
                    getObtenerCiudadesLocalidades(
                        newDatosLaboralesAdicionalesTitularData.identPais,
                        newDatosLaboralesAdicionalesTitularData.identDepartamentos
                    );

                    if (newDatosLaboralesAdicionalesTitularData.identProvincias > 0) {

                        getObtenerBarriosColonias(
                            newDatosLaboralesAdicionalesTitularData.identPais,
                            newDatosLaboralesAdicionalesTitularData.identDepartamentos,
                            newDatosLaboralesAdicionalesTitularData.identProvincias
                        );

                    }
                }
            }

            setViewModel(newDatosLaboralesAdicionalesTitularData);
        }

    }, [viewModelStepFiveFinal]);

    useEffect(() => {
        if (clickNext !== 0 && step === 5) {
            setNewDatosLaboralesAdicionalesTitularData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS LABORALES DEL TITULAR"} obligatorio={false} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        name={"identActividadEconomica"}
                        title={"Actividad económica"}
                        options={listActividadEconomica}
                        value={viewModel.identActividadEconomica}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identActividadEconomica: newValue })}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        name={"identPais"}
                        title={"País"}
                        options={listPais}
                        value={viewModel.identPais}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identPais: newValue,
                                identDepartamentos: 0,
                                identProvincias: 0,
                                identDistritos: 0
                            })
                            getObtenerDepartamentos(newValue);
                        }}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identDepartamentos"}
                        title={"Departamento"}
                        options={listaProvinciasDepartamentos}
                        value={viewModel.identDepartamentos}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel, identDepartamentos: newValue,
                                identProvincias: 0,
                                identDistritos: 0
                            })
                            getObtenerCiudadesLocalidades(viewModel.identPais, newValue);
                        }}
                        required={false}
                    />
                </div>

            </div>

            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identProvincias"}
                        title={"Provincia"}
                        options={listaCiudadesLocalidades}
                        value={viewModel.identProvincias}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel, identProvincias: newValue,
                                identDistritos: 0
                            })
                            getObtenerBarriosColonias(viewModel.identPais, viewModel.identDepartamentos, newValue);
                        }}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identDistritos"}
                        title={"Distrito"}
                        options={listaBarriosColonias}
                        value={viewModel.identDistritos}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, identDistritos: newValue })
                        }}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreVia"}
                        title={"Nombre de vía"}
                        placeholder=""
                        value={viewModel.nombreVia}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, nombreVia: event.target.value })}
                        required={false}
                    />
                </div>

            </div>

            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"numeroVia"}
                        title={"Número"}
                        placeholder=""
                        value={viewModel.numeroVia}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, numeroVia: event.target.value })}
                        required={false}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"intDptoPiso"}
                        title={"Int/Dpto/Piso"}
                        maxLength={15}
                        placeholder=""
                        value={viewModel.intDptoPiso}
                        disabled={readonlyForm}
                        required={false}
                        onChange={(event: any) => setViewModel({ ...viewModel, intDptoPiso: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"manzana"}
                        title={"Manzana"}
                        maxLength={15}
                        placeholder=""
                        value={viewModel.manzana}
                        disabled={readonlyForm}
                        required={false}
                        onChange={(event: any) => setViewModel({ ...viewModel, manzana: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"lote"}
                        title={"Lote"}
                        placeholder=""
                        value={viewModel.lote}
                        disabled={readonlyForm}
                        required={false}
                        onChange={(event: any) => setViewModel({ ...viewModel, lote: event.target.value })}
                        maxLength={15}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreAgrupacion"}
                        title={"Nombre agrupación"}
                        required={false}
                        placeholder=""
                        value={viewModel.nombreAgrupacion}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, nombreAgrupacion: event.target.value })}
                    />
                </div>

            </div>

            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"referencia"}
                        title={"Referencia"}
                        placeholder=""
                        value={viewModel.referencia}
                        disabled={readonlyForm}
                        required={false}
                        onChange={(event: any) => setViewModel({ ...viewModel, referencia: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"telefono"}
                        title={"Teléfono"}
                        placeholder=""
                        value={viewModel.telefono}
                        disabled={readonlyForm}
                        longNumber={9}
                        onChange={(event: any) => setViewModel({ ...viewModel, telefono: event.target.value })}
                        required={false}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"anexo"}
                        title={"Anexo"}
                        placeholder=""
                        value={viewModel.anexo}
                        disabled={readonlyForm}
                        longNumber={9}
                        required={false}
                        onChange={(event: any) => setViewModel({ ...viewModel, anexo: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"correo"}
                        title={"Correo"}
                        placeholder=""
                        value={viewModel.correo}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, correo: event.target.value })}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identCargoLaboral"}
                        title={"Cargo laboral"}
                        options={listProfesion}
                        value={viewModel.identCargoLaboral}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identCargoLaboral: newValue })}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"descripcionCargo"}
                        title={"Descripción cargo"}
                        placeholder=""
                        value={viewModel.descripcionCargo}
                        disabled={readonlyForm}
                        required={false}
                        onChange={(event: any) => setViewModel({ ...viewModel, descripcionCargo: event.target.value })}
                    />
                </div>
            </div>
        </div>
    );
};

export default DatosLaboralesAdicionalesTitularCard;