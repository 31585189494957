export class BTAsociarDocumentoDigitalRequest {
    tipoDocumentoId: number = 0;
    nombre: string = '';
    archivoCodificado: string = '';
    fechaVencimiento: string = '';
    fechaEmision: string = '';
}

export class BTActualizarDocumentoDigitalRequest {
    documentoId: number = 0;
    nombre?: string | null;
    archivoCodificado?: string | null;
    fechaVencimiento?: string | null;
    fechaEmision?: string | null;
}

export class BTObtenerDocumentoDigitalRequest {
    documentoId: number = 0;
}