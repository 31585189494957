import { Box, FormControl, FormLabel, Input, Switch, Tooltip, Typography } from "@mui/joy";
import { Select, MenuItem, Autocomplete, TextField, AutocompleteCloseReason, AutocompleteInputChangeReason, AutocompleteChangeReason } from '@mui/material';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import './styles.scss';
import GeneralHelper from "../../../../../../helpers/GeneralHelper";
import icoQuestion from '../../../../../../../assets/media/icons/ico_question.svg';
import { useState } from "react";
import { appConfigRegExp } from "../../../../../../../config/Config";

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const SelectAutocompleteInput = (props: any) => {
    var [showToolTip, setShowToolTip] = useState(false);
    const handlerButtonClick = (e: any) => {
        setShowToolTip(!showToolTip);

        //e.target.open=true;
    }

    const isOptionEqualToValue = (option: any, value: number | null) => {
        return option.id === value;
    };

    const defaultOption = { id: 0, label: 'Seleccione' };
    const optionsWithDefault = [defaultOption, ...props?.options ?? []];
    const selectedOption = optionsWithDefault.find((option: any) => option.id === props.value) ?? null;

    let lastValue = selectedOption;

    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>}
                    {props.tooltip !== undefined && (<Tooltip sx={{ borderColor: '#0089B5' }} open={showToolTip} disableInteractive={true} title={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: 250,
                                justifyContent: 'center',
                                p: 1
                            }}
                        >
                            {props.tooltip}
                        </Box>}
                        variant="outlined"
                        placement="top-start"
                        size="sm">
                        <button className="circle-btn-tooltip" onClick={handlerButtonClick} >
                            <img src={icoQuestion} />
                        </button>
                    </Tooltip>)}
                </FormLabel>
            )}

            <Autocomplete
                disablePortal
                options={optionsWithDefault}
                value={selectedOption}
                clearText=""
                openText=""
                onChange={(event: any, newValue: any, reason: AutocompleteChangeReason, details: any) => {
                    if (reason === 'clear') return;
                    lastValue = newValue?.id ?? null
                    props.onChange(event, lastValue);
                }}
                onInputChange={(event: any, newValue: any, reason: AutocompleteInputChangeReason) => {
                    if (reason === 'clear') {
                        lastValue = defaultOption.id
                        props.onChange(event, lastValue);
                        return
                    } else if (reason === 'input') {
                        if (newValue === '') {
                            lastValue = null
                        }
                    }
                }}
                onClose={(event: any, reason: AutocompleteCloseReason) => {
                    if (!lastValue) {
                        props.onChange(event, defaultOption.id);
                    }
                }}
                disabled={props.disabled}
                getOptionLabel={(option: any) => {
                    return option.label
                }}
                // isOptionEqualToValue={isOptionEqualToValue}
                renderInput={(params) => <TextField {...params} label="" />
                }
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option?.label}
                        </li>
                    );
                }}
                popupIcon={(<KeyboardArrowDown />)}
                noOptionsText="No hay opciones disponibles"
            />
        </FormControl>
    );
};

