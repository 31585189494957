import { FormControl, FormLabel, Input } from "@mui/joy";
import './index.scss'

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const SimpleInput = (props: any) => {
    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        if (props.regex) {
            if (!props.regex.test(value)) {
                e.preventDefault();
                return;
            }
        }

        if (props.onChange) {
            props.onChange(e);
        }
    };

    return (
        <FormControl className={props.className}>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={handleOnChange}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
                slotProps={{
                    input: {
                        minLength: props.minLength,
                        maxLength: props.maxLength
                    }
                }}

            />
        </FormControl>
    );
};